import { FieldError } from '@spike/model';

export enum ServiceCategoryType {
    CATEGORY,
    DETAIL,
    PRICING,
    STAFF
}

export interface FieldErrorWithKey extends FieldError {
    key: number;
    id?: string;
}
