import React, { FunctionComponent, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { MarketplaceTax } from '@spike/marketplace-model';
import {
	Button,
	TextField2,
	PercentageField2,
	ConfirmDialog
} from 'components/UI';
import { useNonInitialEffect } from '@versiondos/hooks';
import { MarketplaceStatus } from '@spike/marketplace-action';
import { BusinessSettingsTitle } from '../UI';
import { reduceResolution, wbp } from 'Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { showError, showWarning } from '@spike/notifications-action';
import { useMarketplace } from 'hooks';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export interface Props {
	taxes: Array<MarketplaceTax>;
	onSave?: (taxes: Array<MarketplaceTax>) => void;
	onContinue?: (taxes: Array<MarketplaceTax>) => void;
	onChange?: (taxes: Array<MarketplaceTax>) => void;
	className?: string;
	openSidebar?: () => void;
	isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(565)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '565px'
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				height: '100%'
			}
		},
		titleContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			marginBottom: '-20px'
		},
		businessSettingsTitle: {
			'display': 'flex',
			'alignItems': 'center',
			'& $iconBusinessTitle': {
				fontSize: '16px'
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '20px',
				marginBottom: '5px'
			}
		},
		iconBusinessTitle: {
			position: 'relative',
			marginRight: '8px'
		},
		subtitle: {
			width: '100%',
			fontSize: `$16px`,
			[theme.breakpoints.down(wbp)]: {
				lineHeight: `${reduceResolution(35)}px`,
				marginBottom: `${reduceResolution(10)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				lineHeight: '42px',
				marginBottom: '10px'
			}
		},

		taxContainer: {
			display: 'flex',
			width: '100%',
			[theme.breakpoints.down(wbp)]: {
				marginTop: `${reduceResolution(44)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '44px'
			}
		},
		taxContainerFirst: {
			display: 'flex',
			width: '100%',
		},
		taxName: {
			display: 'flex',
			width: '65%',
			color: '#D3D3D3',

			[theme.breakpoints.down(wbp)]: {
				paddingRight: `${reduceResolution(19)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				paddingRight: '19px'
			}
		},
		taxNameField: {
			'& input': {
				[theme.breakpoints.down(wbp)]: {
					paddingLeft: `${reduceResolution(30)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					paddingLeft: '30px'
				}
			},
			'& .MuiOutlinedInput-root': {
				[theme.breakpoints.down(wbp)]: {
					borderRadius: `${reduceResolution(33)}px`
				},
				[theme.breakpoints.up(wbp)]: {
					borderRadius: '33px'
				},
				'& .MuiOutlinedInput-input:-webkit-autofill': {}
			},
			'& fieldset': {
				border: '1px #D3D3D3 solid',
				borderColor: '#D3D3D3 !important'
			},
			'& input.focus-visible ~ fieldset': {
				border: '1px solid #222222 !important'
			}
		},
		taxValue: {
			display: 'flex',
			width: '22.5%',
			[theme.breakpoints.down('sm')]: {
				width: '37%'
			}
		},
		taxValueField: {
			'textAlign': 'right',
			'& .MuiOutlinedInput-adornedEnd': {
				'& .MuiInputBase-input': {
					fontWeight: 500
				},
				'& p': {
					color: '#7A7A7A'
				},
				'& input': {
					[theme.breakpoints.down(wbp)]: {
						paddingLeft: `${reduceResolution(30)}px`
					},
					[theme.breakpoints.up(wbp)]: {
						paddingLeft: '30px'
					}
				},
				'& fieldset': {
					border: '1px #D3D3D3 solid',
					borderColor: '#D3D3D3 !important'
				},
				'& input.focus-visible ~ fieldset': {
					border: '1px solid #222222 !important'
				}
			}
		},
		trashIconBox: {
			display: 'flex',
			width: '10%'
		},
		trashIcon: {
			fontSize: `$16px`,
			cursor: 'pointer',
			color: 'black',
			opacity: 1,
			marginTop: 'auto',
			marginBottom: 'auto',
			paddingLeft: '18px',
			paddingTop: '20px'
		},

		trashIconDisabled: {
			cursor: 'default',
			opacity: 0.7
		},

		addOtherContainer: {
			marginTop: '40px',
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(600)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				width: '600px'
			}
		},

		addOther: {
			fontWeight: 600,
			cursor: 'pointer',
			fontSize: `$16px`,
			[theme.breakpoints.down(wbp)]: {
				lineHeight: `${reduceResolution(30)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				lineHeight: '30px'
			},
			[theme.breakpoints.down('sm')]: {
				color: '#92B4A7'
			}
		},
		addOtherDisabled: {
			cursor: 'default',
			color: '#666666'
		},
		buttonContainer: {
			display: 'flex',
			width: '100%',

			[theme.breakpoints.down(wbp)]: {
				marginTop: `${reduceResolution(28)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: '28px'
			},
			[theme.breakpoints.down('sm')]: {
				position: 'absolute',
				bottom: 0
			}
		},
		right: {
			justifyContent: 'flex-end'
		},
		lineDivider: {
			borderWidth: '1px',
			width: 'inherit',
			marginTop: '18px',
			marginBottom: '-25px',
			borderColor: '#efefef69',
			[theme.breakpoints.up('md')]: {
				visibility: 'hidden'
			}
		},
		button: {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				height: '55px',
				borderRadius: '30px',
				marginRight: '30px',
				marginBottom: '20px'
			}
		}
	})
);

export const Taxes: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const marketplace = useMarketplace();
	const loading = useSelector<RootState, boolean>(
		state => state.marketplace.loading
	);
	const status = useSelector<RootState, MarketplaceStatus>(
		state => state.marketplace.status
	);
	const [saved, setSaved] = useState(false);
	const [dropDownIsActive, setDropDownIsActive] = useState(false);
	const [taxSelected, setTaxSelected] = useState('');

	const storeTaxes = useSelector<RootState, Array<MarketplaceTax>>(
		state => state.marketplace.marketplace.taxes
	);
	const [taxes, setTaxes] = useState([...marketplace.taxes]);

	const dispatch = useDispatch();

	useNonInitialEffect(() => {
		props.onChange && props.onChange(taxes);
	}, [taxes]);

	useNonInitialEffect(() => {
		if (status === MarketplaceStatus.SaveSuccess) {
			setSaved(true);
			refreshTaxes();
		}
	}, [status]);

	const changeTaxNameHandler = (value: string, uuid: string) => {
		let index = returnTaxIndex(uuid);
		let taxOthers = [...taxes];
		taxOthers[index].name = value;
		setTaxes(taxOthers);
		setSaved(false);
	};

	const changeTaxValueHandler = (value: number, uuid: string) => {
		let index = returnTaxIndex(uuid);
		let taxOthers = [...taxes];
		taxOthers[index].percentage = value;
		setTaxes(taxOthers);
		setSaved(false);
	};

	const refreshTaxes = () => {
		let taxOthers = [...taxes];
		let taxMapped = taxOthers.map(tax => ({
			id: returnTaxId(tax.uuid),
			uuid: tax.uuid,
			name: tax.name,
			percentage: tax.percentage
		}));
		setTaxes(taxMapped);
	};

	const handleAddOther = () => {
		if (!loading) {
			let taxUuid = uuid();
			taxes.push({ name: 'My Tax', percentage: 5, uuid: taxUuid });
			const newOthers = [...taxes];
			setTaxes(newOthers);
			setSaved(false);
		}
	};

	const handleRemoveRequest = (uuid: string) => {
		if (!loading) {
			setDropDownIsActive(true);
			setTaxSelected(uuid);
			setSaved(false);
		} else {
			dispatch(showWarning('Please wait until the update is completed'));
		}
	};

	const handleCancelRemove = () => {
		setDropDownIsActive(false);
	};

	const handleRemoveOther = (uuid: string) => {
		let index = returnTaxIndex(uuid);
		let taxOthers = [...taxes];
		let taxIndexed = taxOthers[index];
		let taxOthersSplice = taxOthers;
		taxOthersSplice = taxOthersSplice.splice(index, 1);
		setTaxes(taxOthers);
		setDropDownIsActive(false);
		if (taxIndexed && taxIndexed['id']) {
			props.onSave && props.onSave([...taxOthers]);
		}
	};

	const returnTaxIndex = (uuid: string) => {
		const taxIndex = taxes.map(tax => tax.uuid).indexOf(uuid);
		return taxIndex;
	};

	const returnTaxId = (uuid: string) => {
		const taxId = storeTaxes.find(tax => tax.uuid === uuid)?.id;
		return taxId;
	};

	const areFieldsValid = () => {
		const taxesArr = taxes.map(function (tax) {
			return tax.name;
		});
		const isDuplicate = taxesArr.some(function (tax, idx) {
			return taxesArr.indexOf(tax) != idx;
		});

		return !isDuplicate;
	};

	const clickButtonHandler = () => {
		if (areFieldsValid() == true) {
			props.onSave && props.onSave([...taxes]);
			props.onContinue && props.onContinue([...taxes]);
		} else {
			dispatch(showError('One or more taxes have the same name'));
		}
	};

	const button = (
		<Button
			id="business_settings_taxes_button_save"
			label={props.onSave ? (saved ? 'Saved!' : 'Save') : 'Continue'}
			onClick={clickButtonHandler}
			loading={loading}
			className={classes.button}
		/>
	);

	return (
		<Box className={clsx(classes.container, props.className)}>
			<Box className={classes.titleContainer}>
				<BusinessSettingsTitle
					className={classes.businessSettingsTitle}
					subtitle={'Add the taxes you wish to collect on products and services.'}
				>
					{' '}
					{props.isMobile && (
						<FontAwesomeIcon
							className={classes.iconBusinessTitle}
							onClick={props.openSidebar}
							icon={faArrowLeft}
							size="2x"
						/>
					)}{' '}
					Taxes
					
				</BusinessSettingsTitle>
			</Box>

			{taxes.map((tax, index) => {
				return (
					<Box key={tax.uuid}>
						<Typography className={classes.subtitle}></Typography>

						<Box
							id={
								'business_settings_taxes_div_taxcontainer_' +
								index
							}
							className={index === 0 ? classes.taxContainerFirst : classes.taxContainer}
						>
							<Box className={classes.taxName}>
								<TextField2
									id={
										'business_settings_taxes_input_tax_name_' +
										index
									}
									className={classes.taxNameField}
									label={'Name'}
									name="tax.name"
									placeholder="Tax Name"
									value={tax.name}
									autoFocus={tax.id ? false : true}
									onChange={value =>
										changeTaxNameHandler(value, tax.uuid)
									}
									required={true}
								/>
							</Box>
							<Box className={classes.taxValue}>
								<PercentageField2
									id={
										'business_settings_taxes_input_tax_value_' +
										index
									}
									className={classes.taxValueField}
									label={'Tax'}
									name="tax.percentage"
									value={tax.percentage}
									scale={2}
									precision={4}
									onChange={value =>
										changeTaxValueHandler(
											value || 0,
											tax.uuid
										)
									}
								/>
							</Box>

							<Box className={classes.trashIconBox}>
								<FontAwesomeIcon
									id={
										'business_settings_taxes_icon_delete_' +
										index
									}
									icon={faTrashAlt}
									className={
										!loading
											? classes.trashIcon
											: clsx(
													classes.trashIcon,
													classes.trashIconDisabled
											  )
									}
									onClick={() =>
										handleRemoveRequest(tax.uuid)
									}
								/>
							</Box>

							{dropDownIsActive === true &&
								taxSelected === tax.uuid && (
									<ConfirmDialog
										open={true}
										title={
											<Typography>
												Delete {tax.name}
											</Typography>
										}
										question={
											<Typography>
												Do you want to{' '}
												<strong>delete</strong> this
												Tax?
											</Typography>
										}
										cancelButtonLabel="Cancel"
										confirmButtonLabel="Delete"
										onCancel={handleCancelRemove}
										onConfirm={() =>
											handleRemoveOther(tax.uuid)
										}
										processing={loading}
									/>
								)}
						</Box>
						{taxes.length > 1 && (
							<hr className={classes.lineDivider} />
						)}
					</Box>
				);
			})}

			<Box className={classes.addOtherContainer}>
				<Typography
					id="business_settings_taxes_button_add_other"
					className={
						!loading
							? classes.addOther
							: clsx(classes.addOther, classes.addOtherDisabled)
					}
					onClick={handleAddOther}
					noWrap={true}
				>
					+ Add other
				</Typography>
			</Box>

			<Box
				className={clsx(classes.buttonContainer, {
					[classes.right]: props.onContinue
				})}
			>
				{button}
			</Box>
		</Box>
	);
};

export default Taxes;
