import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Service, {
	FixedPriceOption,
	StaffMember,
	VariableDuration,
	VariablePrice,
} from "../../model/Service";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import NumberFormat from "react-number-format";
import { StaffChip } from "components/UI";
import { reduceResolution, wbp } from "Theme";
import moment from "moment";
import {petSizeVariable} from "../Service/Pricing/NewVariablePrice/NewVariablePrice";
import {useMarketplace} from "../../hooks";

interface Props {
	service: Service;
	staff: Array<StaffMember>;
	variablePrice?: VariablePrice;
	fixedPrice: Array<FixedPriceOption>;
	variableDuration: Array<VariableDuration>;
	showStaff: boolean;
	showVariablePrices: boolean;
	showFixedPrices: boolean;
	showDuration: boolean;
	showTaxes: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "flex-start",
			[theme.breakpoints.down(wbp)]: {
				marginTop: `${reduceResolution(30)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: "30px",
			},
		},
		header: {
			display: "flex",
			borderBottom: "1px solid #D3D3D3",
			[theme.breakpoints.down(wbp)]: {
				paddingBottom: `${reduceResolution(10)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				paddingBottom: "10px",
			},
		},
		cell: {
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(105)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				width: "105px",
			},
		},
		cellStaff: {
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(120)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				width: "120px",
			},
		},
		headerText: {
			fontWeight: 500,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(14)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: "14px",
			},
		},
		cellHairLength: {
			[theme.breakpoints.down(wbp)]: {
				width: `${reduceResolution(120)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				width: "120px",
			},
		},
		text: {
			fontWeight: 400,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(14)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: "14px",
			},
		},
		row: {
			display: "flex",
		},
		rowsContainer: {
			display: "flex",
			flexDirection: "column",
			[theme.breakpoints.down(wbp)]: {
				marginTop: `${reduceResolution(3)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				marginTop: "4px",
			},
		},
		emptyHeader: {
			display: "flex",
			[theme.breakpoints.down("lg")]: {
				width: "21%",
			},
			[theme.breakpoints.up("xl")]: {
				width: "21%",
			},
		},
		empty: {
			display: "flex",
			[theme.breakpoints.down("lg")]: {
				width: "21%",
			},
			[theme.breakpoints.up("xl")]: {
				width: "21%",
			},
		},
		staffHeader: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			textAlign: "center",
			[theme.breakpoints.down("lg")]: {
				width: "19%",
			},
			[theme.breakpoints.up("xl")]: {
				width: "19%",
			},
		},
		staff: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			[theme.breakpoints.down("lg")]: {
				width: "19%",
			},
			[theme.breakpoints.up("xl")]: {
				width: "19%",
			},
		},
		duration: {
			display: "flex",
			flexDirection: "column",
			width: "20%",
		},
		price: {
			display: "flex",
			flexDirection: "column",
			width: "30%",
		},
		fixedPriceType: {
			fontWeight: 500,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(12)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: "12px",
			},
		},
		fixedPrice: {
			textAlign: "left",
			fontWeight: 700,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(12)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: "12px",
			},
		},
		taxesHeader: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			[theme.breakpoints.down("lg")]: {
				width: "10%",
			},
			[theme.breakpoints.up("xl")]: {
				width: "10%",
			},
		},
		taxes: {
			display: "flex",
			flexDirection: "column",
			width: "10%",
		},
		undefined: {
			color: "red",
		},
		expandedStaff: {
			display: "flex",
			flexDirection: "column",
			"& .MuiChip-root": {
				background: "inherit",
				[theme.breakpoints.down(wbp)]: {
					display: "flex",
					justifyContent: "left",
					paddingLeft: "0px",
					marginTop: `${reduceResolution(12)}px`,
				},
				[theme.breakpoints.up(wbp)]: {
					display: "flex",
					justifyContent: "left",
					paddingLeft: "0px",
					marginTop: "12px",
				},
			},
		},
		bold: {
			fontWeight: 600,
		},
		taxContainer: {
			display: "flex",
		},
		taxNameContainer: {
			width: "65%",
		},
		taxName: {
			fontWeight: 500,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(12)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: "12px",
			},
		},
		taxPercentageContainer: {
			width: "35%",
		},
		taxPercentage: {
			fontWeight: 300,
			[theme.breakpoints.down(wbp)]: {
				fontSize: `${reduceResolution(12)}px`,
			},
			[theme.breakpoints.up(wbp)]: {
				fontSize: "12px",
			},
			color: "#7A7A7A !important",
		},
	})
);

export const TableDetailService: FunctionComponent<Props> = (props) => {
	const classes = useStyles();

	let petType = [];
	let petSize = [];
	let petHairType = [];
	let petHairLength = [];
	const marketplace = useMarketplace();
	const dogsSizesArr = petSizeVariable ? marketplace.petTypes.dogs?.sizes! : [undefined];

	if (props.variablePrice !== undefined) {
		petType = props.variablePrice.prices.filter(
			(variablePrice) => variablePrice.petType !== undefined
		);
		petSize = props.variablePrice.prices.filter(
			(variablePrice) => variablePrice.petSize !== undefined
		);
		petHairType = props.variablePrice.prices.filter(
			(variablePrice) => variablePrice.hairType !== undefined
		);
		petHairLength = props.variablePrice.prices.filter(
			(variablePrice) => variablePrice.hairLength !== undefined
		);
	}

	const formatDuration = (
		hours: number | undefined,
		minutes: number | undefined
	) => {
		const time: Array<string | number> = [];

		if (hours && hours > 0) {
			time.push(hours);
			time.push(hours > 1 ? "hours" : "hour");
		}

		if (minutes && minutes > 0) {
			time.push(`${minutes} min`);
		}

		return time.join(" ");
	};

	const getVariableDuration = ({ hours, minutes }: Duration) => {
		return formatDuration(hours, minutes);
	};

	const staff = props.staff.map((staff) => (
		<StaffChip key={staff.id} staff={staff} />
	));

	const expandedMultipleStaff = (
		<Box className={classes.expandedStaff}>{staff}</Box>
	);

	return (
		<Box className={classes.container}>
			<Box className={classes.header}>
				<Box className={classes.emptyHeader}></Box>
				<Box className={classes.staffHeader}>
					<Box className={classes.cellStaff}>
						{props.showStaff && (
							<Typography
								className={clsx(classes.headerText, classes.bold)}
							>
								Staff Members
							</Typography>
						)}
					</Box>
				</Box>
				<Box className={classes.duration}>
					{props.showDuration && (
						<Box className={classes.row}>
							{!props.service.duration.simple && (
								<Box className={classes.cell}>
									<Typography
										className={clsx(
											classes.headerText,
											classes.bold
										)}
									>
										Size
									</Typography>
								</Box>
							)}
							<Box className={classes.cell}>
								<Typography
									className={clsx(
										classes.headerText,
										classes.bold
									)}
								>
									Time
								</Typography>
							</Box>
						</Box>
					)}
				</Box>
				<Box className={classes.price}>
					{props.showFixedPrices && (
						<Box className={classes.row}>
							<Box className={classes.cell}>
								<Typography
									className={clsx(
										classes.headerText,
										classes.bold
									)}
								>
									Type
								</Typography>
							</Box>
							<Box className={classes.cell}>
								<Typography
									className={clsx(
										classes.headerText,
										classes.bold
									)}
								>
									Price
								</Typography>
							</Box>
						</Box>
					)}
					{props.showVariablePrices && (
						<Box className={classes.row}>
							{petType.length > 0 ? (
								<Box className={classes.cell}>
									<Typography
										className={clsx(
											classes.headerText,
											classes.bold
										)}
									>
										Type
									</Typography>
								</Box>
							) : (
								<></>
							)}
							{petSize.length > 0 ? (
								<Box className={classes.cell}>
									<Typography
										className={clsx(
											classes.headerText,
											classes.bold
										)}
									>
										Size
									</Typography>
								</Box>
							) : (
								<></>
							)}
							{petHairType.length > 0 ? (
								<Box className={classes.cell}>
									<Typography
										className={clsx(
											classes.headerText,
											classes.bold
										)}
									>
										Hair Type
									</Typography>
								</Box>
							) : (
								<></>
							)}
							{petHairLength.length > 0 ? (
								<Box className={classes.cellHairLength}>
									<Typography
										className={clsx(
											classes.headerText,
											classes.bold
										)}
									>
										Hair Length
									</Typography>
								</Box>
							) : (
								<></>
							)}
							<Box className={clsx(classes.cell)}>
								<Typography
									className={clsx(
										classes.headerText,
										classes.bold
									)}
								>
									Price
								</Typography>
							</Box>
						</Box>
					)}
				</Box>
				<Box className={classes.taxesHeader}>
					{props.showTaxes && (
						<Box>
							<Typography
								className={clsx(classes.headerText, classes.bold)}
							>
								Taxes
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
			<Box className={classes.rowsContainer}>
				<Box className={classes.row}>
					<Box className={classes.empty}></Box>
					<Box className={classes.staff}>
						{props.showStaff && (
							<Box className={classes.cellStaff}>
								{expandedMultipleStaff}
							</Box>
						)}
					</Box>
					<Box className={classes.duration}>
						{props.showDuration &&
							(props.service.duration.simple ? (
								<Box className={classes.row}>
									<Box className={classes.cell}>
										<Typography className={classes.text}>
											{formatDuration(
												props.service.duration.simple.hours,
												props.service.duration.simple.minutes
											)}
										</Typography>
									</Box>
								</Box>
							) : (
								dogsSizesArr.map((variab) => (
									<Box className={classes.row}>
										<Box className={classes.cell}>
											<Typography className={classes.text}>
												{props.service.duration.variable.find((d) => d.petSize.id == variab?.element?.id)?.petSize.name}
											</Typography>
										</Box>
										<Box className={classes.cell}>
											<Typography className={classes.text}>
												{getVariableDuration(
													props.service.duration.variable.find((d) => d.petSize.id == variab?.element?.id)?.duration ?? {}
												)}
											</Typography>
										</Box>
									</Box>
								))
							))}
					</Box>
					<Box className={classes.price}>
						{props.showFixedPrices &&
							props.service.petTypes
								.map((pt) =>
									props.service.pricing.fixedPrice.some(
										(fp) => fp.petType.id === pt.id
									)
										? props.service.pricing.fixedPrice.find(
												(fp) => fp.petType.id === pt.id
										  )!
										: { petType: pt, price: undefined }
								)
								.map((fixedPrice) => (
									<Box
										className={classes.row}
										key={fixedPrice.petType.id}
									>
										<Box className={classes.cell}>
											<Typography
												className={clsx(
													classes.fixedPriceType,
													{
														[classes.undefined]:
															fixedPrice.price ===
															undefined,
													}
												)}
											>
												{fixedPrice.petType.plural}
											</Typography>
										</Box>
										<Box>
											{fixedPrice.price !== undefined ? (
												<Box className={classes.cell}>
													<NumberFormat
														value={fixedPrice.price!}
														displayType={"text"}
														prefix={"$"}
														thousandSeparator={true}
														decimalScale={2}
														fixedDecimalScale={true}
														renderText={(value) => (
															<Typography
																className={clsx(
																	classes.fixedPrice,
																	{
																		[classes.undefined]:
																			fixedPrice.price ===
																			undefined,
																	}
																)}
															>
																{value}
															</Typography>
														)}
													/>
												</Box>
											) : (
												<Box className={classes.cell}>
													<Typography
														className={clsx(
															classes.fixedPrice,
															{
																[classes.undefined]:
																	fixedPrice.price ===
																	undefined,
															}
														)}
													>
														-
													</Typography>
												</Box>
											)}
										</Box>
									</Box>
								))}
						{props.showVariablePrices &&
							props.variablePrice !== undefined &&
							props.variablePrice.prices.filter((p) => dogsSizesArr
								.map((ds) => ds?.element.id).includes(p.petSize?.id) )
								.map((variablePrice) => (
								<Box
									key={variablePrice.uuid}
									className={classes.row}
								>
									{(petType.length > 0 ||
										variablePrice.petType !== undefined) && (
										<Box className={classes.cell}>
											<Typography className={classes.text}>
												{variablePrice.petType?.plural}
											</Typography>
										</Box>
									)}
									{(petSize.length > 0 ||
										variablePrice.petSize !== undefined) && (
										<Box className={classes.cell}>
											<Typography className={classes.text}>
												{variablePrice.petSize?.name}
											</Typography>
										</Box>
									)}
									{(petHairType.length > 0 ||
										variablePrice.hairType !== undefined) && (
										<Box className={classes.cell}>
											<Typography className={classes.text}>
												{variablePrice.hairType?.name}
											</Typography>
										</Box>
									)}
									{(petHairLength.length > 0 ||
										variablePrice.hairLength !== undefined) && (
										<Box className={classes.cellHairLength}>
											<Typography className={classes.text}>
												{variablePrice.hairLength?.name}
											</Typography>
										</Box>
									)}
									<Box className={classes.cell}>
										<NumberFormat
											value={variablePrice.price || 0}
											displayType={"text"}
											prefix={"$"}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											renderText={(value) => (
												<Typography
													className={classes.fixedPrice}
												>
													{value}
												</Typography>
											)}
										/>
									</Box>
								</Box>
							))}
					</Box>
					<Box className={classes.taxes}>
						<Typography>
							{props.showTaxes &&
								props.service.pricing.taxes.map((tax) => {
									return (
										<Box
											className={classes.taxContainer}
											key={tax.uuid}
										>
											<Box
												className={classes.taxNameContainer}
											>
												<Typography
													className={classes.taxName}
												>
													{tax.name}
												</Typography>
											</Box>
											<Box
												className={
													classes.taxPercentageContainer
												}
											>
												<Typography
													className={classes.taxPercentage}
												>
													{tax.percentage}%
												</Typography>
											</Box>
										</Box>
									);
								})}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default TableDetailService;
