import {
    faSpinner,
    faGear,
    faExclamationTriangle
} from '@fortawesome/pro-regular-svg-icons';

export enum ApplicationStatus {
    Review,
    Processing,
    Setup
}

export const statusConfig = {
    [ApplicationStatus.Review]: {
        title: 'Review Your Application',
        description:
            "Take a moment to review the details you've provided in your application. Make sure all information is accurate before final submission.",
        buttonLabel: 'Review Now',
        icon: faExclamationTriangle,
        showButton: true
    },
    [ApplicationStatus.Processing]: {
        title: 'Application Processing',
        description:
            "Your CuddlesPay application has been submitted successfully. Our team is currently reviewing it. Once approved, you'll gain access to the CuddlesPay dashboard.",
        buttonLabel: 'View Status',
        icon: faSpinner,
        showButton: false
    },
    [ApplicationStatus.Setup]: {
        title: 'Setup CuddlesPay',
        description:
            'Time to set up your CuddlesPay! With just a click on the button below, you can manage all your banking details quickly and securely. Ready to streamline your payments with Cuddles!',
        buttonLabel: 'Update Info',
        icon: faGear,
        showButton: true
    }
};
