import { FunctionComponent, } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCat,
  faDog,
  faNarwhal,
} from "@fortawesome/pro-light-svg-icons"

interface PetImageProps {
  petImage?: string;
  petType?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      width: "100%",
    },
    image: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundImage: (props: PetImageProps) => "url(" + props.petImage + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "50%",
      textAlign: "center",
      position: "relative",
      width: 64,
      height: 64
    },
    emptyImage: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      background: "#F8F5F1",
      textAlign: "center",
      width: 64,
      height: 64
    },
    bgCat: {
      backgroundColor: "#FAEFDF",
    },
    bgDog: {
      backgroundColor: "#E9F0ED",
    },
    uploadIcon: {
      color: "#BAA997",
      fontSize: 35,
      lineHeight: 30
    },
    uploadDog: {
      color: "#5E8677"
    },
    uploadCat: {
      color: "#EAB464"
    },
  })
);

export const PetImage: FunctionComponent<PetImageProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={clsx(classes.container, props.className)}>
      {!isEmpty(props.petImage) ?
        <Box className={classes.image} />
        :
        <Box
          className={clsx(classes.emptyImage,
            { [classes.bgCat]: !isEmpty(props.petType) && props.petType === "cats" },
            { [classes.bgDog]: !isEmpty(props.petType) && props.petType === "dogs" })}>
          <FontAwesomeIcon
            icon={!isEmpty(props.petType)
              && props.petType === "cats" ? faCat : props.petType === "dogs" ? faDog : faNarwhal}
            className={clsx(classes.uploadIcon,
              { [classes.uploadCat]: !isEmpty(props.petType) && props.petType === "cats" },
              { [classes.uploadDog]: !isEmpty(props.petType) && props.petType === "dogs" },
            )}
          />
        </Box>}
    </Box>
  );
};

export default PetImage;
