import React, { FunctionComponent, ChangeEvent, FocusEvent, KeyboardEvent, useState } from "react";
import { Box, TextField as MUITextField, InputAdornment, InputProps } from "@material-ui/core";
import clsx from "clsx";
import { useNonInitialEffect } from "@versiondos/hooks";
import { FieldError } from "@spike/model";
import { useFieldStyles } from "./FieldStyles";
import LabelField from "./LabelField";
import padStart from "lodash/padStart";

export interface NumberField2Props {
  id?: string;
  name?: string;
  label?: string | JSX.Element;
  placeholder?: string;
  value: number | undefined;
  required?: boolean;
  errors?: Array<FieldError>;
  onBlur?: () => void;
  onChange: (value: number | undefined, name?: any) => void;
  onFocus?: () => void;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: "off" | "on";
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  multiline?: boolean;
  onEnterPressed?: () => void;
  disabled?: boolean;
  scale?: number;
  precision?: number;
  alignRight?: boolean;
  information?: string;
  labelClassName?: string;
  max?: number;
}

export const NumberField2: FunctionComponent<NumberField2Props> = (props) => {
  const classes = useFieldStyles();

  const [text, setText] = useState(props.value?.toString() || "");

  const hasError = props.errors?.some((error) => error.fieldName === props.name);

  useNonInitialEffect(() => {
    const timer: NodeJS.Timer = setTimeout(() => {
      props.onChange(Number(text), props.name);
    }, 200);
    return () => clearTimeout(timer);
  }, [text]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const cleanValue = newValue.replace(/\D/g, "").replace(/^0+/, "");

    if ((newValue.length === 0 || Number(newValue) === 0 || (newValue.length > 0 && cleanValue.length > 0)) && (!props.precision || cleanValue.length <= props.precision)) {
      if (cleanValue.length === 0) {
        if (props.scale !== undefined && props.scale === 0) {
          setText("0");
        } else {
          setText("0.00");
        }
      } else if (props.scale && props.scale >= cleanValue.length) {
        setText(`0.${padStart(cleanValue, props.scale, "0")}`);
      } else if (props.scale) {
        setText(`${cleanValue.slice(0, cleanValue.length - props.scale)}.${cleanValue.slice(cleanValue.length - props.scale)}`);
      } else {
        setText(cleanValue);
      }
    }

  };

  const onFocusHandler = (event: FocusEvent<HTMLInputElement>): void => {
    event.target.selectionStart = 0;
    event.target.selectionEnd = event.target.value.length;
    props.onFocus && props.onFocus();
  };

  const onBlurHandler = (event: any): void => {
    props.onBlur && props.onBlur();
  };

  const keyPressHandler = (event: KeyboardEvent) => {
    event.key === "Enter" && props.onEnterPressed && props.onEnterPressed();
  };


  let inputProps: InputProps = { inputProps: { max: undefined }, startAdornment: undefined, endAdornment: undefined };

  if (props.startIcon) {
    inputProps = {
      startAdornment: <InputAdornment position="start">{props.startIcon}</InputAdornment>,
    };
  } else if (props.endIcon) {
    inputProps = {
      endAdornment: <InputAdornment position="end">{props.endIcon}</InputAdornment>,
    };
  }

  if (inputProps.inputProps && props.max) {
    inputProps.inputProps.max = props.max;
  };

  return (
    <Box className={clsx(classes.container, props.className)}>
      <LabelField className={props.labelClassName} {...props} />
      <Box>
        <MUITextField
          id={props.id}
          name={props.name}
          placeholder={props.placeholder}
          variant="outlined"
          value={text}
          onBlur={onBlurHandler}
          onChange={changeHandler}
          onFocus={onFocusHandler}
          autoFocus={props.autoFocus}
          autoComplete={"nope"}
          className={clsx(classes.field, {
            [classes.startIcon]: props.startIcon,
            [classes.endIcon]: props.endIcon,
            [classes.emptyField]: text.length === 0,
            [classes.fieldError]: hasError,
            [classes.inputTextRight]: props.alignRight
          })}
          error={hasError}
          InputProps={inputProps}
          multiline={props.multiline}
          onKeyPress={keyPressHandler}
          disabled={props.disabled}
        />
      </Box>
    </Box>
  );
};

export default NumberField2;
