import { isEmpty, isEqual, isNil } from 'lodash';
import { Service } from 'model/Service';
import { FieldErrorWithKey, ServiceCategoryType } from './model';

export const validateServicePetType = (
    service: Service
): Array<FieldErrorWithKey> => {
    const errors: Array<FieldErrorWithKey> = [];
    if (isEmpty(service.petTypes)) {
        errors.push({
            fieldName: 'petType',
            errorMessage: 'Pet type is required',
            key: ServiceCategoryType.CATEGORY
        });
    }
    return errors;
};

export const validateServiceName = (
    service: Service
): Array<FieldErrorWithKey> => {
    const errors: Array<FieldErrorWithKey> = [];
    if (!service.name || service.name.length === 0) {
        errors.push({
            fieldName: 'name',
            errorMessage: 'Service name is required',
            key: ServiceCategoryType.DETAIL
        });
    }
    return errors;
};

export const validateServiceDuration = (
    service: Service
): Array<FieldErrorWithKey> => {
    const errors: Array<FieldErrorWithKey> = [];

    if (
        (service.duration.simple === undefined ||
            service.duration.simple.hours + service.duration.simple.minutes ===
                0) &&
        service.duration.variable.length === 0
    ) {
        errors.push({
            fieldName: 'duration',
            errorMessage: 'Duration is required',
            key: ServiceCategoryType.DETAIL
        });
    }
    if (
        (service.duration.simple === undefined ||
            service.duration.simple.hours + service.duration.simple.minutes ===
                0) &&
        (service.duration.variable.length === 0 ||
            service.duration.variable.some(
                duration =>
                    (duration.duration.hours || 0) +
                        (duration.duration.minutes || 0) ===
                    0
            ))
    ) {
        service.duration.variable
            .filter(
                duration =>
                    duration.duration.hours + duration.duration.minutes === 0
            )
            .forEach(duration => {
                errors.push({
                    id: duration.petSize.id,
                    fieldName: 'duration',
                    errorMessage: `Duration is required`,
                    key: ServiceCategoryType.DETAIL
                });
            });
    }

    return errors;
};

export const validateServicePricing = (
    service: Service
): Array<FieldErrorWithKey> => {
    const errors: Array<FieldErrorWithKey> = [];
    const serviceVariable = service.pricing.variablePrice;
    if (!isNil(serviceVariable)) {
        const dogTypeSelected = service.petTypes.some(
            type => type.id === 'dogs'
        );
        const catTypeSelected = service.petTypes.some(
            type => type.id === 'cats'
        );

        const dogsVariablePrices = serviceVariable.prices.filter(
            pet => pet.petType?.id === 'dogs'
        );
        const catsVariablePrices = serviceVariable.prices.filter(
            pet => pet.petType?.id === 'cats'
        );
        if (
            (catTypeSelected && isEmpty(catsVariablePrices)) ||
            catsVariablePrices.some(
                pet => isNil(pet.price) || isEqual(pet.price, 0)
            )
        ) {
            errors.push({
                fieldName: 'pricing_cats',
                errorMessage: 'Price is required',
                key: ServiceCategoryType.PRICING
            });
        }
        if (
            (dogTypeSelected && isEmpty(dogsVariablePrices)) ||
            dogsVariablePrices.some(
                pet => isNil(pet.price) || isEqual(pet.price, 0)
            )
        ) {
            errors.push({
                fieldName: 'pricing_dogs',
                errorMessage: 'Price is required',
                key: ServiceCategoryType.PRICING
            });
        }
    }
    if (
        (isNil(serviceVariable) && isEmpty(service.pricing.fixedPrice)) ||
        (service.pricing.fixedPrice.length === service.petTypes.length &&
            service.pricing.fixedPrice.some(
                type => type.price === undefined || isEqual(type.price, 0)
            ))
    ) {
        errors.push({
            fieldName: 'pricing',
            errorMessage:
                'The service does not have the Pricing section completed',
            key: ServiceCategoryType.PRICING
        });
    }

    return errors;
};

export const validateServiceStaff = (
    service: Service
): Array<FieldErrorWithKey> => {
    const errors: Array<FieldErrorWithKey> = [];
    if (isEmpty(service.staff)) {
        errors.push({
            fieldName: 'staff',
            errorMessage: 'Staff cannot be empty',
            key: ServiceCategoryType.STAFF
        });
    }
    return errors;
};

export const validateService = (service: Service): Array<FieldErrorWithKey> => {
    const petTypeErrors = validateServicePetType(service);
    const nameErrors = validateServiceName(service);
    const durationErrors = validateServiceDuration(service);
    const pricingErrors = validateServicePricing(service);

    return [
        ...petTypeErrors,
        ...nameErrors,
        ...durationErrors,
        ...pricingErrors
    ];
};

export const validateCategoryStep = (service: Service): boolean => {
    return isEmpty(validateServicePetType(service));
};

export const validateDetailsStep = (service: Service): boolean => {
    return isEmpty([
        ...validateServiceName(service),
        ...validateServiceDuration(service)
    ]);
};

export const validatePricingStep = (service: Service): boolean => {
    return isEmpty(validateServicePricing(service));
};

export const validateStaffStep = (service: Service) => {
    return isEmpty(validateServiceStaff(service));
};

export const validateServiceComplete = (service: Service) => {
    return isEmpty(validateService(service));
};
