import {
  ServicesAction,
  SERVICES_RESET_ACTION_TYPE,
  SERVICES_CLEAR_ACTION_TYPE,
  SERVICES_FETCH_START_ACTION_TYPE,
  SERVICES_FETCH_SUCCESS_ACTION_TYPE,
  SERVICES_SAVE_START_ACTION_TYPE,
  SERVICES_SAVE_SUCCESS_ACTION_TYPE,
  SERVICES_DELETE_START_ACTION_TYPE,
  SERVICES_DELETE_SUCCESS_ACTION_TYPE,
  SERVICES_ACTIVATE_START_ACTION_TYPE,
  SERVICES_ACTIVATE_SUCCESS_ACTION_TYPE,
  SERVICES_INACTIVATE_START_ACTION_TYPE,
  SERVICES_INACTIVATE_SUCCESS_ACTION_TYPE,
  SERVICES_PET_TYPES_UPDATE_START_ACTION_TYPE,
  SERVICES_PET_TYPES_UPDATE_SUCCESS_ACTION_TYPE,
  SERVICES_BUSINESS_AREA_UPDATE_START_ACTION_TYPE,
  SERVICES_BUSINESS_AREA_UPDATE_SUCCESS_ACTION_TYPE,
  SERVICES_STAFF_UPDATE_START_ACTION_TYPE,
  SERVICES_STAFF_UPDATE_SUCCESS_ACTION_TYPE,
  SERVICES_ERROR_ACTION_TYPE,
} from "./ServicesActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AxiosResponse } from "axios";
import { ServicesState } from "reducers/services/ServicesState";
import ApiClient, { createTokenConfig, isCancelled } from "api/ApiClient";
import { NotificationsAction } from "@spike/notifications-action";
import { showError } from "@spike/notifications-action";
import store from "store";
import { Status } from "model";
import { convertToService } from "./ServicesConverter";
import { Service } from "model/Service";
import { ServiceDto } from "./ServicesDtos";

const servicesUrl = "/services";
const activeServicesUrl = "/status/service";
const deleteServicesUrl = "/destroy/service";
const petTypesUpdateServicesUrl = "/pet_types/service";
const businessAreaUpdateServicesUrl = "/service/bulk_business_area";

export const resetServicesThunk = (): ThunkAction<void, ServicesState, null, ServicesAction> => {
  return async (dispatch) => {
    dispatch(clear());
  };
};

export const fetchServicesThunk = (
  duplicatedId?: number
): ThunkAction<void, ServicesState, null, ServicesAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${servicesUrl}?marketplace_id=${marketplaceId}`;

    try {
      const response: AxiosResponse<Array<ServiceDto>> = await ApiClient.get(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(fetchSuccess(response.data, duplicatedId));
    } catch (apiError) {
      if (!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error fetching services."));
      }
    }
  };
};

export const saveServiceThunk = (
  service: Service,
  changedName?: boolean,
  duplicated?: boolean
): ThunkAction<void, ServicesState, null, ServicesAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(saveStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const apiClientMethod = service.id === undefined ? ApiClient.post : ApiClient.patch;
    const url =
      service.id === undefined
        ? `${servicesUrl}?marketplace_id=${marketplaceId}`
        : `${servicesUrl}/${service.id}?marketplace_id=${marketplaceId}`;


    try {

      const serviceNoName = {
        id: service.id,
        uuid: service.uuid,
        marketplace_id: marketplaceId,
        description: service.description,
        duration_hours: service.duration.simple ? service.duration.simple.hours : null,
        duration_minutes: service.duration.simple ? service.duration.simple.minutes : null,
        variable_duration: JSON.stringify(
          service.duration.variable.map((vd) => ({
            size_meta_variable: vd.petSize.id,
            hours: vd.duration.hours,
            minutes: vd.duration.minutes,
          }))
        ),
        includes_tax: service.pricing.taxable,
        tax_ids: service.pricing.taxes.map(tax => (tax.id)),
        fixed_price: JSON.stringify(
          service.pricing.fixedPrice.map((fp) => ({
            pet_type_id: fp.petType.id,
            price: fp.price,
          }))
        ),
        variable_price:
          service.pricing.variablePrice === undefined
            ? null
            : JSON.stringify({
              variables: {
                pet_type_variable: service.pricing.variablePrice!.variables.petTypeVariable,
                pet_size_variable: service.pricing.variablePrice!.variables.petSizeVariable,
                hair_type_variable: service.pricing.variablePrice!.variables.hairTypeVariable,
                hair_length_variable: service.pricing.variablePrice!.variables.hairLengthVariable,
                exotic_type_variable: service.pricing.variablePrice!.variables.exoticTypeVariable,
              },
              prices: service.pricing.variablePrice!.prices.map((vpo) => ({
                uuid: vpo.uuid,
                pet_type_id: vpo.petType?.id,
                size_meta_variable: vpo.petSize ? vpo.petSize.id : undefined,
                hair_type_meta_variable: vpo.hairType ? vpo.hairType.id : undefined,
                hair_length_meta_variable: vpo.hairLength ? vpo.hairLength?.id : undefined,
                exotic_type_meta_variable: vpo.exoticType ? vpo.exoticType?.id : undefined,
                price: vpo.price,
              })),
            }),
        business_area: service.businessArea ? service.businessArea.id : "",
        pet_list: service.petTypes.map((petType) => petType.id),
        staff_ids: service.staff.map((staff) => staff.id),
        status_id: service.status.id,
        tag_list: service.tags,
        has_report: service.hasReport,
        house_call: service.houseCall,
        olb_enabled: service.olbEnabled
      };

      const serviceName = { ...serviceNoName, name: service.name }

      const serviceToSend = (service.id !== undefined && changedName === false) ? serviceNoName : serviceName

      const response: AxiosResponse<ServiceDto> = await apiClientMethod(
        url,
        {
          service: serviceToSend,
        },
        createTokenConfig(store.getState().login.auth.token!)
      );
      dispatch(saveSuccess(convertToService(response.data)));
    } catch (apiError) {
      if (!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error creating service."));
        dispatch(fetchServicesThunk());
      }
    }
  };
};

export const deleteServicesThunk = (
  serviceIds: Array<number>
): ThunkAction<void, ServicesState, null, ServicesAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(deleteStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${deleteServicesUrl}?marketplace_id=${marketplaceId}&ids=${serviceIds}`;

    try {
      await ApiClient.delete(url, createTokenConfig(store.getState().login.auth.token!));
      dispatch(deleteSuccess());
    } catch (apiError) {
      if (!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error deleting services."));
      }
    }
    dispatch(fetchServicesThunk());
  };
};

export const activateServicesThunk = (
  serviceIds: Array<number>,
  active: boolean
): ThunkAction<void, ServicesState, null, ServicesAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(active ? activateStart() : inactivateStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${activeServicesUrl}?marketplace_id=${marketplaceId}`;

    try {
      await ApiClient.post(
        url,
        {
          ids: serviceIds,
          status_id: active ? Status.ACTIVE : Status.INACTIVE,
        },
        createTokenConfig(store.getState().login.auth.token!)
      );
      dispatch(active ? activateSuccess() : inactivateSuccess());
    } catch (apiError) {
      if (!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error activating services."));
      }
    }
    dispatch(fetchServicesThunk());
  };
};

export const updatePetTypesServicesThunk = (
  serviceIds: Array<number>,
  petTypeIds: Array<string>
): ThunkAction<void, ServicesState, null, ServicesAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(petTypesUpdateStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${petTypesUpdateServicesUrl}?marketplace_id=${marketplaceId}`;

    try {
      await ApiClient.post(
        url,
        {
          ids: serviceIds,
          list: petTypeIds,
        },
        createTokenConfig(store.getState().login.auth.token!)
      );
      dispatch(petTypesUpdateSuccess());
    } catch (apiError) {
      if (!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error updating pet types."));
      }
    }
    dispatch(fetchServicesThunk());
  };
};

export const updateBusinessAreaServicesThunk = (
  serviceIds: Array<number>,
  businessAreaId: string
): ThunkAction<void, ServicesState, null, ServicesAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(businessAreaUpdateStart());

    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${businessAreaUpdateServicesUrl}?marketplace_id=${marketplaceId}`;

    try {
      await ApiClient.post(
        url,
        {
          ids: serviceIds,
          service: businessAreaId,
        },
        createTokenConfig(store.getState().login.auth.token!)
      );
      dispatch(businessAreaUpdateSuccess());
    } catch (apiError) {
      if (!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error updating business area."));
      }
    }
    dispatch(fetchServicesThunk());
  };
};

export const updateStaffServicesThunk = (
  serviceId: number,
  staffIds: Array<number>
): ThunkAction<void, ServicesState, null, ServicesAction | NotificationsAction> => {
  return async (dispatch) => {
    dispatch(staffUpdateStart());
    const marketplaceId = store.getState().login.auth.marketplaceId;
    const url = `${servicesUrl}/${serviceId}?marketplace_id=${marketplaceId}`;

    try {
      await ApiClient.patch(
        url,
        {
          service: {
            staff_ids: staffIds,
          },
        },
        createTokenConfig(store.getState().login.auth.token!)
      );
      dispatch(staffUpdateSuccess());
    } catch (apiError) {
      if (!isCancelled(apiError)) {
        dispatch(error());
        dispatch(showError("Error updating business area."));
      }
    }
    dispatch(fetchServicesThunk());
  };
};

export const reset = (): ServicesAction => {
  return {
    type: SERVICES_RESET_ACTION_TYPE,
  };
};

const clear = (): ServicesAction => {
  return {
    type: SERVICES_CLEAR_ACTION_TYPE,
  };
};

const fetchStart = (): ServicesAction => {
  return {
    type: SERVICES_FETCH_START_ACTION_TYPE,
  };
};

export const fetchSuccess = (servicesDto: Array<ServiceDto>, duplicatedId?: number): ServicesAction => {
  return {
    type: SERVICES_FETCH_SUCCESS_ACTION_TYPE,
    payload: {
      services: servicesDto.map((service) => convertToService(service)),
      duplicatedId,
    },
  };
};

const saveStart = (): ServicesAction => {
  return {
    type: SERVICES_SAVE_START_ACTION_TYPE,
  };
};

const saveSuccess = (service: Service): ServicesAction => {
  return {
    type: SERVICES_SAVE_SUCCESS_ACTION_TYPE,
    payload: {
      service
    },
  };
};

const deleteStart = (): ServicesAction => {
  return {
    type: SERVICES_DELETE_START_ACTION_TYPE,
  };
};

const deleteSuccess = (): ServicesAction => {
  return {
    type: SERVICES_DELETE_SUCCESS_ACTION_TYPE,
  };
};

const activateStart = (): ServicesAction => {
  return {
    type: SERVICES_ACTIVATE_START_ACTION_TYPE,
  };
};

const activateSuccess = (): ServicesAction => {
  return {
    type: SERVICES_ACTIVATE_SUCCESS_ACTION_TYPE,
  };
};

const inactivateStart = (): ServicesAction => {
  return {
    type: SERVICES_INACTIVATE_START_ACTION_TYPE,
  };
};

const inactivateSuccess = (): ServicesAction => {
  return {
    type: SERVICES_INACTIVATE_SUCCESS_ACTION_TYPE,
  };
};

const petTypesUpdateStart = (): ServicesAction => {
  return {
    type: SERVICES_PET_TYPES_UPDATE_START_ACTION_TYPE,
  };
};

const petTypesUpdateSuccess = (): ServicesAction => {
  return {
    type: SERVICES_PET_TYPES_UPDATE_SUCCESS_ACTION_TYPE,
  };
};

const businessAreaUpdateStart = (): ServicesAction => {
  return {
    type: SERVICES_BUSINESS_AREA_UPDATE_START_ACTION_TYPE,
  };
};

const businessAreaUpdateSuccess = (): ServicesAction => {
  return {
    type: SERVICES_BUSINESS_AREA_UPDATE_SUCCESS_ACTION_TYPE,
  };
};

const staffUpdateStart = (): ServicesAction => {
  return {
    type: SERVICES_STAFF_UPDATE_START_ACTION_TYPE,
  };
};

const staffUpdateSuccess = (): ServicesAction => {
  return {
    type: SERVICES_STAFF_UPDATE_SUCCESS_ACTION_TYPE,
  };
};

const error = (): ServicesAction => {
  return {
    type: SERVICES_ERROR_ACTION_TYPE,
  };
};
