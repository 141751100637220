import Service, {
    VariablePrice as ServiceVariablePrice,
    VariablePriceOption,
    VariablePriceCache,
    VariablePriceConstants
} from 'model/Service';
import { PetType } from '@spike/model';
import React, { FunctionComponent, Fragment, useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { v4 as createUuid } from 'uuid';
import { SelectableOption } from 'model';
import { useMarketplace } from 'hooks';
import { Option } from '@spike/model';
import NewGrid from './NewGrid/NewGrid';
import Box from '@material-ui/core/Box';
import { FieldErrorWithKey } from 'components/Service/model';
import { isEmpty } from 'lodash';
import { Typography } from '@material-ui/core';
import useNonInitialEffect from '@versiondos/hooks';

interface Props {
    service: Service;
    errors: Array<FieldErrorWithKey>;
    priceTypeSelected: Option<string>;
    isMultiplePet: boolean;
    subPetSelected?: string;
    onChange: (variablePrice: ServiceVariablePrice) => void;
    changeSubPetSelected: (type: string) => void;
}

interface CreatedPrices {
    cats: string[];
    dogs: string[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.down('sm')]: {
                margin: '0px -16px'
            }
        },
        gridContainer: {
            marginTop: 24
        }
    })
);

export const exoticTypeVariable: Option<string> = {
    id: 'exotic_type',
    name: 'Exotic Type'
};
export const hairLengthVariable: Option<string> = {
    id: 'by_hair_lenght',
    name: 'By Hair Length'
};
export const hairTypeVariable: Option<string> = {
    id: 'hair_type',
    name: 'Hair Type'
};
export const petSizeVariable: Option<string> = {
    id: 'by_pet_size',
    name: 'By Pet Size'
};

export const NewVariablePrice: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const marketplace = useMarketplace();

    const [errors, setErrors] = useState(props.errors);

    const dogsSizesArr = petSizeVariable
        ? marketplace.petTypes.dogs?.sizes
        : [undefined];
    const catsSizesArr = petSizeVariable
        ? marketplace.petTypes.cats?.sizes
        : [undefined];
    const dogsHairLengthsArr = (
        hairTypeVariable &&
        marketplace.petTypes.dogs &&
        marketplace.petTypes.dogs?.hairLengths
    )?.sort((a, b) => a.order! - b.order!) || [undefined];
    const catsHairLengthsArr = (
        hairTypeVariable &&
        marketplace.petTypes.cats &&
        marketplace.petTypes.cats?.hairLengths
    )?.sort((a, b) => a.order! - b.order!) || [undefined];
    const cachePositions = {
        dogs: {
            by_pet_size: 0,
            by_pet_size_and_hair_length: 1
        },
        cats: {
            by_pet_size: 2,
            by_pet_size_and_hair_length: 3
        }
    };

    const VPCONSTANTS: VariablePriceConstants = {
        DOGS: 'dogs',
        CATS: 'cats',
        BY_PET_SIZE: 'by_pet_size',
        BY_PET_SIZE_AND_HAIR_LENGTH: 'by_pet_size_and_hair_length',
        BY_HAIR_LENGTH: 'by_hair_lenght'
    };

    const validateActiveSizePricings = (arr: ServiceVariablePrice) => {
        const copyDogsPrices = [...arr.prices];
        arr.prices.splice(0, arr.prices.length);
        if (
            props.service.petTypes.length === 1 &&
            props.service.petTypes[0]?.id === 'cats' &&
            copyDogsPrices
        ) {
            arr.prices.push(...copyDogsPrices);
        } else {
            dogsSizesArr
                ?.map(e => e?.element)
                .forEach(petSize => {
                    if (
                        !copyDogsPrices
                            .map(d => d.petSize?.id)
                            .includes(petSize?.id)
                    ) {
                        if (
                            props.priceTypeSelected.id ===
                            VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH
                        ) {
                            const arrSizeByHair = dogsHairLengthsArr.map(
                                dh => dh?.element
                            );
                            arrSizeByHair.forEach(sh => {
                                arr.prices.push({
                                    uuid: createUuid(),
                                    petType: {
                                        id: 'dogs',
                                        plural: 'Dogs',
                                        singular: 'Dog'
                                    },
                                    petSize,
                                    hairLength: sh
                                });
                            });
                        } else {
                            arr.prices.push({
                                uuid: createUuid(),
                                petType: {
                                    id: 'dogs',
                                    plural: 'Dogs',
                                    singular: 'Dog'
                                },
                                petSize
                            });
                        }
                    } else {
                        const dogVariablePrice = copyDogsPrices.filter(
                            el => el.petSize?.id == petSize?.id
                        );
                        if (
                            props.priceTypeSelected.id ===
                            VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH
                        ) {
                            const arrSizeByHair = dogsHairLengthsArr.map(
                                dh => dh?.element
                            );
                            arrSizeByHair.map(sbh => {
                                if (
                                    !dogVariablePrice
                                        .map(vp => vp.hairLength?.id)
                                        .includes(sbh?.id)
                                ) {
                                    dogVariablePrice.push({
                                        uuid: createUuid(),
                                        petType: {
                                            id: 'dogs',
                                            plural: 'Dogs',
                                            singular: 'Dog'
                                        },
                                        petSize,
                                        hairLength: sbh
                                    });
                                }
                            });
                        }
                        if (dogVariablePrice.length > 0)
                            arr.prices.push(...dogVariablePrice);
                    }
                });
        }
        return arr;
    };

    const [serviceVariablePrice, setServiceVariablePrice] =
        useState<ServiceVariablePrice>(() => {
            if (props.service.pricing.variablePrice?.prices) {
                const dogPrices = { ...props.service.pricing.variablePrice };
                if (props.isMultiplePet) {
                    if (
                        !dogPrices.prices.some(
                            price => price.petType?.id === VPCONSTANTS.DOGS
                        )
                    ) {
                        if (
                            props.priceTypeSelected.id ===
                            VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH
                        ) {
                            dogsSizesArr
                                ?.map(e => e?.element)
                                .forEach(petSize => {
                                    dogsHairLengthsArr
                                        .map(e => e?.element)
                                        .forEach(hairLength => {
                                            dogPrices.prices.push({
                                                uuid: createUuid(),
                                                petType: {
                                                    id: 'dogs',
                                                    plural: 'Dogs',
                                                    singular: 'Dog'
                                                },
                                                petSize,
                                                hairLength
                                            });
                                        });
                                });
                        } else {
                            if (
                                props.priceTypeSelected.id ===
                                VPCONSTANTS.BY_PET_SIZE
                            ) {
                                dogsSizesArr
                                    ?.map(e => e?.element)
                                    .forEach(petSize => {
                                        dogPrices.prices.push({
                                            uuid: createUuid(),
                                            petType: {
                                                id: 'dogs',
                                                plural: 'Dogs',
                                                singular: 'Dog'
                                            },
                                            petSize
                                        });
                                    });
                            }
                        }
                    }
                    dogPrices.prices = dogPrices.prices.filter(
                        price => price.petType?.id === VPCONSTANTS.DOGS
                    );
                    return validateActiveSizePricings(dogPrices);
                } else {
                    return validateActiveSizePricings(dogPrices);
                }
            } else {
                return {
                    variables: {
                        petTypeVariable: false,
                        petSizeVariable: false,
                        hairTypeVariable: false,
                        hairLengthVariable: false,
                        exoticTypeVariable: false
                    },
                    prices: []
                };
            }
        });

    const [serviceVariablePriceCache, setServiceVariablePriceCache] = useState<
        Array<VariablePriceCache>
    >(() => {
        if (props.service.pricing.variablePrice?.prices) {
            const baseCache: Array<VariablePriceCache> = [
                {
                    petType: VPCONSTANTS.DOGS,
                    priceType: VPCONSTANTS.BY_PET_SIZE,
                    variablePrice: undefined
                },
                {
                    petType: VPCONSTANTS.DOGS,
                    priceType: VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH,
                    variablePrice: undefined
                },
                {
                    petType: VPCONSTANTS.CATS,
                    priceType: VPCONSTANTS.BY_PET_SIZE,
                    variablePrice: undefined
                },
                {
                    petType: VPCONSTANTS.CATS,
                    priceType: VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH,
                    variablePrice: undefined
                }
            ];

            if (props.service.petTypes.length === 1) {
                if (props.service.petTypes[0].id === VPCONSTANTS.DOGS) {
                    if (
                        props.service.pricing.variablePrice.variables
                            .petSizeVariable &&
                        props.service.pricing.variablePrice.variables
                            .hairLengthVariable
                    ) {
                        baseCache[
                            cachePositions.dogs.by_pet_size_and_hair_length
                        ] = {
                            ...baseCache[
                                cachePositions.dogs.by_pet_size_and_hair_length
                            ],
                            variablePrice: props.service.pricing.variablePrice
                        };
                    } else {
                        baseCache[cachePositions.dogs.by_pet_size] = {
                            ...baseCache[cachePositions.dogs.by_pet_size],
                            variablePrice: props.service.pricing.variablePrice
                        };
                    }
                }
                if (props.service.petTypes[0].id === VPCONSTANTS.CATS) {
                    if (
                        props.service.pricing.variablePrice.variables
                            .petSizeVariable &&
                        props.service.pricing.variablePrice.variables
                            .hairLengthVariable
                    ) {
                        baseCache[
                            cachePositions.cats.by_pet_size_and_hair_length
                        ] = {
                            ...baseCache[
                                cachePositions.cats.by_pet_size_and_hair_length
                            ],
                            variablePrice: props.service.pricing.variablePrice
                        };
                    } else {
                        baseCache[cachePositions.cats.by_pet_size] = {
                            ...baseCache[cachePositions.cats.by_pet_size],
                            variablePrice: props.service.pricing.variablePrice
                        };
                    }
                }
            } else {
                let dogPrices = { ...props.service.pricing.variablePrice };
                dogPrices.prices = dogPrices.prices.filter(
                    price => price.petType?.id === VPCONSTANTS.DOGS
                );
                dogPrices = validateActiveSizePricings(dogPrices);
                //dogPrices.prices = dogPrices.prices.filter((price) => price.petType?.id === VPCONSTANTS.DOGS);
                const catPrices = { ...props.service.pricing.variablePrice };
                catPrices.prices = catPrices.prices.filter(
                    price => price.petType?.id === VPCONSTANTS.CATS
                );

                if (
                    catPrices.prices.length === 0 &&
                    props.priceTypeSelected.id === VPCONSTANTS.BY_PET_SIZE
                ) {
                    catsSizesArr
                        ?.map(e => e?.element)
                        .forEach(petSize => {
                            catPrices.prices.push({
                                uuid: createUuid(),
                                petType: {
                                    id: 'cats',
                                    singular: 'Cat',
                                    plural: 'Cats'
                                },
                                petSize
                            });
                        });
                }
                if (
                    catPrices.prices.length === 0 &&
                    props.priceTypeSelected.id ===
                        VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH
                ) {
                    catsSizesArr
                        ?.map(e => e?.element)
                        .forEach(petSize => {
                            catsHairLengthsArr
                                .map(e => e?.element)
                                .forEach(hairLength => {
                                    catPrices.prices.push({
                                        uuid: createUuid(),
                                        petType: {
                                            id: 'cats',
                                            singular: 'Cat',
                                            plural: 'Cats'
                                        },
                                        petSize,
                                        hairLength
                                    });
                                });
                        });
                }

                if (
                    props.service.pricing.variablePrice.variables
                        .petSizeVariable &&
                    props.service.pricing.variablePrice.variables
                        .hairLengthVariable
                ) {
                    baseCache[cachePositions.dogs.by_pet_size_and_hair_length] =
                        {
                            ...baseCache[
                                cachePositions.dogs.by_pet_size_and_hair_length
                            ],
                            variablePrice: dogPrices
                        };
                    baseCache[cachePositions.cats.by_pet_size_and_hair_length] =
                        {
                            ...baseCache[
                                cachePositions.cats.by_pet_size_and_hair_length
                            ],
                            variablePrice: catPrices
                        };
                } else {
                    baseCache[cachePositions.dogs.by_pet_size] = {
                        ...baseCache[cachePositions.dogs.by_pet_size],
                        variablePrice: dogPrices
                    };
                    baseCache[cachePositions.cats.by_pet_size] = {
                        ...baseCache[cachePositions.cats.by_pet_size],
                        variablePrice: catPrices
                    };
                }
            }
            return baseCache;
        } else {
            return [
                {
                    petType: VPCONSTANTS.DOGS,
                    priceType: VPCONSTANTS.BY_PET_SIZE,
                    variablePrice: undefined
                },
                {
                    petType: VPCONSTANTS.DOGS,
                    priceType: VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH,
                    variablePrice: undefined
                },
                {
                    petType: VPCONSTANTS.CATS,
                    priceType: VPCONSTANTS.BY_PET_SIZE,
                    variablePrice: undefined
                },
                {
                    petType: VPCONSTANTS.CATS,
                    priceType: VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH,
                    variablePrice: undefined
                }
            ];
        }
    });

    const [createdPrices, setCreatedPrices] = useState<CreatedPrices>(() => {
        if (props.service.pricing.variablePrice?.prices) {
            let baseCreatedPrices: CreatedPrices = {
                dogs: [],
                cats: []
            };

            if (props.service.petTypes.length === 1) {
                if (props.service.petTypes[0].id === VPCONSTANTS.DOGS) {
                    if (
                        props.service.pricing.variablePrice.variables
                            .petSizeVariable &&
                        props.service.pricing.variablePrice.variables
                            .hairLengthVariable
                    ) {
                        baseCreatedPrices = {
                            ...baseCreatedPrices,
                            dogs: [VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH]
                        };
                    } else {
                        baseCreatedPrices = {
                            ...baseCreatedPrices,
                            dogs: [VPCONSTANTS.BY_PET_SIZE]
                        };
                    }
                }
                if (props.service.petTypes[0].id === VPCONSTANTS.CATS) {
                    if (
                        props.service.pricing.variablePrice.variables
                            .hairLengthVariable
                    ) {
                        baseCreatedPrices = {
                            ...baseCreatedPrices,
                            cats: [VPCONSTANTS.BY_HAIR_LENGTH]
                        };
                    } else {
                        baseCreatedPrices = {
                            ...baseCreatedPrices,
                            cats: [VPCONSTANTS.BY_PET_SIZE]
                        };
                    }
                }
                return baseCreatedPrices;
            } else {
                if (
                    props.service.pricing.variablePrice.variables
                        .petSizeVariable &&
                    props.service.pricing.variablePrice.variables
                        .hairLengthVariable
                ) {
                    baseCreatedPrices = {
                        ...baseCreatedPrices,
                        dogs: [VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH]
                    };
                    baseCreatedPrices = {
                        ...baseCreatedPrices,
                        cats: [VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH]
                    };
                } else {
                    baseCreatedPrices = {
                        ...baseCreatedPrices,
                        dogs: [VPCONSTANTS.BY_PET_SIZE]
                    };
                    baseCreatedPrices = {
                        ...baseCreatedPrices,
                        cats: [VPCONSTANTS.BY_PET_SIZE]
                    };
                }
                return baseCreatedPrices;
            }
        } else {
            return {
                dogs: [],
                cats: []
            };
        }
    });

    const setVariables = (
        petSizeSelected: boolean,
        hairTypeSelected: boolean,
        hairLengthSelected: boolean,
        exoticTypeSelected: boolean
    ) => {
        const initialVariables: SelectableOption<string>[] = [
            { element: petSizeVariable, selected: petSizeSelected },
            { element: hairTypeVariable, selected: hairTypeSelected },
            { element: hairLengthVariable, selected: hairLengthSelected },
            { element: exoticTypeVariable, selected: exoticTypeSelected }
        ];
        return initialVariables;
    };

    const setInitialVariables = (priceTypeSelected: string) => {
        switch (priceTypeSelected) {
            case VPCONSTANTS.BY_PET_SIZE:
                return setVariables(true, false, false, false);
            case VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH:
                return setVariables(true, false, true, false);
            case VPCONSTANTS.BY_HAIR_LENGTH:
                return setVariables(false, false, true, false);
            default:
                return setVariables(false, false, false, false);
        }
    };

    const setPrices = (): Array<VariablePriceOption> => {
        const updatedPrices: Array<VariablePriceOption> = [];
        let subPetType: PetType;
        if (props.isMultiplePet) {
            props.subPetSelected === VPCONSTANTS.DOGS
                ? (subPetType = {
                      id: VPCONSTANTS.DOGS,
                      plural: 'Dogs',
                      singular: 'Dog'
                  })
                : (subPetType = {
                      id: VPCONSTANTS.CATS,
                      plural: 'Cats',
                      singular: 'Cat'
                  });
        }
        if (props.service.petTypes != undefined) {
            switch (props.priceTypeSelected.id) {
                case VPCONSTANTS.BY_PET_SIZE:
                    if (
                        (props.isMultiplePet === true &&
                            subPetType!.id === VPCONSTANTS.CATS) ||
                        (props.isMultiplePet === false &&
                            props.service.petTypes[0].id === VPCONSTANTS.CATS)
                    ) {
                        catsSizesArr
                            ?.map(e => e?.element)
                            .forEach(petSize => {
                                updatedPrices.push({
                                    uuid: createUuid(),
                                    petType: props.isMultiplePet
                                        ? subPetType
                                        : props.service.petTypes[0],
                                    petSize
                                });
                            });
                    } else {
                        dogsSizesArr
                            ?.map(e => e?.element)
                            .forEach(petSize => {
                                updatedPrices.push({
                                    uuid: createUuid(),
                                    petType: props.isMultiplePet
                                        ? subPetType
                                        : props.service.petTypes[0],
                                    petSize
                                });
                            });
                    }
                    break;
                case VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH:
                    if (
                        props.isMultiplePet === true &&
                        subPetType!.id === VPCONSTANTS.CATS
                    ) {
                        catsSizesArr
                            ?.map(e => e?.element)
                            .forEach(petSize => {
                                catsHairLengthsArr
                                    .map(e => e?.element)
                                    .forEach(hairLength => {
                                        updatedPrices.push({
                                            uuid: createUuid(),
                                            petType: props.isMultiplePet
                                                ? subPetType
                                                : props.service.petTypes[0],
                                            petSize,
                                            hairLength
                                        });
                                    });
                            });
                    } else {
                        dogsSizesArr
                            ?.map(e => e?.element)
                            .forEach(petSize => {
                                dogsHairLengthsArr
                                    .map(e => e?.element)
                                    .forEach(hairLength => {
                                        updatedPrices.push({
                                            uuid: createUuid(),
                                            petType: props.isMultiplePet
                                                ? subPetType
                                                : props.service.petTypes[0],
                                            petSize,
                                            hairLength
                                        });
                                    });
                            });
                    }
                    break;
                case VPCONSTANTS.BY_HAIR_LENGTH:
                    catsHairLengthsArr
                        .map(e => e?.element)
                        .forEach(hairLength => {
                            updatedPrices.push({
                                uuid: createUuid(),
                                petType: props.isMultiplePet
                                    ? subPetType
                                    : props.service.petTypes[0],
                                petSize: {
                                    id: 'small',
                                    name: 'Small',
                                    description: 'Default fot Cats'
                                },
                                hairLength
                            });
                        });
                    break;
            }
        }
        return updatedPrices;
    };

    const createVariablesForServiceVariablePrice = (
        variables: Array<SelectableOption<string>>
    ) => {
        return {
            petTypeVariable: true,
            petSizeVariable: variables.some(
                variable =>
                    variable.element.id === petSizeVariable.id &&
                    variable.selected
            ),
            hairTypeVariable: variables.some(
                variable =>
                    variable.element.id === hairTypeVariable.id &&
                    variable.selected
            ),
            hairLengthVariable: variables.some(
                variable =>
                    variable.element.id === hairLengthVariable.id &&
                    variable.selected
            ),
            exoticTypeVariable: variables.some(
                variable =>
                    variable.element.id === exoticTypeVariable.id &&
                    variable.selected
            )
        };
    };

    const calculateVariablePrices = (
        PriceTypeSelected: string
    ): ServiceVariablePrice => {
        return {
            variables: createVariablesForServiceVariablePrice(
                setInitialVariables(PriceTypeSelected)
            ),
            prices: setPrices()
        };
    };

    const handleUpdateVariablePriceCache = (
        serviceVariablePrice: ServiceVariablePrice
    ) => {
        let newVariablePriceCache;
        if (serviceVariablePrice.prices[0].petType?.id === VPCONSTANTS.DOGS) {
            newVariablePriceCache = serviceVariablePriceCache.map(
                variablePriceCache =>
                    variablePriceCache.petType === VPCONSTANTS.DOGS &&
                    variablePriceCache.priceType === props.priceTypeSelected.id
                        ? {
                              ...variablePriceCache,
                              variablePrice: serviceVariablePrice
                          }
                        : variablePriceCache
            );
            setServiceVariablePriceCache(newVariablePriceCache);
        }
        if (serviceVariablePrice.prices[0].petType?.id === VPCONSTANTS.CATS) {
            newVariablePriceCache = serviceVariablePriceCache.map(
                variablePriceCache =>
                    variablePriceCache.petType === VPCONSTANTS.CATS &&
                    variablePriceCache.priceType === props.priceTypeSelected.id
                        ? {
                              ...variablePriceCache,
                              variablePrice: serviceVariablePrice
                          }
                        : variablePriceCache
            );
            setServiceVariablePriceCache(newVariablePriceCache);
        }
    };

    const bindServiceVariablePrice = (
        priceTypeSelected: string,
        target: string,
        cachePosition: number
    ) => {
        if (priceTypeSelected === target)
            setServiceVariablePrice(
                serviceVariablePriceCache[cachePosition].variablePrice!
            );
    };

    const unifiedVariablePriceServiceHandler = (
        serviceVariablePriceChache: Array<VariablePriceCache>
    ) => {
        let dogCachePosition = 0;
        let catCachePosition = 0;
        if (props.priceTypeSelected.id === VPCONSTANTS.BY_PET_SIZE) {
            dogCachePosition = cachePositions.dogs.by_pet_size;
            catCachePosition = cachePositions.cats.by_pet_size;
        }
        if (
            props.priceTypeSelected.id ===
            VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH
        ) {
            dogCachePosition = cachePositions.dogs.by_pet_size_and_hair_length;
            catCachePosition = cachePositions.cats.by_pet_size_and_hair_length;
        }
        if (
            serviceVariablePriceChache[dogCachePosition]?.variablePrice?.prices
                ?.length &&
            serviceVariablePriceChache[catCachePosition]?.variablePrice?.prices
                ?.length
        ) {
            const unifiedVariables = Object.assign(
                {},
                serviceVariablePriceChache[dogCachePosition].variablePrice!
                    .variables,
                serviceVariablePriceChache[catCachePosition].variablePrice!
                    .variables
            );

            const unifiedPrices = [
                ...serviceVariablePriceChache[dogCachePosition].variablePrice!
                    .prices,
                ...serviceVariablePriceChache[catCachePosition].variablePrice!
                    .prices
            ];

            const unifiedVariablePriceService: ServiceVariablePrice = {
                variables: unifiedVariables,
                prices: unifiedPrices
            };
            props.onChange(unifiedVariablePriceService);
        } else if (
            serviceVariablePriceChache[dogCachePosition]?.variablePrice?.prices
                ?.length &&
            serviceVariablePriceChache[catCachePosition]?.variablePrice ===
                undefined
        ) {
            props.onChange(
                serviceVariablePriceChache[dogCachePosition].variablePrice!
            );
        }
    };

    const changeHandler = (newPrice: VariablePriceOption) => {
        setServiceVariablePrice(prev => {
            return {
                ...prev,
                prices: prev.prices.map(price => {
                    return price.uuid === newPrice.uuid ? newPrice : price;
                })
            };
        });
    };

    useEffect(() => {
        if (props.isMultiplePet === true) {
            if (serviceVariablePrice.prices.length > 0)
                handleUpdateVariablePriceCache(serviceVariablePrice);
        } else {
            props.onChange(serviceVariablePrice!);
        }
    }, [serviceVariablePrice]);

    useEffect(() => {
        if (props.isMultiplePet === true) {
            if (props.subPetSelected === VPCONSTANTS.DOGS) {
                if (
                    createdPrices.dogs.some(
                        price => price === props.priceTypeSelected.id
                    )
                ) {
                    bindServiceVariablePrice(
                        props.priceTypeSelected.id,
                        VPCONSTANTS.BY_PET_SIZE,
                        cachePositions.dogs.by_pet_size
                    );
                    bindServiceVariablePrice(
                        props.priceTypeSelected.id,
                        VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH,
                        cachePositions.dogs.by_pet_size_and_hair_length
                    );
                } else {
                    setServiceVariablePrice(
                        calculateVariablePrices(props.priceTypeSelected.id)
                    );
                    if (props.service.petTypes[0].id === VPCONSTANTS.DOGS) {
                        setCreatedPrices(prevState => ({
                            ...prevState,
                            dogs: [
                                ...prevState.dogs,
                                props.priceTypeSelected.id
                            ]
                        }));
                    }
                }
            }
            if (props.subPetSelected === VPCONSTANTS.CATS) {
                if (
                    createdPrices.cats.some(
                        price => price === props.priceTypeSelected.id
                    )
                ) {
                    bindServiceVariablePrice(
                        props.priceTypeSelected.id,
                        VPCONSTANTS.BY_PET_SIZE,
                        cachePositions.cats.by_pet_size
                    );
                    bindServiceVariablePrice(
                        props.priceTypeSelected.id,
                        VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH,
                        cachePositions.cats.by_pet_size_and_hair_length
                    );
                } else {
                    setServiceVariablePrice(
                        calculateVariablePrices(props.priceTypeSelected.id)
                    );
                    if (props.service.petTypes[1].id === VPCONSTANTS.CATS) {
                        setCreatedPrices(prevState => ({
                            ...prevState,
                            cats: [
                                ...prevState.cats,
                                props.priceTypeSelected.id
                            ]
                        }));
                    }
                }
            }
        } else {
            if (
                (props.service.petTypes[0].id === VPCONSTANTS.DOGS &&
                    !createdPrices.dogs.some(
                        price => price === props.priceTypeSelected.id
                    )) ||
                (props.service.petTypes[0].id === VPCONSTANTS.CATS &&
                    !createdPrices.cats.some(
                        price => price === props.priceTypeSelected.id
                    ))
            ) {
                setServiceVariablePrice(
                    calculateVariablePrices(props.priceTypeSelected.id)
                );
                if (props.service.petTypes[0].id === VPCONSTANTS.DOGS) {
                    setCreatedPrices(prevState => ({
                        ...prevState,
                        dogs: [props.priceTypeSelected.id]
                    }));
                }
                if (props.service.petTypes[0].id === VPCONSTANTS.CATS) {
                    setCreatedPrices(prevState => ({
                        ...prevState,
                        cats: [props.priceTypeSelected.id]
                    }));
                }
            }
        }
    }, [props.subPetSelected, props.priceTypeSelected]);

    useNonInitialEffect(() => {
        setErrors([]);
    }, [props.priceTypeSelected]);

    useEffect(() => {
        unifiedVariablePriceServiceHandler(serviceVariablePriceCache);
    }, [serviceVariablePriceCache]);

    useEffect(() => {
        setErrors(props.errors);
    }, [props.errors]);

    const multipleSelectionGrids = (
        <Box>
            {props.subPetSelected === VPCONSTANTS.DOGS &&
                props.priceTypeSelected.id === VPCONSTANTS.BY_PET_SIZE &&
                serviceVariablePriceCache[cachePositions.dogs.by_pet_size]
                    .variablePrice !== undefined &&
                serviceVariablePriceCache[cachePositions.dogs.by_pet_size]
                    .variablePrice!.prices.length > 0 && (
                    <Box className={classes.gridContainer}>
                        <NewGrid
                            errors={errors}
                            id="services_pricetype_multiple_dogs_pet_size"
                            service={props.service}
                            vPConst={VPCONSTANTS}
                            serviceVariablePrice={
                                serviceVariablePriceCache[
                                    cachePositions.dogs.by_pet_size
                                ].variablePrice!
                            }
                            priceTypeSelected={props.priceTypeSelected.id}
                            petType={props.subPetSelected!}
                            subPetSelected={props.subPetSelected}
                            changeSubPetSelected={props.changeSubPetSelected}
                            onChange={changeHandler}
                            isMultiplePet={props.isMultiplePet}
                        />
                    </Box>
                )}
            {props.subPetSelected === VPCONSTANTS.DOGS &&
                props.priceTypeSelected.id ===
                    VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH &&
                serviceVariablePriceCache[
                    cachePositions.dogs.by_pet_size_and_hair_length
                ].variablePrice !== undefined &&
                serviceVariablePriceCache[
                    cachePositions.dogs.by_pet_size_and_hair_length
                ].variablePrice!.prices.length > 0 && (
                    <Box className={classes.gridContainer}>
                        <NewGrid
                            errors={errors}
                            id="services_pricetype_multiple_dogs_pet_size_hair_length"
                            service={props.service}
                            vPConst={VPCONSTANTS}
                            serviceVariablePrice={
                                serviceVariablePriceCache[
                                    cachePositions.dogs
                                        .by_pet_size_and_hair_length
                                ].variablePrice!
                            }
                            priceTypeSelected={props.priceTypeSelected.id}
                            petType={props.subPetSelected!}
                            subPetSelected={props.subPetSelected}
                            changeSubPetSelected={props.changeSubPetSelected}
                            onChange={changeHandler}
                            isMultiplePet={props.isMultiplePet}
                        />
                    </Box>
                )}
            {props.subPetSelected === VPCONSTANTS.CATS &&
                props.priceTypeSelected.id === VPCONSTANTS.BY_PET_SIZE &&
                serviceVariablePriceCache[cachePositions.cats.by_pet_size]
                    .variablePrice !== undefined &&
                serviceVariablePriceCache[cachePositions.cats.by_pet_size]
                    .variablePrice!.prices.length > 0 && (
                    <Box className={classes.gridContainer}>
                        <NewGrid
                            errors={errors}
                            id="services_pricetype_multiple_cats_pet_size"
                            service={props.service}
                            vPConst={VPCONSTANTS}
                            serviceVariablePrice={
                                serviceVariablePriceCache[
                                    cachePositions.cats.by_pet_size
                                ].variablePrice!
                            }
                            priceTypeSelected={props.priceTypeSelected.id}
                            petType={props.subPetSelected!}
                            subPetSelected={props.subPetSelected}
                            changeSubPetSelected={props.changeSubPetSelected}
                            onChange={changeHandler}
                            isMultiplePet={props.isMultiplePet}
                        />
                    </Box>
                )}
            {props.subPetSelected === VPCONSTANTS.CATS &&
                props.priceTypeSelected.id ===
                    VPCONSTANTS.BY_PET_SIZE_AND_HAIR_LENGTH &&
                serviceVariablePriceCache[
                    cachePositions.cats.by_pet_size_and_hair_length
                ].variablePrice !== undefined &&
                serviceVariablePriceCache[
                    cachePositions.cats.by_pet_size_and_hair_length
                ].variablePrice!.prices.length > 0 && (
                    <Box className={classes.gridContainer}>
                        <NewGrid
                            errors={errors}
                            id="services_pricetype_multiple_cats_pet_size_hair_length"
                            service={props.service}
                            vPConst={VPCONSTANTS}
                            serviceVariablePrice={
                                serviceVariablePriceCache[
                                    cachePositions.cats
                                        .by_pet_size_and_hair_length
                                ].variablePrice!
                            }
                            priceTypeSelected={props.priceTypeSelected.id}
                            petType={props.subPetSelected!}
                            subPetSelected={props.subPetSelected}
                            changeSubPetSelected={props.changeSubPetSelected}
                            onChange={changeHandler}
                            isMultiplePet={props.isMultiplePet}
                        />
                    </Box>
                )}
        </Box>
    );

    const singleGrids = (
        <Box>
            {serviceVariablePrice !== undefined &&
                serviceVariablePrice.prices !== undefined &&
                serviceVariablePrice.prices.length > 0 && (
                    <Box className={classes.gridContainer}>
                        <NewGrid
                            errors={errors}
                            id="services_pricetype_grid_single"
                            service={props.service}
                            vPConst={VPCONSTANTS}
                            serviceVariablePrice={serviceVariablePrice}
                            priceTypeSelected={props.priceTypeSelected.id}
                            petType={props.service.petTypes[0].id}
                            subPetSelected={
                                props.service.petTypes.length > 1
                                    ? props.subPetSelected
                                    : undefined
                            }
                            changeSubPetSelected={props.changeSubPetSelected}
                            onChange={changeHandler}
                            isMultiplePet={props.isMultiplePet}
                        />
                    </Box>
                )}
        </Box>
    );

    return (
        <Box className={classes.container}>
            <Fragment>
                {isEmpty(props.errors) && (
                    <Typography>{props.errors[0]?.errorMessage}</Typography>
                )}
                {props.isMultiplePet === false && singleGrids}
                {props.isMultiplePet === true && multipleSelectionGrids}
            </Fragment>
        </Box>
    );
};

export default NewVariablePrice;
