import React, { FunctionComponent, useState } from 'react';
import { faEllipsisH, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	ClickAwayListener
} from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

export interface Props extends PropsWithChildren {
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		containerDropDown: {
			display: 'flex',
			position: 'relative',
			width: '30px',
			justifyContent: 'center'
		},
		dropdown: {
			display: 'flex',
			position: 'absolute',
			width: 'auto',
			right: '5px',
			zIndex: 10,
			backgroundColor: 'white',
			borderStyle: 'solid',
			borderWidth: '1px',
			[theme.breakpoints.only('xs')]: {
				top: '50px',
				borderRadius: '8px',
				paddingLeft: '10px',
				paddingRight: '10px',
				paddingTop: '5px',
				paddingBottom: '5px'
			},
			[theme.breakpoints.only('sm')]: {
				top: '50px',
				borderRadius: '8px',
				paddingLeft: '10px',
				paddingRight: '10px',
				paddingTop: '5px',
				paddingBottom: '5px'
			},
			[theme.breakpoints.only('md')]: {
				top: '50px',
				borderRadius: '9px',
				paddingLeft: '15px',
				paddingRight: '15px',
				paddingTop: '8px',
				paddingBottom: '8px'
			},
			[theme.breakpoints.only('lg')]: {
				top: '65px',
				borderRadius: '10px',
				paddingLeft: '20px',
				paddingRight: '20px',
				paddingTop: '10px',
				paddingBottom: '10px'
			},
			[theme.breakpoints.only('xl')]: {
				top: '65px',
				borderRadius: '15px',
				paddingLeft: '30px',
				paddingRight: '30px',
				paddingTop: '12px',
				paddingBottom: '12px'
			}
		},
		dropdownItemsContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},
		icon: {
			cursor: 'pointer'
		}
	})
);

export const ToolbarButtonClients: FunctionComponent<Props> = props => {
	const classes = useStyles();
	const [show, setShow] = useState(false);

	const clickToolbarButtonClientsHandler = (): void => {
		setShow(prev => {
			return !prev;
		});
	};

	return (
		<ClickAwayListener onClickAway={() => setShow(false)}>
			<Box
				className={clsx(classes.containerDropDown, props.className)}
				onClick={clickToolbarButtonClientsHandler}
			>
				<Box className={classes.icon}>
					{show ? (
						<FontAwesomeIcon color="#92B4A7" icon={faEllipsisV} />
					) : (
						<FontAwesomeIcon color="#000000" icon={faEllipsisH} />
					)}
				</Box>
				{show && (
					<ClickAwayListener onClickAway={() => setShow(false)}>
						<Box className={classes.dropdown}>
							<Box className={classes.dropdownItemsContainer}>
								{props.children}
							</Box>
						</Box>
					</ClickAwayListener>
				)}
			</Box>
		</ClickAwayListener>
	);
};

export default ToolbarButtonClients;
