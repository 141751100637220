import useNonInitialEffect from '@versiondos/hooks';
import ActionCable, { Cable, Channel } from 'actioncable';
import { addThunk } from 'actions/userNotifications/userNotificationActions';

import { useAuth } from 'hooks';
import { OnboardingSteps as OnboardingStepsModel } from 'model/OnboardingSteps';
import { UserNofiticationsWebSocketsMessage } from 'model/WebSockets';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { debugConsoleLog } from 'utils/GeneralUtils';

const UserNotificationsWebSockets: FunctionComponent = () => {
	const auth = useAuth();
	const dispatch = useDispatch();
	const onBoardingProgress = useSelector<RootState, OnboardingStepsModel>(
		state => state.onboardingSteps.onboardingSteps
	);

	const isOnBoardingCompleted =
		onBoardingProgress.businessHoursDone &&
		onBoardingProgress.teamDone &&
		onBoardingProgress.petTypesDone &&
		onBoardingProgress.servicesDone &&
		onBoardingProgress.clientsDone &&
		onBoardingProgress.bookingDone;

	const prepareWebSocket = () => {
		let cable: Cable;
		let channel: Channel;

		//console.info({ "authData": auth, "signed": auth.signedIn });

		if (
			auth.signedIn &&
			isOnBoardingCompleted &&
			process.env.REACT_APP_NOTIFICATIONS_ENABLED?.toLowerCase() ===
			'true'
		) {
			[cable, channel] = subscribe(
				auth.marketplaceId!,
				auth.user!.staffId
			);
		}

		return () => {
			unsubscribe(cable, channel);
		};
	};

	useEffect(() => {
		prepareWebSocket();
	}, []);

	useNonInitialEffect(() => {
		prepareWebSocket();
	}, [auth, onBoardingProgress]);

	const subscribe = (
		marketplaceId: number,
		staffId: number
	): [Cable, Channel] => {
		const cable = ActionCable.createConsumer(
			`${process.env.REACT_APP_WEBSOCKETS_HOST_URL}${process.env.REACT_APP_WEBSOCKETS_BASE_URL}?access_token=${auth.token}`
		);

		const channel = cable!.subscriptions.create(
			{
				channel: 'NotificationsChannel',
				marketplace_id: marketplaceId,
				staff_id: staffId
			},
			{ received: (message: any) => messageHandler(message) }
		);

		debugConsoleLog({
			action: 'Notifications subscribed',
			channel
		});

		return [cable, channel];
	};

	const unsubscribe = (cable: Cable, channel: Channel) => {
		cable && cable.disconnect();
		channel && channel.unsubscribe();
	};

	const messageHandler = (message: UserNofiticationsWebSocketsMessage) => {
		debugConsoleLog({
			action: 'Notifications message received',
			message
		});

		if (message.notification && message.notification_receipt) {
			const receipt = {
				...message.notification_receipt,
				notification_type_id: message.notification.type_id,
				notification_body: message.notification.body,
				notification_subject: message.notification.subject
			};
			dispatch(addThunk(receipt));
		} else {
			debugConsoleLog('Incorrect format: ' + message);
		}
	};
	return null;
};

export default UserNotificationsWebSockets;
