import {
    Box,
    createStyles,
    List,
    ListItem,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { MarketplaceTax } from '@spike/marketplace-model';
import { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { wbp, reduceResolution } from 'Theme';
import Checkbox from '../Checkbox';
import { useMarketplace } from 'hooks';
import useNonInitialEffect from '@versiondos/hooks';
import { Button } from 'components/UI';
import { FieldError } from '@spike/model';
import { useFieldStyles } from '../FieldStyles';
import clsx from 'clsx';

interface TaxesSelectorProps {
    id?: string;
    title?: string;
    subtitle?: string;
    name?: string;
    checked?: boolean;
    onSave?: (selectedTaxes: Array<MarketplaceTax>) => void;
    onContinue?: (selectedTaxes: Array<MarketplaceTax>) => void;
    onChange?: (selectedTaxes: Array<MarketplaceTax>) => void;
    className?: string;
    selectedTaxes: Array<MarketplaceTax>;
    onTaxClick?: () => void;
    errors?: Array<FieldError>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        taxesDisplayContainer: {
            [theme.breakpoints.down(wbp)]: {
                marginBottom: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginBottom: '30px'
            }
        },
        title: {
            '& span p': {
                width: 'auto !important'
            },
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`,
                lineHeight: `${reduceResolution(42)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px',
                lineHeight: '42px'
            }
        },

        subtitle: {
            fontSize: 14,
            marginTop: 12,
            color: '#969696',

            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        taxDisplayContainer: {
            marginTop: 24,
            borderRadius: 14
        },

        taxContainer: {
            'gap': 8,
            'padding': '15px 0px',

            '&:not(:last-child)': {
                borderBottom: 'solid 1px #D4D4D4'
            },
            '& .MuiButtonBase-root': {
                padding: 0
            },
            '& .MuiTypography-root': {
                display: 'none'
            }
        },
        taxLabel: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 500,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        taxPercentage: {
            color: 'grey',
            marginLeft: 'auto'
        },
        taxDivider: {
            width: '85%',
            margin: 'auto',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '10px'
            }
        },
        warningText: {
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '40px'
            },
            fontWeight: 600
        },
        /** Deposit Alerts */
        depositAlert: {
            'display': 'flex',
            'backgroundColor': '#F8F5F1',
            'border': '2px solid #BCB8AE',
            'borderRadius': '10px',
            'padding': '14px 16px 16px',

            '& b': {
                fontWeight: 600
            }
        },
        alertForByPetSize: {
            width: '40%',
            margin: '0px auto'
        },
        alertForByPetSizeAndHairLength: {
            width: '60%',
            marginLeft: '5%',
            marginBottom: '2%'
        },
        alertForByHairLength: {},
        alertForCatsByPetSize: {
            width: '95%',
            marginTop: '7%',
            marginBottom: '2%'
        },
        showAlert: {
            display: 'flex'
        },

        /** Span Messages */
        iconInfo: {},
        sectionIconInfo: {
            'color': '#BAA997',
            '&:hover': {
                cursor: 'pointer'
            },
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(24)}px`,
                paddingRight: `${reduceResolution(10)}px`,
                marginTop: `${reduceResolution(2)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '24px',
                paddingRight: '10px',
                marginTop: '2px'
            }
        },
        boxIcon: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(38)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '38px'
            }
        },
        spanMessage: {
            fontSize: 14,
            lineHeight: 1.3,
            fontWeight: 400
        },
        linkWrapper: {
            textDecoration: 'none',
            marginTop: '20px'
        },
        linkButton: {
            'marginTop': '10px',
            'paddingRight': 20,
            'paddingLeft': 20,
            'height': 40,

            '& .MuiButton-label': {
                fontSize: 14
            }
        },
        listWrapper: {
            padding: '10px 22px',
            borderRadius: '25px',
            backgroundColor: '#FAFAFA',

            [theme.breakpoints.up('md')]: {
                display: 'inline-flex'
            }
        },
        taxList: {
            minWidth: 200,

            [theme.breakpoints.up('md')]: {
                minWidth: 250
            }
        },
        BoxTaxable: {
            marginTop: 15,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                alignItems: 'flex-start'
            }
        },
        labelError: {
            fontWeight: 500,
            marginLeft: 12,
            [theme.breakpoints.down('md')]: {
                marginTop: 10,
                marginLeft: 0,
                fontSize: 14
            },
            [theme.breakpoints.down('sm')]: {
                fontWeight: 400
            }
        }
    })
);

export const TaxesSelector: FunctionComponent<TaxesSelectorProps> = props => {
    const classes = useStyles();
    const classesFields = useFieldStyles();

    const marketplace = useMarketplace();
    const [checked, setChecked] = useState(props.checked ?? false);
    const [selectedTaxes, setSelectedTaxes] = useState([
        ...props.selectedTaxes
    ]);
    const [taxes] = useState([
        ...marketplace.taxes.map(tax => ({
            uuid: tax.uuid,
            id: tax.id,
            name: tax.name,
            percentage: tax.percentage,
            selected:
                selectedTaxes
                    .map(selectedTax => selectedTax.uuid)
                    .indexOf(tax.uuid) == -1
                    ? false
                    : true
        }))
    ]);

    useNonInitialEffect(() => {
        props.onChange && props.onChange(selectedTaxes);
    }, [selectedTaxes]);

    const returnTaxIndex = (uuid: string) => {
        const taxIndex = taxes.map(tax => tax.uuid).indexOf(uuid);
        return taxIndex;
    };

    const isSelected = (uuid: string): boolean => {
        return taxes[returnTaxIndex(uuid)].selected;
    };

    const handleClick = () => {
        if (checked == false) {
            setChecked(true);
            setSelectedTaxes([]);
        } else {
            setChecked(false);
            setSelectedTaxes([]);
        }
    };

    const updateSelectedTaxes = (taxIndex: number, newSelection: boolean) => {
        let taxChecksMod = [...taxes];
        taxChecksMod[taxIndex].selected = newSelection;
        taxChecksMod = taxChecksMod.filter(tax => tax.selected == true);
        const taxChecksModMap = taxChecksMod.map(tax => ({
            id: tax.id,
            uuid: tax.uuid,
            name: tax.name,
            percentage: tax.percentage
        }));
        setSelectedTaxes(taxChecksModMap);
    };

    const handleTaxCheck = (uuid: string) => {
        const taxIndex = returnTaxIndex(uuid);
        if (
            taxes.filter((tax: MarketplaceTax) => tax.uuid == uuid)[0]
                .selected == false
        ) {
            updateSelectedTaxes(taxIndex, true);
        } else {
            updateSelectedTaxes(taxIndex, false);
        }
    };
    const iconSVG = (icon: IconDefinition, styles: string) => {
        return <FontAwesomeIcon icon={icon} className={styles} />;
    };

    return (
        <Box
            id={props.id}
            className={props.className ?? classes.taxesDisplayContainer}
        >
            <Box className={classes.BoxTaxable}>
                <Checkbox
                    iconColor={'black'}
                    id={props.id ? props.id + '_checkbox_taxable' : props.id}
                    fontWeight={500}
                    className={classes.title}
                    label={props.title ?? 'Taxable'}
                    checked={checked}
                    onChange={handleClick}
                ></Checkbox>

                {props.errors && props.errors.length > 0 && (
                    <Typography
                        className={clsx(
                            classesFields.labelError,
                            classes.labelError
                        )}
                    >
                        {props.errors[0].errorMessage}
                    </Typography>
                )}
            </Box>

            <Typography className={classes.subtitle}>
                {props.subtitle ?? 'Choose the applicable tax: '}
            </Typography>

            {checked == true && (
                <Box className={classes.taxDisplayContainer}>
                    {taxes.length > 0 ? (
                        <Box className={classes.listWrapper}>
                            <List disablePadding className={classes.taxList}>
                                <Box>
                                    {taxes.map((tax, index) => {
                                        return (
                                            <ListItem
                                                key={tax.uuid}
                                                className={classes.taxContainer}
                                            >
                                                <Checkbox
                                                    iconColor={'black'}
                                                    fontWeight={500}
                                                    label={''}
                                                    checked={
                                                        isSelected(tax.uuid) ??
                                                        false
                                                    }
                                                    onChange={() =>
                                                        handleTaxCheck(tax.uuid)
                                                    }
                                                />
                                                <span
                                                    className={classes.taxLabel}
                                                >
                                                    {tax.name}
                                                </span>
                                                <span
                                                    className={
                                                        classes.taxPercentage
                                                    }
                                                >
                                                    {tax.percentage}%
                                                </span>
                                            </ListItem>
                                        );
                                    })}
                                </Box>
                            </List>
                        </Box>
                    ) : (
                        <Box className={classes.depositAlert}>
                            <Box>
                                <Box className={classes.boxIcon}>
                                    {iconSVG(
                                        faInfoCircle,
                                        `${classes.sectionIconInfo}`
                                    )}
                                </Box>
                            </Box>
                            <Box>
                                <Typography className={classes.spanMessage}>
                                    You have not added any tax yet. Click below
                                    to add taxes or go to{' '}
                                    <strong>Settings</strong> and then click on{' '}
                                    <strong>Taxes</strong>.
                                </Typography>
                                <Box className={classes.linkWrapper}>
                                    <Button
                                        id={
                                            props.id
                                                ? props.id + '_button_add_tax'
                                                : props.id
                                        }
                                        label="Add Tax"
                                        color="green"
                                        size="medium"
                                        className={classes.linkButton}
                                        onClick={() =>
                                            props.onTaxClick &&
                                            props.onTaxClick()
                                        }
                                    ></Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};
export default TaxesSelector;
