import {
    Box,
    InputAdornment,
    InputBaseComponentProps,
    TextField as MUITextField
} from '@material-ui/core';
import { FieldError } from '@spike/model';
import clsx from 'clsx';
import { ChangeEvent, FunctionComponent, KeyboardEvent } from 'react';
import { useFieldStyles } from './FieldStyles';
import LabelField from './LabelField';
import { customCapitalization } from 'utils/ValidationUtils';

export interface ControlledTextField2Props {
    name?: string;
    label?: string | JSX.Element;
    id?: string;
    placeholder?: string;
    value: string;
    required?: boolean;
    errors?: Array<FieldError>;
    onBlur?: () => void;
    onChange: (value: string, name?: any) => void;
    onFocus?: () => void;
    labelClassName?: string;
    className?: string;
    autoFocus?: boolean;
    autoComplete?: 'off' | 'on';
    endIcon?: JSX.Element;
    startIcon?: JSX.Element;
    multiline?: boolean;
    onEnterPressed?: () => void;
    disabled?: boolean;
    capitalize?: boolean;
    upperCase?: boolean;
    maxLength?: number;
    allowSpecialCharacters?: boolean;
    withEmojis?: boolean;
}

export const ControlledTextField2: FunctionComponent<
    ControlledTextField2Props
> = props => {
    const classes = useFieldStyles();

    const hasError = props.errors?.some(
        error => error.fieldName === props.name
    );

    const changeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        let newValue = event.target.value;

        // Apply custom capitalization based on the `capitalize` prop and whether special characters are allowed
        if (props.capitalize) {
            newValue = customCapitalization(
                newValue,
                props.allowSpecialCharacters ?? false,
                props.withEmojis
            );
        } else if (props.upperCase) {
            newValue = newValue.toUpperCase();
        }

        props.onChange(newValue, props.name);
    };

    const onFocusHandler = (event: any): void => {
        props.onFocus && props.onFocus();
    };

    const onBlurHandler = (event: any): void => {
        props.onBlur && props.onBlur();
    };

    const keyPressHandler = (event: KeyboardEvent) => {
        event.key === 'Enter' && props.onEnterPressed && props.onEnterPressed();
    };

    let inputProps = {};

    if (props.endIcon) {
        inputProps = {
            ...inputProps,
            endAdornment: (
                <InputAdornment position="end">{props.endIcon}</InputAdornment>
            )
        };
    }

    if (props.startIcon) {
        inputProps = {
            ...inputProps,
            startAdornment: (
                <InputAdornment position="start">
                    {props.startIcon}
                </InputAdornment>
            )
        };
    }

    let properties: InputBaseComponentProps | undefined = undefined;
    if (props.capitalize && (props.value ?? '').length > 0) {
        properties = { style: { textTransform: 'capitalize' } };
    } else if (props.upperCase && (props.value ?? '').length > 0) {
        properties = { style: { textTransform: 'uppercase' } };
    }

    if (props.maxLength) {
        properties = { ...properties, maxLength: props.maxLength };
    }

    return (
        <Box className={clsx(classes.container, props.className)}>
            <LabelField {...props} />
            <Box>
                <MUITextField
                    name={props.name}
                    placeholder={props.placeholder}
                    id={props.id}
                    variant="outlined"
                    value={props.value}
                    onBlur={onBlurHandler}
                    onChange={changeHandler}
                    onFocus={onFocusHandler}
                    autoFocus={props.autoFocus}
                    autoComplete={'nope'}
                    className={clsx(classes.field, {
                        [classes.fieldWithStartAdornment]: props.startIcon,
                        [classes.fieldWithEndAdornment]: props.endIcon,
                        [classes.emptyField]: props.value.length === 0,
                        [classes.fieldError]: hasError
                    })}
                    error={hasError}
                    InputProps={inputProps}
                    multiline={props.multiline}
                    onKeyPress={keyPressHandler}
                    disabled={props.disabled}
                    inputProps={properties}
                />
            </Box>
        </Box>
    );
};

export default ControlledTextField2;
