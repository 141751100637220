import replace from 'lodash/replace';
import { Location } from 'history';

export const LoginPath = '/login';
export const ImpersonatePath = '/impersonate/:marketplaceId/:token';
export const LogoutPath = '/logout';
export const ForgotPasswordPath = '/forgotpassword';
export const ForgotPasswordSentPath = '/forgotpassword_sent';
export const ResetPasswordPath = '/resetpassword/:token';
export const NewPasswordPath = '/newpassword';
export const ResetCustomerPath = '/resetpassword_customer/:token';
export const SignUpPath = '/signup';
export const SignUpAlmostPath = '/signup/almost';
export const SignUpBusinessPath = '/signup/business';
export const SignUpVerifyPath = '/signup/verify';
export const ActivateAccountPath = '/activate_business/:token';
export const AgreementsSignaturePath = '/agreements/signature/:hash';
export const TwoWaySMSPath = '/sms';

export const HomePath = '/';
export const CalendarPath = '/calendar';
export const BookingsPath = '/bookings';
export const BookingDetailPath = '/bookings/:hash';
export const ServicesPath = '/services';
export const CreateServiceAddonPath = '/services/addons/create';
export const GlobalPath = '/globalResult/:query';
export const CreationServicePath = '/services/:create';
export const ClientsPath = '/clients';
export const AgreementsPath = '/clients#agreements';
export const ClientsEditPath = '/clients/:hash';
export const StaffPath = '/staff';
export const BusinessSettingsPath = '/business_settings';
export const MyAccountPath = '/my_account';
export const InsightsPath = '/insights';
export const ProductsPath = '/products';
export const PaymentsPath = '/payments';
export const HelpCenterPath = 'https://help.cuddlesapp.com/kb/en';
export const HelpSupportPath = '/help_support';
/**
 * Marketing route paths
 */
export const MarketingPath = '/marketing';
export const SMSGetStartedPath = '/marketing/sms/get-started';
export const SMSCampaignCreatePath = '/marketing/sms/create';

/**
 * Settings route paths
 */

export const BusinessPlanPath = '/business_settings/info/plans';

export const isPublic = (location: Location): boolean => {
    const path = location.pathname;

    const publicPaths = [
        LoginPath,
        ImpersonatePath,
        LogoutPath,
        ForgotPasswordPath,
        ForgotPasswordSentPath,
        ResetPasswordPath,
        NewPasswordPath,
        ResetCustomerPath,
        SignUpPath,
        SignUpAlmostPath,
        SignUpBusinessPath,
        SignUpVerifyPath,
        ActivateAccountPath,
        AgreementsSignaturePath
    ].map(path => replace(path, /\/:.*/g, ''));

    return publicPaths.some(p => path.startsWith(p));
};

export const isPrivate = (location: Location): boolean => {
    return !isPublic(location);
};

export const requiresCleanUp = (location: Location): boolean => {
    const path = location.pathname;

    const avoidCleanUpPublicPaths = [
        ForgotPasswordSentPath,
        NewPasswordPath,
        SignUpAlmostPath,
        SignUpBusinessPath,
        SignUpVerifyPath
    ].map(path => replace(path, /\/:.*/g, ''));

    return (
        isPublic(location) &&
        !avoidCleanUpPublicPaths.some(p => path.startsWith(p))
    );
};

export type Path =
    | typeof LoginPath
    | typeof ForgotPasswordPath
    | typeof ForgotPasswordSentPath
    | typeof ResetPasswordPath
    | typeof ResetCustomerPath
    | typeof SignUpPath
    | typeof SignUpAlmostPath
    | typeof SignUpBusinessPath
    | typeof SignUpVerifyPath
    | typeof ActivateAccountPath
    | typeof HomePath
    | typeof CalendarPath
    | typeof GlobalPath
    | typeof BookingsPath
    | typeof BookingDetailPath
    | typeof ServicesPath
    | typeof CreationServicePath
    | typeof ClientsPath
    | typeof StaffPath
    | typeof BusinessSettingsPath
    | typeof MyAccountPath
    | typeof InsightsPath
    | typeof ProductsPath
    | typeof PaymentsPath
    | typeof AgreementsSignaturePath
    | typeof HelpCenterPath
    | typeof HelpSupportPath;
