import { FunctionComponent, Fragment, useState } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { Status } from 'model';
import Service, { createEmptyService } from 'model/Service';
import { useMasterData, useMarketplace } from 'hooks';
import { Button } from 'components/UI';
import { ServicesStatus } from 'reducers/services/ServicesState';
import Success from './Success';
import UnsavedChangesPopup from 'components/UI/UnsavedChangesPopup';
import { Prompt, useHistory } from 'react-router';
import { Location, Action } from 'history';
import { useDispatch } from 'react-redux';
import useNonInitialEffect from '@versiondos/hooks';
import { fetchOnboardingStepsThunk } from 'actions/onboardingSteps/OnboardingStepsActions';
import ServiceEdit from './ServiceEdit';
import ServiceResume from './ServiceResume';
import { ServiceCategoryType } from './model';
import {
    validateCategoryStep,
    validatePricingStep,
    validateService,
    validateServiceComplete,
    validateServiceDuration,
    validateServiceName
} from './Validations';
import { isEmpty, isEqual } from 'lodash';
import { BusinessSettingsPath } from 'routes/Paths';

interface ServiceComponentProps {
    service?: Service;
    addStaff: boolean;
    services: Array<Service>;
    onBack: () => void;
    onSave: (service: Service, changedName?: boolean) => void;
}

interface SectionState {
    collapsed: boolean;
    disabled: boolean;
    editable: boolean;
    completed: boolean;
}

enum ServiceStep {
    EditStep,
    FinishStep,
    SuccessStep
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scroll: {
            height: '100%',
            width: '100%'
        },
        container: {
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            [theme.breakpoints.down('md')]: {
                paddingBottom: '0px'
            }
        },
        headerContainer: {
            gap: 10,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#fff',
            padding: '26px 16px 22px',
            boxShadow: 'none',

            [theme.breakpoints.up('lg')]: {
                top: 0,
                gap: 20,
                zIndex: 900,
                position: 'sticky',
                padding: '20px 100px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)'
            },
            [theme.breakpoints.up('xl')]: {
                padding: '20px 208px'
            }
        },
        alertContainer: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: '15px',
                marginRight: '15px'
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: '20px'
            }
        },
        bodyContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: 'fit-content',

            [theme.breakpoints.up('lg')]: {
                paddingLeft: 100,
                paddingRight: 100
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: 208,
                paddingRight: 208
            }
        },
        successContainer: {
            height: '100%'
        },
        backContainer: {
            cursor: 'pointer',
            fontSize: 20,

            [theme.breakpoints.up('lg')]: {
                fontSize: 32
            }
        },
        titleButtonContainer: {
            width: '100%',

            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'space-between'
            }
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '50%',
            [theme.breakpoints.down('md')]: {
                width: '100%'
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                justifyContent: 'flex-start'
            }
        },
        title: {
            fontWeight: 600,
            [theme.breakpoints.down('lg')]: {
                fontSize: '27px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '36px'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '20px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '93%'
            }
        },
        newService: {
            [theme.breakpoints.down('lg')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '14px'
            }
        },
        saveDraftButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        saveDraftButton: {
            [theme.breakpoints.down('lg')]: {
                width: '127px',
                height: '36px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '170px',
                height: '49px'
            },
            '& .MuiButton-label': {
                [theme.breakpoints.down('lg')]: {
                    fontSize: '13px'
                },
                [theme.breakpoints.up('xl')]: {
                    fontSize: '16px'
                }
            }
        },
        sectionContainer: {
            display: 'flex',

            [theme.breakpoints.up('lg')]: {
                minWidth: '750px',
                marginTop: '30px'
            },
            [theme.breakpoints.down('md')]: {
                margin: '10px'
            }
        },
        sectionContainerFixedPrice: {},
        sectionContainerCategory: {
            [theme.breakpoints.down('md')]: {
                marginTop: '0px'
            }
        },
        lastSectionContainer: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                marginTop: '30px'
            },
            [theme.breakpoints.down('md')]: {
                margin: '10px'
            },
            [theme.breakpoints.up('xl')]: {
                minWidth: '1000px',
                marginTop: '30px'
            }
        },
        finishContainer: {
            padding: '0px 16px'
        },
        saveMessage: {
            width: '100%',
            textAlign: 'left',
            [theme.breakpoints.down('lg')]: {
                fontSize: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '15px'
            }
        },
        finishButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 32,
            marginBottom: 24,

            [theme.breakpoints.up('md')]: {
                marginTop: '42px',
                marginBottom: '53px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '57px',
                marginBottom: '71px'
            },
            [theme.breakpoints.down('sm')]: {
                '& > button': {
                    'width': '100%',
                    'height': '50px',
                    'borderRadius': '31px',
                    '& .MuiButton-label': {
                        fontSize: '18px'
                    }
                }
            }
        },
        finishButton: {
            [theme.breakpoints.down('lg')]: {
                width: '127px',
                height: '36px'
            },
            [theme.breakpoints.up('xl')]: {
                height: '49px',
                width: '170px'
            },
            '& .MuiButton-label': {
                [theme.breakpoints.down('lg')]: {
                    fontSize: '13px'
                },
                [theme.breakpoints.up('xl')]: {
                    fontSize: '16px'
                }
            }
        },
        confirmBackQuestion: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '14px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '16px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '18px'
            }
        },
        separator: {
            display: 'none',
            backgroundColor: '#F4F3F0',
            height: '16px',
            width: '110%',
            marginLeft: '-5%',
            [theme.breakpoints.down('md')]: {
                display: 'block'
            }
        }
    })
);

export const ServiceComponent: FunctionComponent<
    ServiceComponentProps
> = props => {
    const classes = useStyles();
    const masterData = useMasterData();
    const marketplace = useMarketplace();
    const history = useHistory();
    const dispatch = useDispatch();

    const hasNotPetTypes = marketplace.petTypes
        ? !marketplace.petTypes.cats &&
          !marketplace.petTypes.dogs &&
          !marketplace.petTypes.exotics
        : false;

    const status = useSelector<RootState, ServicesStatus>(
        state => state.services.status
    );

    const servicesDone = useSelector<RootState, boolean>(
        state => state.onboardingSteps.onboardingSteps.servicesDone ?? false
    );

    const [service, setService] = useState(
        props.service
            ? props.service
            : createEmptyService(masterData, marketplace)
    );

    const [title, setTitle] = useState<string | undefined>(props.service?.name);

    const [changedName, setChangedName] = useState(false);

    const [editedService, setEditedService] = useState<boolean>(false);

    const [categoryId, setCategoryId] = useState<number>(
        ServiceCategoryType.CATEGORY
    );

    const [step, setStep] = useState<ServiceStep>(
        props.service === undefined
            ? ServiceStep.EditStep
            : ServiceStep.FinishStep
    );

    const [leavingPath, setLeavingPath] = useState<string | undefined>();

    const [saving, setSaving] = useState(false);

    const [showKeepEditingDialog, setShowKeepEditingDialog] =
        useState<boolean>(false);

    const [showSaveConfirmationDialog, setShowSaveConfirmationDialog] =
        useState<boolean>(false);

    useNonInitialEffect(() => {
        if (status === ServicesStatus.SaveSuccess) {
            dispatch(fetchOnboardingStepsThunk());
            setSaving(false);
            setChangedName(false);
            if (!leavingPath) {
                setStep(ServiceStep.SuccessStep);
            } else {
                history.push(`${BusinessSettingsPath}/sales/taxes`);
                setLeavingPath(undefined);
            }
        } else if (status === ServicesStatus.DeleteSuccess) {
            if (servicesDone === true) {
                dispatch(fetchOnboardingStepsThunk());
            }
        } else if (status === ServicesStatus.Error) {
            setSaving(false);
        }
    }, [status]);

    const serviceChangeHandler = (updatedService: Service) => {
        setEditedService(
            !isEqual(
                props.services.find(
                    service => service.id === props.service?.id
                ),
                updatedService
            )
        );

        if (!isEqual(service.petTypes, updatedService.petTypes)) {
            setService(prev => ({
                ...prev,
                pricing: {
                    fixedPrice: [],
                    taxes: [],
                    variablePrice: undefined,
                    taxable: false
                }
            }));
        }
        setService(updatedService);
    };

    const editHandler = (viewId: number) => {
        setCategoryId(viewId);
        setStep(ServiceStep.EditStep);
    };

    const titleChangeHandler = (text: string) => {
        setTitle(text);
        setChangedName(true);
    };

    const handlePrompt = (location: Location<unknown>, action: Action) => {
        if (leavingPath === undefined) {
            setLeavingPath(location.pathname);
            return false;
        }
        return true;
    };

    const backHandler = () => {
        const isValidService = validateServiceComplete(service);
        const isPricingStepValid = validatePricingStep(service);
        const isServiceNameValid = isEmpty(validateServiceName(service));
        const isDurationValid = isEmpty(validateServiceDuration(service));

        if (editedService && isValidService) {
            setShowSaveConfirmationDialog(true);
        } else if (
            isEqual(service, props.service) ||
            (!isEqual(service, props.service) &&
                !isServiceNameValid &&
                !isDurationValid &&
                !isPricingStepValid)
        ) {
            props.onBack && props.onBack();
        } else {
            setShowKeepEditingDialog(true);
        }
    };

    const backConfirmHandler = () => {
        if (leavingPath === undefined) {
            props.onBack();
        } else {
            history.push(leavingPath);
        }
    };

    const finishHandler = () => {
        setSaving(true);
        if (service.staff.length > 0 && service.status.id === Status.INACTIVE) {
            props.onSave(service, changedName);
        } else {
            const statusId: Status =
                service.staff.length === 0 ? Status.DRAFT : Status.ACTIVE;

            const status = masterData.status.find(s => s.id === statusId)!;

            props.onSave({ ...service, status }, changedName);
        }
    };

    const saveHandler = (updatedService: Service) => {
        const isServiceValid = validateService(updatedService);
        if (
            !showSaveConfirmationDialog &&
            isServiceValid &&
            step === ServiceStep.EditStep
        ) {
            setStep(ServiceStep.FinishStep);
        }
        if (isServiceValid && step === ServiceStep.FinishStep) {
            finishHandler();
        }
    };

    const addTaxesHandler = () => {
        const isValidService = validateServiceComplete(service);
        const categoryStepValidated = validateCategoryStep(service);
        if (!isValidService && categoryStepValidated) {
            setLeavingPath(`${BusinessSettingsPath}/sales/taxes`);
            setShowKeepEditingDialog(true);
        } else if (isValidService && !isEqual(props.service, service)) {
            setLeavingPath(`${BusinessSettingsPath}/sales/taxes`);
            setShowSaveConfirmationDialog(true);
        } else {
            history.push(`${BusinessSettingsPath}/sales/taxes`);
        }
    };

    const keepEditingDialog = (
        <UnsavedChangesPopup
            onDiscard={backConfirmHandler}
            onSave={() => {
                setShowKeepEditingDialog(false);
            }}
            onBackdropClick={() => setShowKeepEditingDialog(false)}
            questionTitle={`Exit without saving?`}
            questionSubtitle="Your unsaved changes will be lost."
            saveButtonLabel="Keep editing"
        />
    );

    const saveConfirmationDialog = (
        <UnsavedChangesPopup
            onDiscard={backConfirmHandler}
            onBackdropClick={() => setShowSaveConfirmationDialog(false)}
            onSave={() => {
                setShowSaveConfirmationDialog(false);
                finishHandler();
            }}
        />
    );

    const header = (
        <Fragment>
            <Box className={classes.backContainer} onClick={backHandler}>
                <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            <Box className={classes.titleButtonContainer}>
                <Box className={classes.titleContainer}>
                    <Typography className={classes.title}>
                        {title ? title : 'Adding a Service'}
                    </Typography>
                </Box>
                <Box className={classes.saveDraftButtonContainer}>
                    {step === ServiceStep.FinishStep && (
                        <Button
                            id="services_add_service_button_save"
                            label="Save"
                            disabled={!editedService}
                            onClick={finishHandler}
                            loading={saving}
                            className={classes.saveDraftButton}
                        />
                    )}
                </Box>
            </Box>
        </Fragment>
    );

    return (
        <Fragment>
            {!hasNotPetTypes && <Prompt message={handlePrompt} />}
            <Box className={classes.container}>
                {step !== ServiceStep.SuccessStep && (
                    <Box className={clsx(classes.headerContainer)}>
                        {header}
                    </Box>
                )}
                <Box
                    className={clsx(classes.bodyContainer, {
                        [classes.successContainer]:
                            step === ServiceStep.SuccessStep
                    })}
                >
                    {step === ServiceStep.EditStep && (
                        <ServiceEdit
                            selectedCategory={categoryId}
                            service={service}
                            onSave={saveHandler}
                            onChangeService={serviceChangeHandler}
                            onChangeServiceName={titleChangeHandler}
                            onAddTaxes={addTaxesHandler}
                        />
                    )}
                    {step === ServiceStep.FinishStep && (
                        <ServiceResume
                            service={service}
                            loading={saving}
                            isServiceEdited={!editedService}
                            onEdit={editHandler}
                            onSave={finishHandler}
                        />
                    )}
                    {step === ServiceStep.SuccessStep && (
                        <Success
                            isEditMode={props.service !== undefined}
                            onOk={backConfirmHandler}
                            onAddStaff={() => {}}
                            onDoLater={backConfirmHandler}
                            service={service}
                        />
                    )}
                </Box>
                {showKeepEditingDialog && keepEditingDialog}
                {showSaveConfirmationDialog && saveConfirmationDialog}
            </Box>
        </Fragment>
    );
};

export default ServiceComponent;
