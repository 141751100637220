import { Box, ClickAwayListener } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Period } from '@spike/model';
import { FunctionComponent, useMemo, useState } from 'react';
import PrevNextSelector from './PrevNextSelector';
import WeekSelectorDropdown from './WeekSelectorDropdown';

interface WeekSelectorProps {
    week: Period;
    onChange?: (week: Period) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative'
        },
        calendarContainer: {
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            top: '45px',
            zIndex: 10,
            left: '-30%',
            right: '-30%'
        }
    })
);

export const WeekSelector: FunctionComponent<WeekSelectorProps> = props => {
    const classes = useStyles();

    const [showCalendar, setShowCalendar] = useState(false);

    const label = useMemo(() => {
        const startMonth = props.week.from.format('MMMM').toUpperCase();
        const endMonth = props.week.to.format('MMMM').toUpperCase();
        let title;
        startMonth === endMonth
            ? (title = `${props.week.from
                  .format('MMMM')
                  .toUpperCase()} ${props.week.from.format(
                  'D'
              )} - ${props.week.to.format('D')}, ${props.week.to.format(
                  'YYYY'
              )}`)
            : (title = `${props.week.from
                  .format('MMMM')
                  .toUpperCase()} ${props.week.from.format(
                  'D'
              )} - ${endMonth} ${props.week.to.format(
                  'D'
              )}, ${props.week.to.format('YYYY')}`);
        return title;
    }, [props.week]);

    const changeWeek = (days: number) => {
        props.onChange &&
            props.onChange({
                from: props.week.from.clone().add(days, 'days'),
                to: props.week.to.clone().add(days, 'days')
            });
    };

    const clickPrevHandler = () => {
        changeWeek(-7);
    };

    const clickNextHandler = () => {
        changeWeek(7);
    };

    const changeWeekHandler = (week: Period) => {
        props.onChange && props.onChange(week);
        setShowCalendar(false);
    };

    const dropdown = (
        <ClickAwayListener onClickAway={() => setShowCalendar(false)}>
            <Box className={classes.calendarContainer}>
                <WeekSelectorDropdown
                    week={props.week}
                    onChange={changeWeekHandler}
                />
            </Box>
        </ClickAwayListener>
    );

    return (
        <Box className={classes.container}>
            <PrevNextSelector
                onPrev={clickPrevHandler}
                onNext={clickNextHandler}
                onClick={() => setShowCalendar(prev => !prev)}
                label={label}
            />
            {showCalendar && dropdown}
        </Box>
    );
};

export default WeekSelector;
