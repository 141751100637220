import {
    faCircleDollarToSlot,
    faCog,
    faPaw,
    faStore,
    faUsers
} from '@fortawesome/pro-light-svg-icons';
import { Box, useMediaQuery } from '@material-ui/core';
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme
} from '@material-ui/core/styles';
import { MarketplaceSchedule } from '@spike/model';
import { fetchOnboardingStepsThunk } from 'actions/onboardingSteps/OnboardingStepsActions';
import {
    openBusinessSettingsSidebar,
    closeBusinessSettingsSidebar
} from 'actions/businessSettingsSidebar/BusinessSettingsSidebarActions';
import clsx from 'clsx';
import Sidebar, {
    SidebarItem,
    SidebarSection
} from 'components/UI/Sidebar/Sidebar';
import Marketplace, {
    MarketplaceAdditionalInfo,
    MarketplaceBasics,
    MarketplaceDeposit,
    MarketplaceGrooming,
    MarketplaceIntegrations,
    MarketplaceLogos,
    MarketplaceNotifications,
    MarketplacePetTypes,
    MarketplaceRequirements,
    MarketplaceStaffTraining,
    MarketplaceTax,
    MarketplaceTip
} from '@spike/marketplace-model';
import { FunctionComponent, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch
} from 'react-router-dom';
import {
    MarketplaceStatus,
    saveMarketplaceThunk
} from '@spike/marketplace-action';
import { RootState } from 'store';
import AdditionalInfo from './AdditionalInfo';
import Amenities from './Amenities';
import Basics from './Basics';
import Deposit from './Deposit';
import EmailNotifications from './EmailNotifications';
import Integrations from './Integrations';
import OnlineBooking from './OnlineBooking';
import Logos from './Logos';
import PetRequirements from './PetRequirements';
import PetTypes from './PetTypes';
import Schedule from './Schedule';
import StaffTraining from './StaffTraining';
import Taxes from './Taxes';
import Tips from './Tips';
import {
    useApiClientWrapper,
    useAuth,
    useHasPremiumPlan,
    usePremiumPlan
} from 'hooks';
import { setTimeZone } from 'actions/login/LoginActions';
import { businessBasicSettingsHubspot } from 'actions/hubspot/HubSpotActions';
import { Plans } from './Plans/Plans';
import { Billing } from './Billing/Billing';
import TextMessages from './TextMessages';
import { OWNER_ID } from '@spike/model';
import IntakeForm from 'components/Marketplace/BusinessSettings/IntakeForm/IntakeForm';

export interface Props {
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            minHeight: '100%',
            height: 'max-content',
            borderTop: '2px solid #F1F1F1',
            background: 'white'
        },
        sidebarContainer: {
            display: 'none',
            flex: '0 0 157px',
            paddingTop: '21px',
            paddingLeft: '35px',
            paddingRight: '20px',
            borderRight: '2px solid #F1F1F1',
            [theme.breakpoints.up('sm')]: {
                display: 'flex'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
                paddingTop: '13px',
                paddingLeft: '15px',
                paddingRight: '15px',
                position: 'fixed',
                left: '-100%',
                visibility: 'hidden',
                top: '50px',
                height: 'calc(100vh - 50px)',
                background: '#fff',
                zIndex: 9,
                transition: 'all .3s',
                overflowY: 'scroll',
                paddingBottom: '180px',
                display: 'block'
            },
            [theme.breakpoints.only('md')]: {
                paddingTop: '21px',
                paddingLeft: '35px',
                paddingRight: '20px'
            },
            [theme.breakpoints.only('lg')]: {
                flex: '0 0 230px',
                paddingTop: '28px',
                paddingLeft: '47px',
                paddingRight: '26px'
            },
            [theme.breakpoints.only('xl')]: {
                flex: '0 0 316px',
                paddingTop: '42px',
                paddingLeft: '71px',
                paddingRight: '40px'
            }
        },
        sidebarContainerDefault: {
            left: '0px !important',
            transition: 'none'
        },
        sidebarContainerMain: {
            transition: 'none !important'
        },
        sidebarContainerVisible: {
            [theme.breakpoints.down('sm')]: {
                left: '0px !important',
                visibility: 'visible !important'
            }
        },
        bodyContainer: {
            width: '100%',
            display: 'flex',
            paddingTop: 19,
            paddingLeft: 16,
            paddingRight: 16,
            [theme.breakpoints.up('sm')]: {
                paddingTop: 31,
                paddingLeft: 25,
                paddingRight: 25
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: 41,
                paddingLeft: 45,
                paddingRight: 45
            }
        },
        bodyContainerNew: {
            [theme.breakpoints.down('sm')]: {
                paddingTop: '20px',
                paddingLeft: '15px',
                paddingRight: '15px'
            }
        },
        loadingContainer: {
            [theme.breakpoints.down('sm')]: {
                opacity: 0
            }
        },
        hiddenOnMobile: {
            opacity: 0
        }
    })
);

const enabledDeposits: boolean =
    process.env.REACT_APP_DEPOSITS_ENABLED?.toLowerCase() === 'true';

export const BusinessSettingsComponent: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { pathname } = useLocation();
    const location = useLocation();
    const selectedPath = pathname.split('/').slice(1)[2];
    const theme = useTheme();
    const dispatch = useDispatch();
    const activeSubscription = usePremiumPlan();

    const [bodyClass, setBodyClass] = useState('');
    const [hide, setHide] = useState(true);
    const [loading, setLoading] = useState(true);

    const isOpenBusinessSettingsSideBar = useSelector(
        (state: RootState) =>
            state.BusinessSettingsSidebar.isOpenBusinessSettingsSideBar
    );

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const openSidebar = () => {
        dispatch(openBusinessSettingsSidebar());
    };

    const closeSidebar = () => {
        dispatch(closeBusinessSettingsSidebar());
    };

    const apiClientWrapper = useApiClientWrapper();

    const marketplace = useSelector<RootState, Marketplace>(
        state => state.marketplace.marketplace
    );

    const hasPremiumPlan = useHasPremiumPlan();

    const basicsItem = { id: 'basics', name: 'Basics' };
    const additionalInfoItem = { id: 'additional', name: 'Additional Info' };
    const plansItem = { id: 'plans', name: 'Plans' };
    const billingItem = { id: 'billing', name: 'Billing' };
    const scheduleItem = { id: 'hours', name: 'Hours' };
    const logoItem = { id: 'logo', name: 'Logo' };
    const onlineBookingItem = { id: 'onlineBooking', name: 'Online Booking' };
    const textMessagesItem = { id: 'textMessages', name: 'Text Messages' };

    const intakeFormItem = {
        id: 'intake-form',
        name: 'Intake Form',
        isNew: true
    };

    const requirementsItem = { id: 'requirements', name: 'Requirements' };
    const typesItem = { id: 'types', name: 'Types' };

    const taxesItem = { id: 'taxes', name: 'Taxes' };
    const tipsItem = { id: 'tips', name: 'Tips' };
    const depositItem = { id: 'deposit', name: 'Deposits' };

    const emailItem = { id: 'email', name: 'Notifications' };
    const integrationsItem = { id: 'integrations', name: 'Integrations' };

    const amenitiesItem = { id: 'amenities', name: 'Amenities' };
    const trainingItem = { id: 'training', name: 'Staff Trainning' };

    const [selectedSidebarItem, setSelectedSidebarItem] = useState(basicsItem);
    const [logosSelected, setLogosSelected] = useState(marketplace.logos);
    const logosAuxiliar = marketplace.logos;

    const marketplaceStatus = useSelector<RootState, MarketplaceStatus>(
        state => state.marketplace.status
    );

    const getbusinessInfoItems = () => {
        const items = [
            basicsItem,
            additionalInfoItem,
            plansItem,
            textMessagesItem,
            billingItem,
            scheduleItem,
            logoItem,
            onlineBookingItem
        ];

        let filteredItems = items;

        if (!hasPremiumPlan) {
            filteredItems = items.filter(item => item.id !== 'textMessages');
        }

        return filteredItems;
    };

    const businessInfoItems: SidebarSection = {
        onclick: closeSidebar,
        toggleClassOnTitle: true,
        sectionAccordion: true,
        title: 'BUSINESS INFO',
        path: 'info',
        icon: faStore,
        items: getbusinessInfoItems()
    };

    const clientsItems: SidebarSection = {
        onclick: closeSidebar,
        toggleClassOnTitle: true,
        sectionAccordion: true,
        title: 'CLIENTS',
        path: 'clients',
        icon: faUsers,
        items: [intakeFormItem]
    };

    const petsItems: SidebarSection = {
        onclick: closeSidebar,
        toggleClassOnTitle: true,
        sectionAccordion: true,
        title: 'PETS',
        path: 'pets',
        icon: faPaw,
        items: [requirementsItem, typesItem]
    };

    const salesItems: SidebarSection = {
        onclick: closeSidebar,
        toggleClassOnTitle: true,
        sectionAccordion: true,
        title: 'SALES',
        path: 'sales',
        icon: faCircleDollarToSlot,
        items: enabledDeposits
            ? [taxesItem, tipsItem, depositItem]
            : [taxesItem, tipsItem]
    };

    const facilityAndStaffItems: SidebarSection = {
        onclick: closeSidebar,
        toggleClassOnTitle: true,
        sectionAccordion: true,
        title: 'FACILITY & STAFF',
        path: 'facilityStaff',
        icon: faUsers,
        items: [amenitiesItem, trainingItem]
    };

    const settingsItems: SidebarSection = {
        onclick: closeSidebar,
        toggleClassOnTitle: true,
        sectionAccordion: true,
        title: 'SETTINGS',
        path: 'settings',
        icon: faCog,
        items: [emailItem]
    };

    useEffect(() => {
        hide && setTimeout(() => setHide(false), 500);
    }, []);

    useEffect(() => {
        // Check if the query parameter "?open=settings" is present
        const isOpenFromQueryParam = location.search.includes('open=settings');

        dispatch(
            isOpenFromQueryParam
                ? openBusinessSettingsSidebar()
                : closeBusinessSettingsSidebar()
        );
        setBodyClass(isOpenFromQueryParam ? 'prevent-scroll' : '');

        setLoading(false);
    }, [location.search, dispatch]);

    useEffect(() => {
        const body = document.body;
        body.classList.toggle('prevent-scroll', isOpenBusinessSettingsSideBar);
        body.style.overflow = isOpenBusinessSettingsSideBar ? 'hidden' : '';
        body.style.height = isOpenBusinessSettingsSideBar ? '100%' : '';

        return () => {
            body.style.overflow = '';
            body.style.height = '';
            body.classList.remove('your-class-name');
        };
    }, [isOpenBusinessSettingsSideBar]);

    useEffect(() => {
        setLogosSelected(logosAuxiliar);
    }, [logosAuxiliar, selectedSidebarItem]);

    const selectSidebareItemHandler = (item: SidebarItem) => {
        setSelectedSidebarItem(item);
    };

    useEffect(() => {
        if (marketplaceStatus === MarketplaceStatus.SaveSuccess) {
            dispatch(fetchOnboardingStepsThunk());
        }
        // eslint-disable-next-line
    }, [marketplaceStatus]);

    const save = (marketplace: Marketplace) => {
        dispatch(
            saveMarketplaceThunk(apiClientWrapper, marketplace, setTimeZone)
        );
    };

    const saveBasicsHandler = (basics: MarketplaceBasics) => {
        save({ ...marketplace, basics });
        dispatch(businessBasicSettingsHubspot(basics));
    };

    const saveIntegrationsHandler = (integrations: MarketplaceIntegrations) => {
        save({ ...marketplace, integrations });
    };

    const saveAdditionalInfoHandler = (
        additionalInfo: MarketplaceAdditionalInfo
    ) => {
        save({ ...marketplace, additionalInfo });
    };

    const saveScheduleHandler = (schedule: MarketplaceSchedule) => {
        save({ ...marketplace, schedule });
    };

    const saveLogosHandler = (logos: MarketplaceLogos) => {
        const newMarketplace = { ...marketplace };
        newMarketplace.logos = { ...logos };
        save(newMarketplace);
    };

    const saveRequirementsHandler = (requirements: MarketplaceRequirements) => {
        const payload = {
            ...marketplace,
            requirements: {
                ...requirements,
                requirements:
                    requirements.requirements.length > 0
                        ? requirements.requirements
                        : [],
                otherRequirements:
                    requirements.otherRequirements.length > 0
                        ? requirements.otherRequirements
                        : []
            }
        };
        save(payload);
    };

    const savePetTypesHandler = (petTypes: MarketplacePetTypes) => {
        const newMarketplace = { ...marketplace };
        newMarketplace.petTypes = { ...petTypes };
        save(newMarketplace);
    };

    const saveTaxesHandler = (taxes: Array<MarketplaceTax>) => {
        const newMarketplace = { ...marketplace };
        newMarketplace.taxes = [...taxes];
        save(newMarketplace);
    };

    const saveTipsHandler = (tips: Array<MarketplaceTip>) => {
        const newMarketplace = { ...marketplace };
        newMarketplace.tips = [...tips];
        save(newMarketplace);
    };

    const saveDepositHandler = (deposit: MarketplaceDeposit) => {
        const newMarketplace = { ...marketplace };
        newMarketplace.deposit = { ...deposit };
        save(newMarketplace);
    };

    const saveGroomingHandler = (grooming: MarketplaceGrooming) => {
        const newMarketplace = { ...marketplace };
        newMarketplace.grooming = { ...grooming };
        save(newMarketplace);
    };

    const saveStaffTrainingHandler = (
        staffTraining: MarketplaceStaffTraining
    ) => {
        const newMarketplace = { ...marketplace };
        newMarketplace.staffTraining = { ...staffTraining };
        save(newMarketplace);
    };

    const onChangeLogosHandler = (logos: MarketplaceLogos) => {
        const newMarketplace = { ...marketplace };
        newMarketplace.logos = { ...logos };
        setLogosSelected(newMarketplace.logos);
    };

    const saveNotificationsHandler = (
        notifications: MarketplaceNotifications
    ) => {
        save({
            ...marketplace,
            notifications: { ...notifications }
        });
    };

    const sidebar = (
        <Sidebar
            alternativeTitle="Business Settings"
            title={marketplace.basics.businessName}
            subtitle={`${marketplace.basics.address.street} ${
                marketplace.basics.address.suite
                    ? ' ' + marketplace.basics.address.suite
                    : ''
            }, ${marketplace.basics.address.city} ${
                marketplace.basics.address.state
            } ${marketplace.basics.address.zipcode}`}
            sections={[
                businessInfoItems,
                clientsItems,
                petsItems,
                salesItems,
                settingsItems
            ]}
            selectedItemId={selectedPath}
            onSelect={selectSidebareItemHandler}
            className="SidebarBusinessSettings"
        />
    );

    const petTypes = (
        <Box
            className={clsx(classes.bodyContainer, classes.bodyContainerNew, {
                [classes.hiddenOnMobile]: hide && isMobile
            })}
        >
            <PetTypes
                onSave={savePetTypesHandler}
                petTypes={marketplace.petTypes}
                openSidebar={openSidebar}
                isMobile={isMobile}
            />
        </Box>
    );

    const basics = (
        <Box
            className={clsx(classes.bodyContainer, classes.bodyContainerNew, {
                [classes.hiddenOnMobile]: hide && isMobile
            })}
        >
            <Basics
                basics={{ ...marketplace.basics }}
                onSave={saveBasicsHandler}
                openSidebar={openSidebar}
                isMobile={isMobile}
            />
        </Box>
    );

    const additionalInfo = (
        <Box className={clsx(classes.bodyContainer, classes.bodyContainerNew)}>
            <AdditionalInfo
                additionalInfo={{ ...marketplace.additionalInfo }}
                onSave={saveAdditionalInfoHandler}
                openSidebar={openSidebar}
                isMobile={isMobile}
            />
        </Box>
    );

    const plans = (
        <Box className={clsx(classes.bodyContainer, classes.bodyContainerNew)}>
            <Plans openSidebar={openSidebar} isMobile={isMobile} />
        </Box>
    );

    const billing = (
        <Box className={clsx(classes.bodyContainer, classes.bodyContainerNew)}>
            <Billing openSidebar={openSidebar} isMobile={isMobile} />
        </Box>
    );

    const textMessages = (
        <Box className={clsx(classes.bodyContainer, classes.bodyContainerNew)}>
            <TextMessages
                openSidebar={openSidebar}
                isMobile={isMobile}
                subscription={activeSubscription}
            />
        </Box>
    );

    const schedule = (
        <Box className={clsx(classes.bodyContainer, classes.bodyContainerNew)}>
            <Schedule
                schedule={{ ...marketplace.schedule }}
                enableSpecialHours={true}
                onSave={saveScheduleHandler}
                openSidebar={openSidebar}
                isMobile={isMobile}
            />
        </Box>
    );

    const logos = (
        <Box className={clsx(classes.bodyContainer, classes.bodyContainerNew)}>
            <Logos
                basics={{ ...marketplace.basics }}
                logos={logosSelected}
                onChange={onChangeLogosHandler}
                onSave={saveLogosHandler}
                openSidebar={openSidebar}
                isMobile={isMobile}
            />
        </Box>
    );

    const onlineBooking = (
        <Box className={clsx(classes.bodyContainer, classes.bodyContainerNew)}>
            <OnlineBooking
                openSidebar={openSidebar}
                isMobile={isMobile}
                onlineBooking={{ ...marketplace.onlineBooking }}
            />
        </Box>
    );

    const intakeForm = (
        <Box className={clsx(classes.bodyContainer, classes.bodyContainerNew)}>
            <IntakeForm isMobile={isMobile} openSidebar={openSidebar} />
        </Box>
    );

    const requirements = (
        <Box className={clsx(classes.bodyContainer, classes.bodyContainerNew)}>
            <PetRequirements
                requirements={{ ...marketplace.requirements }}
                onSave={saveRequirementsHandler}
                openSidebar={openSidebar}
                isMobile={isMobile}
            />
        </Box>
    );

    const taxes = (
        <Box className={classes.bodyContainer}>
            <Taxes
                openSidebar={openSidebar}
                isMobile={isMobile}
                taxes={[...marketplace.taxes]}
                onSave={saveTaxesHandler}
            />
        </Box>
    );

    const tips = (
        <Box className={classes.bodyContainer}>
            <Tips
                openSidebar={openSidebar}
                isMobile={isMobile}
                tips={[...marketplace.tips]}
                onSave={saveTipsHandler}
            />
        </Box>
    );

    const deposit = (
        <Box className={classes.bodyContainer}>
            <Deposit
                deposit={{ ...marketplace.deposit }}
                onSave={saveDepositHandler}
                openSidebar={openSidebar}
                isMobile={isMobile}
            />
        </Box>
    );

    const amenities = (
        <Box className={classes.bodyContainer}>
            <Amenities
                grooming={{ ...marketplace.grooming }}
                onSave={saveGroomingHandler}
                openSidebar={openSidebar}
                isMobile={isMobile}
            />
        </Box>
    );
    const training = (
        <Box className={classes.bodyContainer}>
            <StaffTraining
                staffTraining={{ ...marketplace.staffTraining }}
                onSave={saveStaffTrainingHandler}
                openSidebar={openSidebar}
                isMobile={isMobile}
            />
        </Box>
    );
    const emailNotifications = (
        <Box className={clsx(classes.bodyContainer, classes.bodyContainerNew)}>
            <EmailNotifications
                notifications={{ ...marketplace.notifications }}
                onSave={saveNotificationsHandler}
                openSidebar={openSidebar}
                isMobile={isMobile}
            />
        </Box>
    );

    const integrations = (
        <Box className={classes.bodyContainer}>
            <Integrations
                integrations={{ ...marketplace.integrations }}
                onSave={saveIntegrationsHandler}
                openSidebar={openSidebar}
                isMobile={isMobile}
            />
        </Box>
    );

    if (loading) {
        return <div className="loading"></div>;
    }

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box
                className={clsx(
                    classes.sidebarContainer,
                    { [classes.sidebarContainerMain]: hide && isMobile },
                    {
                        [classes.sidebarContainerVisible]:
                            isMobile && isOpenBusinessSettingsSideBar
                    }
                )}
            >
                {sidebar}
            </Box>

            <Switch>
                <Redirect
                    exact
                    strict
                    path={`${path}`}
                    to={`${path}/${businessInfoItems.path}/${basicsItem.id}${
                        isMobile ? '?open=settings' : ''
                    }`}
                />
                <Route
                    path={`${path}/${businessInfoItems.path}/${basicsItem.id}`}
                >
                    <span>{basics}</span>
                </Route>
                <Route
                    path={`${path}/${businessInfoItems.path}/${plansItem.id}`}
                >
                    {plans}
                </Route>
                <Route
                    path={`${path}/${businessInfoItems.path}/${billingItem.id}`}
                >
                    {billing}
                </Route>
                {hasPremiumPlan && (
                    <Route
                        path={`${path}/${businessInfoItems.path}/${textMessagesItem.id}`}
                    >
                        {textMessages}
                    </Route>
                )}
                <Route
                    path={`${path}/${businessInfoItems.path}/${additionalInfoItem.id}`}
                >
                    {additionalInfo}
                </Route>
                <Route
                    path={`${path}/${businessInfoItems.path}/${scheduleItem.id}`}
                >
                    {schedule}
                </Route>
                <Route
                    path={`${path}/${businessInfoItems.path}/${logoItem.id}`}
                >
                    {logos}
                </Route>
                <Route
                    path={`${path}/${businessInfoItems.path}/${onlineBookingItem.id}`}
                >
                    {onlineBooking}
                </Route>
                <Route
                    path={`${path}/${clientsItems.path}/${intakeFormItem.id}`}
                >
                    {intakeForm}
                </Route>
                <Route
                    path={`${path}/${petsItems.path}/${requirementsItem.id}`}
                >
                    {requirements}
                </Route>
                <Route path={`${path}/${petsItems.path}/${typesItem.id}`}>
                    {petTypes}
                </Route>

                <Route path={`${path}/${salesItems.path}/${taxesItem.id}`}>
                    {taxes}
                </Route>
                <Route path={`${path}/${salesItems.path}/${tipsItem.id}`}>
                    {tips}
                </Route>
                <Route path={`${path}/${salesItems.path}/${depositItem.id}`}>
                    {deposit}
                </Route>

                <Route
                    path={`${path}/${facilityAndStaffItems.path}/${amenitiesItem.id}`}
                >
                    {amenities}
                </Route>
                <Route
                    path={`${path}/${facilityAndStaffItems.path}/${trainingItem.id}`}
                >
                    {training}
                </Route>
                <Route path={`${path}/${settingsItems.path}/${emailItem.id}`}>
                    {emailNotifications}
                </Route>
                <Route
                    path={`${path}/${settingsItems.path}/${integrationsItem.id}`}
                >
                    {integrations}
                </Route>
            </Switch>
            {/* Conditionally rendered style element */}
            {bodyClass && (
                <style>{`
					body.${bodyClass} {
						overflow: hidden;
					}
				`}</style>
            )}
        </Box>
    );
};

export default BusinessSettingsComponent;
