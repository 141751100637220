import React, { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Moment } from "moment";
import { Img } from "react-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { Spinner } from "components/UI";
import { PetService } from "@spike/new-booking-model";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { PetsState } from "@spike/pets-action";
import Pet from "@spike/pet-model";

interface PetServiceWrapper extends PetService {
  duration?: Duration;
}
interface AppointmentDataVaraible {
  service: PetServiceWrapper;
  staffId: number;
  date: Moment;
}

interface AppointmentMultiple {
  clientId: number;
  petId: number;
  createdByStaffId: number;
  notes?: string;
  variableData: Array<AppointmentDataVaraible>;
}

interface AppointmentCreatedProps {
  multipleAppointments: Array<AppointmentMultiple>;
  date?: Moment;
  petId?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      alignItems: "center",

    },
    header: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
      height: "40%",
      width: 346,
    },
    content: {
      display: "flex",
      justifyContent: "center"
    },
    icon: {
      color: "#5E8677",
      width: 82,
      height: 82,
      paddingBottom: 11,
      [theme.breakpoints.up('md')]: {
        width: 97,
        height: 97,
        paddingBottom: 13
      },
    },
    textContainer: {
      display: "flex",
      flexWrap: "wrap"
    },
    title: {
      fontWeight: 600,
      fontSize: 26,
      lineHeight: "32px",
      paddingBottom: 11,
      [theme.breakpoints.up('md')]: {
        fontSize: 32,
        lineHeight: "35px",
        paddingBottom: 13
      },
    },
    text: {
      fontSize: 16,
      lineHeight: "22px"
    },
    bold: {
      fontWeight: 600
    },
    normal: {
      fontWeight: 400
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      height: "60%",
      width: "100%",
      textAlign: "center"
    },
    image: {
      color: "#5E8677",
      height: "100%"
    },
  })
);

export const AppointmentCreated: FunctionComponent<AppointmentCreatedProps> = (props) => {
  const classes = useStyles();

  const petsStateFilter = useSelector<RootState, PetsState>(
    state => state.pets
  );

  const getPetsName = () => {
    let petNames = '';
    props.multipleAppointments.forEach((appt: AppointmentMultiple, index: number) => {
      const petFounded = petsStateFilter.list.find((pet: Pet) => pet.id === appt.petId);
        if(petFounded) {
            if(index === 0) {
              petNames+= `${petFounded.name} `;
            } else {
              petNames+= ` and ${petFounded.name} `
            }
            
        }
    })
    if(petNames === '') {
      petNames = petsStateFilter.list.find((pet: Pet) => pet.id === props.petId)?.name ?? '';
    }
    return petNames;
  };

  const multiplePetsOrServices = () => {
    if(props.multipleAppointments.length > 1 || (props.multipleAppointments.length === 1 && props.multipleAppointments[0].variableData.length > 1)) {
      return 'appointments were'
    } else {
      return 'appointment was'
    }
  };

  return (
      <Box className={classes.container}>
        <Box className={classes.header}>
          <FontAwesomeIcon 
            icon={faCheckCircle} 
            className={classes.icon}
          />
          <Typography 
            id="booking_label_appointment_booked"
            className={classes.title}
          >
            Appointment Booked
          </Typography>
          <Box className={classes.textContainer}>
              <Typography className={classes.text}>
                <span className={classes.bold}>{getPetsName()}</span>&apos;s {multiplePetsOrServices()} booked for {props.multipleAppointments[0]?.variableData[0]?.date?.format('dddd, MMM Do h:mm a') ?? props.date?.format('dddd, MMM Do h:mm a')}
              </Typography>
          </Box>
        </Box>
        <Box className={classes.imageContainer}>
          <Img 
            src="/images/bookings/AppointmentCreated.svg" 
            loader={<Spinner />}
            className={classes.image}
          />
        </Box>
      </Box>
  );
};

export default AppointmentCreated;
