import { FunctionComponent } from 'react';
import { TextField as MUITextField } from '@material-ui/core';
import { VariablePriceOption } from 'model/Service';
import NumberFormat from 'react-number-format';
import { commonStyles } from 'Theme';
import clsx from 'clsx';
export interface Props {
    id?: string;
    value?: number;
    defaultValue?: number;
    placeholder?: string;
    error?: boolean;
    onChange: (
        price?: number,
        variablePriceOption?: VariablePriceOption
    ) => void;
    onBlur?: (variablePriceOption?: VariablePriceOption) => void;
    className?: string;
    suffix?: string;
    variablePriceOption?: VariablePriceOption;
}

export const PriceField: FunctionComponent<Props> = props => {
    const commonClasses = commonStyles();

    return (
        <NumberFormat
            id={props.id}
            error={props.error}
            prefix="$"
            suffix={props.suffix}
            value={props.value}
            defaultValue={props.defaultValue}
            allowNegative={false}
            decimalScale={2}
            allowLeadingZeros={false}
            customInput={MUITextField}
            variant="outlined"
            onValueChange={values => {
                props.variablePriceOption
                    ? props.onChange(
                          values.floatValue,
                          props.variablePriceOption
                      )
                    : props.onChange(values.floatValue);
            }}
            onBlur={() => props.onBlur && props.onBlur()}
            className={clsx(commonClasses.field, props.className)}
            onFocus={(event: any) => event.target.select()}
            placeholder={props.placeholder}
        />
    );
};

export default PriceField;
