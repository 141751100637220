/* eslint-disable react/no-unescaped-entities */
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import { useApiClientWrapper } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchPetServicesThunk,
    NewBookingsStatus
} from '@spike/new-bookings-action';
import { PetService } from '@spike/new-booking-model';
import { RootState } from 'store';
import { Spinner } from 'components/UI';
import SelectServices from './SelectServices';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { AlertNewBooking } from '../UI/AlertNewBooking';
import clsx from 'clsx';

interface Props {
    clientId: number;
    petId: number;
    selectedService?: PetServiceWrapper;
    readOnly?: boolean;
    withoutBorder?: boolean;
    servicesSelected?: Array<number>;
    multiplePets?: boolean;
    onSelectService: (service: PetServiceWrapper) => void;
}

interface PetServiceWrapper extends PetService {
    duration?: Duration;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            borderBottom: (props: Props) => props.multiplePets ? 'none' : "1px solid #D4D4D4",
            paddingBottom: 18,
            [theme.breakpoints.up('md')]: {
                paddingBottom: 20
            }
        },
        title: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '16px',
            marginBottom: 18,
            [theme.breakpoints.up('md')]: {
                marginBottom: 20
            }
        },
        alert: {
            marginBottom: '0px !important',
            height: 53
        },
        alertText: {
            fontSize: 14,
            lineHeight: '20px'
        },
        withoutBorder: {
            border: 'none !important'
        }
    })
);

export const ServiceSelector: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const dispatch = useDispatch();

    const ref = useRef<HTMLDivElement>(null);

    const apiClientWrapper = useApiClientWrapper();

    const petServices = useSelector<RootState, Array<PetService>>(
        state => state.newBookings.petServices
    );

    const newBookingStatus = useSelector<RootState, NewBookingsStatus>(
        state => state.newBookings.status
    );

    const services: Array<PetServiceWrapper> = petServices
        .map(petService => ({
            ...petService,
            duration: {
                hours: Math.trunc(petService.durationInMinutes / 60),
                minutes: petService.durationInMinutes % 60
            }
        }))
        .sort((wrapper, otherWrapper) =>
            wrapper.service.name
                .toLowerCase()
                .localeCompare(otherWrapper.service.name.toLowerCase())
        );

    const [loading, setLoading] = useState(true);

    const getServicesUnused = () => {
        if (props.servicesSelected) {
            const servicesToSelect: Array<PetServiceWrapper> = [];
            services.forEach(serv => {
                const serviceFounded = props.servicesSelected?.find(
                    s => s === serv?.service.id
                );
                if (!serviceFounded) {
                    servicesToSelect.push(serv);
                }
            });
            return servicesToSelect;
        }
        return services;
    };

    useEffect(() => {
        setLoading(true);
        dispatch(fetchPetServicesThunk(apiClientWrapper, props.petId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.petId]);

    useEffect(() => {
        if (!props.readOnly) {
            setTimeout(() => {
                ref?.current?.scrollIntoView();
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, props.petId]);

    useNonInitialEffect(() => {
        switch (newBookingStatus) {
            case NewBookingsStatus.FetchPetServicesSuccess:
            case NewBookingsStatus.Error:
                setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newBookingStatus]);

    const emptyServicesComponent = (
        <Box id="booking_div_no_services_alert">
            <AlertNewBooking
                className={classes.alert}
                bWidth="2px"
                icon={faExclamationTriangle}
                iconSize="lg"
                iconColor="#EAB464"
                bgColor="#F8F5F1"
                bdColor="#BCB8AE"
            >
                <Typography className={classes.alertText}>
                    No services available for this pet's size or hair length
                </Typography>
            </AlertNewBooking>
        </Box>
    );

    return (
        <Box
            className={clsx(classes.container, {
                [classes.withoutBorder]: props.withoutBorder
            })}
        >
            {loading ? (
                <Spinner />
            ) : (
                <div ref={ref}>
                    <Typography className={classes.title}>{props.multiplePets ? 'Services' : 'Service'}</Typography>
                    {getServicesUnused()?.length === 0 ? (
                        emptyServicesComponent
                    ) : (
                        <SelectServices
                            placeholder="Select a Service"
                            selectedOption={props.selectedService}
                            options={getServicesUnused()}
                            onSelect={(service: PetServiceWrapper) =>
                                props.onSelectService(service)
                            }
                        />
                    )}
                </div>
            )}
        </Box>
    );
};

export default ServiceSelector;
