import { FunctionComponent, Fragment, MouseEvent, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import ConfirmDialog from "components/Products/UI/Dialog";

export interface Props {
  icon: IconProp;
  onConfirmedClick: () => void;
  text: string;
  title: string;
  color?: string;
  confirmQuestion: JSX.Element;
  subConfirmQuestion?: JSX.Element,
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      width: "max-content",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "6px",
        fontSize: "6px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
    icon: {
      [theme.breakpoints.down("md")]: {
        marginRight: "8px",
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: "10px",
      },
    },
    title: {
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
  })
);

export const ChangeStatusItemWithConfirmation: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(false);

  const clickHandler = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setShowDialog(true);
  }

  const cancelHandler = () => {
    setShowDialog(false);
  }

  const confirmHandler = () => {
    setShowDialog(false);
    props.onConfirmedClick();
  }

  return (
    <Fragment>
      <Box className={classes.item} onClick={clickHandler}>
        <FontAwesomeIcon color={props.color} icon={props.icon} className={classes.icon} />
        <Typography style={{ color: props.color || "" }} className={classes.title}>{props.text}</Typography>
      </Box>
      <ConfirmDialog 
        open={showDialog}
        title={props.title + " vendor"}
        question={props.confirmQuestion}
        onCancel={cancelHandler}
        onConfirm={confirmHandler}
        confirmButtonLabel={props.title}
        cancelButtonLabel="Cancel"
      />
    </Fragment>
  );
};

export default ChangeStatusItemWithConfirmation;