import { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import ToolbarButton from "components/UI/Toolbar/ToolbarButton";
import ToolbarItem from "components/UI/Toolbar/ToolbarItem";
import { faBan, faCheckCircle, faPencil } from "@fortawesome/pro-light-svg-icons";
import ProductVendor from "model/ProductVendor";
import ChangeStatusItemWithConfirmation from "../Vendor/ChangeStatusItem";
import { useDispatch } from "react-redux";
import { saveThunk } from "actions/productVendors/ProductVendorsActions";
import { formatPhoneNumber } from "@spike/phone-utils";
import WarningDialog from "../UI/WarningDialog";
import { useMarketplace } from "hooks";

interface Props {
  vendor: ProductVendor;
  onEdit: (vendor: ProductVendor) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      "&:hover": {
        backgroundColor: "#FAFAFA !important",
        cursor: "pointer"
      }
    },
    cell: {
      paddingLeft: "0px",
      verticalAlign: "top !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        paddingTop: "21px",
        paddingBottom: "21px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        paddingTop: "26px",
        paddingBottom: "26px",
      },
    },
    options: {
      position: "absolute",
    },
    firstCell: {
      [theme.breakpoints.down("md")]: {
        paddingLeft: "84px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "105px",
      },
    },
    lastCell: {
      float: "right",
      [theme.breakpoints.down("md")]: {
        paddingRight: "88px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: "110px",
      },
    },
    text: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "22px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "27px"
      },
    },
    textDialog: {
      textAlign: "center",
      [theme.breakpoints.up("lg")]: {
        fontSize: "15px"
      },
    },
    vendorsNameCells: {
      width: "20%"
    },
    vendorsCells: {
      width: "12%"
    },
    vendorsEmailCells: {
      width: "17%"
    },
    vendorsStatusCells: {
      width: "7%"
    },
    vendorsSmallCells: {
      width: "10%"
    },
    products: {
      [theme.breakpoints.down("md")]: {
        marginLeft: "22%",
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: "19%",
      },
    },
    typoDelete: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
      },
    },
    deactivated: {
      color: "#00000080 !important"
    }
  })
);

export const VendorRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const marketplace = useMarketplace();

  const [vendor, setVendor] = useState<ProductVendor>(props.vendor);

  const [showWarningAssignedProducts, setShowWarningAssignedProducts] = useState(false);

  const dispatch = useDispatch();

  const handleChangeStatus = (action: string) => {
    let vendorAux = {...vendor};
    if(action === "activate") {
      vendorAux.active = true;
    } else {
      vendorAux.active = false;
    }
    setVendor(vendorAux);
    dispatch(saveThunk(vendorAux));
  }

  return (
    <TableRow className={clsx(classes.row)}>
      <TableCell className={clsx(classes.cell, classes.firstCell, classes.vendorsNameCells)} onClick={() => props.onEdit(vendor)}>
        <Typography className={clsx(classes.text, {[classes.deactivated]: !vendor.active})}>{vendor.name}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.vendorsCells)} onClick={() => props.onEdit(vendor)}>
        <Typography className={clsx(classes.text, {[classes.deactivated]: !vendor.active})}>{vendor.contact ? vendor.contact : "-"}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.vendorsCells)} onClick={() => props.onEdit(vendor)}>
        <Typography className={clsx(classes.text, {[classes.deactivated]: !vendor.active})}>{vendor.phone ? formatPhoneNumber(vendor.phone, marketplace.basics.address.country!.id) : "-"}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.vendorsEmailCells)} onClick={() => props.onEdit(vendor)}>
        <Typography className={clsx(classes.text, {[classes.deactivated]: !vendor.active})}>{vendor.email ? vendor.email : "-"}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.vendorsEmailCells)} onClick={() => props.onEdit(vendor)}>
        <Typography className={clsx(classes.text, {[classes.deactivated]: !vendor.active})}>{vendor.website ? vendor.website : "-"}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.vendorsStatusCells)} onClick={() => props.onEdit(vendor)}>
        <Typography className={clsx(classes.text, {[classes.deactivated]: !vendor.active})}>{vendor?.address?.state ? vendor.address?.state : "-"}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.vendorsStatusCells, classes.products)} onClick={() => props.onEdit(vendor)}>
        <Typography className={clsx(classes.text, classes.products, {[classes.deactivated]: !vendor.active})}>{vendor.products.length}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.vendorsStatusCells)} onClick={() => props.onEdit(vendor)}>
        <Typography className={clsx(classes.text, {[classes.deactivated]: !vendor.active})}>{vendor.active ? "Active" : "Inactive"}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.vendorsSmallCells, classes.lastCell)}>
        <Box className={classes.options}>
          <ToolbarButton>
            <ToolbarItem icon={faPencil} onClick={() => props.onEdit(vendor)} text={"Edit/view"} />
            {vendor.active ? 
              vendor.products.length === 0 ? 
              <Box>
                <ChangeStatusItemWithConfirmation
                  icon={faBan}
                  title="Deactivate"
                  text="Deactivate"
                  onConfirmedClick={() => handleChangeStatus("deactivate")}
                  confirmQuestion={<Typography className={classes.typoDelete}>An inactive vendor won’t be able to be attach to any product.</Typography>}
                  confirmButtonLabel="Deactivate"
                  cancelButtonLabel="Cancel"
                />
              </Box> : 
              <ToolbarItem icon={faBan} onClick={() => setShowWarningAssignedProducts(true)} text={"Deactivate"} />
            :
            <Box>
              <ChangeStatusItemWithConfirmation
                icon={faCheckCircle}
                title="Activate"
                text={"Activate"}
                onConfirmedClick={() => handleChangeStatus("activate")}
                confirmQuestion={<Typography className={classes.typoDelete}>Are you sure you want to activate vendor?</Typography>}
                confirmButtonLabel="Activate"
                cancelButtonLabel="Cancel"
              />
            </Box>}
          </ToolbarButton>
        </Box>
      </TableCell>
      <WarningDialog 
        open={showWarningAssignedProducts}
        title={`(${vendor.products.length}) products currently assigned to this vendor."`}
        description="In order to deactivate, you must first remove products assigned to this vendor."
        onOk={() => setShowWarningAssignedProducts(false)}
      />
    </TableRow>
  );
};

export default VendorRow;