import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Appointment, { status } from '@spike/appointment-model';
import { BlockCalendar } from '@spike/block-calendar-model';
import {
    BlockCalendarStatus,
    countRecurrentThunk,
    fetchThunk as fetchBlocksThunk,
    saveThunk
} from '@spike/block-calendar-action';
import {
    AppointmentsStatus,
    countRecurrentAppointmentsThunk,
    fetchAppointmentsThunk
} from '@spike/bookings-action';
import {
    NewBookingsStatus,
    recurrentRescheduleThunk,
    singleRescheduleThunk
} from '@spike/new-bookings-action';
import useNonInitialEffect from '@versiondos/hooks';
import BookingDetail from 'components/BookingDetail2';
import { OverFullWindow, Spinner } from 'components/UI';
import CreateBookingDrawer from 'components/UI/CreateBookingDrawer';
import { useApiClientWrapper, useMarketplace, useTimeZone } from 'hooks';
import Staff from 'model/Staff';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import CalendarHeader from './CalendarHeader';
import DayFullCalendar from './DayFullCalendar';
import { StaffResource } from './DayFullCalendar/DayFullCalendar';
import { TempNewBooking } from './FullCalendar/model';
import MonthFullCalendar from './MonthFullCalendar';
import WeekFullCalendar from './WeekFullCalendar';
import ConfirmRescheduleDialog from 'components/UI/ConfirmRescheduleDialog';
import Client from 'pages/Client';
import { Client as ClientModel } from '@spike/client-model';
import Pet from '@spike/pet-model';
import BlockCalendarsWebSockets from 'WebSockets/BlockCalendarsWebSockets';
import Booking, { BookingWrapper } from '@spike/booking-model';
import { v4 as uuid } from 'uuid';
import ConfirmRescheduleRecurringDialog from 'components/CreateBooking/UI/ConfirmRescheduleRecurringDialog';
import PopUpUpdateBlockCalendar from './BlockedTime/PopUpUpdateBlockCalendar';
import {
    daily,
    monthly,
    weekly
} from './CalendarHeader/CalendarSelector/CalendarSelector';

interface BlockCalendarDraggable {
    blockedTime: BlockCalendar;
    revert: () => void;
}

const calendarAppointmentStatus = [
    status.BOOKED,
    status.CONFIRMED,
    status.CHECKED_IN,
    status.IN_PROGRESS,
    status.READY_FOR_CHECK_OUT,
    status.PAYMENT,
    status.COMPLETED
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flexGrow: 1
        },
        header: {
            display: 'flex'
        },
        body: {
            display: 'flex',
            flexGrow: 1
        },
        rightNewBookingContainer: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: 590,
            overflowY: 'hidden'
        }
    })
);

export const Calendar: FunctionComponent = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const timeZone = useTimeZone();
    const marketplace = useMarketplace();
    const today = moment().tz(timeZone);

    const [week, setWeek] = useState({
        from: today.clone().startOf('week'),
        to: today.clone().endOf('week')
    });

    const [date, setDate] = useState(today.clone());

    const [month, setMonth] = useState(today.clone().startOf('month'));

    const [calendarType, setCalendarType] = useState(weekly.id);

    const [newBooking, setNewBooking] = useState<
        { at: Moment; staffId?: number } | undefined
    >();

    const [tempNewBooking, setTempNewBooking] = useState<
        TempNewBooking | undefined
    >();

    const [showBooking, setShowBooking] = useState<
        { appointmentId: number; bookingId: number } | undefined
    >();
    const [reschedule, setReschedule] = useState<
        | {
              bookingId: number;
              appointmentId: number;
              petName: string;
              staffId?: number;
              from: Moment;
              to: Moment;
              revert: () => void;
              customerName: string;
          }
        | undefined
    >();

    const [showBlockCalendar, setShowBlockCalendar] = useState<
        { blockId: number; start?: Moment } | undefined
    >();

    const [reschedulingAppointments, setReschedulingAppointments] = useState<
        Array<{ id: number; at: Moment }>
    >([]);

    const appointments = useSelector<RootState, Array<Appointment>>(
        state => state.appointments.appointments
    );
    const appointmentsStatus = useSelector<RootState, AppointmentsStatus>(
        state => state.appointments.status
    );

    const blocks = useSelector<RootState, Array<BlockCalendar>>(
        state => state.blockCalendar.blocks
    );

    const staff = useSelector<RootState, Array<Staff>>(
        state => state.staff.staff
    );

    const newBookingsStatus = useSelector<RootState, NewBookingsStatus>(
        state => state.newBookings.status
    );

    const recurrentBlockCalendar = useSelector<
        RootState,
        { blockId: number; remaining: number } | undefined
    >(state => state.blockCalendar.recurrent);

    const blockCalendarStatus = useSelector<RootState, BlockCalendarStatus>(
        state => state.blockCalendar.status
    );

    const [loading, setLoading] = useState({
        appointments: true,
        blocks: true
    });
    const [selectedStaffIds, setSelectedStaffIds] = useState<
        Array<number> | undefined
    >();

    const [showNewClient, setShowNewClient] = useState(false);
    const [showClient, setShowClient] = useState(false);
    const [isNewBooking, setIsNewBooking] = useState(false);
    const [customerID, setCustomerID] = useState<number | undefined>(undefined);
    const [petID, setPetID] = useState<number | undefined>(undefined);
    const [petAdded, setPetAdded] = useState(false);
    const [clientAdded, setClientAdded] = useState(false);
    const [rescheduleBooking, setRescheduleBooking] = useState<
        BookingWrapper | undefined
    >(undefined);
    const [recurring, setRecurring] = useState(false);

    const [rescheduleFromDetail, setRescheduleFromDetail] = useState(false);
    const [rebookBooking, setRebookBooking] = useState<Booking | undefined>(
        undefined
    );
    const [savingBlockedTime, setSavingBlockedTime] = useState(false);
    const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
    const [blockedTime, setBlockedTime] = useState<
        BlockCalendarDraggable | undefined
    >(undefined);

    const multipleBookingIds = useMemo(() => {
        return Array.from(
            appointments
                .reduce((map, appointment) => {
                    const bookingId = appointment.bookingId;
                    const appointmentsCount = map.get(bookingId) ?? 0;
                    map.set(bookingId, appointmentsCount + 1);
                    return map;
                }, new Map<number, number>())
                .entries()
        )
            .filter(entry => entry[1] > 1)
            .map(entry => entry[0]);
    }, [appointments]);

    const calendarAppointments = useMemo(
        () =>
            appointments.filter(
                appointment =>
                    selectedStaffIds === undefined ||
                    selectedStaffIds.includes(
                        appointment.services[0].staff?.id ?? 0
                    )
            ),
        [appointments, selectedStaffIds]
    );

    const calendarBlocks = useMemo(
        () =>
            blocks.filter(
                block =>
                    selectedStaffIds === undefined ||
                    selectedStaffIds.includes(block.staff?.id ?? 0)
            ),
        [blocks, selectedStaffIds]
    );

    const staffResource: Array<StaffResource> = useMemo(
        () =>
            staff
                .filter(
                    member =>
                        selectedStaffIds === undefined ||
                        selectedStaffIds.includes(member.id ?? 0)
                )
                .filter(member => member.active && !member.deleted)
                .map(member => ({
                    id: member.id ?? 0,
                    firstName: member.person.firstName,
                    lastName: member.person.lastName,
                    avatar: member.person.avatar,
                    schedule: member.schedule
                })),
        [selectedStaffIds, staff]
    );

    useEffect(() => {
        if (calendarType === monthly.id) {
            setLoading(prev => ({ ...prev, appointments: true }));
            dispatch(
                fetchAppointmentsThunk(
                    apiClientWrapper,
                    calendarAppointmentStatus,
                    month.clone().format('YYYY-MM-DD'),
                    month.clone().endOf('month').format('YYYY-MM-DD')
                )
            );
        } else {
            if (week) {
                setLoading(prev => ({
                    ...prev,
                    appointments: true,
                    blocks: true
                }));
                dispatch(
                    fetchAppointmentsThunk(
                        apiClientWrapper,
                        calendarAppointmentStatus,
                        calendarType === weekly.id
                            ? week.from.clone().format('YYYY-MM-DD')
                            : date.clone().format('YYYY-MM-DD'),
                        calendarType === weekly.id
                            ? week.to.clone().format('YYYY-MM-DD')
                            : date.clone().format('YYYY-MM-DD')
                    )
                );
                dispatch(
                    fetchBlocksThunk(
                        apiClientWrapper,
                        calendarType === weekly.id
                            ? week.from.clone()
                            : date.clone(),
                        calendarType === weekly.id
                            ? week.to.clone()
                            : date.clone(),
                        marketplace.schedule.id ?? 0
                    )
                );
            }
        }
    }, [month, week, date, calendarType]);

    useNonInitialEffect(() => {
        switch (appointmentsStatus) {
            case AppointmentsStatus.FetchSuccess:
            case AppointmentsStatus.Error:
                setLoading(prev => ({ ...prev, appointments: false }));
        }
    }, [appointmentsStatus]);

    useNonInitialEffect(() => {
        switch (appointmentsStatus) {
            case AppointmentsStatus.FetchSuccess:
            case AppointmentsStatus.Error:
                setLoading(prev => ({ ...prev, blocks: false }));
        }
    }, [appointmentsStatus]);

    useNonInitialEffect(() => {
        switch (newBookingsStatus) {
            case NewBookingsStatus.SingleRescheduleSuccess:
                setReschedule(undefined);
                setShowBooking(undefined);
                setReschedulingAppointments(prev =>
                    prev.filter(rescheduledApp => {
                        const appointment = appointments.find(
                            app => app.id === rescheduledApp.id
                        );
                        return !appointment?.duration.from.isSame(
                            rescheduledApp.at
                        );
                    })
                );
                break;
            case NewBookingsStatus.RecurrentRescheduleSuccess:
                setReschedule(undefined);
                setShowBooking(undefined);
                setRecurring(false);
                break;
            case NewBookingsStatus.Error:
                reschedule && reschedule.revert();
                setRecurring(false);
                setReschedule(undefined);
                setReschedulingAppointments([]);
        }
    }, [newBookingsStatus]);

    useNonInitialEffect(() => {
        switch (blockCalendarStatus) {
            case BlockCalendarStatus.CountRecurrentSuccess:
                savingBlockedTime &&
                    (recurrentBlockCalendar?.remaining ?? 1) === 1 &&
                    updateBlockedTime(blockedTime?.blockedTime!);
                savingBlockedTime &&
                    (recurrentBlockCalendar?.remaining ?? 1) > 1 &&
                    setShowUpdateConfirmation(true);
                break;
            case BlockCalendarStatus.SaveSuccess:
                setSavingBlockedTime(false);
                setShowUpdateConfirmation(false);
                setBlockedTime(undefined);
                break;
            case BlockCalendarStatus.Error:
                setSavingBlockedTime(false);
                setShowUpdateConfirmation(false);
                setBlockedTime(undefined);
                blockedTime?.revert();
        }
    }, [blockCalendarStatus]);

    const bookHandler = (at: Moment, staffId?: number) => {
        setNewBooking({ at, staffId });
    };

    const showAppointmentHandler = (appointmentId: number) => {
        const appointment = appointments.find(
            appointment => appointment.id === appointmentId
        );
        if (appointment) {
            setShowBooking({ appointmentId, bookingId: appointment.bookingId });
        }
    };

    const showBlockCalendarHandler = (blockId: number) => {
        const block = blocks.find(block => block.id === blockId);
        setShowBlockCalendar({ blockId, start: block?.start.clone() });
    };

    const rescheduleHandler = (
        appointmentId: number,
        at: Moment,
        revert: () => void,
        staffId?: number
    ) => {
        const appointment = appointments.find(
            appointment => appointment.id === appointmentId
        );

        if (appointment) {
            if (appointment.duration.from.isSame(at)) {
                setReschedulingAppointments(prev => [
                    ...prev,
                    { id: appointmentId, at: at.clone() }
                ]);
                dispatch(
                    singleRescheduleThunk(
                        apiClientWrapper,
                        appointment.bookingId,
                        appointmentId,
                        at,
                        staffId,
                        undefined,
                        undefined,
                        undefined
                    )
                );
            } else {
                if (appointment.recurringUuid) {
                    setRecurring(true);
                    dispatch(
                        countRecurrentAppointmentsThunk(
                            apiClientWrapper,
                            appointmentId
                        )
                    );
                }
                setReschedule({
                    appointmentId,
                    bookingId: appointment.bookingId,
                    petName: appointment.pet.name,
                    from: appointment.duration.from,
                    to: at,
                    revert,
                    staffId,
                    customerName:
                        appointment.customer.firstName +
                        ' ' +
                        appointment.customer.lastName
                });
            }
        } else {
            revert();
        }
    };

    const rescheduleBlockedHandler = (
        blockedData: BlockCalendar,
        revert: () => void
    ) => {
        setSavingBlockedTime(true);
        dispatch(countRecurrentThunk(apiClientWrapper, blockedData.id!));
        setBlockedTime({ blockedTime: blockedData, revert });
    };

    const updateBlockedTime = (blockData: BlockCalendar) => {
        dispatch(saveThunk(apiClientWrapper, blockData));
    };

    const cancelRescheduleHandler = () => {
        reschedule && reschedule.revert();
        setReschedule(undefined);
        setRecurring(false);
    };

    const confirmRescheduleHandler = (check?: boolean, value?: string) => {
        if (reschedule) {
            if (recurring) {
                if (value === 'only') {
                    dispatch(
                        singleRescheduleThunk(
                            apiClientWrapper,
                            reschedule.bookingId,
                            reschedule.appointmentId,
                            reschedule.to,
                            reschedule.staffId,
                            undefined,
                            undefined,
                            check
                        )
                    );
                } else {
                    dispatch(
                        recurrentRescheduleThunk(
                            apiClientWrapper,
                            reschedule.appointmentId,
                            reschedule.to,
                            reschedule.staffId,
                            undefined,
                            undefined,
                            undefined,
                            check
                        )
                    );
                }
            } else {
                dispatch(
                    singleRescheduleThunk(
                        apiClientWrapper,
                        reschedule.bookingId,
                        reschedule.appointmentId,
                        reschedule.to,
                        reschedule.staffId,
                        undefined,
                        undefined,
                        check
                    )
                );
            }
        }
    };

    const closeNewBooking = () => {
        setNewBooking(undefined);
        setShowBlockCalendar(undefined);
        setTempNewBooking(undefined);
        setIsNewBooking(false);
        setCustomerID(undefined);
        setPetID(undefined);
        setClientAdded(false);
        setPetAdded(false);
    };

    const handleRemoveClient = () => {
        setCustomerID(undefined);
        setClientAdded(false);
        setPetID(undefined);
        setPetAdded(false);
    };

    const changeCalendarTypeHandler = (type: string) => {
        closeNewBooking();
        setCalendarType(type);
    };

    const changeBookingTimeHandler = (
        at: Moment | undefined,
        durationMinutes: number | undefined,
        staffId: number | undefined,
        show: boolean
    ) => {
        const dateFormat = 'YYYYMMDD';

        if (
            at &&
            calendarType === weekly.id &&
            at.clone().week() !== week.from.clone().week()
        ) {
            setLoading(prev => ({ ...prev, appointments: true }));
            setWeek({
                from: at.clone().startOf('week'),
                to: at.clone().endOf('week')
            });
        }

        if (
            at &&
            calendarType === daily.id &&
            date.format(dateFormat) !== at.format(dateFormat)
        ) {
            setLoading(prev => ({ ...prev, appointments: true }));
            setDate(at.clone());
        }

        if (show && at) {
            setTempNewBooking({
                at: at.clone(),
                durationMinutes: durationMinutes ?? 15,
                staffId: staffId ?? newBooking?.staffId
            });
        } else {
            setTempNewBooking(undefined);
        }
    };

    const addNewClient = () => {
        setShowNewClient(true);
    };

    const backClientHandler = () => {
        setShowNewClient(false);
        setShowClient(false);
        setIsNewBooking(false);
    };

    const deleteClientHandler = () => {
        setShowNewClient(false);
        setShowClient(false);
        setIsNewBooking(false);
        setCustomerID(undefined);
    };

    const clientSaveHandler = (client: ClientModel) => {
        setCustomerID(client.id);
        setIsNewBooking(false);
        setClientAdded(true);
    };

    const handlerCreateClient = (pet: Pet) => {
        setCustomerID(pet.clientId);
        setPetID(pet.id);
        setShowNewClient(false);
        setShowClient(false);
        setIsNewBooking(false);
        setPetAdded(true);
    };

    const handlerBooked = () => {
        setIsNewBooking(false);
        setCustomerID(undefined);
        setPetID(undefined);
        setTempNewBooking(undefined);
        setClientAdded(false);
        setPetAdded(false);
    };

    const addNewPet = (clientID: number) => {
        setCustomerID(clientID);
        setShowNewClient(true);
        setIsNewBooking(true);
    };

    const handlerRescheduleFromDetail = (rescheduleBook: BookingWrapper) => {
        setRescheduleFromDetail(true);
        setRescheduleBooking(rescheduleBook);
    };

    const handleBackRescheduleFromDetail = () => {
        setRescheduleFromDetail(false);
        setRescheduleBooking(undefined);
        setRebookBooking(undefined);
    };

    const handleShowClient = (clientId: number) => {
        setShowClient(true);
        setCustomerID(clientId);
    };

    const rebookHandler = (selectedBooking: Booking) => {
        const bookingToRebook: Booking = {
            ...selectedBooking,
            bookedAt: moment().tz(marketplace.schedule.timeZone),
            customer: selectedBooking.customer,
            id: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            appointments: selectedBooking?.appointments.map(appointment => ({
                ...appointment,
                id: undefined,
                recurringUuid: undefined,
                uuid: uuid(),
                notes: '',
                reports: {
                    dentalReportId: undefined,
                    groomingReportId: undefined
                },
                duration: {
                    from: moment().tz(marketplace.schedule.timeZone),
                    to: moment()
                        .tz(marketplace.schedule.timeZone)
                        .add(
                            appointment.duration.to.diff(
                                appointment.duration.from
                            )
                        )
                },
                createdAt: undefined,
                updatedAt: undefined
            }))
        };

        setRebookBooking(bookingToRebook);
        setShowBooking(undefined);
    };

    const createBooking = (
        <CreateBookingDrawer
            at={newBooking?.at ?? showBlockCalendar?.start}
            staffId={newBooking?.staffId}
            parentID={customerID}
            petID={petID}
            clientAdded={clientAdded}
            petAdded={petAdded}
            showTabs={true}
            blockCalendarId={showBlockCalendar?.blockId}
            onClose={closeNewBooking}
            onBooked={handlerBooked}
            onChangeTime={changeBookingTimeHandler}
            onStartBooking={handlerBooked}
            onAddClient={addNewClient}
            onAddPet={addNewPet}
            onRemoveClient={handleRemoveClient}
            blockedEdit={showBlockCalendar?.blockId ? true : false}
        />
    );

    const rescheduleBookingView = (
        <CreateBookingDrawer
            detailView={true}
            bookingReschedule={rescheduleBooking}
            appointmentId={showBooking?.appointmentId}
            onClose={handleBackRescheduleFromDetail}
            onBooked={handleBackRescheduleFromDetail}
        />
    );

    const bookingDetail = (
        <BookingDetail
            bookingId={showBooking?.bookingId ?? 0}
            appointmentId={showBooking?.appointmentId ?? 0}
            onReschedule={handlerRescheduleFromDetail}
            onBack={() => setShowBooking(undefined)}
            onShowClient={handleShowClient}
            onRebook={rebookHandler}
        />
    );

    const newClient = (
        <OverFullWindow onClose={backClientHandler}>
            <Client
                hideHeader={true}
                clientId={customerID}
                isNewBooking={isNewBooking}
                onClose={backClientHandler}
                onClientSaved={clientSaveHandler}
                onPetSaved={handlerCreateClient}
                onDelete={deleteClientHandler}
            />
        </OverFullWindow>
    );

    const reBook = (
        <CreateBookingDrawer
            bookingRebook={rebookBooking}
            detailView={true}
            petID={rebookBooking?.appointments[0].pet.id}
            parentID={rebookBooking?.customer.id}
            appointmentId={rebookBooking?.appointments[0].id}
            showTabs={false}
            onClose={handleBackRescheduleFromDetail}
            onBooked={handleBackRescheduleFromDetail}
        />
    );

    const updateConfirmation = (
        <PopUpUpdateBlockCalendar
            onClose={() => {
                setShowUpdateConfirmation(false);
                setSavingBlockedTime(false);
                blockedTime?.revert();
            }}
            onConfirm={recurrent =>
                updateBlockedTime(blockedTime?.blockedTime!)
            }
        />
    );

    return (
        <>
            <Box className={classes.container}>
                <Box className={classes.header}>
                    <CalendarHeader
                        week={week}
                        date={date}
                        month={month}
                        selectedStaffIds={selectedStaffIds}
                        onChangeMonth={setMonth}
                        onChangeWeek={setWeek}
                        onChangeDate={setDate}
                        onSelectStaffIds={setSelectedStaffIds}
                        onChangeView={changeCalendarTypeHandler}
                        onAdd={() => bookHandler(moment().tz(timeZone))}
                    />
                </Box>
                <Box className={classes.body}>
                    {loading.appointments || (loading.blocks && <Spinner />)}
                    {calendarType === monthly.id && (
                        <MonthFullCalendar
                            month={month}
                            appointments={calendarAppointments}
                            onBook={bookHandler}
                        />
                    )}
                    {calendarType === weekly.id && (
                        <WeekFullCalendar
                            from={week.from}
                            appointments={calendarAppointments}
                            blocks={calendarBlocks}
                            multipleBookingsIds={multipleBookingIds}
                            tempNewBooking={tempNewBooking}
                            shrink={newBooking !== undefined}
                            onBook={bookHandler}
                            onClickAppointment={showAppointmentHandler}
                            onClickBlockCalendar={showBlockCalendarHandler}
                            onRescheduled={rescheduleHandler}
                            onRescheduledBlocked={rescheduleBlockedHandler}
                        />
                    )}
                    {calendarType === daily.id && (
                        <DayFullCalendar
                            date={date}
                            appointments={calendarAppointments}
                            blocks={calendarBlocks}
                            multipleBookingsIds={multipleBookingIds}
                            staff={staffResource}
                            tempNewBooking={tempNewBooking}
                            loadingAppointmentIds={reschedulingAppointments.map(
                                reschedule => reschedule.id
                            )}
                            onBook={bookHandler}
                            onClickAppointment={showAppointmentHandler}
                            onClickBlockCalendar={showBlockCalendarHandler}
                            onRescheduled={rescheduleHandler}
                            onRescheduledBlocked={rescheduleBlockedHandler}
                        />
                    )}
                </Box>
            </Box>
            {(showNewClient || showClient) && newClient}
            {showBooking &&
                !rescheduleFromDetail &&
                !showClient &&
                bookingDetail}
            {rescheduleFromDetail && rescheduleBookingView}
            <BlockCalendarsWebSockets />
            {(newBooking || showBlockCalendar) &&
                !showNewClient &&
                createBooking}
            {reschedule && !recurring && (
                <ConfirmRescheduleDialog
                    name={reschedule.customerName}
                    from={reschedule.from}
                    to={reschedule.to}
                    open={true}
                    onCancel={cancelRescheduleHandler}
                    onConfirm={confirmRescheduleHandler}
                />
            )}
            {rebookBooking && reBook}
            {reschedule && recurring && (
                <ConfirmRescheduleRecurringDialog
                    rescheduleRecurring
                    name={reschedule.customerName}
                    from={reschedule.from}
                    to={reschedule.to}
                    open={true}
                    onCancel={cancelRescheduleHandler}
                    onConfirm={confirmRescheduleHandler}
                />
            )}
            {showUpdateConfirmation && updateConfirmation}
        </>
    );
};

export default Calendar;
