import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import TerminalIcon from 'components/Payments/Terminals/UI/TerminalIcon';
import { FunctionComponent } from 'react';
import { paymentMethodIcons } from '@spike/payments-model';
interface PaymentMethodProps {
	id: string;
	name: string;
	selected?: boolean;
	disabled?: boolean;
	className?: string;
	onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			gap: 8,
			height: 93,
			width: '100%',
			display: 'flex',
			cursor: 'pointer',
			borderRadius: 8,
			alignItems: 'center',
			flexDirection: 'column',
			justifyContent: 'center',
			backgroundColor: '#F7F7F7',
			border: '2px solid #F7F7F7',

			[theme.breakpoints.up('md')]: {
				width: 110
			}
		},
		selectedBorder: {
			border: '2px solid #222222'
		},
		terminalIcon: {
			width: '30px !important',
			height: 'auto !important'
		},
		icon: {
			color: '#000',
			fontSize: 30,
			fontWeight: 400
		},
		text: {
			width: '100%',
			fontSize: 16,
			lineHeight: 1,
			fontWeight: 500,
			color: '#222222',
			textAlign: 'center'
		},
		disabled: {
			opacity: 0.4,
			cursor: 'default'
		}
	})
);

export const PaymentMethod: FunctionComponent<PaymentMethodProps> = props => {
	const classes = useStyles();
	const icon = paymentMethodIcons.get(props.id) || faDollarSign;

	return (
		<Box
			id={`booking_pay_payment_method_${props.id}`}
			className={clsx(
				classes.container,
				{
					[classes.disabled]: props.disabled,
					[classes.selectedBorder]: props.selected
				},
				props.className
			)}
			onClick={() => {
				!props.disabled && props.onClick && props.onClick();
			}}
		>
			{props.id === 'pos' ? (
				<TerminalIcon
					className={clsx(classes.terminalIcon, {
						[classes.disabled]: props.disabled
					})}
				/>
			) : (
				<FontAwesomeIcon
					icon={icon}
					className={clsx(classes.icon, {
						[classes.disabled]: props.disabled
					})}
					size="3x"
				/>
			)}
			<Typography
				className={clsx(classes.text, {
					[classes.disabled]: props.disabled
				})}
			>
				{props.name}
			</Typography>
		</Box>
	);
};

export default PaymentMethod;
