import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    useMediaQuery
} from '@material-ui/core';
import { AlertBox } from 'components/UI/Alerts';
import { FunctionComponent, useState } from 'react';
import Category from './Category';
import Details from './Details';
import Pricing from './Pricing';
import ServiceStaff from './Staff';
import { BusinessSettingsPath } from 'routes/Paths';
import { useAuth, useMarketplace, useMasterData } from 'hooks';
import Service, {
    FixedPriceOption,
    ServiceDuration,
    StaffMember,
    VariablePrice
} from 'model/Service';
import {
    validateCategoryStep,
    validateDetailsStep,
    validatePricingStep,
    validateService
} from './Validations';
import { MarketplaceTax } from '@spike/marketplace-model';
import {
    ACCESS_LEVEL_ADMIN_ID,
    Option,
    PetType,
    Selectable
} from '@spike/model';
import _, { isEmpty, isEqual } from 'lodash';
import { FieldErrorWithKey, ServiceCategoryType } from './model';
import useNonInitialEffect from '@versiondos/hooks';

interface SectionState {
    collapsed: boolean;
    disabled: boolean;
    editable: boolean;
    completed: boolean;
}

interface ServiceEditProps {
    service: Service;
    selectedCategory: number;
    onChangeService?: (updatedSevice: Service) => void;
    onSave?: (updatedService: Service) => void;
    onSaveDraft?: (updatedService: Service) => void;
    onChangeServiceName?: (name: string) => void;
    onAddTaxes?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alertContainer: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: '15px',
                marginRight: '15px'
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: '20px'
            }
        },
        sectionContainer: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                minWidth: '750px',
                marginTop: '30px'
            },
            [theme.breakpoints.down('md')]: {
                margin: '10px'
            }
        },
        lastSectionContainer: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                marginTop: '30px'
            },
            [theme.breakpoints.down('md')]: {
                margin: '10px'
            },
            [theme.breakpoints.up('xl')]: {
                minWidth: '1000px',
                marginTop: '30px'
            }
        },
        saveMessage: {
            width: '100%',
            textAlign: 'left',
            [theme.breakpoints.down('lg')]: {
                fontSize: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '15px'
            }
        }
    })
);

const ServiceEdit: FunctionComponent<ServiceEditProps> = props => {
    const masterData = useMasterData();
    const marketplace = useMarketplace();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const classes = useStyles();
    const auth = useAuth();

    const isAdmin = auth.user!.accessLevel.id === ACCESS_LEVEL_ADMIN_ID;

    const hasNotPetTypes = marketplace.petTypes
        ? !marketplace.petTypes.cats &&
          !marketplace.petTypes.dogs &&
          !marketplace.petTypes.exotics
        : false;

    const [category, setCategory] = useState<SectionState>({
        collapsed: !(props.selectedCategory === ServiceCategoryType.CATEGORY),
        disabled: hasNotPetTypes,
        editable: false,
        completed: false
    });
    const [details, setDetails] = useState<SectionState>({
        collapsed: !(props.selectedCategory === ServiceCategoryType.DETAIL),
        disabled: true,
        editable: false,
        completed: false
    });
    const [pricing, setPricing] = useState<SectionState>({
        collapsed: !(props.selectedCategory === ServiceCategoryType.PRICING),
        disabled: true,
        editable: false,
        completed: false
    });
    const [staff, setStaff] = useState<SectionState>({
        collapsed: !(props.selectedCategory === ServiceCategoryType.STAFF),
        disabled: true,
        editable: false,
        completed: false
    });

    const [service, setService] = useState(props.service);

    const [errors, setErrors] = useState<Array<FieldErrorWithKey>>([]);

    useNonInitialEffect(() => {
        props.onChangeService && props.onChangeService(service);
    }, [service]);

    // STEP 1

    const reportChangeHandler = (hasReport: boolean): void => {
        setService(prevService => {
            return {
                ...prevService,
                hasReport
            };
        });
    };

    const categoryCollapseHandler = () => {
        setCategory(prev => ({
            ...prev,
            collapsed: true
        }));
    };

    const categoryEditHandler = () => {
        if (!isEmpty(errors)) {
            const serviceErrors = validateService(service);
            setErrors(serviceErrors);
        }
        setCategory(prev => {
            return { ...prev, collapsed: false, completed: false };
        });

        setDetails(prev => {
            return {
                ...prev,
                collapsed: true,
                disabled: !prev.editable,
                completed: validateDetailsStep(service)
            };
        });

        setPricing(prev => {
            return {
                ...prev,
                collapsed: true,
                disabled: !prev.editable,
                completed: validatePricingStep(service)
            };
        });

        setStaff(prev => {
            return { ...prev, collapsed: true, disabled: !prev.editable };
        });
    };

    const categoryChangeHandler = (
        selectedPetTypes: Array<Selectable<PetType>>
    ) => {
        const petTypes = selectedPetTypes
            .filter(option => option.selected)
            .map(option => option.element);

        const petTypeIds = petTypes.map(petType => petType.id);

        setService(prev => {
            return {
                ...prev,
                petTypes,
                pricing: {
                    taxable: prev.pricing.taxable,
                    taxes: prev.pricing.taxes,
                    variablePrice: prev.pricing.variablePrice && {
                        variables: {
                            petTypeVariable:
                                (prev.petTypes.length === 1 &&
                                    selectedPetTypes.filter(o => o.selected)
                                        .length > 1) ||
                                (selectedPetTypes.filter(o => o.selected)
                                    .length !== 1 &&
                                    prev.pricing.variablePrice.variables
                                        .petTypeVariable),
                            petSizeVariable:
                                _.intersection(petTypeIds, ['dogs', 'cats']) &&
                                prev.pricing.variablePrice.variables
                                    .petSizeVariable,
                            hairTypeVariable:
                                _.intersection(petTypeIds, ['dogs', 'cats'])
                                    .length > 0 &&
                                prev.pricing.variablePrice.variables
                                    .hairTypeVariable,
                            hairLengthVariable:
                                _.intersection(petTypeIds, ['dogs', 'cats'])
                                    .length > 0 &&
                                prev.pricing.variablePrice.variables
                                    .hairLengthVariable,
                            exoticTypeVariable:
                                petTypeIds.includes('exotics') &&
                                prev.pricing.variablePrice.variables
                                    .exoticTypeVariable
                        },
                        prices: prev.pricing.variablePrice.prices.filter(p =>
                            petTypeIds.includes(p.petType?.id || '')
                        )
                    },
                    fixedPrice:
                        prev.pricing.fixedPrice.length === 0
                            ? prev.pricing.fixedPrice
                            : prev.pricing.fixedPrice.filter(fp =>
                                  petTypeIds.includes(fp.petType.id)
                              )
                }
            };
        });
    };

    const categoryNextHandler = () => {
        setCategory(prev => {
            return {
                ...prev,
                collapsed: true,
                editable: true,
                completed: true
            };
        });

        setDetails(prev => {
            return {
                ...prev,
                collapsed: false,
                disabled: false,
                completed: false
            };
        });
    };

    // STEP 2

    const detailsEditHandler = () => {
        if (!isEmpty(errors)) {
            const serviceErrors = validateService(service);
            setErrors(serviceErrors);
        }
        setCategory(prev => {
            return {
                ...prev,
                collapsed: true,
                disabled: !prev.editable,
                completed: validateCategoryStep(service)
            };
        });

        setDetails(prev => {
            return { ...prev, collapsed: false, completed: false };
        });

        setPricing(prev => {
            return {
                ...prev,
                collapsed: true,
                disabled: !prev.editable,
                completed: validatePricingStep(service)
            };
        });

        setStaff(prev => {
            return { ...prev, collapsed: true, disabled: !prev.editable };
        });
    };

    const detailsNextHandler = () => {
        setDetails(prev => {
            return {
                ...prev,
                collapsed: true,
                editable: true,
                completed: true
            };
        });

        setPricing(prev => {
            return {
                ...prev,
                collapsed: false,
                disabled: false,
                completed: false
            };
        });
    };

    const nameChangeHandler = (name: string): void => {
        setService(prevService => {
            return {
                ...prevService,
                name
            };
        });
        props.onChangeServiceName && props.onChangeServiceName(name);
    };

    const businessLocationHandler = (isMobile: boolean) => {
        setService(prevService => {
            return {
                ...prevService,
                houseCall: isMobile
            };
        });
    };

    const businessAreaChangeHandler = (businessArea: Option<string>): void => {
        setService(prevService => {
            return {
                ...prevService,
                businessArea
            };
        });
    };

    const tagsChangeHandler = (tags: Array<string>): void => {
        setService(prevService => {
            return {
                ...prevService,
                tags
            };
        });
    };

    const durationChangeHandler = (duration: ServiceDuration): void => {
        setService(prevService => {
            return {
                ...prevService,
                duration
            };
        });
    };

    const descriptionChangeHandler = (description: string): void => {
        setService(prevService => {
            return {
                ...prevService,
                description
            };
        });
    };

    const onlineBookingHandler = (active: boolean) => {
        setService(prevService => {
            return {
                ...prevService,
                olbEnabled: active
            };
        });
    };

    // STEP 3

    const pricingEditHandler = () => {
        if (!isEmpty(errors)) {
            const serviceErrors = validateService(service);
            setErrors(serviceErrors);
        }
        setCategory(prev => {
            return {
                ...prev,
                collapsed: true,
                disabled: !prev.editable,
                completed: validateCategoryStep(service)
            };
        });

        setDetails(prev => {
            return {
                ...prev,
                collapsed: true,
                disabled: !prev.editable,
                completed: validateDetailsStep(service)
            };
        });

        setPricing(prev => {
            return { ...prev, collapsed: false, completed: false };
        });

        setStaff(prev => {
            return { ...prev, collapsed: true, disabled: !prev.editable };
        });
    };

    const pricingChangeHandler = (
        taxable: boolean,
        fixedPrice: Array<FixedPriceOption>,
        variablePrice: VariablePrice | undefined,
        taxes: Array<MarketplaceTax>
    ) => {
        setService(prevService => {
            return {
                ...prevService,
                pricing: {
                    taxable,
                    fixedPrice,
                    variablePrice,
                    taxes
                }
            };
        });
    };

    const pricingNextHandler = () => {
        setPricing(prev => {
            return {
                ...prev,
                collapsed: true,
                editable: true,
                completed: true
            };
        });

        setStaff(prev => {
            return {
                ...prev,
                collapsed: false,
                disabled: false,
                completed: false
            };
        });
    };

    // STEP 4

    const staffEditHandler = () => {
        const serviceErrors = validateService(service);
        if (!isEmpty(serviceErrors)) {
            setErrors(serviceErrors);
        }
        setCategory(prev => {
            return {
                ...prev,
                collapsed: true,
                disabled: !prev.editable,
                completed: validateCategoryStep(service)
            };
        });

        setDetails(prev => {
            return {
                ...prev,
                collapsed: true,
                disabled: !prev.editable,
                completed: validateDetailsStep(service)
            };
        });

        setPricing(prev => {
            return {
                ...prev,
                collapsed: true,
                disabled: !prev.editable,
                completed: validatePricingStep(service)
            };
        });

        setStaff(prev => {
            return { ...prev, collapsed: false, completed: false };
        });
    };

    const staffChangeHandler = (staff: Array<StaffMember>) => {
        setService(prevService => {
            return {
                ...prevService,
                staff
            };
        });
    };

    const staffNextHandler = () => {
        setService(prevService => {
            return {
                ...prevService,
                staff: prevService.staff.sort((member, otherMember) => {
                    return member.firstName.localeCompare(
                        otherMember.firstName
                    );
                })
            };
        });

        setStaff(prev => {
            return {
                ...prev,
                collapsed: true,
                editable: true,
                completed: true
            };
        });

        const serviceErrors = validateService(service);

        const categoryStepCompleted = validateCategoryStep(service);
        const detailStepCompleted = validateDetailsStep(service);
        const pricingStepCompleted = validatePricingStep(service);

        if (
            !categoryStepCompleted ||
            !detailStepCompleted ||
            !pricingStepCompleted
        ) {
            setErrors(serviceErrors);
            if (!categoryStepCompleted) {
                categoryEditHandler();
            }
            if (!detailStepCompleted) {
                detailsEditHandler();
            }
            if (detailStepCompleted && !pricingStepCompleted) {
                pricingEditHandler();
            }
        } else {
            props.onSave && props.onSave(service);
        }
    };

    const staticOptionsBusinessAreas = [
        { id: 'dental', name: 'Dental' },
        { id: 'grooming', name: 'Grooming' }
    ];

    const alertText = (
        <>
            Oops! It looks like you&apos;re trying to add a service, but we need
            to set up some <strong>pet types</strong> first. Go to the business
            settings page to configure pet types and then come back to add your
            services!
        </>
    );

    return (
        <>
            {hasNotPetTypes && (
                <Box className={classes.alertContainer}>
                    <AlertBox
                        text={alertText}
                        actionable={true}
                        label="Add Pet Types"
                        path={`${BusinessSettingsPath}/pets/types`}
                    ></AlertBox>
                </Box>
            )}

            <Box className={classes.sectionContainer}>
                <Category
                    {...category}
                    petTypes={masterData.petTypes.map(pt => ({
                        element: pt,
                        selected: service.petTypes.some(spt => spt.id === pt.id)
                    }))}
                    service={service}
                    editable={isMobile ? false : true}
                    modifiable={true}
                    disabled={hasNotPetTypes}
                    optionsBusinessAreas={staticOptionsBusinessAreas}
                    onNext={categoryNextHandler}
                    onChange={categoryChangeHandler}
                    onEdit={categoryEditHandler}
                    onCollapse={categoryCollapseHandler}
                    onChangeBusinessArea={businessAreaChangeHandler}
                    marketplaceBasics={marketplace.basics}
                />
            </Box>
            <Box className={classes.sectionContainer}>
                <Details
                    {...details}
                    service={service}
                    editable={isMobile ? false : true}
                    modifiable={true}
                    disabled={hasNotPetTypes}
                    isAdmin={isAdmin}
                    errors={errors.filter(
                        error => error.key === ServiceCategoryType.DETAIL
                    )}
                    onNext={detailsNextHandler}
                    onEdit={
                        validateCategoryStep(service)
                            ? detailsEditHandler
                            : undefined
                    }
                    onNameChange={nameChangeHandler}
                    onDescriptionChange={descriptionChangeHandler}
                    onDurationChange={durationChangeHandler}
                    onTagsChange={tagsChangeHandler}
                    onReportChange={reportChangeHandler}
                    onBusinessLocationChange={businessLocationHandler}
                    onOnlineBookingChange={onlineBookingHandler}
                />
            </Box>
            <Box className={classes.sectionContainer}>
                <Pricing
                    {...pricing}
                    errors={errors.filter(
                        error => error.key === ServiceCategoryType.PRICING
                    )}
                    service={service}
                    editable={isMobile ? false : true}
                    modifiable={true}
                    disabled={hasNotPetTypes}
                    onNext={pricingNextHandler}
                    onEdit={
                        validateCategoryStep(service)
                            ? pricingEditHandler
                            : undefined
                    }
                    onAddTaxes={props.onAddTaxes}
                    onChange={pricingChangeHandler}
                />
            </Box>
            <Box className={classes.lastSectionContainer}>
                <ServiceStaff
                    {...staff}
                    service={service}
                    editable={isMobile ? false : true}
                    modifiable={true}
                    disabled={hasNotPetTypes}
                    onNext={staffNextHandler}
                    onEdit={
                        validateCategoryStep(service)
                            ? staffEditHandler
                            : undefined
                    }
                    onChange={staffChangeHandler}
                />
            </Box>
        </>
    );
};

export default ServiceEdit;
