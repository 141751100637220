import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface Props {
  title: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width:"100%",
    },
    title: {
      fontWeight: 600,
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "18px",
      },
    }

  })
);

export const SimpleHeaderTitle: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{props.title}</Typography>
    </Box>
  );
};

export default SimpleHeaderTitle;
