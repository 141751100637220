import { CSVLink } from 'react-csv';
import { useExportCSVStyle } from './ExportCSV.styles';
import { FunctionComponent } from 'react';
import { CSVModel } from './CSVModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { useTheme, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

interface ExportCSVProps extends CSVModel {
    onLoading?: boolean;
    label: string;
}

const ExportCSV: FunctionComponent<ExportCSVProps> = props => {
    const classes = useExportCSVStyle();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <CSVLink
            onClick={props.onLoading ? undefined : props.onClick}
            asyncOnClick={props.asyncOnClick}
            data={props.data || []}
            headers={props.headers}
            enclosingCharacter=""
            separator=","
            filename={props.fileName}
            target="_blank"
            className={clsx([
                classes.csvLink,
                {
                    [classes.disabled]: props.onLoading
                }
            ])}
        >
            <div className={classes.csv}>
                <>
                    <FontAwesomeIcon icon={faArrowDownToLine} color="#000" />
                    {!isMobile && <span>{props.label}</span>}
                </>
            </div>
        </CSVLink>
    );
};

export default ExportCSV;
