import { UserNotification } from 'model/UserNotifications';
import React from 'react';
import NotificationRow from './NotificationRow';

type NotificationsListProps = {
	notifications: Array<UserNotification>;
	emptyMessage: React.ReactElement;
};

export const NotificationsList: React.FunctionComponent<
	NotificationsListProps
> = ({ notifications, emptyMessage }) => {
	return (
		<>
			{/* Notifications list only if there are more than 0 */}
			{notifications.length > 0 &&
				notifications.map(notification => (
					<NotificationRow
						key={notification.id}
						notification={notification}
					/>
				))}

			{/* Empty message if there are no notifications */}
			{notifications.length === 0 && emptyMessage}
		</>
	);
};
