import * as amplitude from '@amplitude/analytics-browser';
import { faArrowLeft, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    Theme,
    Typography,
    createStyles,
    makeStyles
} from '@material-ui/core';
import { Invoice, Tip as InvoiceTip } from '@spike/invoice-model';
import { InvoicesStatus, saveTipThunk } from '@spike/invoices-action';
import { Option } from '@spike/model';
import {
    PaymentsStatus,
    confirmAndSendThunk,
    getEnrollmentStatusThunk,
    sendThunk
} from '@spike/payments-action';
import { PaymentMethodIds } from '@spike/payments-model';
import { cancelThunk as cancelPosThunk } from '@spike/pos-action';
import { isEmailValid } from '@spike/validations';
import { useNonInitialEffect } from '@versiondos/hooks';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import { Button, Spinner } from 'components/UI';
import { AMPLITUDE } from 'constants/index';
import { useApiClientWrapper } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import CreditCardPayment from './CreditCardPayment';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import PosPayment from './PosPayment';
import SendingInformation from './SendingInformation';
import Tips from './Tips';
import { isSameTip } from './Tips/TipUtils';
import Total from './Total';
import { getBookingThunk } from '@spike/bookings-action';
import Booking from '@spike/booking-model';

interface PaymentsProps {
    title?: string;
    bookingId?: number;
    invoiceId: number;
    totalBalance: string;
    subtotalWithoutTipTaxes: string;
    tip: InvoiceTip | null;
    customerEmail: string | null;
    className?: string;
    hiddenReport?: boolean;
    mobileTitle?: string;
    onComplete?: (total: string, date: Moment) => void;
    onClose?: () => void;
    onScrollToTop?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: 'fit-content',

            [theme.breakpoints.down('sm')]: {
                padding: '24px 16px'
            },
            [theme.breakpoints.up('md')]: {
                margin: '30px'
            }
        },
        title: {
            color: '#000',
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        totalBalanceContainer: {
            width: '100%',
            borderBottom: '2px solid #F1F1F1',
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(30)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '30px'
            }
        },
        reportAndReceipt: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(33)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '33px'
            }
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(50)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '50px'
            }
        },
        button: {
            width: '100%',
            borderRadius: '100px',
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(72)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                height: '72px'
            }
        },
        messageContainer: {
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(40)}px ${reduceResolution(
                    50
                )}px 0px ${reduceResolution(50)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '40px 50px 0px 50px'
            }
        },
        success: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: '#00AA00',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`,
                marginTop: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px',
                marginTop: '10px'
            }
        },
        error: {
            fontFamily: 'Poppins',
            color: '#C14A4A',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`,
                marginBottom: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px',
                marginBottom: '10px'
            }
        },
        paymentFormContainer: {
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(50)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '50px'
            }
        },
        backCloseContainer: {
            'display': 'flex',
            'alignItems': 'center',

            [theme.breakpoints.down('sm')]: {
                gap: 10,
                marginBottom: 32
            },

            '& svg:last-child': {
                marginLeft: 'auto'
            }
        },
        closeIcon: {
            cursor: 'pointer',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(24)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '24px'
            }
        },
        spinner: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '20px'
            }
        },
        sectionMargin: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(33)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '33px'
            }
        }
    })
);

interface CheckoutData {
    paymentMethodId: string | null;
    tip: InvoiceTip | null;
    email: string | null;
    sendReport: boolean;
    sendInvoice: boolean;
    posTerminalId: number | null;
    posServiceId: string | null;
    isZeroTotalBalance: boolean;
}

export const Payments: FunctionComponent<PaymentsProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );
    const isPaymentsEnabled = useSelector<RootState, boolean | undefined>(
        state => state.payments.enrollmentStatus?.enabled
    );

    const invoicesStatus = useSelector<RootState, InvoicesStatus>(
        state => state.invoices.status
    );
    const savedInvoice = useSelector<RootState, Invoice | undefined>(
        state => state.invoices.savedInvoice
    );

    const booking = useSelector<RootState, Booking | undefined>(
        state => state.bookings.booking
    );

    const [checkoutData, setCheckoutData] = useState<CheckoutData>({
        paymentMethodId: null,
        tip: props.tip,
        email: null,
        sendReport: true,
        sendInvoice: true,
        posTerminalId: null,
        posServiceId: null,
        isZeroTotalBalance: Number(props.totalBalance) === 0
    });

    const isFreeServicesInvoice = Number(props.subtotalWithoutTipTaxes) === 0;

    const [completing, setCompleting] = useState(false);

    const [totalBalance, setTotalBalance] = useState(props.totalBalance);
    const [prevTip, setPrevTip] = useState<InvoiceTip | null>(
        props.tip ? { ...props.tip } : null
    );
    const [saving, setSaving] = useState(false);

    const [errors, setErrors] = useState<Array<string>>([]);
    const [show, setShow] = useState({
        paymentMethods: true,
        tips: true,
        totalBalance: true,
        creditCard: false,
        pos: false,
        sending: Number(props.totalBalance) === 0
    });

    useEffect(() => {
        dispatch(getBookingThunk(apiClientWrapper, props.bookingId!));
        dispatch(getEnrollmentStatusThunk(apiClientWrapper));
        amplitude.track(AMPLITUDE.LOAD_PAYMENT_METHOD_CHECK_OUT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (checkoutData.email === null && props.customerEmail !== null) {
            setCheckoutData(prev => ({ ...prev, email: props.customerEmail }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.customerEmail]);

    useNonInitialEffect(() => {
        switch (invoicesStatus) {
            case InvoicesStatus.SaveTipSuccess:
                const isZeroTotalBalance = Number(savedInvoice!.totalDue) === 0;
                setTotalBalance(savedInvoice!.totalDue);
                setCheckoutData(prev => ({
                    ...prev,
                    isZeroTotalBalance,
                    paymentMethodId: isZeroTotalBalance
                        ? null
                        : prev.paymentMethodId
                }));
                setShow(prev => ({
                    ...prev,
                    sending:
                        isZeroTotalBalance ||
                        checkoutData.paymentMethodId !== null
                }));
                setSaving(false);
                break;
            case InvoicesStatus.Error:
                setSaving(false);
                setCheckoutData(prev => ({ ...prev, tip: null }));
        }
    }, [invoicesStatus, savedInvoice]);

    useNonInitialEffect(() => {
        switch (paymentsStatus) {
            case PaymentsStatus.SendSuccess:
                setCompleting(false);
                props.onComplete && props.onComplete(totalBalance, moment());
                break;
            case PaymentsStatus.Error:
                setCompleting(false);
        }
    }, [paymentsStatus]);

    const dispatchSaveTip = (
        amount: string | null,
        percentage: string | null
    ) => {
        dispatch(
            saveTipThunk(apiClientWrapper, props.invoiceId, amount, percentage)
        );
    };

    const changeTipHandler = (tip: InvoiceTip | null) => {
        if (!isSameTip(checkoutData.tip, tip)) {
            setSaving(true);
            setCheckoutData(prev => ({ ...prev, tip }));
            setPrevTip(tip);
            dispatchSaveTip(
                tip === null ? null : tip.amount,
                tip === null ? null : tip.percentage
            );
        }
    };

    const changeSendingDataHandler = (
        sendReport: boolean,
        sendInvoice: boolean,
        email: string
    ) => {
        setCheckoutData(prev => ({ ...prev, sendReport, sendInvoice, email }));
    };

    const changePaymentMethodHandler = (paymentMethod: Option<string>) => {
        if (paymentMethod.id === PaymentMethodIds.POS) {
            setSaving(true);
            dispatchSaveTip(null, null);
        } else if (checkoutData.paymentMethodId === PaymentMethodIds.POS) {
            setSaving(true);
            dispatchSaveTip(
                prevTip?.amount || null,
                prevTip?.percentage || null
            );
        }

        setCheckoutData(prev => ({
            ...prev,
            paymentMethodId: paymentMethod.id,
            tip:
                paymentMethod.id === PaymentMethodIds.POS
                    ? null
                    : prev.paymentMethodId === PaymentMethodIds.POS
                    ? prevTip
                    : prev.tip
        }));

        setShow({
            paymentMethods: true,
            tips: paymentMethod.id !== PaymentMethodIds.POS,
            totalBalance: true,
            creditCard: false,
            pos: false,
            sending: true
        });

        switch (paymentMethod.id) {
            case 'cash':
                amplitude.track(AMPLITUDE.CTA_CASH_CHECK_OUT);
                break;
            case 'pos':
                amplitude.track(AMPLITUDE.CTA_POS_CHECK_OUT);
                break;
            case 'credit_card':
                amplitude.track(AMPLITUDE.CTA_CREDIT_CARD_CHECK_OUT);
                break;
            case 'other':
                amplitude.track(AMPLITUDE.CTA_OTHER_CHECK_OUT);
                break;
            default:
                break;
        }
    };

    const completeHandler = () => {
        const errors = validate(checkoutData);
        setErrors(errors);

        if (
            ![
                PaymentMethodIds.CreditCard.toString(),
                PaymentMethodIds.POS.toString()
            ].includes(checkoutData.paymentMethodId!) &&
            errors.length === 0
        ) {
            setCompleting(true);
            dispatch(
                confirmAndSendThunk(
                    apiClientWrapper,
                    props.invoiceId,
                    checkoutData.paymentMethodId || PaymentMethodIds.Other,
                    checkoutData.sendInvoice,
                    checkoutData.sendReport,
                    checkoutData.email!
                )
            );
        } else if (
            PaymentMethodIds.CreditCard === checkoutData.paymentMethodId! &&
            errors.length === 0
        ) {
            setShow({
                paymentMethods: false,
                tips: false,
                totalBalance: true,
                creditCard: true,
                pos: false,
                sending: false
            });
        } else if (
            PaymentMethodIds.POS === checkoutData.paymentMethodId! &&
            errors.length === 0
        ) {
            setShow({
                paymentMethods: false,
                tips: false,
                totalBalance: true,
                creditCard: false,
                pos: true,
                sending: false
            });
        }
    };

    const successCreditCardPaymentHandler = (creditCardsessionId: string) => {
        setCompleting(true);
        dispatch(
            confirmAndSendThunk(
                apiClientWrapper,
                props.invoiceId,
                checkoutData.paymentMethodId!,
                checkoutData.sendInvoice,
                checkoutData.sendReport,
                checkoutData.email!,
                creditCardsessionId
            )
        );
    };

    const backCreditCardPaymentHandler = () => {
        setShow({
            paymentMethods: true,
            tips: true,
            totalBalance: true,
            creditCard: false,
            pos: false,
            sending: true
        });
    };

    const closeHandler = () => {
        if (show.pos) {
            checkoutData.posServiceId &&
                checkoutData.posTerminalId &&
                dispatch(
                    cancelPosThunk(
                        apiClientWrapper,
                        props.invoiceId,
                        checkoutData.posTerminalId,
                        checkoutData.posServiceId
                    )
                );
            props.onClose && props.onClose();
        } else {
            props.onClose && props.onClose();
        }
        amplitude.track(AMPLITUDE.CTA_CLOSE_PAYMENT_METHODS);
    };

    const backPosPaymentHandler = () => {
        checkoutData.posServiceId &&
            checkoutData.posTerminalId &&
            dispatch(
                cancelPosThunk(
                    apiClientWrapper,
                    props.invoiceId,
                    checkoutData.posTerminalId,
                    checkoutData.posServiceId
                )
            );
        setShow({
            paymentMethods: true,
            tips: false,
            totalBalance: true,
            creditCard: false,
            pos: false,
            sending: true
        });
    };

    const successPosPaymentHandler = () => {
        setCompleting(true);
        dispatch(
            sendThunk(
                apiClientWrapper,
                props.invoiceId,
                checkoutData.sendInvoice,
                checkoutData.sendReport,
                checkoutData.email!
            )
        );
    };

    const validate = (data: CheckoutData): Array<string> => {
        const errors: Array<string> = [];

        if (
            (data.sendInvoice || data.sendReport) &&
            isEmpty((data.email || '').trim())
        ) {
            errors.push('Email is required');
        }
        if (
            (data.sendInvoice || data.sendReport) &&
            !isEmpty((data.email || '').trim()) &&
            !isEmailValid(data.email || '')
        ) {
            errors.push('Email is invalid.');
        }
        if (!data.isZeroTotalBalance && data.paymentMethodId === null) {
            errors.push('Select a Payment Method.');
        }

        // if (data.paymentMethodId !== "pos" && data.tip === null) {
        //   errors.push("Select a Tip option.");
        // }

        return errors;
    };

    const showValidateEmailErr = (
        sendReport: boolean,
        sendReceipt: boolean
    ) => {
        return sendReport || sendReceipt;
    };

    const hasReport =
        booking?.appointments.some(
            appointment =>
                appointment.reports.dentalReportId !== null ||
                appointment.reports.groomingReportId !== null
        ) || false;

    return (
        <div className={clsx(classes.container, props.className)}>
            <Box className={classes.backCloseContainer}>
                {(show.creditCard || show.pos) && (
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        className={classes.closeIcon}
                        onClick={
                            show.creditCard
                                ? backCreditCardPaymentHandler
                                : backPosPaymentHandler
                        }
                    />
                )}

                {props.title && (
                    <Typography className={classes.title}>
                        {props.title}
                    </Typography>
                )}

                <FontAwesomeIcon
                    icon={faTimes}
                    className={classes.closeIcon}
                    onClick={closeHandler}
                />
            </Box>
            {show.paymentMethods &&
                (isPaymentsEnabled === undefined ? (
                    <Spinner className={classes.spinner} />
                ) : (
                    <PaymentMethods
                        paymentsEnabled={isPaymentsEnabled}
                        isFreeServicesInvoice={isFreeServicesInvoice}
                        selectedId={checkoutData.paymentMethodId}
                        onSelect={changePaymentMethodHandler}
                        disabled={checkoutData.isZeroTotalBalance}
                    />
                ))}
            {show.tips && (
                <Tips
                    invoiceId={props.invoiceId}
                    invoiceTip={checkoutData.tip}
                    subtotalWithoutTipTaxes={props.subtotalWithoutTipTaxes}
                    tipDistribution={savedInvoice?.tipDistribution ?? []}
                    totalBalance={value => setTotalBalance(value)}
                    saving={saving}
                    className={classes.sectionMargin}
                    onChange={changeTipHandler}
                />
            )}
            {show.totalBalance && (
                <Total
                    amount={totalBalance}
                    title="Total Balance"
                    loading={saving}
                    className={classes.sectionMargin}
                />
            )}

            {show.pos && (
                <PosPayment
                    invoiceId={props.invoiceId}
                    className={classes.sectionMargin}
                    onSuccess={successPosPaymentHandler}
                    onGetPosServiceId={(posTerminalId, posServiceId) =>
                        setCheckoutData(prev => ({
                            ...prev,
                            posTerminalId,
                            posServiceId
                        }))
                    }
                />
            )}

            {show.sending &&
                (props.customerEmail === null ? (
                    <Spinner className={classes.reportAndReceipt} />
                ) : (
                    <>
                        <SendingInformation
                            hasReport={hasReport}
                            className={classes.reportAndReceipt}
                            email={props.customerEmail}
                            hiddenReport={props.hiddenReport}
                            onChange={changeSendingDataHandler}
                        />
                        <Box className={classes.buttonContainer}>
                            {showValidateEmailErr(
                                checkoutData.sendReport,
                                checkoutData.sendInvoice
                            ) &&
                                errors.map((error, index) => (
                                    <Typography
                                        className={classes.error}
                                        key={index}
                                    >
                                        {error}
                                    </Typography>
                                ))}
                            <Button
                                label={
                                    checkoutData.paymentMethodId ===
                                    PaymentMethodIds.CreditCard
                                        ? 'Confirm & Pay'
                                        : !props.hiddenReport
                                        ? 'Complete Appointment'
                                        : 'Pay'
                                }
                                id="booking_confirm_pay_button"
                                className={classes.button}
                                onClick={completeHandler}
                                loading={completing}
                                disabled={saving}
                            />
                        </Box>
                    </>
                ))}
            {show.creditCard && (
                <CreditCardPayment
                    invoiceId={props.invoiceId}
                    onSuccess={successCreditCardPaymentHandler}
                    onBack={backCreditCardPaymentHandler}
                />
            )}
        </div>
    );
};

export default Payments;
