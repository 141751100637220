import React, {
	FunctionComponent,
	useState,
	MouseEvent,
	ChangeEvent,
	useMemo,
	useEffect,
} from "react";
import { Box, Checkbox, Grid } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CustomInput from "../Forms/CustomInput";
import { Button } from 'components/UI';
import commonsStyles from "rocket/assets/css/Commons";
import CustomPasswordInput from "../Forms/CustomPasswordInput";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { setDataThunk, verifyEmailThunk } from "actions/signUp/SignUpActions";
import { SignUpStatus } from "reducers/signUp/SignUpState";
import { useHistory } from "react-router";
import { LoginPath, SignUpAlmostPath } from "routes/Paths";
import { useNonInitialEffect } from "@versiondos/hooks";
import SignUp from "model/SignUp";
import Badge from "../Commons/Badge";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { validatePassword, isEmailValid } from "@spike/validations";
import { FieldError } from "@spike/model";
import isEmpty from "lodash/isEmpty";
import { showError } from "@spike/notifications-action";
import { isBoolean, isString } from "lodash";
import { useQuery } from "hooks";

const useStyles = makeStyles((theme: Theme) => ({
	title: {
		marginBottom: 45,
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
			marginBottom: 40,
		},
	},
	column: {
		display: "flex",
		paddingTop: 15,
		paddingLeft: 20,
		paddingRight: 20,
		paddingBottom: 60,
		marginLeft: "auto",
		minHeight: "100vh",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: "#F8F5F1",
		[theme.breakpoints.between("sm", "md")]: {
			paddingLeft: 100,
			paddingRight: 100,
		},
		[theme.breakpoints.up("md")]: {
			maxWidth: "55%",
			flexBasis: "55%",
			paddingTop: 0,
			paddingLeft: "7%",
			paddingRight: "9%",
			paddingBottom: 80,
			backgroundColor: "#ffffff",
		},
		[theme.breakpoints.up("lg")]: {
			paddingLeft: "7%",
			paddingRight: "13%",
		},
	},
	formColumn: {
		marginBottom: 16,
	},
	checksColumn: {
		display: "flex",
		marginLeft: "0",
	},
	smsOptIn: {
		marginTop: -5,
		paddingTop: "0px !important",
		marginLeft: "0px",
		[theme.breakpoints.down("md")]: {
			'& .MuiCheckbox-root': {
				paddingTop: 0,
				alignItems: "flex-start"
			}
		}
	},
	linkTermsAndPrivacyContainer: {
		justifyContent: "center",
	},
	linkTermsAndPrivacyContainerSms: {
		[theme.breakpoints.down("md")]: {
			display: "flex",
			alignItems: "flex-start"
		}
	},
	linkTermsAndPrivacy: {
		color: "#000000",
		fontSize: 15,
		lineHeight: 1.6,
		fontFamily: '"Poppins", sans-serif',
		'& .disclaimer': {
			fontSize: 12,
			marginTop: "-9px",
			paddingLeft: 42,
			marginBottom: 10,
			color: "#000"
		},
		[theme.breakpoints.down(1281)]: {
			fontSize: 14,
		},
		[theme.breakpoints.down("md")]: {
			lineHeight: 1.1,
			'& .disclaimer': {
				fontSize: 10,
				marginTop: "5px",
				paddingLeft: 0
			},
		},
	},
	linkTermsAndPrivacyError: {
		color: "#EA6464",
		fontSize: 15,
		lineHeight: 1.6,
		fontFamily: '"Poppins", sans-serif',
		[theme.breakpoints.down(1281)]: {
			fontSize: 14,
		},
		[theme.breakpoints.down("md")]: {
			lineHeight: 1.1,
		},
	},
	error: {
		color: "#EA6464",
	},
	root: {
		"&$checked": {
			color: "black",
		},
	},
	checked: {},
	asterisk: {
		color: "#EAB464",
		fontWeight: 600,
	},
	primaryButton: {
		width: '100%',
		height: '55px',
	  },
}));

const initialFormValues = {
	firstName: "",
	lastName: "",
	emailAddress: "",
	password: "",
	terms: false,
	privacy: false,
	smsMarketingOptIn: false,
};

export const RightColumn: FunctionComponent = () => {
	const classes = useStyles();
	const otherClasses = commonsStyles();

	const query = useQuery();
	const history = useHistory();
	const dispatch = useDispatch();

	const status = useSelector<RootState, SignUpStatus>(
		(state) => state.signUp.status
	);
	const loading = useSelector<RootState, boolean>((state) => state.signUp.loading);
	const data = useSelector<RootState, SignUp | undefined>(
		(state) => state.signUp.data
	);

	const [promoCode, setPromoCode] = useState("");
	const [usedEmail, setUsedEmail] = useState(false);
	const [touched, setTouched] = useState({
		firstName: false,
		lastName: false,
		emailAddress: false,
		smsMarketingOptIn: false,
		password: false,
		terms: false,
		privacy: false,
	});

	const [values, setValues] = useState(
		data
			? {
				firstName: data.firstName,
				lastName: data.lastName,
				emailAddress: data.email,
				password: data.password,
				terms: data.terms,
				privacy: data.privacy,
				smsMarketingOptIn: data.smsMarketingOptIn,
			}
			: initialFormValues
	);
	const [errors, setErrors] = useState<Array<FieldError>>([]);

	const validate = (values: any): Array<FieldError> => {
		const errors: Array<FieldError> = [];

		if (isEmpty(values.firstName) && touched.firstName) {
			errors.push({
				fieldName: "firstName",
				errorMessage: "First name is required",
			});
		}

		if (isEmpty(values.lastName) && touched.lastName) {
			errors.push({
				fieldName: "lastName",
				errorMessage: "Last name is required",
			});
		}

		if (isEmpty(values.emailAddress) && touched.emailAddress) {
			errors.push({
				fieldName: "emailAddress",
				errorMessage: "Email address is required",
			});
		}

		if (
			!isEmpty(values.emailAddress) &&
			!isEmailValid(values.emailAddress) &&
			touched.emailAddress
		) {
			errors.push({
				fieldName: "emailAddress",
				errorMessage: "Email address incorrect format",
			});
		}

		if (!values.terms && touched.terms) {
			errors.push({
				fieldName: "terms",
				errorMessage: "Accept the terms & conditions.",
			});
		}

		if (!values.privacy && touched.privacy) {
			errors.push({
				fieldName: "privacy",
				errorMessage: "Accept the privacy policy.",
			});
		}

		/*

		if (!values.smsMarketingOptIn && touched.smsMarketingOptIn) {
			errors.push({
				fieldName: "smsMarketingOptIn",
				errorMessage: "Accept the marketing opt-in.",
			});
		}
		*/

		if (touched.password) {
			errors.push(...validatePassword(values.password));
		}

		return errors;
	};

	const handleInputValue = (e: any) => {
		const { name, value } = e.target;

		setValues({ ...values, [name]: value });
		setTouched({ ...touched, [name]: true });
	};

	const clickTermsAndConditionsHandler = (
		event: ChangeEvent<{}>,
		checked: boolean
	) => {
		setTouched((prev) => ({ ...prev, terms: true }));
		setValues((prev) => ({ ...prev, terms: checked }));
	};

	const clickPrivacyPolicyHandler = (event: ChangeEvent<{}>, checked: boolean) => {
		setTouched((prev) => ({ ...prev, privacy: true }));
		setValues((prev) => ({ ...prev, privacy: checked }));
	};

	const smsMarketingOptInHandler = (event: ChangeEvent<{}>, checked: boolean) => {
		setTouched((prev) => ({ ...prev, smsMarketingOptIn: true }));
		setValues((prev) => ({ ...prev, smsMarketingOptIn: checked }));
	};

	const submit = async (event: MouseEvent) => {
		event.preventDefault();

		const errors = validate(values);
		setErrors(errors);

		if (errors.length === 0) {
			dispatch(
				setDataThunk({
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.emailAddress,
					password: values.password,
					promocode: promoCode,
					terms: values.terms,
					privacy: values.privacy,
					smsMarketingOptIn: values.smsMarketingOptIn,
					businessName: "",
					businessType: "",
					phoneNumber: "",
					country: "",
					address: "",
					city: "",
					state: "",
					zipcode: "",
					hearAboutUs: "",
					locations: undefined,
					groomers: undefined,
					software: undefined,
					services: [],
					newBusiness: false
				})
			);
			dispatch(verifyEmailThunk(values.emailAddress));
		} else {
			errors.forEach((error) => dispatch(showError(error.errorMessage)));
		}
	};

	const isInvalid = useMemo(() => {
		return Object.entries(values).some((v) => {
			const name = v[0];
			const value = v[1];

			if (name === "password" && isString(value)) {
				return validatePassword(value).length > 0;
			}

			if (name === "smsMarketingOptIn") {
				return false;
			}

			return isBoolean(value) ? value === false : isEmpty(value);
		});
	}, [values]);

	useEffect(() => {
		const promoCode = query.get("promocode");

		if (!isEmpty(promoCode)) {
			setPromoCode(promoCode!);
		}
	}, [query]);

	useEffect(() => {
		setErrors(validate(values));
	}, [values]);

	useNonInitialEffect(() => {
		if (status === SignUpStatus.EmailVerificationSuccess) {
			history.push(SignUpAlmostPath);
		} else if (status === SignUpStatus.EmailVerificationFailed) {
			setUsedEmail(true);
			setErrors((prev) => [
				...prev,
				{
					fieldName: "emailAddress",
					errorMessage: "Email is already used.",
				},
			]);
		}
	}, [status]);

	return (
		<Grid item xs={12} className={classes.column}>
			<h2 className={classes.title}>Sign up for Free Today!</h2>

			<Grid container spacing={2}>
				<Grid className={classes.formColumn} item xs={12} md={6}>
					<CustomInput
						id="signup_input_firstname"
						required
						name="firstName"
						label="First Name"
						placeholder="First Name"
						onBlur={handleInputValue}
						onChange={handleInputValue}
						defaultValue={data?.firstName}
						error={errors.some(
							(error) => error.fieldName === "firstName"
						)}
						maxLength={30}
					/>
				</Grid>
				<Grid className={classes.formColumn} item xs={12} md={6}>
					<CustomInput
						id="signup_input_lastname"
						required
						name="lastName"
						label="Last Name"
						placeholder="Last Name"
						onBlur={handleInputValue}
						onChange={handleInputValue}
						error={errors.some(
							(error) => error.fieldName === "lastName"
						)}
						defaultValue={data?.lastName}
						maxLength={30}
					/>
				</Grid>
				<Grid className={classes.formColumn} item xs={12}>
					<CustomInput
						required
						id="signup_input_email"
						name="emailAddress"
						label="Email Address"
						placeholder="Email Address"
						onBlur={handleInputValue}
						onChange={handleInputValue}
						error={errors.some(
							(error) => error.fieldName === "emailAddress"
						)}
						defaultValue={data?.email}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomPasswordInput
						id="signup_input_password"
						required
						name="password"
						label="Password"
						onBlur={handleInputValue}
						onChange={handleInputValue}
						defaultValue={data?.password}
						error={errors.some(
							(error) => error.fieldName === "password"
						)}
					/>
				</Grid>
				<Grid item xs={12} className={classes.checksColumn}>
					<Box className={classes.linkTermsAndPrivacyContainer}>
						<Checkbox
							id="signup_checkbox_conditions"
							required
							checked={values.terms}
							onChange={clickTermsAndConditionsHandler}
							classes={{
								root: classes.root,
								checked: classes.checked,
							}}
						/>
						<a
							href={"https://cuddlesapp.com/legal/terms"}
							className={clsx(
								{
									[classes.linkTermsAndPrivacy]: !errors.some(
										(error) => error.fieldName === "terms"
									),
								},
								{
									[classes.linkTermsAndPrivacyError]: errors.some(
										(error) => error.fieldName === "terms"
									),
								}
							)}
							target="_blank"
							rel="noreferrer"
						>
							Terms & Conditions
						</a>
						<span className={classes.asterisk}> *</span>
					</Box>
					<Box className={classes.linkTermsAndPrivacyContainer}>
						<Checkbox
							id="signup_checkbox_privacy"
							required
							checked={values.privacy}
							onChange={clickPrivacyPolicyHandler}
							classes={{
								root: classes.root,
								checked: classes.checked,
							}}
						/>
						<a
							href={"https://cuddlesapp.com/legal/privacy-policy"}
							target="_blank"
							rel="noreferrer"
							className={clsx(
								{
									[classes.linkTermsAndPrivacy]: !errors.some(
										(error) => error.fieldName === "privacy"
									),
								},
								{
									[classes.linkTermsAndPrivacyError]: errors.some(
										(error) => error.fieldName === "privacy"
									),
								}
							)}
						>
							Privacy Policy
						</a>
						<span className={classes.asterisk}> *</span>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					className={clsx([classes.checksColumn, classes.smsOptIn])}
				>
					<Box className={clsx([classes.linkTermsAndPrivacyContainer, classes.linkTermsAndPrivacyContainerSms])}>
						<Checkbox
							id="signup_checkbox_smsmarketing"
							checked={values.smsMarketingOptIn}
							onChange={smsMarketingOptInHandler}
							classes={{
								root: classes.root,
								checked: classes.checked,
							}}
						/>
						<span
							className={clsx(classes.linkTermsAndPrivacy, {
								[classes.error]: errors.some(
									(error) =>
										error.fieldName === "smsMarketingOptIn"
								),
							})}
						>
							I agree to receive email and SMS regarding my account
							<div className="disclaimer">Reply STOP to cancel. Msg & data rates may apply. (Optional)</div>
						</span>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Button
						id="signup_button_next"
						onClick={submit}
						loading={loading}
						label="Next Step"
						disabled={isInvalid}
						className={classes.primaryButton}
					/>
				</Grid>
				{usedEmail && (
					<Grid item xs={12}>
						<Badge>
							This email has already been registered in Cuddles. Please
							sign up with a new email or{" "}
							<Link
								to={LoginPath}
								className={otherClasses.underlinedLink}
							>
								Login here
							</Link>
						</Badge>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default RightColumn;
