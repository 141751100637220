import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Fragment, FunctionComponent, useState, useEffect } from "react";
import { reduceResolution, wbp } from "Theme";
import { useCommonStyles } from "../CommonStyles";
import { useDispatch, useSelector } from "react-redux";
import { Terminal, statusTerminal, TerminalOrder, statusOrder } from "@spike/terminal-model";
import { RootState } from "store";
import { TerminalRequest } from "./Terminal/TerminalRequest";
import { TerminalHeader } from "./Terminal/TerminalHeader";
import { TerminalsTable } from "./Terminal/TerminalsTable";
import { TerminalDetail } from "./Terminal/TerminalDetail";
import { OrderDetail } from "./Order/OrderDetail";
import { fetchTerminalsThunk, fetchTerminalsOrderThunk, saveTerminalThunk } from "@spike/terminals-action";
import { Spinner } from "components/UI";
import { TerminalsStatus, TerminalsState } from "@spike/terminals-action";
import useNonInitialEffect from "@versiondos/hooks";
import { Order } from "./Order/Order";
import { OrdersTable } from "./Order/OrdersTable";
import { useApiClientWrapper } from "hooks";
interface TerminalsProps {
  className?: string;
  //
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(63)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "63px",
      },
    },

    active: {
      color: "#222 !important",
      borderBottom: "2px solid #222",
    },

    boxSpinner: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "10%",
    },

    boxTerminals: {
      height: "100%",
    },

    boxHeaderContainer: {
      display: "flex",
      [theme.breakpoints.down(wbp)]: {
        padding: `${reduceResolution(0)}px ${reduceResolution(24)}px ${reduceResolution(0)}px ${reduceResolution(
          24
        )}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        padding: "0px 24px 0px 24px",
      },
    },

    boxPadding: {
      paddingRight: "30px",
    },

    boxItem: {
      padding: "14px 0px",
      color: "#BCB8AE",
      fontWeight: 500,
      "&:hover": {
        cursor: "pointer",
      }
    },



    boxTableContainer: {
      background: "#FAFAFA",
      paddingTop: "15px",
    },

    boxTable: {
      paddingTop: "35px",
    },

    boxSelectionContainer: {
      width: "fit-content",
      paddingLeft: "10px",
    },

    selectionBar: {
      backgroundColor: "white",
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(22)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "22px",
      },
    },
  })
);

export enum OptionStates {
  MY_TERMINALS = "MyTerminals",
  ORDERS = "Orders",
};

export const Terminals: FunctionComponent<TerminalsProps> = (props) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const terminals = useSelector<RootState, Array<Terminal>>((state) => state.terminals.terminals);

  const orders = useSelector<RootState, TerminalsState>((state) => state.terminals);
  const terminalsStatus = useSelector<RootState, TerminalsStatus>((state) => state.terminals.status);

  const [isEmptyTerminal, setIsEmptyTerminal] = useState<boolean>(true);
  const [isEmptyOrder, setIsEmptyOrder] = useState<boolean>(true);
  const [optionSelect, setOptionSelect] = useState<OptionStates>(OptionStates.MY_TERMINALS);
  const [orderSelected, setOrderSelected] = useState<TerminalOrder>();
  const [orderToComplete, setOrderToComplete] = useState<TerminalOrder>();
  const [showAddTerminal, setShowAddTerminal] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [showTerminalDetail, setShowTerminalDetail] = useState(false);
  const [terminalSelected, setTerminalSelected] = useState<Terminal>();

  const checkTerminalEmpty = (terminals: Array<Terminal>) => {
    let isEmptyTerminal = false;

    if (terminals.length === 0) isEmptyTerminal = true;

    let total = terminals.filter((terminal) =>
      terminal.status === statusTerminal.READY ||
      terminal.status === statusTerminal.IN_USE ||
      terminal.status === statusTerminal.SUPPORT)

    if (total.length === 0) isEmptyTerminal = true;

    return isEmptyTerminal;
  };

  useEffect(() => {
    dispatch(fetchTerminalsThunk(apiClientWrapper));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNonInitialEffect(() => {
    switch (terminalsStatus) {
      case TerminalsStatus.SaveTerminalSuccess:
        dispatch(fetchTerminalsThunk(apiClientWrapper));
        break;
      case TerminalsStatus.FetchTerminalsSuccess:
        dispatch(fetchTerminalsOrderThunk(apiClientWrapper));
        if (!checkTerminalEmpty(terminals)) {
          setIsEmptyTerminal(false);
        }
        else {
          setIsEmptyTerminal(true);
          setOptionSelect(OptionStates.ORDERS);
          setShowLoading(false);
        };

        break;
      case TerminalsStatus.FetchTerminalsOrdersSuccess:
        (orders.orders.length > 0) ? setIsEmptyOrder(false) : setIsEmptyOrder(true);
        setShowLoading(false);
        break;
      default:
        break;
    }
  }, [terminalsStatus]);

  const closeTerminalHandler = () => {
    setShowTerminalDetail(false);
    setShowTable(true);
  };

  const showTerminalHandler = (id: number) => {
    let terminal = terminals.find((t) => t.id === id);
    setTerminalSelected(terminal);

    setShowTable(false);
    setShowTerminalDetail(true);
  };

  const activateTerminalHandler = (terminal: Terminal, active: boolean) => {
    dispatch(saveTerminalThunk(apiClientWrapper, { ...terminal, active }));
  };

  const showOrderHandler = (order: TerminalOrder) => {
    setShowTable(false);

    if (order.status === statusOrder.NEW) {
      setShowAddTerminal(true);
      setOrderToComplete(order);
    }

    if (order.status !== statusOrder.NEW) {
      setShowOrderDetail(true);
      setOrderSelected(order);
    };
  };

  const closeOrderHandler = () => {
    setShowOrderDetail(false);
    setShowTable(true);
  };

  const closeCreateOrderHandler = () => {
    setShowAddTerminal(false);
    setShowTable(true);
  };

  const addTerminalOrderHandler = () => {
    setShowAddTerminal(true);
    setOrderToComplete(undefined);
  };
  //

  const menuView = (
    <Box className={clsx(classes.boxHeaderContainer)}>
      {
        !checkTerminalEmpty(terminals) && (
          <Box className={classes.boxPadding} onClick={() => setOptionSelect(OptionStates.MY_TERMINALS)}>
            <Box className={clsx(classes.boxItem, { [classes.active]: optionSelect === OptionStates.MY_TERMINALS })}>My Terminals</Box>
          </Box>
        )
      }

      <Box className={classes.boxPadding} onClick={() => setOptionSelect(OptionStates.ORDERS)}>
        <Box className={clsx(classes.boxItem, { [classes.active]: optionSelect === OptionStates.ORDERS })}>Orders</Box>
      </Box>
    </Box>
  );

  const terminalDetailView = (
    <TerminalDetail
      terminal={terminalSelected!}
      //
      onClose={closeTerminalHandler}
    />
  );

  const orderDetailView = (
    <OrderDetail
      order={orderSelected!}
      //
      onClose={closeOrderHandler}
    />
  );

  const loadingView = (
    <Box className={classes.boxSpinner}>
      <Spinner />
    </Box>
  );

  const tablesView = (
    <Fragment>
      <TerminalHeader
        showButton={true}
        //
        onClick={addTerminalOrderHandler}
      />

      {menuView}

      {optionSelect === OptionStates.MY_TERMINALS &&
        <TerminalsTable
          terminals={terminals}
          //
          onShowTerminal={showTerminalHandler}
          activate={activateTerminalHandler}
        />
      }

      {optionSelect === OptionStates.ORDERS &&
        <OrdersTable
          orders={orders.orders}
          //
          onShowTerminal={showTerminalHandler}
          onShowOrder={showOrderHandler}
        />
      }
    </Fragment>
  );

  const createOrderView = (
    <Order
      order={orderToComplete}
      //
      onClose={closeCreateOrderHandler} />
  );

  //

  return (
    <Fragment>
      <Box className={clsx(commonClasses.container, props.className, classes.boxTerminals)}>
        {showLoading && loadingView}

        {!showLoading
          && (isEmptyTerminal && isEmptyOrder)
          && <TerminalRequest onClick={() => setShowAddTerminal(true)} />}

        {!showLoading
          && (!isEmptyTerminal || !isEmptyOrder)
          && showTable && tablesView}

        {showOrderDetail && orderDetailView}
        {showTerminalDetail && terminalDetailView}

        {showAddTerminal && createOrderView}
      </Box>
    </Fragment>
  );
};

export default Terminals;
