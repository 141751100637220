import { Box, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Fragment, FunctionComponent } from 'react';
import { reduceResolution, wbp } from 'Theme';
import Table from '../../Table';
import { useCommonStyles as useTableStyles } from '../../Table/CommonStyles';
import { Terminal, statusTerminal } from '@spike/terminal-model';
import { useMarketplace } from 'hooks';
import { ToolbarButton, ToolbarItem } from 'components/UI';
import { faPen, faCheck, faBan } from '@fortawesome/pro-light-svg-icons';

interface TerminalsTableProps {
	terminals?: Array<Terminal>;
	className?: string;
	//
	onClose?: () => void;
	onShowTerminal: (id: number) => void;
	activate: (terminal: Terminal, activate: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			[theme.breakpoints.down(wbp)]: {
				paddingBottom: `${reduceResolution(63)}px`
			},
			[theme.breakpoints.up(wbp)]: {
				paddingBottom: '63px'
			}
		},

		active: {
			color: '#222',
			borderBottom: '2px solid #222'
		},

		boxTableContainer: {
			background: '#FAFAFA',
			paddingTop: '15px'
		},

		boxTable: {
			paddingTop: '35px'
		}
	})
);

export const TerminalsTable: FunctionComponent<TerminalsTableProps> = props => {
	const classes = useStyles();
	const tableStyles = useTableStyles();

	const marketplace = useMarketplace();
	const businessName = marketplace.basics.businessName;

	const showTerminalHandler = (id: number | undefined) => {
		if (id) props.onShowTerminal(id);
	};

	const activeHandler = (terminal: Terminal, active: boolean) => {
		props.activate(terminal, active);
	};

	const headersMyTerminal = (
		<>
			<TableCell
				className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
			>
				Name
			</TableCell>
			<TableCell
				className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
			>
				Model
			</TableCell>
			<TableCell
				className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
			>
				Serial #
			</TableCell>
			<TableCell
				className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
			>
				Business Name
			</TableCell>
			<TableCell
				className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
			>
				Status
			</TableCell>
			<TableCell
				className={clsx(tableStyles.headerCell, tableStyles.darkCell)}
			/>
		</>
	);

	const rowsTerminals = (
		<>
			{props.terminals
				?.sort((s1, s2) => {
					return s1.name.localeCompare(s2.name);
				})

				.filter(
					terminal =>
						terminal.status === statusTerminal.READY ||
						terminal.status === statusTerminal.IN_USE ||
						terminal.status === statusTerminal.SUPPORT
				)
				.map((terminal, index) => {
					return (
						<TableRow key={index}>
							<TableCell
								className={tableStyles.cell}
								onClick={() => showTerminalHandler(terminal.id)}
							>
								{terminal.name}
							</TableCell>
							<TableCell
								className={tableStyles.cell}
								onClick={() => showTerminalHandler(terminal.id)}
							>
								Cuddles {terminal.model.toUpperCase()}
							</TableCell>
							<TableCell
								className={tableStyles.cell}
								onClick={() => showTerminalHandler(terminal.id)}
							>
								{terminal.serial}
							</TableCell>
							<TableCell
								className={tableStyles.cell}
								onClick={() => showTerminalHandler(terminal.id)}
							>
								{businessName}
							</TableCell>
							<TableCell className={tableStyles.cell}>
								<ToolbarButton>
									<ToolbarItem
										text="Edit"
										icon={faPen}
										onClick={() =>
											props.onShowTerminal(terminal.id!)
										}
									/>
									{terminal.active === false && (
										<ToolbarItem
											text="Activate"
											icon={faCheck}
											onClick={() =>
												activeHandler(terminal, true)
											}
										/>
									)}
									{terminal.active === true && (
										<ToolbarItem
											text="Deactivate"
											icon={faBan}
											onClick={() =>
												activeHandler(terminal, false)
											}
										/>
									)}
								</ToolbarButton>
							</TableCell>
						</TableRow>
					);
				})}
		</>
	);

	return (
		<Fragment>
			<Box className={classes.boxTableContainer}>
				<Box className={clsx(classes.boxTable)}>
					<Table
						headers={headersMyTerminal}
						rows={rowsTerminals}
						loading={false}
					/>
				</Box>
			</Box>
		</Fragment>
	);
};

export default TerminalsTable;
