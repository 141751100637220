import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Fragment, FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import { useCommonStyles } from "../../CommonStyles";
import { useTerminalCommonStyles } from "../TerminalCommonStyles";
import { TerminalHeader } from "./TerminalHeader";
import TitlePrimary from "components/UI/Title/TitlePrimary";
import PrimaryButton from "rocket/components/Buttons/PrimaryButton";
import { TerminalIcon } from "../UI/TerminalIcon";
import NotMobileYet from "components/UI/NotMobileYet";

interface TerminalRequestProps {
  className?: string;
  //
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxContainer: {
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      height: "100%",
      [theme.breakpoints.down(wbp)]: {
      },
      [theme.breakpoints.up(wbp)]: {
      },
    },

    boxFlex: {
      display: "flex",
    },

    typoTittle: {
      fontWeight: 600,
      fontSize: "30px",
      color: "#BAA997",
      fontFamily: "YoungSerif",
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(20)}px`,
        paddingBottom: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "20px",
        paddingBottom: "20px",
      },
    },

    typoMessage: {
      color: "#000",
      [theme.breakpoints.down(wbp)]: {
        paddingBottom: `${reduceResolution(20)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingBottom: "20px",
      },
    },

    boxImage: {
      background: "#000",
      borderRadius: "50%",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down(wbp)]: {
        width: `${reduceResolution(120)}px`,
        height: `${reduceResolution(120)}px`,
        marginTop: `${reduceResolution(40)}px`,
        marginBottom: `${reduceResolution(30)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        width: "120px",
        height: "120px",
        marginTop: "40px",
        marginBottom: "30px",
      },
    },

    image: {
      width: "45% !important",
      height: "45% !important",
    },

    button: {
      width: "auto",
      fontWeight: 500,
      paddingLeft: 35,
      paddingRight: 35,
      margin: "0px auto",
      display: "inline-block",
    },
  })
);

export const TerminalRequest: FunctionComponent<TerminalRequestProps> = (props) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const terminalCommonClasses = useTerminalCommonStyles();

  return (
    <>
      <NotMobileYet />
      <Fragment>
        <Box className={clsx(commonClasses.container, props.className)}>
          <TerminalHeader
            showButton={false} />
          <Box className={clsx(commonClasses.container, props.className, classes.boxContainer)}>
            <Box className={classes.boxImage}>
              <TerminalIcon
                style={true}
                className={classes.image}
                white={true}
              />
            </Box>

            <TitlePrimary>Request a POS Terminal</TitlePrimary>
            <Typography className={clsx(classes.boxFlex, terminalCommonClasses.typo18_400, classes.typoMessage)}>Click the “Request Terminal” button to get one</Typography>

            <Box className={clsx(classes.boxFlex)}>
              <PrimaryButton
                label="Request Terminal"
                className={classes.button}
                onClick={() => props.onClick()} />
            </Box>
          </Box>
        </Box>
      </Fragment>
    </>
  );
};

export default TerminalRequest;
