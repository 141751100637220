import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    AppointmentsStatus,
    completeAppointmentThunk,
    declineAppointmentThunk
} from '@spike/bookings-action';
import { useNonInitialEffect } from '@versiondos/hooks';
import {
    getDentalReportByAppointmentIdThunk,
    getDentalReportPDFByAppointmentIdThunk,
    getGroomingReportByAppointmentIdThunk,
    getGroomingReportPDFByAppointmentIdThunk,
    resendReportThunk,
    saveDentalReportThunk,
    saveGroomingReportThunk,
    skipReportThunk
} from 'actions/reports/ReportsActions';
import { Spinner } from 'components/UI';
import { useApiClientWrapper, useHubspotTrackingCode } from 'hooks';
import DentalReport from 'model/DentalReport';
import GroomingReport from 'model/GroomerReport';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReportsStatus } from 'reducers/reports/ReportsState';
import { RootState } from 'store';
import { DentalReportComponent } from './DentalReport/DentalReportComponent';
import DentalReportSummary from './DentalReport/DentalReportSummary';
import { GroomingReportComponent } from './GroomingReport/GroomingReportComponent';
import GroomingReportSummary from './GroomingReport/GroomingReportSummary';
import SuccessDecline from './UI/SucessDecline';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';

interface Props {
    appointmentId: number;
    businessAreaId: string;
    editReport?: boolean;
    declined: boolean;
    onlyAddReport?: boolean;
    componentClient?: boolean;
    sendSms?: boolean;
    emailConfigured?: boolean;
    //
    onBack: () => void;
    onClose: () => void;
    onSave?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center'
        }
    })
);

export const CompleteReport: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const { setTrackEvent } = useHubspotTrackingCode();

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [completing, setCompleting] = useState(false);
    const [step, setStep] = useState<1 | 2>(1);
    const [successDecline, setSuccessDecline] = useState(false);

    const [reportToSent, setReportToSent] = useState(false);

    const groomingReport = useSelector<RootState, GroomingReport | undefined>(
        state => state.reports.groomingReport
    );
    const dentalReport = useSelector<RootState, DentalReport | undefined>(
        state => state.reports.dentalReport
    );
    const reportStatus = useSelector<RootState, ReportsStatus>(
        state => state.reports.status
    );
    const appointmentStatus = useSelector<RootState, AppointmentsStatus>(
        state => state.appointments.status
    );
    const loadingDownload = useSelector<RootState, boolean>(
        state => state.reports.loading
    );

    useEffect(() => {
        if (props.businessAreaId === 'dental') {
            dispatch(getDentalReportByAppointmentIdThunk(props.appointmentId));
        } else if (props.businessAreaId === 'grooming') {
            dispatch(
                getGroomingReportByAppointmentIdThunk(props.appointmentId)
            );
        }

        if (props.editReport) setStep(1);
    }, [props.appointmentId]);

    useNonInitialEffect(() => {
        if (
            [
                ReportsStatus.GetDentalByAppointmentSuccess,
                ReportsStatus.GetGroomingByAppointmentSuccess
            ].includes(reportStatus)
        ) {
            setLoading(false);
        } else if ([ReportsStatus.SaveDentalSuccess].includes(reportStatus)) {
            setSaving(false);
            completeHandler(props.appointmentId);
        } else if ([ReportsStatus.SaveGroomingSuccess].includes(reportStatus)) {
            setSaving(false);
            completeHandler(props.appointmentId);
            if (reportToSent) {
                setLoading(true);
                dispatch(resendReportThunk(props.appointmentId));
              }
        } else if ([ReportsStatus.ReportSkipped].includes(reportStatus)) {
            setSaving(false);
            completeHandler(props.appointmentId);
        } else if ([ReportsStatus.Error].includes(reportStatus)) {
            setSaving(false);
            setLoading(false);
        } else if ([ReportsStatus.ResendReportSuccess].includes(reportStatus)) {
            setSaving(false);
            setLoading(false);
        }
    }, [reportStatus]);

    useNonInitialEffect(() => {
        switch (appointmentStatus) {
            case AppointmentsStatus.CompleteSuccess:
                props.onSave ? props.onSave() : props.onBack();
                break;
            case AppointmentsStatus.Error:
                setCompleting(false);
                break;
        }
    }, [appointmentStatus]);

    useEffect(() => {
        if (appointmentStatus === AppointmentsStatus.DeclineSuccess) {
            setSuccessDecline(true);
        }
    }, [appointmentStatus]);


    const saveGroomingReportHandler = (report: GroomingReport) => {
        setSaving(true);
        dispatch(saveGroomingReportThunk(report, groomingReport?.images));
        amplitude.track(AMPLITUDE.CTA_SAVE_REPORT);
    };

    const saveDentalReportHandler = (report: DentalReport) => {
        setSaving(true);
        dispatch(saveDentalReportThunk(report));
        //amplitude.track(AMPLITUDE.CTA_SAVE_REPORT); // Causes issues with saves, please review.
    };

    const sendDentalReportHandler = (report: DentalReport) => {
        setSaving(true);
        setReportToSent(true);
        dispatch(saveDentalReportThunk(report));
    };

    const skipReportHandler = () => {
        dispatch(skipReportThunk());
        amplitude.track(AMPLITUDE.CTA_SKIP_REPORT);
    };

    const sendReportHandler = (report: GroomingReport) => {
        setSaving(true);
        setReportToSent(true);
        dispatch(saveGroomingReportThunk(report, groomingReport?.images));
    };

    const completeHandler = (appointmentId: number) => {
        setCompleting(true);
        if (props.onlyAddReport) {
            props.onClose();
        }

        if (props.declined) {
            dispatch(declineAppointmentThunk(apiClientWrapper, appointmentId));
        }
    };

    const sendAndCompleteHandler = (appointmentId: number) => {
        setCompleting(true);
        dispatch(declineAppointmentThunk(apiClientWrapper, appointmentId));
    };

    const handleDownloadDentalReport = (appointmentId: number) => {
        dispatch(getDentalReportPDFByAppointmentIdThunk(appointmentId));
    };

    const handleDownloadGroomingReport = (appointmentId: number) => {
        dispatch(getGroomingReportPDFByAppointmentIdThunk(appointmentId));
    };

    const editHandler = () => {
        setStep(1);
    };

    const closeHandler = () => {
        props.onBack();
    };

    const groomingReportComponent = (
        <GroomingReportComponent
            groomingReport={groomingReport!}
            singleImage={false}
            onSave={saveGroomingReportHandler}
            onSend={sendReportHandler}
            back={props.onBack}
            saving={saving}
            declined={props.declined}
            componentClient={props.componentClient && props.componentClient}
            emailConfigured={props.emailConfigured}
        />
    );

    const dentalReportComponent = (
        <DentalReportComponent
            dentalReport={dentalReport!}
            singleImage={true}
            onSave={saveDentalReportHandler}
            onSkip={skipReportHandler}
            onSend={sendDentalReportHandler}
            back={props.onBack}
            saving={saving}
            declined={props.declined}
            emailConfigured={props.emailConfigured}
        />
    );

    const groomingReportSummary = (
        <GroomingReportSummary
            singleImage={false}
            groomingReport={groomingReport!}
            onComplete={report => completeHandler(report.appointment.id!)}
            onCompleteReport={report =>
                sendAndCompleteHandler(report.appointment.id!)
            }
            onEditReport={() => editHandler()}
            back={() => setStep(1)}
            sending={completing}
            declined={props.declined}
            onDownloadReport={handleDownloadGroomingReport}
            onClose={closeHandler}
            loading={loadingDownload}
        />
    );

    const dentalReportSummary = (
        <DentalReportSummary
            singleImage={true}
            dentalReport={dentalReport!}
            onComplete={report => completeHandler(report.appointment.id!)}
            onEditReport={() => editHandler()}
            onSendAndComplete={report =>
                sendAndCompleteHandler(report.appointment.id!)
            }
            back={() => setStep(1)}
            onClose={closeHandler}
            sending={completing}
            declined={props.declined}
            onDownloadReport={handleDownloadDentalReport}
            loading={loadingDownload}
        />
    );

    const successDeclineComponent = (
        <SuccessDecline
            appointment={
                props.businessAreaId === 'dental'
                    ? dentalReport?.appointment
                    : groomingReport?.appointment
            }
            onGoToBookings={() => props.onBack()}
        />
    );

    return (
        <Box className={classes.container}>
            {loading && <Spinner />}
            {!loading &&
                !successDecline &&
                props.businessAreaId === 'dental' &&
                step === 1 &&
                dentalReportComponent}
            {!loading &&
                !successDecline &&
                props.businessAreaId === 'grooming' &&
                step === 1 &&
                groomingReportComponent}
            {!loading &&
                !successDecline &&
                props.businessAreaId === 'dental' &&
                step === 2 &&
                dentalReportSummary}
            {!loading &&
                !successDecline &&
                props.businessAreaId === 'grooming' &&
                step === 2 &&
                groomingReportSummary}
            {!loading && successDecline && successDeclineComponent}
        </Box>
    );
};

export default CompleteReport;
