import { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import ToolbarButton from "components/UI/Toolbar/ToolbarButton";
import ToolbarItem from "components/UI/Toolbar/ToolbarItem";
import { faPencil, faTrashAlt, faBan, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import ProductCategory from "model/ProductCategory";
import ProductsTable from "../Category/ProductsTable";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ProductsState } from "@spike/products-action";

interface Props {
  category: ProductCategory;
  onAssign: (category: ProductCategory) => void;
  onRename: (category: ProductCategory) => void;
  onDelete: (category: ProductCategory) => void;
  onActivate: (category: ProductCategory) => void;
  onDeactivate: (category: ProductCategory) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      "&:hover": {
        backgroundColor: "#FAFAFA !important",
        cursor: "pointer"
      }
    },
    rowSelected: {
      backgroundColor: "#FAFAFA !important",
    },
    cell: {
      paddingLeft: "0px",
      verticalAlign: "top !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        paddingTop: "21px",
        paddingBottom: "21px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        paddingTop: "26px",
        paddingBottom: "26px",
      },
    },
    options: {
      position: "absolute",
    },
    firstCell: {
      [theme.breakpoints.down("md")]: {
        paddingLeft: "84px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "105px",
      },
    },
    lastCell: {
      float: "right",
      [theme.breakpoints.down("md")]: {
        paddingRight: "86px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: "110px",
      },
    },
    text: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "22px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "27px"
      },
    },
    textDialog: {
      textAlign: "center",
      [theme.breakpoints.down("lg")]: {
        fontSize: "15px",
      },
    },
    delete: {
      color: "#EF4F57"
    },
    categoriesCells: {
      width: "25%"
    },
    categoriesSmallCells: {
      width: "15%"
    },
    categoriesActionsCells: {
      width: "10%"
    },
    assignProducts: {
      color: "#92B4A7",
      cursor: "pointer",
      textDecoration: "underline"
    },
    products: {
      [theme.breakpoints.down("md")]: {
        marginLeft: "7%",
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: "8%",
      },
    },
    deactivated: {
      color: "#00000080 !important"
    },
    rowContainer: {
      display: "contents",
      flexDirection: "column",
      width: "100%"
    },
    productsContainer: {
      width: "100%",
      
    },
    firstProductCell: {
      [theme.breakpoints.down("md")]: {
        paddingLeft: "130px",
        paddingBottom: "40px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "130px",
        paddingBottom: "40px",
      },
      padding: 0
    },
    bestseller: {
      marginLeft: "8%",
    },
  })
);

export const CategoryRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [showProducts, setShowProducts] = useState(false);
  const [numberProducts, setNumberProducts] = useState(0);
  const products = useSelector<RootState, ProductsState>((state) => state.products);

  useEffect(() => {
    if (products && products.list.length > 0) {
      let productsAux = products.list.filter((p) => p.category?.id === props.category.id);
      setNumberProducts(productsAux.length);
    }
  }, [products])

  return (
    <Box className={classes.rowContainer}>
      <TableRow className={clsx(classes.row, {[classes.rowSelected]: showProducts})}>
        <TableCell className={clsx(classes.cell, classes.firstCell, classes.categoriesCells)} onClick={() => setShowProducts(numberProducts > 0 ? !showProducts : false)}>
          <Typography className={clsx(classes.text, {[classes.deactivated]: !props.category.active})}>{props.category.name}</Typography>
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.categoriesCells)} onClick={() => setShowProducts(numberProducts > 0 ? !showProducts : false)}>
          <Typography className={clsx(classes.text, classes.products, {[classes.deactivated]: !props.category.active})}>{numberProducts}</Typography>
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.categoriesSmallCells)} onClick={() => setShowProducts(numberProducts > 0 ? !showProducts : false)}>
          <Typography className={clsx(classes.text, classes.bestseller, {[classes.deactivated]: !props.category.active})}>0</Typography>
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.categoriesSmallCells)}>
          <Typography className={clsx(classes.text, classes.assignProducts)} onClick={() => props.onAssign(props.category)}>Assign Products</Typography>
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.categoriesSmallCells)} onClick={() => setShowProducts(numberProducts > 0 ? !showProducts : false)}>
          <Typography className={clsx(classes.text, {[classes.deactivated]: !props.category.active})}>{props.category.active ? "Active" : "Inactive"}</Typography>
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.categoriesActionsCells, classes.lastCell)}>
          {props.category.custom && <Box className={classes.options}>
            <ToolbarButton>
              <ToolbarItem icon={faPencil} onClick={() => props.onRename(props.category)} text={"Rename"} />
              <ToolbarItem icon={faTrashAlt} onClick={() => props.onDelete(props.category)} text={"Delete"} />
              {props.category.active && <ToolbarItem icon={faBan} onClick={() => props.onDeactivate(props.category)} text={"Deactivate"} />}
              {!props.category.active && <ToolbarItem icon={faCheckCircle} onClick={() => props.onActivate(props.category)} text={"Activate"} />}
            </ToolbarButton>
          </Box>}
        </TableCell>
      </TableRow>
      {showProducts && <TableRow className={classes.productsContainer}>
          <TableCell colSpan={3} className={classes.firstProductCell}>
            <ProductsTable categoryId={props.category.id!} />
          </TableCell>
      </TableRow>}
    </Box>
  );
};

export default CategoryRow;