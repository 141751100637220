import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Option } from "@spike/model";
import useNonInitialEffect from "@versiondos/hooks";
import { createDepositInvoiceThunk } from "@spike/invoices-action";
import { confirmDepositThunk, getEnrollmentStatusThunk } from "@spike/payments-action";
import { cancelThunk as cancelPosThunk } from "@spike/pos-action";
import PaymentMethods from "components/Checkout/PaymentMethods";
import Invoice from "@spike/invoice-model";
import { EnrollmentStatus, PaymentMethodIds } from "@spike/payments-model";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InvoicesStatus } from "@spike/invoices-action";
import { PaymentsStatus } from "@spike/payments-action";
import { RootState } from "store";
import { useApiClientWrapper } from "hooks";
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from "constants/index";
import FooterComponent from "../FooterComponent";
import PosPayment from "./PosPayment";
import clsx from "clsx";
import CreditCardPayment from "./CreditCardPayment";

interface CollectDepositProps {
  bookingId: number;
  onStartBooking?: () => void;
  onCollected?: () => void;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentBackground: {
      position: "absolute",
      bottom: 0,
      left: 0,
      zIndex: 10,
      display: "flex",
      width: "100%",
      minHeight: "100%",
    },
    paymentContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    paymentContent: {
      padding: "0px 29px 32px",
      [theme.breakpoints.up('md')]: {
        padding: "0px 32px 35px"
      },
    },
    expanded: {
      height: "100%"
    },
    sectionMargin: {
      height: "85%",
      padding: `0px 30px 33px`,
      [theme.breakpoints.up('md')]: {
        padding: "0px 32px 35px"
      },
    },
    sectionMarginPos: {
      height: "85%",
    },
    title: {
      fontWeight: 600,
      fontSize: 24
    },
    totalContainer: {
      height: "15%",
      borderTop: "1px solid #D4D4D4",
      marginTop: 10,
      marginBottom: 12,
      [theme.breakpoints.up('md')]: {
        marginTop: 11,
        marginBottom: 14
      }
    },
    paymentMethods: {
      "& .MuiTypography-body1": {
        fontSize: 17
      },
      "& .MuiBox-root": {
        height: 93,
        width: "100%"
      }
    },
    pos: {
      height: "100%",
      "& .MuiTypography-body1": {
        fontSize: 17
      }
    }
  })
);

interface CheckoutData {
  paymentMethodId: string | null;
  posTerminalId: number | null;
  posServiceId: string | null;
}

export const CollectDeposit: FunctionComponent<CollectDepositProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();

  const depositInvoice = useSelector<RootState, Invoice | undefined>((state) => state.invoices.depositInvoice);
  const invoicesStatus = useSelector<RootState, InvoicesStatus>((state) => state.invoices.status);

  const enrollmentStatus = useSelector<RootState, EnrollmentStatus | undefined>(
    (state) => state.payments.enrollmentStatus
  );
  const paymentsStatus = useSelector<RootState, PaymentsStatus>((state) => state.payments.status);

  const [checkoutData, setCheckoutData] = useState<CheckoutData>({
    paymentMethodId: null,
    posTerminalId: null,
    posServiceId: null,
  });

  const [loading, setLoading] = useState({invoice: true, paymentStatus: true, confirmDeposit: false});
  
  useEffect(() => {
    dispatch(getEnrollmentStatusThunk(apiClientWrapper));
    dispatch(createDepositInvoiceThunk(apiClientWrapper, props.bookingId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNonInitialEffect(() => {
    switch (paymentsStatus) {
      case PaymentsStatus.GetEnrollmentStatusSuccess:
        setLoading(prev => ({...prev, paymentStatus: false}));
        break;
      case PaymentsStatus.ConfirmingDeposit: 
        props.onStartBooking && props.onStartBooking();
        break;
      case PaymentsStatus.ConfirmDepositSuccess:
        setLoading(prev => ({...prev, confirmDeposit: false}));
        props.onCollected && props.onCollected();
        break;
      case PaymentsStatus.Error:
        setLoading(prev => ({...prev, paymentStatus: false, confirmDeposit: false}));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsStatus]);

  useNonInitialEffect(() => {
    switch (invoicesStatus) {
      case InvoicesStatus.CreateDepositInvoiceSuccess:
      case InvoicesStatus.Error:
        setLoading(prev => ({...prev, invoice: false}));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesStatus]);

  const confirmDeposit = (invoiceId: number, paymentMethodId: string, sessionId?: string) => {
    setLoading(prev => ({...prev, confirmDeposit: true}));
    dispatch(confirmDepositThunk(apiClientWrapper, invoiceId, paymentMethodId, sessionId));  
    amplitude.track(AMPLITUDE.PAYMENT_CONFIRMATION_DEPOSIT);
  }

  const collectCreditCardHandler = (sessionId: string) => {
    confirmDeposit(depositInvoice!.id!, checkoutData.paymentMethodId!, sessionId);
  };
  
  const collectPOSHandler = () => {
    props.onCollected && props.onCollected();
  };

  const collectHandler = () => {
    confirmDeposit(depositInvoice!.id!, checkoutData.paymentMethodId!);
  }

  const changePaymentMethod = (option: Option<string>) => {
    switch (option.id) {
      case PaymentMethodIds.Cash:
        amplitude.track(AMPLITUDE.CTA_CASH);
        break;
      case PaymentMethodIds.POS:
        amplitude.track(AMPLITUDE.CTA_POS);
        break;
      case PaymentMethodIds.CreditCard:
        amplitude.track(AMPLITUDE.CTA_CREDIT_CARD);
        break;
      case PaymentMethodIds.Other:
        amplitude.track(AMPLITUDE.CTA_OTHER);
        break;
      default:
        break;
    }
    cancelPos();
    setCheckoutData((prev) => ({ ...prev, paymentMethodId: option.id, posTerminalId: null, posServiceId: null }));
  };

  const cancelPos = () => {
      checkoutData.posServiceId &&
      checkoutData.posTerminalId &&
      dispatch(cancelPosThunk(apiClientWrapper, depositInvoice!.id!, checkoutData.posTerminalId, checkoutData.posServiceId));
  };

  const getTruncatedUnpaidTotal = (total: string) => {
    const totalInteger = total.split(".");
    return totalInteger[totalInteger.length - 1] === "0" || totalInteger[totalInteger.length - 1] === "00" ? totalInteger[0] : total;
  };

  return (
    <Box className={classes.paymentBackground}>
      <Box className={classes.paymentContainer}>
        <Box className={clsx(classes.paymentContent, {
          [classes.expanded]: checkoutData.paymentMethodId === PaymentMethodIds.Other || checkoutData.paymentMethodId === PaymentMethodIds.Cash
        })}>
          {!loading.invoice && !loading.paymentStatus && (
            <PaymentMethods
              paymentsEnabled={enrollmentStatus?.enabled || false}
              selectedId={checkoutData.paymentMethodId}
              className={classes.paymentMethods}
              onSelect={changePaymentMethod}
            />
          )}
        </Box>

        {checkoutData.paymentMethodId === PaymentMethodIds.CreditCard && (
          <Box className={classes.sectionMargin}>
            <CreditCardPayment
              depositInvoiceId={depositInvoice!.id!}
              onSuccess={collectCreditCardHandler}
            />
          </Box>
        )}

        {checkoutData.paymentMethodId === PaymentMethodIds.POS && (
          <Box className={classes.sectionMarginPos}>
            <PosPayment
              depositInvoiceId={depositInvoice!.id!}
              onSuccess={collectPOSHandler}
              onGetPosServiceId={(posTerminalId, posServiceId) =>
                setCheckoutData((prev) => ({ ...prev, posTerminalId, posServiceId }))
              }
              className={classes.pos}
            />
          </Box>
        )}

        {[PaymentMethodIds.Cash.toString(), PaymentMethodIds.Other.toString()].includes(
          checkoutData.paymentMethodId || ""
        ) && 
        <Box className={classes.totalContainer}>
          <FooterComponent 
            title="Deposit"
            total={depositInvoice?.deposits.unpaidTotal ? getTruncatedUnpaidTotal(depositInvoice?.deposits.unpaidTotal) : "0"}
            loading={loading.confirmDeposit}
            buttonText="Pay"
            onBook={collectHandler}
          />
        </Box>}
      </Box>
    </Box>
  );
};

export default CollectDeposit;
