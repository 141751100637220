import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import { showErrorThunk } from '@spike/notifications-action';
import { useNonInitialEffect } from '@versiondos/hooks';
import {
	calculateInvoiceThunk,
	prepareEmptyInvoiceThunk,
	reset as resetInvoice,
	saveInvoiceThunk
} from '@spike/invoices-action';
import Payments from 'components/Checkout/Payments';
import { validate as validateInvoice } from '@spike/invoice-model';
import { OverFullWindow } from 'components/UI';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import Invoice from '@spike/invoice-model';
import Client from 'pages/Client';
import {
	FunctionComponent,
	RefObject,
	useEffect,
	useRef,
	useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InvoicesStatus } from '@spike/invoices-action';
import { RootState } from 'store';
import SingleSaleHeader from './SingleSaleHeader';
import SingleSaleItems from './SingleSaleItems';
import { SaleSuccess } from './UI/SaleSuccess';
import { useApiClientWrapper } from 'hooks';
import { Product } from '@spike/product-model';
import { fetchProductsThunk } from '@spike/products-action';
import AddOnService from '@spike/addon-service-model';
import { fetchThunk as fetchAddOnServicesThunk } from '@spike/addon-services-action';
import { ClientsStatus, getClientThunk } from '@spike/clients-action';
import ClientModel from '@spike/client-model';
import { ClientData } from 'model/SingleSale';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		boxContainer: {
			width: '100%',
			height: '100%',
			background: '#ffffff',
			padding: '24px 16px',

			[theme.breakpoints.down('sm')]: {
				overflowY: 'scroll'
			},
			[theme.breakpoints.up('md')]: {
				padding: '30px 25px'
			}
		},
		boxHeader: {
			[theme.breakpoints.up('md')]: {
				borderBottom: '1px solid #d4d4d4'
			}
		},

		paymentBackground: {
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: 10,
			display: 'flex',
			minHeight: '100%',
			width: '100%',
			backgroundColor: 'rgba(200,200,200,0.8)'
		},

		paymentRightContainer: {
			top: 0,
			right: 0,
			bottom: 0,
			zIndex: 10,
			width: '100%',
			display: 'flex',
			overflowY: 'auto',
			position: 'absolute',
			backgroundColor: '#ffffff',

			[theme.breakpoints.up('md')]: {
				width: '600px',
				borderLeft: '2px solid #F1F1F1'
			}
		}
	})
);

const EmptyDescription = '-empty-description-';

const needToBeCalculated = (
	updatedInvoice: Invoice,
	invoice?: Invoice
): boolean => {
	if (updatedInvoice.lines.length < (invoice?.lines.length || 0)) {
		return true;
	}

	return updatedInvoice.lines.some(updatedLine => {
		const line = invoice?.lines.find(
			line => line.uuid === updatedLine.uuid
		);

		if (line) {
			return (
				updatedLine.productId !== line.productId ||
				updatedLine.addOnServiceId !== line.addOnServiceId ||
				updatedLine.quantity !== line.quantity ||
				updatedLine.discount.amount !== line.discount.amount ||
				updatedLine.discount.percentage !== line.discount.percentage ||
				updatedLine.subtotal !== line.subtotal ||
				!isEqual(
					updatedLine.taxes.map(tax => tax.id),
					line.taxes.map(tax => tax.id)
				)
			);
		} else {
			return (
				updatedLine.productId ||
				updatedLine.addOnServiceId ||
				Number(updatedLine.subtotal) !== 0
			);
		}
	});
};

const merge = (
	invoice: Invoice | undefined,
	calculatedInvoice: Invoice
): Invoice => {
	const mergedInvoice = clone(calculatedInvoice);

	if (invoice) {
		mergedInvoice.lines = invoice.lines.map(
			line =>
				mergedInvoice.lines.find(
					calculatedLine => calculatedLine.uuid === line.uuid
				) || line
		);
	}

	mergedInvoice.lines = mergedInvoice.lines.map(line =>
		line.description === EmptyDescription
			? { ...line, description: '' }
			: { ...line }
	);

	return mergedInvoice;
};

export const SingleSale: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const apiClientWrapper = useApiClientWrapper();

	const invoicesStatus = useSelector<RootState, InvoicesStatus>(
		state => state.invoices.status
	);
	const preInvoice = useSelector<RootState, Invoice | undefined>(
		state => state.invoices.invoice
	);
	const savedInvoice = useSelector<RootState, Invoice | undefined>(
		state => state.invoices.savedInvoice
	);
	const clientStatus = useSelector<RootState, ClientsStatus>(
		state => state.clients.status
	);
	const clientInfo = useSelector<RootState, ClientModel | undefined>(
		state => state.clients.client
	);

	const [invoice, setInvoice] = useState<Invoice | undefined>();

	const [showPayments, setShowPayments] = useState(true);
	const [showSale, setShowSale] = useState(true);
	const [showNewClient, setShowNewClient] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [calculating, setCalculating] = useState(false);
	const [saving, setSaving] = useState(false);
	const [errors, setErrors] = useState<Array<FieldError>>([]);
	const [clientData, setClientData] = useState<ClientData | null>();

	const paymentsContainerRef = useRef<HTMLDivElement>(null);

	const addOnServices = useSelector<RootState, Array<AddOnService>>(
		state => state.addOnServices.services
	);

	const products = useSelector<RootState, Array<Product>>(
		state => state.products.list
	)
		.filter(product => product)
		.filter(product => product.businessProduct?.availableForCheckout);

	useEffect(() => {
		dispatch(resetInvoice());
		if (addOnServices.length === 0) {
			dispatch(fetchAddOnServicesThunk(apiClientWrapper));
		}
		if (products.length === 0) {
			dispatch(fetchProductsThunk(apiClientWrapper));
		}
	}, []);

	useNonInitialEffect(() => {
		if (clientStatus === ClientsStatus.GetSuccess) {
			console.log('pre', clientInfo, clientStatus);
			const { firstName, lastName, phone, email, tags } = clientInfo!;
			setClientData({
				name: `${firstName} ${lastName}`,
				phone: phone || '',
				email: email || '',
				tags: {
					positive: tags.positive,
					negative: tags.negative
				}
			});
		}
	}, [clientStatus]);

	useNonInitialEffect(() => {
		switch (invoicesStatus) {
			case InvoicesStatus.PrepareInvoiceSuccess:
				setInvoice(prev => merge(prev, clone(preInvoice!)));
				setCalculating(false);
				break;
			case InvoicesStatus.CalculateInvoiceSuccess:
				setInvoice(prev => merge(prev, clone(preInvoice!)));
				setCalculating(false);
				break;
			case InvoicesStatus.SaveInvoiceSuccess:
			case InvoicesStatus.SaveTipSuccess:
				setInvoice(prev => merge(prev, clone(savedInvoice!)));
				saving && setShowPayments(true);
				setSaving(false);
				setCalculating(false);
				break;
			case InvoicesStatus.Error:
				setSaving(false);
				setCalculating(false);
				break;
		}
	}, [invoicesStatus]);

	const scrollToTopHandler = (ref: RefObject<HTMLDivElement>) => {
		ref.current!.scrollTop = 0;
	};

	const payHandler = () => {
		const errors = validateInvoice(invoice!);
		setErrors(errors);

		uniq(errors.map(error => error.errorMessage)).forEach(error =>
			dispatch(showErrorThunk(error))
		);

		if (errors.length === 0) {
			setSaving(true);
			dispatch(saveInvoiceThunk(apiClientWrapper, preInvoice!));
		}
	};

	const changeClientHandler = (clientId: number | undefined) => {
		clientId
			? dispatch(getClientThunk(apiClientWrapper, clientId))
			: setClientData(null);
		if (!clientId) {
			setInvoice(undefined);
		} else if (preInvoice) {
			dispatch(
				calculateInvoiceThunk(apiClientWrapper, {
					...preInvoice,
					customer: {
						id: clientId,
						name: '',
						phone: '',
						email: ''
					}
				})
			);
		} else {
			dispatch(prepareEmptyInvoiceThunk(apiClientWrapper, clientId));
		}
	};

	const onCompleteHandler = () => {
		setShowSale(false);
		setShowPayments(false);
		setShowSuccess(true);
	};

	const onClosePaymentHandler = () => {
		setShowPayments(false);
	};

	const addNewClientHandler = () => {
		setShowSale(false);
		setShowNewClient(true);
	};

	const calculate = (invoice: Invoice) => {
		setCalculating(true);
		const invoiceToCalculate = clone(invoice);
		invoiceToCalculate.lines = invoiceToCalculate.lines
			.filter(
				line =>
					(line.isProduct && line.productId) ||
					(line.isAddOnService && line.addOnServiceId) ||
					(!line.isProduct && !line.isAddOnService)
			)
			.map(line =>
				isEmpty(line.description)
					? { ...line, description: EmptyDescription }
					: { ...line }
			);

		if (invoiceToCalculate.id) {
			dispatch(saveInvoiceThunk(apiClientWrapper, invoiceToCalculate));
		} else {
			dispatch(
				calculateInvoiceThunk(apiClientWrapper, invoiceToCalculate)
			);
		}
	};

	const changeInvoiceHandler = (updatedInvoice: Invoice) => {
		const oldInvoice = invoice === undefined ? undefined : { ...invoice };

		setInvoice(updatedInvoice);

		if (needToBeCalculated(updatedInvoice, oldInvoice)) {
			calculate(updatedInvoice);
		}
	};

	const backClientHandler = () => {
		setShowNewClient(false);
		setShowSale(true);
	};

	const handlerCreateClient = (client: ClientModel) => {
		setShowNewClient(false);
		setShowSale(true);

		changeClientHandler(client.id);
	};

	const handlerCreatePet = () => {
		setShowNewClient(false);
		setShowSale(true);
	};

	const onSuccessClickHandler = () => {
		dispatch(resetInvoice());
		setShowSuccess(false);
		setShowSale(true);
		setInvoice(undefined);
		setClientData(null);
	};

	const successView = (
		<>
			{savedInvoice && (
				<SaleSuccess
					receivedDate={savedInvoice.invoiceDate}
					total={savedInvoice.grandTotal}
					onClick={onSuccessClickHandler}
				/>
			)}
		</>
	);

	const saleView = (
		<>
			<Box className={classes.boxHeader}>
				<SingleSaleHeader
					onAddNewClient={addNewClientHandler}
					onClientSelected={changeClientHandler}
					data={clientData!}
					errors={errors}
				/>
			</Box>
			{invoice && (
				<SingleSaleItems
					invoice={invoice}
					products={products}
					addOnServices={addOnServices}
					onChange={changeInvoiceHandler}
					errors={errors}
					calculating={calculating}
					saving={saving}
					onPay={payHandler}
				/>
			)}

			{showPayments && savedInvoice && savedInvoice.id && (
				<Box className={classes.paymentBackground}>
					<div
						className={classes.paymentRightContainer}
						ref={paymentsContainerRef}
					>
						<Payments
							mobileTitle="New Sale"
							invoiceId={savedInvoice.id}
							tip={savedInvoice.tip}
							totalBalance={savedInvoice.grandTotal}
							subtotalWithoutTipTaxes={savedInvoice.subtotal}
							customerEmail={savedInvoice.customer?.email || ''}
							hiddenReport={true}
							onComplete={onCompleteHandler}
							onClose={onClosePaymentHandler}
							onScrollToTop={() =>
								scrollToTopHandler(paymentsContainerRef)
							}
						/>
					</div>
				</Box>
			)}
		</>
	);

	const newClientView = (
		<OverFullWindow onClose={backClientHandler}>
			<Client
				hideHeader={true}
				onClose={backClientHandler}
				onClientSaved={handlerCreateClient}
				onPetSaved={handlerCreatePet}
			/>
		</OverFullWindow>
	);

	return (
		<Box className={classes.boxContainer}>
			{showSale && saleView}
			{showNewClient && newClientView}
			{showSuccess && successView}
		</Box>
	);
};

export default SingleSale;
