import { FunctionComponent } from 'react';
import { VariablePriceVariables } from 'model/Service';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SimpleHeaderTitle from './SimpleHeaderTitle';
import Box from '@material-ui/core/Box';

interface Props {
	variables: VariablePriceVariables;
	priceTypeSelected: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			display: 'grid',
			padding: '0px 16px 16px',
			gridTemplateColumns: '1fr 1fr',
			borderBottom: '1px solid #222222',

			[theme.breakpoints.up('md')]: {
				padding: '0px 5% 20px',
				gridTemplateColumns: '1fr 1fr 1fr'
			}
		}
	})
);

export const NewGridHeader: FunctionComponent<Props> = () => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<SimpleHeaderTitle title="Dog Size" />

			<SimpleHeaderTitle title="Price" />
		</Box>
	);
};

export default NewGridHeader;
