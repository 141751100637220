import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useExportCSVStyle = makeStyles((theme: Theme) =>
    createStyles({
        csvLink: { textDecoration: 'none' },
        csv: {
            'textTransform': 'none',
            'height': '47px ',
            'display': 'flex',
            'flexDirection': 'row',
            'alignItems': 'center',
            'textDecoration': 'none',
            'color': '#000',
            'gap': 6,
            'borderRadius': 30,
            'border': '1px solid rgba(0, 0, 0, 0.23)',
            'paddingLeft': '24px',
            'paddingRight': '24px',
            'paddingTop': '9px',
            'paddingBottom': '9px',
            'fontFamily': 'Poppins',
            'fontWeight': 600,
            'fontSize': 18,
            '& a': {
                textDecoration: 'none'
            },
            '& svg': {
                width: 'auto',
                height: '18px'
            },
            [theme.breakpoints.down('lg')]: {
                'fontSize': 14,
                'height': '39px',
                'gap': 8,
                '& svg': {
                    width: 'auto',
                    height: '13px'
                }
            },
            [theme.breakpoints.down('md')]: {
                borderWidth: 1,
                height: '29px'
            },
            [theme.breakpoints.down('sm')]: {
                'height': '47px',
                'width': '47px',
                'border': '2px solid #000',
                'padding': 0,
                'justifyContent': 'center',
                'background': '#fff',
                '&:hover': {
                    background: 'transparent'
                },
                '& svg': {
                    width: 'auto',
                    height: '17px'
                }
            }
        },
        disabled: {
            opacity: 0.5,
            cursor: 'wait'
        }
    })
);
