import React, { FunctionComponent, useEffect } from 'react';
import { OptionsCache } from 'model/Service';
import { Option } from '@spike/model';
import { Radio } from 'components/UI';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export interface Props {
	id?: string;
	options: Array<Option<string>>;
	selected?: Option<string>;
	onChange: (value: Option<string>) => void;
	required?: boolean;
	error?: boolean;
	errorMessage?: string;
	className?: string;
	subPetSelected?: string;
	lastOptionsCache?: OptionsCache;
	changeLastOptionCache?: (value: OptionsCache) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			[theme.breakpoints.down('sm')]: {
				gap: 24,
				display: 'flex',
				flexDirection: 'column'
			}
		},
		radio: {
			'& .MuiRadio-root': {
				[theme.breakpoints.down('sm')]: {
					paddingTop: 0,
					paddingBottom: 0
				}
			}
		}
	})
);

export const PriceTypeSelector: FunctionComponent<Props> = props => {
	const classes = useStyles();

	const changeHandler = (value: string | number) => {
		props.onChange(props.options.find(a => a.id === value)!);
	};

	useEffect(() => {
		if (props.options.length === 1) {
			props.onChange(props.options[0]);
		}
	}, [props.options]);

	const options = props.options.map((option, index) => (
		<Radio
			id={props.id ? props.id + '_' + option.id + '_' + index : props.id}
			key={option.id}
			label={option.name}
			className={classes.radio}
			checked={
				props.options.length === 1
					? true
					: props.selected?.id === option.id
					? true
					: false
			}
			onChange={() => {
				if (props.subPetSelected && props.subPetSelected === 'dogs') {
					changeHandler(option.id);
					props.changeLastOptionCache!({
						...props.lastOptionsCache!,
						dogs: option,
						cats: option
					});
				}
				if (props.subPetSelected && props.subPetSelected === 'cats') {
					changeHandler(option.id);
					props.changeLastOptionCache!({
						...props.lastOptionsCache!,
						dogs: option,
						cats: option
					});
				} else {
					changeHandler(option.id);
				}
			}}
		/>
	));

	return <Box className={clsx(classes.root, props.className)}>{options}</Box>;
};

export default PriceTypeSelector;
