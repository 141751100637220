import { FunctionComponent, useState } from 'react';
import {
    VariablePriceOption,
    UnifiedPriceObject,
    VariablePriceConstants
} from 'model/Service';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { reduceResolution, wbp } from 'Theme';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMarketplace } from 'hooks';
import { faInfoCircle, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { PriceField } from 'components/UI';
import clsx from 'clsx';
import { isEmpty, toNumber } from 'lodash';
import { FieldErrorWithKey } from 'components/Service/model';

interface Props {
    id?: string;
    variablePriceOption: VariablePriceOption;
    unifiedPriceOption?: UnifiedPriceObject;
    vPConst: VariablePriceConstants;
    priceTypeSelected: string;
    petType: string;
    priceIndex: number;
    errors: Array<FieldErrorWithKey>;
    onSaveRow: (variablePriceOption: VariablePriceOption) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        /** Global */
        container: {
            display: 'flex',
            width: '100%',
            alignItems: 'center'
        },
        variablesContainer: {
            width: '100%'
        },
        boxContainer: {
            width: '100%'
        },
        price: {
            '& .MuiOutlinedInput-root': {
                height: 38,
                background: 'white',

                [theme.breakpoints.up('md')]: {
                    height: 42
                }
            },
            '& input': {
                fontSize: 16,
                padding: '0px 16px',

                [theme.breakpoints.down('sm')]: {
                    textAlign: 'right'
                },

                [theme.breakpoints.up('md')]: {
                    fontSize: 18,
                    padding: '0px 24px'
                }
            }
        },

        //** Type Tables Unified */
        unifiedWrapper: {
            width: '100%',
            display: 'flex'
        },
        pricesWrapper: {
            'gap': 17,
            'width': '100%',
            'display': 'flex',
            'padding': '21px 16px',

            [theme.breakpoints.up('md')]: {
                padding: '21px 5%'
            },

            '& hr': {
                display: 'none'
            },

            '&.cats': {
                'rowGap': 15,
                'columnGap': 17,
                'display': 'grid',
                'gridTemplateColumns': '1fr 1fr',
                'borderTop': 'solid 1px #000',

                '& hr': {
                    border: 0,
                    width: '100%',
                    display: 'block',
                    gridColumn: 'span 2/span 2',
                    borderTop: 'solid 1px #D4D4D4',

                    [theme.breakpoints.up('md')]: {
                        display: 'none'
                    }
                }
            }
        },
        columnWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        rowColumn: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        columnTitle: {
            fontSize: 14,
            lineHeight: 1,
            marginBottom: 8,

            [theme.breakpoints.up('md')]: {
                fontSize: 15
            }
        },
        columnPrice: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        /** Price Selected: by_pet_size (For Cats) */
        columnSizeWrapper: {},
        subtitleContainer: {
            width: '100%',
            display: 'flex',
            marginBottom: 24,
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                margin: '0px -16px 24px',
                padding: '20px 16px 0px',
                borderTop: 'solid 1px #000'
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: 32
            }
        },
        catSubtitle: {
            fontSize: 14,
            fontWeight: 400,
            color: '#7A7A7A',

            [theme.breakpoints.up('md')]: {
                fontSize: 15
            }
        },
        catTitleContainer: {
            display: 'flex',
            width: '100%',
            alignItems: 'center'
        },
        catTitle: {
            fontSize: 16,
            fontWeight: 600,
            color: '#000'
        },
        priceContainerCats: {
            'marginTop': 16,

            [theme.breakpoints.up('md')]: {
                width: 180,
                marginBottom: 24
            },

            '& .MuiOutlinedInput-root': {
                height: 54
            },
            '& input': {
                textAlign: 'left'
            }
        },
        catPetSizeVariableWrapper: {
            width: '100%',
            padding: '0px 16px',

            [theme.breakpoints.down('sm')]: {
                marginTop: 8
            },

            [theme.breakpoints.up('md')]: {
                padding: '0px 5%'
            }
        },

        /** Price Selected: by_pet_size (For Dogs) */
        petSizeVariableWrapper: {
            display: 'grid',
            padding: '13px 16px',
            alignItems: 'center',
            gridTemplateColumns: '1fr 1fr',

            [theme.breakpoints.down('sm')]: {
                borderBottom: '1px solid #D4D4D4'
            },
            [theme.breakpoints.up('md')]: {
                gap: 20,
                padding: '21px 5%',
                borderTop: '1px solid #D4D4D4',
                gridTemplateColumns: '1fr 1fr 1fr'
            }
        },
        whiteSpace: {
            display: 'flex',
            width: '5%'
        },
        newPetSizeVariable: {},
        priceContainer: {
            display: 'flex',
            flexDirection: 'column'
        },

        /** Price Selected: by_pet_size_and_hair_length */
        subtitle: {
            width: '100%',
            display: 'flex',
            backgroundColor: '#F4F3F0',
            borderTop: '1px solid #D4D4D4',
            borderBottom: '1px solid #D4D4D4',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        subtitleSize: {
            gap: 10,
            display: 'flex',
            alignItems: 'center',
            padding: '10px 16px 9px',

            [theme.breakpoints.down('sm')]: {
                width: '100%',
                justifyContent: 'space-between'
            },
            [theme.breakpoints.up('md')]: {
                width: '20%',
                marginLeft: '5%',
                padding: '7px 0px'
            }
        },
        title: {
            fontSize: 15,
            lineHeight: 1,
            fontWeight: 600,

            [theme.breakpoints.up('md')]: {
                fontSize: 16
            }
        },
        description: {
            fontSize: 12,
            fontWeight: 300,
            color: '#7A7A7A'
        },

        /** Price Selected: by_hair_lenght */
        catsByHairLenght: {
            borderTop: '1px solid black'
        },

        /** Deposit Alerts */
        depositAlert: {
            'display': 'none',
            'backgroundColor': '#F8F5F1',
            'border': '2px solid #BCB8AE',
            'borderRadius': '10px',
            'padding': '14px 16px 16px',

            '& b': {
                fontWeight: 600
            },

            [theme.breakpoints.down('sm')]: {
                marginTop: 16,
                gridColumn: 'span 2/span 2'
            }
        },
        alertForByPetSize: {},
        alertForByPetSizeAndHairLength: {
            margin: '0px 16px 21px',

            [theme.breakpoints.up('md')]: {
                maxWidth: '60%',
                margin: '0px 5% 21px'
            }
        },
        alertForByHairLength: {},
        alertForCatsByPetSize: {
            marginTop: 24,
            marginBottom: 24,
            alignItems: 'center'
        },
        showAlert: {
            display: 'inline-flex'
        },

        /** Span Messages */
        iconInfo: {},
        sectionIconInfo: {
            'color': '#BAA997',
            '&:hover': {
                cursor: 'pointer'
            },
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(24)}px`,
                paddingRight: `${reduceResolution(10)}px`,
                marginTop: `${reduceResolution(2)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '24px',
                paddingRight: '10px',
                marginTop: '2px'
            }
        },
        boxIcon: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(38)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '38px'
            }
        },
        spanMessage: {
            fontSize: 14,
            lineHeight: 1.3,
            fontWeight: 400
        },
        errorMessage: {
            color: 'red',
            marginTop: 10,
            [theme.breakpoints.down('sm')]: {
                fontSize: 12,
                lineHeight: 1.1
            }
        }
    })
);

export const NewGridRow: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const marketplace = useMarketplace();
    const depositAmount = toNumber(marketplace.deposit.value);
    const depositType = marketplace.deposit.type;

    const [showAlert, setShowAlert] = useState(() => {
        if (depositType !== 'percentage') {
            if (
                props.variablePriceOption.price &&
                props.unifiedPriceOption === undefined
            ) {
                if (props.variablePriceOption.price < depositAmount)
                    return true;
            }
            if (props.unifiedPriceOption) {
                if (
                    props.unifiedPriceOption.prices.some(
                        optionEelement =>
                            optionEelement.price !== undefined &&
                            optionEelement.price < depositAmount
                    )
                )
                    return true;
            }
        } else {
            return false;
        }
    });

    const changePriceHandler = (
        price?: number,
        variablePriceOption?: VariablePriceOption
    ) => {
        if (price !== undefined && variablePriceOption) {
            variablePriceOption.price = price;
            props.onSaveRow(variablePriceOption);
        } else if (price === undefined && variablePriceOption) {
            delete variablePriceOption.price;
            props.onSaveRow(variablePriceOption);
        }
    };

    const onBlurChangePriceHandler = () => {
        if (depositType !== 'percentage') {
            switch (props.priceTypeSelected) {
                case props.vPConst.BY_PET_SIZE:
                    if (
                        props.variablePriceOption.price !== undefined &&
                        props.variablePriceOption.price < depositAmount
                    ) {
                        setShowAlert(true);
                    } else {
                        setShowAlert(false);
                    }
                    break;
                case props.vPConst.BY_PET_SIZE_AND_HAIR_LENGTH:
                    if (
                        props.unifiedPriceOption?.prices.some(
                            optionEelement =>
                                optionEelement.price !== undefined &&
                                optionEelement.price < depositAmount
                        )
                    ) {
                        setShowAlert(true);
                    } else {
                        setShowAlert(false);
                    }
                    break;
                case props.vPConst.BY_HAIR_LENGTH:
                    if (
                        props.unifiedPriceOption?.prices.some(
                            optionEelement =>
                                optionEelement.price !== undefined &&
                                optionEelement.price < depositAmount
                        )
                    ) {
                        setShowAlert(true);
                    } else {
                        setShowAlert(false);
                    }
                    break;
            }
        }
    };

    const populateVariables = (unifiedPriceOption: UnifiedPriceObject) => {
        return (
            <Box
                className={clsx(classes.pricesWrapper, {
                    cats: props.petType === props.vPConst.CATS
                })}
            >
                {unifiedPriceOption!.prices.map(
                    (variablePriceOption, index) => (
                        <Box key={index} className={classes.columnWrapper}>
                            <Box className={classes.rowColumn}>
                                <Box className={classes.columnTitle}>
                                    {variablePriceOption.hairLength?.name}
                                </Box>
                                <Box className={classes.columnPrice}>
                                    <PriceField
                                        error={
                                            !isEmpty(props.errors) &&
                                            (variablePriceOption.price === 0 ||
                                                variablePriceOption.price ===
                                                    undefined)
                                        }
                                        id={
                                            props.id
                                                ? props.id + '_input_price'
                                                : props.id
                                        }
                                        defaultValue={variablePriceOption.price}
                                        onChange={changePriceHandler}
                                        onBlur={onBlurChangePriceHandler}
                                        className={classes.price}
                                        placeholder="$0.00"
                                        variablePriceOption={
                                            variablePriceOption
                                        }
                                    />
                                    {!isEmpty(props.errors) &&
                                        (variablePriceOption.price === 0 ||
                                            variablePriceOption.price ===
                                                undefined) && (
                                            <Typography
                                                className={classes.errorMessage}
                                            >
                                                Price is required
                                            </Typography>
                                        )}
                                </Box>
                            </Box>
                        </Box>
                    )
                )}

                {props.petType === props.vPConst.CATS && <hr />}
            </Box>
        );
    };

    const iconSVG = (icon: IconDefinition, styles: string) => {
        return <FontAwesomeIcon icon={icon} className={styles} />;
    };

    const depositAlert = (message: string) => (
        <Box
            className={clsx(
                classes.depositAlert,
                {
                    [classes.alertForByPetSize]:
                        props.petType === props.vPConst.DOGS &&
                        props.priceTypeSelected === props.vPConst.BY_PET_SIZE
                },
                {
                    [classes.alertForCatsByPetSize]:
                        props.petType === props.vPConst.CATS &&
                        props.priceTypeSelected === props.vPConst.BY_PET_SIZE
                },
                {
                    [classes.alertForByPetSizeAndHairLength]:
                        props.petType === props.vPConst.DOGS &&
                        props.priceTypeSelected ===
                            props.vPConst.BY_PET_SIZE_AND_HAIR_LENGTH
                },
                {
                    [classes.alertForByPetSizeAndHairLength]:
                        props.petType === props.vPConst.CATS &&
                        props.priceTypeSelected ===
                            props.vPConst.BY_PET_SIZE_AND_HAIR_LENGTH
                },
                {
                    [classes.alertForByPetSizeAndHairLength]:
                        props.petType === props.vPConst.CATS &&
                        props.priceTypeSelected === props.vPConst.BY_HAIR_LENGTH
                },
                { [classes.showAlert]: showAlert === true }
            )}
        >
            <Box className={classes.iconInfo}>
                <Box className={classes.boxIcon}>
                    {iconSVG(faInfoCircle, `${classes.sectionIconInfo}`)}
                </Box>
            </Box>
            <Box
                className={classes.spanMessage}
                dangerouslySetInnerHTML={{ __html: message }}
            />
        </Box>
    );

    return (
        <Box className={classes.container}>
            <Box className={clsx(classes.variablesContainer)}>
                {props.petType === props.vPConst.DOGS &&
                    props.priceTypeSelected === props.vPConst.BY_PET_SIZE && (
                        <Box className={classes.petSizeVariableWrapper}>
                            <Box className={classes.newPetSizeVariable}>
                                <Typography>
                                    {props.variablePriceOption.petSize?.name}
                                </Typography>
                            </Box>
                            <Box className={classes.priceContainer}>
                                <PriceField
                                    error={
                                        !isEmpty(props.errors) &&
                                        (props.variablePriceOption.price ===
                                            0 ||
                                            props.variablePriceOption.price ===
                                                undefined)
                                    }
                                    id={
                                        props.id
                                            ? props.id +
                                              '_' +
                                              props.variablePriceOption.petSize?.name.toLowerCase() +
                                              '_input_price'
                                            : props.id
                                    }
                                    variablePriceOption={
                                        props.variablePriceOption
                                    }
                                    defaultValue={
                                        props.variablePriceOption.price
                                    }
                                    onChange={changePriceHandler}
                                    onBlur={onBlurChangePriceHandler}
                                    className={classes.price}
                                    placeholder="$0.00"
                                />
                                {!isEmpty(props.errors) &&
                                    (props.variablePriceOption.price === 0 ||
                                        props.variablePriceOption.price ===
                                            undefined) && (
                                        <Typography
                                            className={classes.errorMessage}
                                        >
                                            Price is required
                                        </Typography>
                                    )}
                            </Box>
                            {depositAlert(
                                'The price entered is lower than the price you have set for your deposits.'
                            )}
                        </Box>
                    )}
                {props.petType === props.vPConst.CATS &&
                    props.priceTypeSelected === props.vPConst.BY_PET_SIZE && (
                        <Box className={classes.catPetSizeVariableWrapper}>
                            <Box className={classes.columnSizeWrapper}>
                                <Box className={classes.subtitleContainer}>
                                    <Typography className={classes.catSubtitle}>
                                        Cat prices are based on a single size.
                                    </Typography>
                                </Box>
                                <Box className={classes.catTitleContainer}>
                                    <Typography className={classes.catTitle}>
                                        {'Price for Cats'}
                                    </Typography>
                                </Box>
                                <Box className={classes.priceContainerCats}>
                                    <PriceField
                                        error={
                                            !isEmpty(props.errors) &&
                                            (props.variablePriceOption.price ===
                                                undefined ||
                                                props.variablePriceOption
                                                    .price === 0)
                                        }
                                        id={
                                            props.id
                                                ? props.id +
                                                  '_' +
                                                  props.variablePriceOption.petSize?.name.toLowerCase() +
                                                  '_input_price'
                                                : props.id
                                        }
                                        variablePriceOption={
                                            props.variablePriceOption
                                        }
                                        defaultValue={
                                            props.variablePriceOption.price
                                        }
                                        onChange={changePriceHandler}
                                        onBlur={onBlurChangePriceHandler}
                                        className={classes.price}
                                        placeholder="$0.00"
                                    />
                                    {!isEmpty(props.errors) &&
                                        (props.variablePriceOption.price ===
                                            0 ||
                                            props.variablePriceOption.price ===
                                                undefined) && (
                                            <Typography
                                                className={classes.errorMessage}
                                            >
                                                Price is required
                                            </Typography>
                                        )}
                                </Box>
                                {depositAlert(
                                    'The price entered is lower than the price you have set for your deposits.'
                                )}
                            </Box>
                        </Box>
                    )}
                {props.petType === props.vPConst.DOGS &&
                    props.priceTypeSelected ===
                        props.vPConst.BY_PET_SIZE_AND_HAIR_LENGTH && (
                        <Box className={classes.boxContainer}>
                            <Box className={classes.unifiedWrapper}>
                                <Box className={classes.subtitle}>
                                    <Box className={classes.subtitleSize}>
                                        <Typography className={classes.title}>
                                            {props.unifiedPriceOption!.petSize!.name.toUpperCase()}
                                        </Typography>
                                        <Typography
                                            className={classes.description}
                                        >
                                            {
                                                props.unifiedPriceOption!
                                                    .petSize!.description
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                {props.unifiedPriceOption &&
                                    populateVariables(props.unifiedPriceOption)}
                            </Box>
                            {depositAlert(
                                'The price entered for <b>Short Hair, Medium Hair & Long Hair</b> is lower than the price you have set for your deposits.'
                            )}
                        </Box>
                    )}
                {props.petType === props.vPConst.CATS &&
                    props.priceTypeSelected ===
                        props.vPConst.BY_PET_SIZE_AND_HAIR_LENGTH && (
                        <Box
                            className={clsx(classes.boxContainer, {
                                [classes.catsByHairLenght]:
                                    props.petType === props.vPConst.CATS &&
                                    props.priceTypeSelected ===
                                        props.vPConst.BY_HAIR_LENGTH
                            })}
                        >
                            <Box>
                                {props.unifiedPriceOption &&
                                    populateVariables(props.unifiedPriceOption)}
                            </Box>
                            {depositAlert(
                                'The price entered is lower than the price you have set for your deposits.'
                            )}
                        </Box>
                    )}
                {props.petType === props.vPConst.CATS &&
                    props.priceTypeSelected ===
                        props.vPConst.BY_HAIR_LENGTH && (
                        <Box
                            className={clsx(classes.boxContainer, {
                                [classes.catsByHairLenght]:
                                    props.petType === props.vPConst.CATS &&
                                    props.priceTypeSelected ===
                                        props.vPConst.BY_HAIR_LENGTH
                            })}
                        >
                            <Box>
                                {props.unifiedPriceOption &&
                                    populateVariables(props.unifiedPriceOption)}
                            </Box>
                            {depositAlert(
                                'The price entered is lower than the price you have set for your deposits.'
                            )}
                        </Box>
                    )}
            </Box>
        </Box>
    );
};

export default NewGridRow;
