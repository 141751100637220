import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { PetService } from "@spike/new-booking-model";
import { Moment } from "moment-timezone";
import ServicesCard from "./ServiceCard";

interface AppointmentDataVaraible {
    service: PetServiceWrapper;
    staffId: number;
    date: Moment;
}

interface PetServiceWrapper extends PetService {
    duration?: Duration;
}

interface AppointmentMultiple {
    clientId: number;
    petId: number;
    createdByStaffId: number;
    notes?: string;
    variableData: Array<AppointmentDataVaraible>;
}

interface Props {
    multipleAppointment: AppointmentMultiple;
    onEdit: (data: AppointmentDataVaraible) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginBottom: 15
        },
    })
);

export const ServicesList: FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    return (
        <>
            {props.multipleAppointment.variableData.map((data: AppointmentDataVaraible) => (
                <Box className={classes.container} key={data.service.service.id}>
                    <ServicesCard 
                        data={data}
                        onEdit={props.onEdit}
                    />
                </Box>
            ))}
        </>
    )
};

export default ServicesList