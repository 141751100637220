// @ts-nocheck 
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, MenuItem } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import { showError } from '@spike/notifications-action';
import { cleanPhoneNumberFormat } from '@spike/phone-utils';
import { isPhoneNumberValid, validateZipcode } from '@spike/validations';
import { setDataThunk} from 'actions/signUp/SignUpActions';
import { Button } from 'components/UI';
import { useMasterData } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import SignUp from 'model/SignUp';
import { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SignUpPath, SignUpBusinessPath } from 'routes/Paths';
import { RootState } from 'store';
import CustomInput from '../Forms/CustomInput';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: 45,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 40,
    },
  },
  column: {
    display: 'flex',
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 60,
    marginLeft: 'auto',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#F8F5F1',
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '55%',
      flexBasis: '55%',
      paddingTop: 0,
      paddingLeft: '7%',
      paddingRight: '9%',
      paddingBottom: 80,
      backgroundColor: '#ffffff',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '7%',
      paddingRight: '13%',
    },
  },
  formColumn: {
    marginBottom: 16,
  },
  checksColumn: {
    display: 'flex',
    marginLeft: '5%',
  },
  primaryButton: {
    width: '100%',
    height: '55px',
  },
  menuItem: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '18px',
    },
  },
  phoneField: {
    '& fieldset': {
      borderWidth: '1px !important',
    },
    '& .MuiInputBase-root': {
      height: 54,
      borderRadius: 33,
    },
    '& .MuiInputBase-input::placeholder': {
      opacity: 1,
      color: '#222 !important',
    },
    '&.error': {
      '& fieldset': {
        borderColor: '#C14A4A !important',
      },
    },
  },
  alert: {
    padding: 20,
    width: '100%',
    color: '#222',
    marginTop: 20,
    display: 'flex',
    borderRadius: 13,
    backgroundColor: '#F8F5F1',
    border: 'solid 2px #BCB8AE',

    '& svg': {
      fontSize: 22,
      marginRight: 6,
      color: '#BAA997',
    },
  },
}));

const initialFormValues = {
  businessName: '',
  businessType: '',
  phoneNumber: '',
  country: '',
  address: '',
  suite: '',
  city: '',
  state: '',
  zipcode: '',
  aboutUs: '',
  promocode: '',
};

const isCompleted = (value: string) => !isEmpty(trim(value));

export const RightColumn: FunctionComponent = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector<RootState, boolean>((state) => state.signUp.loading);
  const data = useSelector<RootState, SignUp | undefined>((state) => state.signUp.data);

  const masterData = useMasterData();

  const countryList = masterData.countries;

  const selectOptions = [...masterData.hearAboutUsOptions];

  const businessTypes = [
    {
      label: 'Brick & Mortar',
      value: 'b&m'
    },
    {
      label: 'At-home',
      value: 'athome'
    },
    {
      label: 'Mobile',
      value: 'mobile'
    },
    {
      label: 'In-home',
      value: 'inhome'
    }
  ];

  useEffect(() => {
    if (!data) {
      history.push(SignUpPath);
    }
  }, [data, history]);

  const [values, setValues] = useState(
    data
      ? {
        businessName: data.businessName ? data.businessName : '',
        businessType: data.businessType ? data.businessType : '',
        phoneNumber: data.phoneNumber ? data.phoneNumber : '',
        promocode: data.promocode,
        address: data.address ? data.address : '',
        country: data.country ? data.country : 'US',
        suite: data.suite ? data.suite : '',
        city: data.city ? data.city : '',
        state: data.state ? data.state : '',
        zipcode: data.zipcode ? data.zipcode : '',
        aboutUs: data.hearAboutUs ? data.hearAboutUs : '',
      }
      : initialFormValues
  );
  const [errors, setErrors] = useState<Array<FieldError>>([]);

  const isSingUpEnabled =
    isCompleted(values.businessName) &&
    isCompleted(values.businessType) &&
    isCompleted(values.phoneNumber) &&
    isCompleted(values.address) &&
    isCompleted(values.country) &&
    isCompleted(values.city) &&
    isCompleted(values.state) &&
    isCompleted(values.zipcode) &&
    isCompleted(values.aboutUs);

  const validate = (values: any): Array<FieldError> => {
    const errors: Array<FieldError> = [];

    if (isEmpty(values.country)) {
      errors.push({
        fieldName: 'country',
        errorMessage: 'Country is required',
      });
    }

    if (isEmpty(values.businessName)) {
      errors.push({
        fieldName: 'businessName',
        errorMessage: 'Business name is required',
      });
    }

    if (isEmpty(values.businessType)) {
      errors.push({
        fieldName: 'businessType',
        errorMessage: 'Business type is required',
      });
    }

    if (isEmpty(values.phoneNumber)) {
      errors.push({
        fieldName: 'phoneNumber',
        errorMessage: 'Phone number is required',
      });
    }

    if (!isEmpty(values.phoneNumber) && !isPhoneNumberValid(values.phoneNumber, values.country ?? '')) {
      errors.push({
        fieldName: 'phoneNumber',
        errorMessage:
          values.country?.toUpperCase() === 'US'
            ? 'Phone number format is (NNN) NNN-NNNN'
            : 'Phone number maximum length is 12 digits',
      });
    }

    if (isEmpty(values.address)) {
      errors.push({
        fieldName: 'address',
        errorMessage: 'Address is required',
      });
    }

    if (isEmpty(values.city)) {
      errors.push({ fieldName: 'city', errorMessage: 'City is required' });
    }

    if (isEmpty(values.state)) {
      errors.push({ fieldName: 'state', errorMessage: 'State is required' });
    }

    if (isEmpty(values.zipcode)) {
      errors.push({
        fieldName: 'zipcode',
        errorMessage: 'Zipcode is required',
      });
    }

    errors.push(...validateZipcode(values.zipcode, values.country));

    if (isEmpty(values.aboutUs)) {
      errors.push({
        fieldName: 'aboutUs',
        errorMessage: 'About us is required',
      });
    }

    return [...errors];
  };

  const handleInputValue = (e: any) => {
    const { name, value } = e.target;
    let newValue = value;

    switch (name) {
      case 'zipcode':
        if (['US', 'CA'].includes(values.country?.toUpperCase() ?? '')) {
          newValue = value?.toUpperCase();
        } else {
          newValue = /^([0-9]|[a-z]|[A-Z]){0,10}$/.test(value) ? value?.toUpperCase() : values.zipcode;
        }
        break;
      case 'phoneNumber':
        if (['US', 'CA'].includes(values.country?.toUpperCase() ?? '')) {
          newValue = cleanPhoneNumberFormat(value);
        } else {
          newValue = /^\+{0,1}[0-9]{0,12}$/.test(value) ? cleanPhoneNumberFormat(value) : values.phoneNumber;
        }
        break;
      case 'city':
        newValue = /^([a-z]|[A-Z]|\s)*$/.test(value) ? value : values.city;
        break;
      case 'state':
        newValue = /^([a-z]|[A-Z]|\s)*$/.test(value) ? value : values.state;
        break;
      case 'country':
        newValue = value;
        setValues(prev => ({ ...prev, address: '', suite: '', city: '', state: '', zipcode: '', phoneNumber: '' }));
        break;
      default:
        newValue = value;
    }

    setValues(prev => ({ ...prev, [name]: newValue }));
  };

  const handleFormSubmit = async (event: MouseEvent) => {
    event.preventDefault();

    const errors = validate(values);
    setErrors(errors);

    if (errors.length === 0) {
      const signUp: SignUp = {
        ...data!,
        businessName: values.businessName,
        businessType: values.businessType,
        phoneNumber: values.phoneNumber,
        address: values.address,
        country: values.country,
        city: values.city,
        state: values.state,
        suite: values.suite,
        zipcode: values.zipcode,
        hearAboutUs: values.aboutUs,
        promocode: values.promocode,
      };
      dispatch(
				setDataThunk(signUp)
			);
      history.push(SignUpBusinessPath);
    } else {
      errors.forEach((error) => dispatch(showError(error.errorMessage)));
    }
  };

  return (
    <Grid item xs={12} className={classes.column}>
      <h2 className={classes.title}>Let's get started</h2>

      <Grid container spacing={2}>
        <Grid className={classes.formColumn} item xs={12}>
          <CustomInput
            id="signup_input_businessname"
            label="Business Name"
            placeholder="Business Name"
            required
            name="businessName"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            error={errors.some((error) => error.fieldName === 'businessName')}
            defaultValue={data?.businessName}
          />
        </Grid>

        <Grid className={classes.formColumn} item xs={12}>
          <CustomInput
            id="signup_input_business_type"
            select
            required
            name="businessType"
            label="Business type"
            placeholder="Choose One"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            error={errors.some((error) => error.fieldName === 'businessType')}
            defaultValue={data?.businessType}
          >
            {businessTypes.map((businessType) => (
              <MenuItem id={"signup_list_item_business_type_" + businessType.value} value={businessType.value} key={businessType.value === '' ? '0' : businessType.value} className={classes.menuItem}>
                {businessType.label}
              </MenuItem>
            ))}
          </CustomInput>
        </Grid>

        <Grid className={classes.formColumn} item xs={12}>
          <CustomInput
            id="signup_input_country"
            select
            required
            name="country"
            label="Country"
            placeholder="Choose One"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            error={errors.some((error) => error.fieldName === 'country')}
            defaultValue={"US"}
          >
            {countryList.map((country) => (
              <MenuItem id={"signup_list_item_country_" + country.id} value={country.id} key={country.id === '' ? '0' : country.id} className={classes.menuItem}>
                {country.name}
              </MenuItem>
            ))}
          </CustomInput>

          {values.country && !['US', 'CA'].includes(values.country.toUpperCase()) && (
            <Box className={classes.alert}>
              <FontAwesomeIcon icon={faInfoCircle} />
              Thank you for choosing Cuddles! While the software is available for use anywhere, please note that we are
              not able to assist with business requirements in countries outside of the U.S. at this time.
            </Box>
          )}
        </Grid>
        <Grid className={classes.formColumn} item xs={12}>
          {['US', 'CA'].includes(values.country?.toUpperCase() ?? '') ? (
            <Box>
              <InputMask
                mask="(999) 999-9999"
                value={values.phoneNumber}
                onChange={handleInputValue}
                onBlur={handleInputValue}
              >
                {(inputProps: any) => (
                  <CustomInput
                    id="signup_input_phonenumber"
                    label="Phone Number"
                    placeholder="Phone Number"
                    required
                    name="phoneNumber"
                    error={errors.some((error) => error.fieldName === 'phoneNumber')}
                  />
                )}
              </InputMask>
            </Box>
          ) : (
            <Box>
              <CustomInput
                id="signup_input_phonenumber"
                label="Phone Number"
                placeholder="Phone Number"
                required
                name="phoneNumber"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                error={errors.some((error) => error.fieldName === 'phoneNumber')}
                defaultValue={data?.phoneNumber}
                value={values.phoneNumber}
                inputProps={{ maxLength: 13 }}
              />
            </Box>
          )}
        </Grid>
        <Grid className={classes.formColumn} item xs={12} md={8}>
          <CustomInput
            id="signup_input_address"
            label="Address"
            placeholder="Address"
            required
            name="address"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            error={errors.some((error) => error.fieldName === 'address')}
            defaultValue={data?.address}
            value={values.address}
          />
        </Grid>
        <Grid className={classes.formColumn} item xs={12} md={4}>
          <CustomInput
            id="signup_input_suite"
            label="Suite"
            placeholder="Suite"
            name="suite"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            defaultValue={data?.suite}
            value={values.suite}
          />
        </Grid>
        <Grid className={classes.formColumn} item xs={12} md={5}>
          <CustomInput
            id="signup_input_city"
            label="City"
            placeholder="City"
            required
            name="city"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            error={errors.some((error) => error.fieldName === 'city')}
            defaultValue={data?.city}
            value={values.city}
          />
        </Grid>
        <Grid className={classes.formColumn} item xs={12} md={3}>
          <CustomInput
            id="signup_input_state"
            label="State"
            placeholder="State"
            required
            name="state"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            error={errors.some((error) => error.fieldName === 'state')}
            defaultValue={data?.state}
            value={values.state}
          />
        </Grid>
        <Grid className={classes.formColumn} item xs={12} md={4}>
          {values.country?.toUpperCase() === 'CA' && (
            <Box>
              <InputMask mask="a9a9a9" value={values.zipcode} onChange={handleInputValue} onBlur={handleInputValue}>
                {(inputProps: any) => (
                  <CustomInput
                    id="signup_input_zipcode"
                    label="Zipcode"
                    placeholder="Zipcode"
                    required
                    name="zipcode"
                    error={errors.some((error) => error.fieldName === 'zipcode')}
                  />
                )}
              </InputMask>
            </Box>
          )}
          {values.country?.toUpperCase() === 'US' && (
            <Box>
              <InputMask mask="99999" value={values.zipcode} onChange={handleInputValue} onBlur={handleInputValue}>
                {(inputProps: any) => (
                  <CustomInput
                    id="signup_input_zipcode"
                    label="Zipcode"
                    placeholder="Zipcode"
                    required
                    name="zipcode"
                    error={errors.some((error) => error.fieldName === 'zipcode')}
                  />
                )}
              </InputMask>
            </Box>
          )}
          {!['US', 'CA'].includes(values.country?.toUpperCase()) && (
            <CustomInput
              id="signup_input_zipcode"
              label="Zipcode"
              placeholder="Zipcode"
              required
              name="zipcode"
              onBlur={handleInputValue}
              onChange={handleInputValue}
              error={errors.some((error) => error.fieldName === 'zipcode')}
              defaultValue={data?.zipcode}
              value={values.zipcode}
              inputProps={{ maxLength: 10 }}
            />
          )}
        </Grid>
        <Grid id="signup_select_aboutus_container" className={classes.formColumn} item xs={12}>
          <CustomInput
            id="signup_select_aboutus"
            label="How’d you hear about us?"
            placeholder="Choose One"
            select
            required
            name="aboutUs"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            error={errors.some((error) => error.fieldName === 'aboutUs')}
            defaultValue={data?.hearAboutUs}
          >
            {selectOptions.map((option) => (
              <MenuItem id={"signup_option_aboutus_" + option.name + "_" + option.id} value={option.id} key={option.id === '' ? '0' : option.id} className={classes.menuItem}>
                {option.name}
              </MenuItem>
            ))}
          </CustomInput>
        </Grid>
        <Grid className={classes.formColumn} item xs={12}>
          <CustomInput
            id="signup_input_promocode"
            name="promocode"
            label="Invite Code or Referring Business Name"
            placeholder="Invite Code or Referring Business Name"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            error={errors.some((error) => error.fieldName === 'promocode')}
            defaultValue={data?.promocode}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            id="signup_button_signup"
            onClick={handleFormSubmit}
            loading={loading}
            label="Almost There"
            size="large"
            disabled={!isSingUpEnabled}
            className={classes.primaryButton}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RightColumn;
