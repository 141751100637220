import React, { FunctionComponent, useState, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import ConfirmDialog from "components/UI/ConfirmDialog";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faPowerOff,
  faCopy,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { SelectableOption, Status } from "../../../model";
import { DuplicateDialog } from "./DuplicateDialog";
import { Typography } from "@material-ui/core";

interface Props {
  className?: string;
  statusId: number;
  name: string;
  duplicateOptions?: Array<SelectableOption<string>>;
  onEdit?: () => void;
  onActivate?: (active: boolean) => void;
  onDuplicate?: (name: string, selectedOptionsIds: Array<string>) => void;
  onDelete?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      background: "#E9F0ED",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-around",
      height: "100%",
      [theme.breakpoints.down("lg")]: {
        width: "38px",
        minHeight: "112px",
        borderRadius: "19px",
        fontSize: "12px",
        paddingLeft: "13px",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "51px",
        minHeight: "150px",
        borderRadius: "26px",
        fontSize: "16px",
        paddingLeft: "18px",
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      overflowX: "visible",
      position: "relative",
      [theme.breakpoints.down("lg")]: {
        height: "26px",
        fontSize: "11px",
      },
      [theme.breakpoints.up("xl")]: {
        height: "30px",
        fontSize: "16px",
      },
    },
    labelContainer: {
      cursor: "pointer",
      position: "absolute",
      [theme.breakpoints.down("lg")]: {
        right: "20px",
      },
      [theme.breakpoints.up("xl")]: {
        right: "24px",
      },
    },
    label: {
      cursor: "pointer",
      backgroundColor: "#5E8677",
      color: "#ffffff",
      [theme.breakpoints.down("lg")]: {
        height: "26px",
      },
      [theme.breakpoints.up("xl")]: {
        height: "30px",
      },
    },
    dialog: {
      "& .MuiDialog-paper": {
        border: "1px solid #222222",
        boxShadow: "0px 10px 40px #00000029",
        [theme.breakpoints.down("lg")]: {
          width: "361px",
          minHeight: "424px",
          borderRadius: "15px",
          paddingBottom: "36px",
        },
        [theme.breakpoints.up("xl")]: {
          width: "482px",
          minHeight: "566px",
          borderRadius: "20px",
          paddingBottom: "48px",
        },
      },
      "& .MuiDialogContent-root": {
        paddingTop: "0px",
        [theme.breakpoints.down("lg")]: {
          paddingBottom: "18px",
          paddingLeft: "30px",
          paddingRight: "30px",
        },
        [theme.breakpoints.up("xl")]: {
          paddingBottom: "24px",
          paddingLeft: "41px",
          paddingRight: "41px",
        },
      },
      "& .MuiDialogActions-root": {
        paddingTop: "0px",
        paddingBottom: "0px",
        justifyContent: "center",
      },
    },
    button: {
      border: 0,
      background: "#5E8677",
      color: "#FFFFFF",
      [theme.breakpoints.down("lg")]: {
        width: "114px",
        minWidth: "114px",
        height: "42px",
        fontSize: "13pt",
        fontWeight: 600,
      },
      [theme.breakpoints.up("xl")]: {
        width: "152px",
        minWidth: "152px",
        height: "57px",
        fontSize: "18pt",
        fontWeight: 600,
      },
      "&:hover": {
        background: "#5E8677",
      },
    },
    duplicateDialogTitle: {
      display: "flex",
      justifyContent: "flex-end",
      padding: 0,
      [theme.breakpoints.down("lg")]: {
        paddingRight: "30px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingRight: "41px",
      },
    },
    duplicateDialogOption: {
      [theme.breakpoints.down("lg")]: {
        marginTop: "17px",
        marginLeft: "24px",
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: "23px",
        marginLeft: "32px",
      },
    },
    disabledButton: {
      cursor: "default",
      color: "#797979",
    }
  })
);

export const RowToolbar: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const containerStyle = clsx(classes.container, props.className);

  const [showEditLabel, setShowEditLabel] = useState(false);
  const [showActiveLabel, setShowActiveLabel] = useState(false);
  const [showDuplicateLabel, setShowDuplicateLabel] = useState(false);
  const [showDeleteLabel, setShowDeleteLabel] = useState(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [confirmStatusDialog, setConfirmStatusDialog] = useState(false);

  const editHoverHandler = (hover: boolean): void => {
    setShowEditLabel(hover);
  };

  const activeHoverHandler = (hover: boolean): void => {
    setShowActiveLabel(hover);
  };

  const duplicateHoverHandler = (hover: boolean): void => {
    setShowDuplicateLabel(hover);
  };

  const deleteHoverHandler = (hover: boolean): void => {
    setShowDeleteLabel(hover);
  };

  const onEditClickHandler = (): void => {
    props.onEdit && props.onEdit();
  };

  const onActiveClickHandler = (): void => {
    props.onActivate && props.onActivate(props.statusId === Status.INACTIVE);
  };

  const onDuplicateClickHandler = (): void => {
    setShowDuplicateDialog(true);
    //props.onDuplicate && props.onDuplicate();
  };

  const onDuplicateHandler = (name: string, selectedOptionIds: Array<string>): void => {
    setShowDuplicateDialog(false);
    props.onDuplicate && props.onDuplicate(name, selectedOptionIds);
  };

  const onDeleteClickHandler = (): void => {
    props.onDelete && props.onDelete();
  };

  const closeDuplicateDialogHandler = (): void => {
    setShowDuplicateDialog(false);
  };

  const inactiveLabel = props.statusId === Status.ACTIVE ? "Deactivate" : "Active";
  const disabledActiveButton = props.statusId === Status.DRAFT || !props.onActivate;
  const disabledDuplicateButton = props.statusId === Status.DRAFT;

  return (
    <Fragment>
      <Box className={containerStyle}>
        <Box
          className={classes.buttonContainer}
          onMouseEnter={() => {
            editHoverHandler(true);
          }}
          onMouseLeave={() => {
            editHoverHandler(false);
          }}
          onClick={onEditClickHandler}
          id="services_toolbar_button_edit"
        >
          <FontAwesomeIcon icon={faPencil} />
          {showEditLabel ? (
            <Box className={classes.labelContainer}>
              <Chip className={classes.label} label="Edit" />
            </Box>
          ) : null}
        </Box>
        <ConfirmDialog
          open={confirmStatusDialog}
          question={<Box>
            {props.statusId === Status.INACTIVE ?
            <Typography>Are you sure you want to activate this service?</Typography> :
            <Typography>Are you sure you want to disable this service?</Typography>}
          </Box>}
          confirmButtonLabel="Confirm"
          cancelButtonLabel="Cancel"
          onConfirm={() => {!disabledActiveButton && onActiveClickHandler();}}
          onCancel={() => {
            setConfirmStatusDialog(false);
          }}
        />
        <Box
          className={clsx(classes.buttonContainer, {[classes.disabledButton]:disabledActiveButton})}
          onMouseEnter={() => {
            !disabledActiveButton && activeHoverHandler(true);
          }}
          onMouseLeave={() => {
            activeHoverHandler(false);
          }}
          onClick={() => setConfirmStatusDialog(true)}
          id="services_toolbar_button_activate_deactivate"
        >
          <FontAwesomeIcon icon={faPowerOff} />
          {showActiveLabel ? (
            <Box className={classes.labelContainer}>
              <Chip className={classes.label} label={inactiveLabel} />
            </Box>
          ) : null}
        </Box>
        {props.onDuplicate && (<Box
          className={clsx(classes.buttonContainer, {[classes.disabledButton]:disabledDuplicateButton})}
          onMouseEnter={() => {
            !disabledDuplicateButton && duplicateHoverHandler(true);
          }}
          onMouseLeave={() => {
            duplicateHoverHandler(false);
          }}
          onClick={() => {!disabledDuplicateButton && onDuplicateClickHandler()}}
        >
          <FontAwesomeIcon icon={faCopy} />
          {showDuplicateLabel ? (
            <Box className={classes.labelContainer}>
              <Chip className={classes.label} label="Duplicate" />
            </Box>
          ) : null}
        </Box>)}
        <Box
          className={clsx(classes.buttonContainer,{[classes.disabledButton]:!props.onDelete})}
          onMouseEnter={() => {
            deleteHoverHandler(true);
          }}
          onMouseLeave={() => {
            deleteHoverHandler(false);
          }}
          onClick={onDeleteClickHandler}
          id="services_toolbar_button_delete"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
          {showDeleteLabel ? (
            <Box className={classes.labelContainer}>
              <Chip className={classes.label} label="Delete" />
            </Box>
          ) : null}
        </Box>
      </Box>
      <DuplicateDialog
        name={props.name}
        onClose={closeDuplicateDialogHandler}
        open={showDuplicateDialog}
        onDuplicate={onDuplicateHandler}
        duplicateOptions={props.duplicateOptions || []}
      />
    </Fragment>
  );
};

export default RowToolbar;
