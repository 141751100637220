import React, { FunctionComponent, ReactNode } from 'react';
import { Button as MUIButton, Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { css } from '@emotion/react';
import PulseLoader from 'react-spinners/PulseLoader';
import clsx from 'clsx';

export interface Props {
	id?: string;
	label?: string | ReactNode;
	variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning';
	size?: 'small' | 'medium' | 'large';
	color?: 'orange' | 'green' | 'black' | 'secondaryGreen';
	onClick?: (event: any) => void;
	disabled?: boolean;
	loading?: boolean;
	startIcon?: IconProp;
	startElement?: ReactNode;
	endIcon?: IconProp;
	endElement?: ReactNode;
	className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			'borderRadius': 30,
			'textTransform': 'none',
			[theme.breakpoints.down('md')]: {
				height: '29px',
				paddingTop: '5px',
				paddingBottom: '5px',
				paddingRight: '24px',
				paddingLeft: '24px'
			},
			[theme.breakpoints.only('lg')]: {
				height: '39px',
				paddingTop: '7px',
				paddingBottom: '7px',
				paddingRight: '32px',
				paddingLeft: '32px'
			},
			[theme.breakpoints.up('xl')]: {
				height: '43px',
				paddingTop: '8px',
				paddingBottom: '8px',
				paddingRight: '36px',
				paddingLeft: '36px'
			},
			'& .MuiButton-startIcon': {
				marginRight: 2,

				[theme.breakpoints.up('md')]: {
					marginRight: 8
				}
			},
			'& .MuiButton-label': {
				whiteSpace: 'nowrap',
				fontWeight: 600,
				[theme.breakpoints.down('md')]: {
					fontSize: '11px',
					lineHeight: '18px'
				},
				[theme.breakpoints.only('lg')]: {
					fontSize: '14px',
					lineHeight: '24px'
				},
				[theme.breakpoints.up('xl')]: {
					fontSize: '16px',
					lineHeight: '27px'
				}
			}
		},
		large: {
			'padding': '8px 16px',

			[theme.breakpoints.only('md')]: {
				paddingTop: '7px',
				paddingBottom: '7px',
				paddingLeft: '18px',
				paddingRight: '18px'
			},
			[theme.breakpoints.only('lg')]: {
				paddingTop: '9px',
				paddingBottom: '9px',
				paddingLeft: '24px',
				paddingRight: '24px'
			},
			[theme.breakpoints.only('xl')]: {
				paddingTop: '14px',
				paddingBottom: '14px',
				paddingLeft: '36px',
				paddingRight: '36px'
			},
			'& .MuiButton-label': {
				fontFamily: 'Poppins',
				fontWeight: 600,
				lineHeight: 1.6,
				[theme.breakpoints.down('lg')]: {
					fontSize: 14
				},
				[theme.breakpoints.only('xl')]: {
					fontSize: 18
				}
			}
		},
		medium: {
			[theme.breakpoints.down('sm')]: {
				paddingTop: '3px',
				paddingBottom: '3px',
				paddingLeft: '7px',
				paddingRight: '7px'
			},
			[theme.breakpoints.only('md')]: {
				paddingTop: '5px',
				paddingBottom: '5px',
				paddingLeft: '12px',
				paddingRight: '12px'
			},
			[theme.breakpoints.only('lg')]: {
				paddingTop: '7px',
				paddingBottom: '7px',
				paddingLeft: '16px',
				paddingRight: '16px'
			},
			[theme.breakpoints.only('xl')]: {
				paddingTop: '10px',
				paddingBottom: '10px',
				paddingLeft: '24px',
				paddingRight: '24px'
			},
			'& .MuiButton-label': {
				fontFamily: 'Poppins',
				fontWeight: 600,
				[theme.breakpoints.down('sm')]: {
					fontSize: '8px',
					lineHeight: '14px'
				},
				[theme.breakpoints.only('md')]: {
					fontSize: '10px',
					lineHeight: '18px'
				},
				[theme.breakpoints.only('lg')]: {
					fontSize: '13px',
					lineHeight: '22px'
				},
				[theme.breakpoints.only('xl')]: {
					fontSize: '16px',
					lineHeight: '27px'
				}
			}
		},
		small: {
			[theme.breakpoints.down('sm')]: {
				paddingTop: '2px',
				paddingBottom: '2px',
				paddingLeft: '5px',
				paddingRight: '5px'
			},
			[theme.breakpoints.only('md')]: {
				paddingTop: '3px',
				paddingBottom: '3px',
				paddingLeft: '8px',
				paddingRight: '8px'
			},
			[theme.breakpoints.only('lg')]: {
				paddingTop: '4px',
				paddingBottom: '4px',
				paddingLeft: '11px',
				paddingRight: '11px'
			},
			[theme.breakpoints.only('xl')]: {
				paddingTop: '6px',
				paddingBottom: '6px',
				paddingLeft: '16px',
				paddingRight: '16px'
			},
			'& .MuiButton-label': {
				fontFamily: 'Poppins',
				fontWeight: 600,
				[theme.breakpoints.down('sm')]: {
					fontSize: '7px',
					lineHeight: '14px'
				},
				[theme.breakpoints.only('md')]: {
					fontSize: '9px',
					lineHeight: '18px'
				},
				[theme.breakpoints.only('lg')]: {
					fontSize: '11px',
					lineHeight: '22px'
				},
				[theme.breakpoints.only('xl')]: {
					fontSize: '14px',
					lineHeight: '27px'
				}
			}
		},
		largeIcon: {
			[theme.breakpoints.down('sm')]: {
				width: '7px',
				height: '7px'
			},
			[theme.breakpoints.only('md')]: {
				width: '12px',
				height: '12px'
			},
			[theme.breakpoints.only('lg')]: {
				width: '16px',
				height: '16px'
			},
			[theme.breakpoints.only('xl')]: {
				width: '24px',
				height: '24px'
			}
		},
		mediumIcon: {
			[theme.breakpoints.down('sm')]: {
				width: '5px',
				height: '5px'
			},
			[theme.breakpoints.only('md')]: {
				width: '8px',
				height: '8px'
			},
			[theme.breakpoints.only('lg')]: {
				width: '11px',
				height: '11px'
			},
			[theme.breakpoints.only('xl')]: {
				width: '16px',
				height: '16px'
			}
		},
		smallIcon: {
			[theme.breakpoints.down('sm')]: {
				width: '5px',
				height: '5px'
			},
			[theme.breakpoints.only('md')]: {
				width: '8px',
				height: '8px'
			},
			[theme.breakpoints.only('lg')]: {
				width: '11px',
				height: '11px'
			},
			[theme.breakpoints.only('xl')]: {
				width: '16px',
				height: '16px'
			}
		},
		orange: {
			'color': '#FFFFFF',
			'backgroundColor': '#EAB464 !important',
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: '#FFFFFF',
					backgroundColor: '#C18733 !important'
				}
			},
			'&.Mui-disabled': {
				color: '#FFFFFF',
				backgroundColor: '#FAEFDF !important'
			}
		},
		secondaryOrange: {
			'color': '#EAB464',
			'backgroundColor': '#FFFFFF',
			'border': '2px solid #EAB464',
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					backgroundColor: '#FAEFDF !important'
				}
			},
			'&:active': {
				color: '#C18733',
				backgroundColor: '#F2D2A2 !important',
				border: '2px solid #C18733'
			},
			'&.Mui-disabled': {
				color: '#FAEFDF',
				backgroundColor: '#FFFFFF !important',
				border: '2px solid #FAEFDF'
			}
		},
		green: {
			'color': '#FFFFFF',
			'backgroundColor': '#92B4A7 !important',
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: '#FFFFFF',
					backgroundColor: '#5E8677 !important'
				}
			},
			'&.Mui-disabled': {
				color: '#FFFFFF',
				backgroundColor: '#D3D3D3 !important'
			}
		},
		secondaryGreen: {
			'color': '#5E8677',
			'backgroundColor': '#FFFFFF !important',
			'border': '2px solid #5E8677',
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					backgroundColor: '#E9F0ED !important'
				}
			},
			'&:active': {
				backgroundColor: '#BDD2CA !important'
			},
			'&.Mui-disabled': {
				color: '#E9F0ED',
				backgroundColor: '#FFFFFF !important',
				border: '2px solid #E9F0ED'
			}
		},
		black: {
			'color': '#FFFFFF',
			'backgroundColor': '#222222 !important',
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: '#FFFFFF',
					backgroundColor: '#565656 !important'
				}
			},
			'&.Mui-disabled': {
				color: '#FFFFFF',
				backgroundColor: '#D3D3D3 !important'
			}
		},
		secondaryBlack: {
			'color': '#222222',
			'backgroundColor': '#FFFFFF !important',
			'border': '2px solid #000000',
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					backgroundColor: '#DBDAD7 !important'
				}
			},
			'&:active': {
				backgroundColor: '#BCB8AE !important'
			},
			'&.Mui-disabled': {
				color: '#DBDAD7',
				backgroundColor: '#FFFFFF !important',
				border: '2px solid #DBDAD7'
			}
		},
		danger: {
			'color': '#FFFFFF',
			'backgroundColor': '#EF4F57 !important',
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: '#FFFFFF',
					backgroundColor: '#CC444B !important'
				}
			},
			'&.Mui-disabled': {
				color: '#FFFFFF',
				backgroundColor: '#FBB9BC !important'
			}
		},
		success: {
			'color': '#FFFFFF',
			'backgroundColor': '#61CE8D !important',
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: '#FFFFFF',
					backgroundColor: '#51A372 !important'
				}
			},
			'&.Mui-disabled': {
				color: '#FFFFFF',
				backgroundColor: '#C0EBD1 !important'
			}
		},
		warning: {
			'color': '#FFFFFF',
			'backgroundColor': '#FFDF6B !important',
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: '#FFFFFF',
					backgroundColor: '#ECC915 !important'
				}
			},
			'&.Mui-disabled': {
				color: '#FFFFFF',
				backgroundColor: '#FFF8DD !important'
			}
		},
		orangeLoading: {
			'backgroundColor': '#C18733 !important',
			'& .MuiButton-label': {
				color: 'rgba(255,255,255,0)'
			},
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: 'rgba(255,255,255,0)',
					backgroundColor: '#C18733 !important'
				}
			}
		},
		greenLoading: {
			'backgroundColor': '#5E8677 !important',
			'& .MuiButton-label': {
				color: 'rgba(255,255,255,0)'
			},
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: 'rgba(255,255,255,0)',
					backgroundColor: '#5E8677 !important'
				}
			}
		},
		blackLoading: {
			'backgroundColor': '#000000 !important',
			'& .MuiButton-label': {
				color: 'rgba(255,255,255,0)'
			},
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: 'rgba(255,255,255,0)',
					backgroundColor: '#000000 !important'
				}
			}
		},
		secondaryOrangeLoading: {
			'backgroundColor': '#F2D2A2 !important',
			'& .MuiButton-label': {
				color: 'rgba(255,255,255,0)'
			},
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: 'rgba(255,255,255,0)',
					backgroundColor: '#F2D2A2 !important'
				}
			}
		},
		secondaryGreenLoading: {
			'backgroundColor': '#BDD2CA !important',
			'& .MuiButton-label': {
				color: 'rgba(255,255,255,0)'
			},
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: 'rgba(255,255,255,0)',
					backgroundColor: '#BDD2CA !important'
				}
			}
		},
		secondaryBlackLoading: {
			'backgroundColor': '#BCB8AE !important',
			'& .MuiButton-label': {
				color: 'rgba(255,255,255,0)'
			},
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: 'rgba(255,255,255,0)',
					backgroundColor: '#BCB8AE !important'
				}
			}
		},
		dangerLoading: {
			'backgroundColor': '#CC444B !important',
			'& .MuiButton-label': {
				color: 'rgba(255,255,255,0)'
			},
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: 'rgba(255,255,255,0)',
					backgroundColor: '#CC444B !important'
				}
			}
		},
		successLoading: {
			'backgroundColor': '#51A372 !important',
			'& .MuiButton-label': {
				color: 'rgba(255,255,255,0)'
			},
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: 'rgba(255,255,255,0)',
					backgroundColor: '#51A372 !important'
				}
			}
		},
		warningLoading: {
			'backgroundColor': '#ECC915 !important',
			'& .MuiButton-label': {
				color: 'rgba(255,255,255,0)'
			},
			'&:hover': {
				[theme.breakpoints.up('md')]: {
					color: 'rgba(255,255,255,0)',
					backgroundColor: '#ECC915 !important'
				}
			}
		},
		spinnerContainer: {
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 2,
			width: '100%',
			height: '100%'
		}
	})
);

const override = css`
	display: block;
	margin: 0 auto;
	border-color: red;
`;

export const Button: FunctionComponent<Props> = props => {
	const classes = useStyles(props);

	const clickHandler = (event: any) => {
		props.onClick &&
			!props.loading &&
			!props.disabled &&
			props.onClick(event);
	};

	const startIconElement = props.startIcon && (
		<FontAwesomeIcon
			icon={props.startIcon!}
			className={clsx({
				[classes.largeIcon]:
					props.size === undefined || props.size === 'large',
				[classes.mediumIcon]: props.size === 'medium',
				[classes.smallIcon]: props.size === 'small'
			})}
		/>
	);

	const endIconElement = props.endIcon && (
		<FontAwesomeIcon
			icon={props.endIcon!}
			className={clsx({
				[classes.largeIcon]:
					props.size === undefined || props.size === 'large',
				[classes.mediumIcon]: props.size === 'medium',
				[classes.smallIcon]: props.size === 'small'
			})}
		/>
	);

	const getSpinnerColor = (variant: string, color: string): string => {
		if (variant === 'secondary' && color === 'green') {
			return '#5E8677';
		} else if (variant === 'secondary' && color === 'black') {
			return '#222222';
		} else if (variant === 'secondary') {
			return '#C18733';
		} else {
			return '#FFFFFF';
		}
	};

	const spinner = (
		<Box className={classes.spinnerContainer}>
			<PulseLoader
				color={getSpinnerColor(
					props.variant || 'primary',
					props.color || 'orange'
				)}
				loading={props.loading}
				css={override}
				size={5}
			/>
		</Box>
	);

	return (
		<MUIButton
			id={props.id || ''}
			variant={props.variant === 'secondary' ? 'outlined' : 'contained'}
			disableElevation={true}
			disableFocusRipple={true}
			disableRipple={true}
			disabled={props.disabled}
			disableTouchRipple={true}
			onClick={clickHandler}
			startIcon={props.startElement || startIconElement}
			endIcon={props.endElement || endIconElement}
			className={clsx(
				classes.button,
				{
					[classes.large]:
						props.size === undefined || props.size === 'large',
					[classes.medium]: props.size === 'medium',
					[classes.small]: props.size === 'small',
					[classes.orange]:
						(props.variant === undefined ||
							props.variant === 'primary') &&
						(props.color === undefined || props.color === 'orange'),
					[classes.green]:
						(props.variant === undefined ||
							props.variant === 'primary') &&
						props.color === 'green',
					[classes.black]:
						(props.variant === undefined ||
							props.variant === 'primary') &&
						props.color === 'black',
					[classes.danger]: props.variant === 'danger',
					[classes.success]: props.variant === 'success',
					[classes.warning]: props.variant === 'warning',
					[classes.secondaryOrange]:
						props.variant === 'secondary' &&
						(props.color === undefined || props.color === 'orange'),
					[classes.secondaryGreen]:
						props.variant === 'secondary' &&
						props.color === 'secondaryGreen',
					[classes.secondaryBlack]:
						props.variant === 'secondary' &&
						props.color === 'black',
					[classes.orangeLoading]:
						props.loading &&
						(props.variant === undefined ||
							props.variant === 'primary') &&
						(props.color === undefined || props.color === 'orange'),
					[classes.greenLoading]:
						props.loading &&
						(props.variant === undefined ||
							props.variant === 'primary') &&
						props.color === 'green',
					[classes.blackLoading]:
						props.loading &&
						(props.variant === undefined ||
							props.variant === 'primary') &&
						props.color === 'black',
					[classes.secondaryOrangeLoading]:
						props.loading &&
						props.variant === 'secondary' &&
						(props.color === undefined || props.color === 'orange'),
					[classes.secondaryGreenLoading]:
						props.loading &&
						props.variant === 'secondary' &&
						props.color === 'green',
					[classes.secondaryBlackLoading]:
						props.loading &&
						props.variant === 'secondary' &&
						props.color === 'black',
					[classes.dangerLoading]:
						props.loading && props.variant === 'danger',
					[classes.successLoading]:
						props.loading && props.variant === 'success',
					[classes.warningLoading]:
						props.loading && props.variant === 'warning'
				},
				props.className
			)}
		>
			<span>{props.label}</span>
			{props.loading && spinner}
		</MUIButton>
	);
};

export default Button;
