import {
    Box,
    Button,
    Divider,
    Theme,
    Typography,
    createStyles,
    makeStyles,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { MarketplaceNotifications } from '@spike/marketplace-model';

import { FunctionComponent, useState } from 'react';
import { useNonInitialEffect } from '@versiondos/hooks';
import EmailPoint from '../UI/EmailPoint';
import { useMarketplace } from 'hooks';
import { Spinner } from 'components/UI';

export interface Props {
    onEdit?: (notifications: MarketplaceNotifications) => void;
    notifications: MarketplaceNotifications;
    loading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        leftColumn: {
            padding: '39px 30px 25px 65px',
            width: '45%',
            borderRight: '#DDDDDD 1px solid;',
            [theme.breakpoints.down('sm')]: {
                padding: '25px 16px',
                width: '100%',
                borderRight: '#DDDDDD 0px solid;'
            }
        },
        rightColumn: {
            width: '55%',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        description: {
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '140%',
            marginTop: '5px',
            marginBottom: '15px'
        },
        title: {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '100%',
            marginTop: '25px',
            marginBottom: '10px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px'
            }
        },
        subtitle: {
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '140%',
            marginBottom: '25px',
            maxWidth: '439px',
            [theme.breakpoints.down('sm')]: {
                maxWidth: 'auto'
            }
        },

        contentContainer: {
            'display': 'flex',
            'flexDirection': 'column',
            'width': '100%',
            '& .MuiAccordionDetails-root': {
                padding: '0px'
            }
        },

        emailPointsContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                '& .MuiOutlinedInput-root': {
                    width: '100% !important'
                },
                'marginBottom': '9px',
                '& > div': {
                    'width': '100%',
                    'marginBottom': '15px',
                    'paddingRight': '12px',
                    '& > div': {
                        'justifyContent': 'flex-end',
                        '& .MuiOutlinedInput-root': {
                            fontSize: '16px',
                            width: '100%'
                        }
                    }
                },
                '& p': {
                    fontSize: '14px',
                    width: '100%',
                    marginTop: '1px'
                }
            },
            [theme.breakpoints.only('md')]: {
                marginBottom: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '30px'
            }
        },
        addPoint: {
            'fontSize': '16px',
            'lineHeight': '30px',
            'fontWeight': 600,
            'color': '#EAB464',
            [theme.breakpoints.down('sm')]: {
                marginTop: '7px',
                marginBottom: '12px'
            },
            '&:hover': {
                cursor: 'pointer'
            },
            '& > span': {
                textTransform: 'none !important'
            }
        },
        divider: {
            marginBottom: '10px',
            marginTop: '15px'
        },
        previewContainer: {
            marginTop: '39px',
            marginBottom: '70px',
            padding: '50px',
            backgroundColor: '#F8F8F8',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '90%',
            minHeight: '80%',
            [theme.breakpoints.down('sm')]: {
                width: '90%',
                marginTop: '0px',
                marginBottom: '0px',
                padding: '20px'
            }
        },

        emailPreviewContainer: {
            backgroundColor: '#fff',
            borderRadius: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '70%',
            minHeight: '500px',
            [theme.breakpoints.down('sm')]: {
                width: '95%'
            }
        },
        previewLogo: {
            width: '152px',
            marginBottom: '40px',
            [theme.breakpoints.down('sm')]: {
                width: '100px'
            }
        },
        emailPreviewContent: {
            padding: '30px'
        },
        previewTitle: {
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '100%',
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px'
            }
        },
        previewDescription: {
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '140%',
            marginBottom: '15px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px'
            }
        },
        previewList: {
            marginTop: '30px',
            width: "100%",
            wordWrap: 'break-word',
            overflowWrap: 'break-word'
        },
        previewItem: {
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '140%',
            letterSpacing: '-0.18px',
            marginBottom: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px'
            }
        },
        previewFooter: {
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '100%',
            marginTop: '35px',
            marginBottom: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px'
            }
        },
        previewFooterLine1: {
            fontSize: '15px',
            fontWeight: 500,
            lineHeight: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px'
            }
        },
        previewFooterLine2: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px'
            }
        },
        previewFooterLine3: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            textDecoration: 'underline',
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px'
            }
        },
        footerContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '25px'
        },
        footerLogo: {
            width: '100px',
            [theme.breakpoints.down('sm')]: {
                width: '82px'
            }
        },
        addButtonContainer: {
            marginTop: '10px'
        }
    })
);

export const EditConfirmation: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const marketplace = useMarketplace();

    const [notifications, setNotifications] =
        useState<MarketplaceNotifications>(props.notifications);

    useNonInitialEffect(() => {
        props.onEdit && props.onEdit(notifications);
    }, [notifications]);

    const handleAddPoint = () => {
        if (notifications.emailContent.length < 10) {
            setNotifications(prev => ({
                ...prev,
                emailContent: [...prev.emailContent, '']
            }));
        }
    };

    const handleDeletePoint = (option: string) => {
        setNotifications(prev => ({
            ...prev,
            emailContent: prev.emailContent.filter(line => line !== option)
        }));
    };

    const handleEditPoint = (value: string, index: number) => {
        setNotifications(prev => ({
            ...prev,
            emailContent: prev.emailContent.map((content, i) =>
                i === index ? value : content
            )
        }));
    };

    const preview = (
        <Box className={classes.previewContainer}>
            <Box className={classes.emailPreviewContainer}>
                <Box className={classes.emailPreviewContent}>
                    <img
                        className={classes.previewLogo}
                        src={marketplace.logos.imageUrl}
                    />

                    <Typography className={classes.previewTitle}>
                        Hi [Client first name]
                    </Typography>
                    <Typography className={classes.previewDescription}>
                        <b>[Pet Name]’s</b> appointment is confirmed for{' '}
                        <b>[Time]</b> on <b>[Date]</b> at <b>[Address]</b>.
                        <br />
                        <br />
                        {notifications.emailContent.length > 0 && (
                            <Box>
                                Here’s how to prepare for <b>[Pet Name]’s</b>{' '}
                                appointment:
                            </Box>
                        )}
                    </Typography>
                    {notifications.emailContent.length > 0 && (
                        <>
                            <Divider />
                            <ul className={classes.previewList}>
                                {notifications.emailContent.map(
                                    (emailCont, index) => (
                                        <li
                                            key={`preview-${emailCont}${index}`}
                                            className={classes.previewItem}
                                        >
                                            {emailCont}
                                        </li>
                                    )
                                )}
                            </ul>
                        </>
                    )}
                    <Typography className={classes.previewFooter}>
                        With love,
                    </Typography>
                    <Typography className={classes.previewFooterLine1}>
                        The {marketplace.basics.businessName} Team
                    </Typography>
                    <Typography className={classes.previewFooterLine2}>
                        {marketplace.basics.phone}
                    </Typography>
                    <Typography className={classes.previewFooterLine3}>
                        {marketplace.basics.website}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.footerContainer}>
                <img
                    className={classes.footerLogo}
                    src="/images/logo/poweredByCuddles.png"
                />
            </Box>
        </Box>
    );
    return (
        <Box className={classes.container}>
            {props.loading && <Spinner />}
            {!props.loading && (
                <>
                    <Box className={classes.leftColumn}>
                        <Typography className={classes.description}>
                            This email notification automatically sends to
                            clients when their appointment is confirmed.
                        </Typography>
                        <Divider />
                        <Typography className={classes.title}>
                            {isMobile
                                ? 'Client Notifications'
                                : 'Email Content'}
                        </Typography>
                        <Typography className={classes.subtitle}>
                            Mention how you expect pet parents to prepare before
                            each appointment.
                        </Typography>
                        <Box className={classes.contentContainer}>
                            <Box className={classes.emailPointsContainer}>
                                {notifications.emailContent.map(
                                    (emailCont, index) => (
                                        <Box key={index}>
                                            <EmailPoint
                                                key={`${emailCont}${index}`}
                                                index={index}
                                                optionName={emailCont}
                                                checked={true}
                                                onEditName={handleEditPoint}
                                                editable
                                                onDelete={handleDeletePoint}
                                                isMobile={isMobile}
                                            />
                                            <Divider
                                                className={classes.divider}
                                            />
                                        </Box>
                                    )
                                )}
                            </Box>
                            <Box className={classes.addButtonContainer}>
                                {notifications.emailContent.length < 10 && (
                                    <Button
                                        className={classes.addPoint}
                                        onClick={handleAddPoint}
                                    >
                                        + Add Point
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.rightColumn}>{preview}</Box>
                </>
            )}
        </Box>
    );
};

export default EditConfirmation;
