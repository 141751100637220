import React, { FunctionComponent, Fragment, useState, useEffect } from 'react';
import ResumeSection, { BodyResumeSection } from '../ResumeSection';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
	makeStyles,
	createStyles,
	Theme,
	useTheme
} from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import { Chip } from 'components/UI';
import Service from 'model/Service';
import { PriceFormat } from 'components/UI';
import { useMediaQuery } from '@material-ui/core';

interface Props {
	service: Service;
	onEdit: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},
		titleContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
			width: '100%'
		},
		title: {
			textTransform: 'uppercase',
			[theme.breakpoints.down('lg')]: {
				fontSize: '9px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
				color: '#7A7A7A',
				fontWeight: 400,
				textTransform: 'unset'
			}
		},
		headerFixedPrice: {
			width: '120px',
			textTransform: 'uppercase',
			[theme.breakpoints.down('lg')]: {
				fontSize: '9px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
				color: '#000',
				textTransform: 'unset',
				fontWeight: 500
			}
		},
		headerVariablePrice: {
			width: '110px',
			textTransform: 'uppercase',
			[theme.breakpoints.down('lg')]: {
				fontSize: '14px',
				textTransform: 'unset',
				fontWeight: 500
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.down('md')]: {
				width: '80px'
			}
		},
		headerVariablePriceHairLength: {
			[theme.breakpoints.down('md')]: {
				width: '100px'
			}
		},
		headerTax: {
			width: '80px',
			[theme.breakpoints.down('lg')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '12px'
			}
		},
		gridContainer: {
			display: 'flex'
		},
		gridContainerVariablePrice: {
			[theme.breakpoints.down('lg')]: {
				marginTop: '20px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '37px'
			},
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		infoContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
			width: '100%',
			[theme.breakpoints.down('lg')]: {
				marginTop: '6px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '8px'
			}
		},
		info: {
			fontWeight: 600,
			width: '110px',
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px'
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
				fontWeight: '400',
				width: '80px'
			}
		},
		infoHairLength: {
			[theme.breakpoints.down('md')]: {
				width: '100px'
			}
		},
		infoFixedPrice: {
			fontWeight: 600,
			width: '120px',
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px'
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
				fontWeight: '400'
			}
		},
		infoTax: {
			fontWeight: 600,
			width: '80px',
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px'
			}
		},

		fixedPricesContainer: {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('lg')]: {
				width: '122px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '286px'
			},
			[theme.breakpoints.down('md')]: {
				'width': '100%',
				'& >div:first-child': {
					marginTop: '0px'
				}
			}
		},
		fixedPriceContainer: {
			display: 'flex',
			width: '100%',
			justifyContent: 'space-between'
		},
		variablesContainer: {
			display: 'flex',
			width: 'fit-content',
			[theme.breakpoints.down('lg')]: {
				marginTop: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '16px'
			},
			[theme.breakpoints.down('md')]: {
				'flexWrap': 'wrap',
				'marginTop': '0px',
				'& .MuiChip-outlined': {
					fontWeight: '500',
					backgroundColor: '#f1f1f1',
					color: '#000000',
					border: 'none',
					fontSize: '14px'
				},
				'& > div': {
					marginRight: '12px',
					marginTop: '10px'
				}
			}
		},
		chipContainer: {
			[theme.breakpoints.down('lg')]: {
				marginRight: '20px'
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: '26px'
			}
		},
		viewDetailsContainer: {
			display: 'flex',
			alignItems: 'center',
			width: 'fit-content',
			cursor: 'pointer',
			[theme.breakpoints.down('lg')]: {
				marginTop: '20px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '25px'
			},
			[theme.breakpoints.down('md')]: {
				display: 'none'
			}
		},
		viewDetails: {
			[theme.breakpoints.down('lg')]: {
				marginRight: '10px',
				fontSize: '10px'
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: '14px',
				fontSize: '12px'
			}
		},
		chevron: {
			[theme.breakpoints.down('lg')]: {
				fontSize: '8px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '12px'
			}
		},
		variablePricesContainer: {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('md')]: {
				overflowX: 'auto',
				width: '100%',
				minWidth: '128%'
			}
		},
		variablePrice: {
			display: 'flex',
			[theme.breakpoints.down('lg')]: {
				marginTop: '4px',
				marginBottom: '4px'
			},
			[theme.breakpoints.up('xl')]: {
				marginTop: '8px',
				marginBottom: '8px'
			}
		},
		variablePriceContainerWide: {
			[theme.breakpoints.down('md')]: {
				minWidth: '120%'
			}
		},
		variablePriceItem: {
			display: 'flex',
			alignItems: 'center',
			[theme.breakpoints.down('lg')]: {
				width: 'auto'
			},
			[theme.breakpoints.up('xl')]: {
				width: 'auto'
			}
		},
		variablePriceAmount: {
			display: 'flex',
			justifyContent: 'flex-start',
			[theme.breakpoints.down('lg')]: {
				width: '130px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '180px'
			}
		},
		variablePriceSeparator: {
			[theme.breakpoints.down('lg')]: {
				fontSize: '10px',
				marginLeft: '20px',
				marginRight: '20px'
			},
			[theme.breakpoints.up('xl')]: {
				marginLeft: '32px',
				marginRight: '32px'
			}
		},
		taxesContainer: {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('lg')]: {
				width: '7g2px'
			},
			[theme.breakpoints.up('xl')]: {
				width: '226px'
			},
			[theme.breakpoints.down('md')]: {
				display: 'none'
			}
		},
		taxContainer: {
			marginLeft: '75px'
		},
		taxPercentage: {
			display: 'inline',
			paddingLeft: '8px',
			fontWeight: 300,
			color: '#7A7A7A !important'
		},
		undefined: {
			color: 'red'
		},
		nonePriceTitle: {
			fontWeight: 600,
			[theme.breakpoints.down('lg')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '18px'
			}
		},
		nonePriceDescription: {
			[theme.breakpoints.down('lg')]: {
				fontSize: '10px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '14px'
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
				color: '#7A7A7A',
				maxWidth: '245px',
				fontWeight: '400'
			}
		}
	})
);

export const ResumePricing: FunctionComponent<Props> = props => {
	const classes = useStyles();
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [showDetails, setShowDetails] = useState(isMobile);

	useEffect(() => {
		setShowDetails(isMobile);
	}, [isMobile]);

	const showDetailChangeHandler = () => {
		setShowDetails(prev => {
			return !prev;
		});
	};

	const multipleVariables =
		props.service.pricing.variablePrice?.variables.petTypeVariable &&
		props.service.pricing.variablePrice?.variables.petSizeVariable &&
		props.service.pricing.variablePrice?.variables.hairTypeVariable &&
		props.service.pricing.variablePrice?.variables.hairLengthVariable;

	const formatPrice = (price: number | undefined) => {
		return price && <PriceFormat price={price} className={classes.info} />;
	};

	const taxes = (
		<Box>
			{props.service.pricing.taxes && (
				<Box
					className={clsx(
						classes.taxesContainer,
						classes.taxContainer
					)}
				>
					<Box
						className={clsx(
							classes.infoContainer,
							classes.fixedPriceContainer
						)}
					>
						{props.service.pricing.taxes.length > 0 && (
							<Typography className={classes.headerTax}>
								TAXES
							</Typography>
						)}
					</Box>
					{props.service.pricing.taxes.map(tax => {
						return (
							<Box
								key={tax.uuid}
								className={clsx(
									classes.infoContainer,
									classes.fixedPriceContainer
								)}
							>
								<Typography className={clsx(classes.infoTax)}>
									{tax.name}
								</Typography>

								<Typography
									className={clsx(
										classes.infoTax,
										classes.taxPercentage
									)}
								>
									{tax.percentage}%
								</Typography>
							</Box>
						);
					})}
				</Box>
			)}
		</Box>
	);

	const fixedPrice = (
		<Fragment>
			<Box className={classes.gridContainer}>
				<Box className={classes.fixedPricesContainer}>
					<Box
						className={clsx(
							classes.infoContainer,
							classes.fixedPriceContainer
						)}
					>
						<Typography className={classes.headerFixedPrice}>
							Fixed by Pet
						</Typography>

						<Typography className={classes.headerFixedPrice}>
							Prices
						</Typography>
					</Box>
					{props.service.petTypes
						.map(pt =>
							props.service.pricing.fixedPrice.some(
								fp => fp.petType.id === pt.id
							)
								? props.service.pricing.fixedPrice.find(
										fp => fp.petType.id === pt.id
								  )!
								: { petType: pt, price: undefined }
						)
						.map(fixedPrice => (
							<Box
								key={fixedPrice.petType.id}
								className={clsx(
									classes.infoContainer,
									classes.fixedPriceContainer
								)}
							>
								<Typography
									className={clsx(classes.infoFixedPrice, {
										[classes.undefined]:
											fixedPrice.price === undefined
									})}
								>
									{fixedPrice.petType.plural}
								</Typography>
								<Typography
									className={clsx(classes.infoFixedPrice, {
										[classes.undefined]:
											fixedPrice.price === undefined
									})}
								>
									{fixedPrice.price !== undefined
										? formatPrice(fixedPrice.price)
										: '-'}
								</Typography>
							</Box>
						))}
				</Box>

				{taxes}
			</Box>
		</Fragment>
	);

	const variablePrices = (
		<Box
			className={clsx(
				classes.gridContainer,
				classes.gridContainerVariablePrice
			)}
		>
			<Box className={classes.variablePricesContainer}>
				<Box
					className={clsx(
						classes.infoContainer,
						classes.variablePrice,
						multipleVariables && classes.variablePriceContainerWide
					)}
				>
					{props.service.pricing.variablePrice?.variables
						.petTypeVariable && (
						<Typography className={classes.headerVariablePrice}>
							Pet Type
						</Typography>
					)}

					{props.service.pricing.variablePrice?.variables
						.petSizeVariable && (
						<Typography className={classes.headerVariablePrice}>
							Pet Size
						</Typography>
					)}

					{props.service.pricing.variablePrice?.variables
						.hairTypeVariable && (
						<Typography className={classes.headerVariablePrice}>
							{' '}
							Hair Type
						</Typography>
					)}

					{props.service.pricing.variablePrice?.variables
						.hairLengthVariable && (
						<Typography
							className={clsx(
								classes.headerVariablePrice,
								classes.headerVariablePriceHairLength
							)}
						>
							{' '}
							Hair Length
						</Typography>
					)}

					{props.service.pricing.variablePrice?.variables
						.exoticTypeVariable && (
						<Typography className={classes.headerVariablePrice}>
							{' '}
							Exotic Type
						</Typography>
					)}

					<Typography className={classes.headerVariablePrice}>
						Price
					</Typography>
				</Box>
				{props.service.pricing.variablePrice?.prices.map(price => (
					<Box key={price.uuid} className={classes.variablePrice}>
						<Box className={classes.variablePriceItem}>
							{props.service.pricing.variablePrice?.variables
								.petTypeVariable && (
								<Typography className={classes.info}>
									{price.petType?.plural}
								</Typography>
							)}
							{props.service.pricing.variablePrice?.variables
								.petSizeVariable && (
								<Fragment>
									<Typography className={classes.info}>
										{price.petSize?.name}
									</Typography>
								</Fragment>
							)}
							{props.service.pricing.variablePrice?.variables
								.hairTypeVariable && (
								<Fragment>
									<Typography className={classes.info}>
										{price.hairType?.name}
									</Typography>
								</Fragment>
							)}
							{props.service.pricing.variablePrice?.variables
								.hairLengthVariable && (
								<Fragment>
									<Typography
										className={clsx(
											classes.info,
											classes.infoHairLength
										)}
									>
										{price.hairLength?.name}
									</Typography>
								</Fragment>
							)}
							{props.service.pricing.variablePrice?.variables
								.exoticTypeVariable && (
								<Fragment>
									<Typography className={classes.info}>
										{price.exoticType?.name}
									</Typography>
								</Fragment>
							)}
						</Box>
						<Box className={classes.variablePriceAmount}>
							{formatPrice(price.price)}
						</Box>
					</Box>
				))}
			</Box>

			{taxes}
		</Box>
	);

	const variablePrice = (
		<Fragment>
			<Box className={classes.titleContainer}>
				<Typography className={classes.title}>Variables</Typography>
			</Box>
			<Box className={classes.variablesContainer}>
				{props.service.pricing.variablePrice?.variables
					.petTypeVariable && (
					<Box className={classes.chipContainer}>
						<Chip label="Pet Type" variant="outlined" />
					</Box>
				)}
				{props.service.pricing.variablePrice?.variables
					.petSizeVariable && (
					<Box className={classes.chipContainer}>
						<Chip label="Pet Size" variant="outlined" />
					</Box>
				)}
				{props.service.pricing.variablePrice?.variables
					.hairTypeVariable && (
					<Box className={classes.chipContainer}>
						<Chip label="Hair Type" variant="outlined" />
					</Box>
				)}
				{props.service.pricing.variablePrice?.variables
					.hairLengthVariable && (
					<Box className={classes.chipContainer}>
						<Chip label="Hair Length" variant="outlined" />
					</Box>
				)}
				{props.service.pricing.variablePrice?.variables
					.exoticTypeVariable && (
					<Box className={classes.chipContainer}>
						<Chip label="Exotic Type" variant="outlined" />
					</Box>
				)}
			</Box>
			{showDetails ? (
				<Fragment>
					{variablePrices}
					<Box
						className={classes.viewDetailsContainer}
						onClick={showDetailChangeHandler}
					>
						<Typography className={classes.viewDetails}>
							Less Detail
						</Typography>
						<FontAwesomeIcon
							icon={faChevronUp}
							className={classes.chevron}
						/>
					</Box>
				</Fragment>
			) : (
				<Box
					className={classes.viewDetailsContainer}
					onClick={showDetailChangeHandler}
				>
					<Typography className={classes.viewDetails}>
						View Detail
					</Typography>
					<FontAwesomeIcon
						icon={faChevronDown}
						className={classes.chevron}
					/>
				</Box>
			)}
		</Fragment>
	);

	const existPrices = () => {
		if (props.service.petTypes.length === 1) {
			if (
				props.service.pricing.fixedPrice.length === 1 &&
				props.service.pricing.fixedPrice[0].price !== undefined
			) {
				return true;
			} else if (
				props.service.pricing.variablePrice &&
				!props.service.pricing.variablePrice.prices.some(
					price => price.price === undefined
				)
			) {
				return true;
			} else {
				return false;
			}
		} else if (props.service.petTypes.length === 2) {
			if (
				props.service.pricing.fixedPrice.length === 2 &&
				!props.service.pricing.fixedPrice.some(
					price => price.price === undefined
				)
			) {
				return true;
			} else if (
				props.service.pricing.variablePrice &&
				!props.service.pricing.variablePrice.prices.some(
					price => price.price === undefined
				)
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const nonExistPrices = (
		<Fragment>
			<Box>
				<Typography className={classes.nonePriceDescription}>
					The Pricing section still needs to be completed.
				</Typography>
			</Box>
		</Fragment>
	);

	return (
		<ResumeSection
			title="Pricing"
			{...props}
			pending={!existPrices()}
			withoutStaff={!existPrices()}
		>
			<BodyResumeSection>
				<Box className={classes.container}>
					{existPrices() &&
						props.service.pricing.fixedPrice.length >= 1 &&
						fixedPrice}
					{existPrices() &&
						props.service.pricing.variablePrice?.prices &&
						variablePrice}
					{!existPrices() && nonExistPrices}
				</Box>
			</BodyResumeSection>
		</ResumeSection>
	);
};

export default ResumePricing;
