import { FunctionComponent, useState, useEffect } from 'react';
import Service, {
    VariablePrice as ServiceVariablePrice,
    VariablePriceOption,
    UnifiedPriceObject,
    VariablePriceConstants
} from 'model/Service';
import { faCat, faDog, faNarwhal } from '@fortawesome/pro-light-svg-icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMarketplace } from 'hooks';
import Typography from '@material-ui/core/Typography';
import NewGridHeader from './NewGridHeader';
import NewGridRow from './NewGridRow';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { v4 as createUuid } from 'uuid';
import { FieldErrorWithKey } from 'components/Service/model';
import { isEmpty } from 'lodash';

interface Props {
    id?: string;
    serviceVariablePrice: ServiceVariablePrice;
    priceTypeSelected: string;
    vPConst: VariablePriceConstants;
    petType: string;
    service: Service;
    subPetSelected?: string;
    isMultiplePet: boolean;
    errors: Array<FieldErrorWithKey>;
    changeSubPetSelected?: (type: string) => void;
    onChange: (price: VariablePriceOption) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            margin: '0px auto',

            [theme.breakpoints.up('md')]: {
                borderRadius: '24px',
                border: '1px solid #D4D4D4'
            }
        },
        headerContainer: {
            display: 'flex',
            width: '100%'
        },
        rowsContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        title: {
            fontWeight: 600,
            [theme.breakpoints.down('lg')]: {
                fontSize: '14px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '18px'
            }
        },
        subtitle: {
            fontSize: 14,
            color: '#969696',
            marginBottom: '-1rem'
        },
        petBox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            marginBottom: 24,
            padding: '0px 16px',

            [theme.breakpoints.up('md')]: {
                marginBottom: 0,
                padding: '24px 30px'
            }
        },
        imageBox: {
            paddingRight: 10
        },
        emptyImage: {
            width: 40,
            height: 40,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            background: '#F8F5F1',

            [theme.breakpoints.up('md')]: {
                width: 65,
                height: 65
            }
        },
        bgCat: {
            backgroundColor: '#DBA960'
        },
        bgDog: {
            backgroundColor: '#92B4A7'
        },
        uploadIcon: {
            fontSize: 20,
            color: '#BAA997',

            [theme.breakpoints.up('md')]: {
                fontSize: 25
            }
        },
        uploadCat: {
            color: '#ffffff'
        },
        uploadDog: {
            color: '#ffffff'
        },
        buttonsContainer: {
            gap: 15,
            width: '100%',
            display: 'flex',
            alignItems: 'center'
        },
        buttonOption: {
            'borderRadius': 30,
            'display': 'flex',
            'alignItems': 'center',
            'backgroundColor': '#E9F0ED',
            'padding': '4px 13px 4px 5px',
            'border': 'solid 1px transparent',

            [theme.breakpoints.up('md')]: {
                padding: '6px 13px 6px 8px'
            },

            '& .MuiButton-startIcon': {
                width: 34,
                height: 34,
                marginLeft: 0,
                marginRight: 7,
                alignItems: 'center',
                display: 'inline-flex',
                justifyContent: 'center',
                color: '#fff',
                borderRadius: '50%',

                [theme.breakpoints.up('md')]: {
                    'width': 42,
                    'height': 42,
                    'marginRight': 10,

                    '& svg': {
                        fontSize: 24
                    }
                }
            }
        },
        dogOptionButton: {
            'backgroundColor': '#E9F0ED',

            '&:hover': {
                backgroundColor: '#E9F0ED'
            },
            '& .MuiButton-startIcon': {
                backgroundColor: '#92B4A7'
            }
        },
        catOptionButton: {
            'backgroundColor': '#FAEFDF',

            '&:hover': {
                backgroundColor: '#FAEFDF'
            },
            '& .MuiButton-startIcon': {
                backgroundColor: '#DBA960'
            }
        },
        headerLabel: {
            fontSize: 16,
            fontWeight: 500,
            color: '#000',

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        buttonLabel: {
            color: '#000',
            fontSize: 16,
            fontWeight: 600
        },
        subPetSelected: {
            border: '1px solid #000000'
        }
    })
);

export const Grid: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const marketplace = useMarketplace();

    const [unifiedRows, setUnifiedRows] = useState<Array<UnifiedPriceObject>>(
        []
    );

    const sortPrices = (prices: Array<VariablePriceOption>) => {
        prices.sort((a, b) => {
            const petTypeOrder = ['dogs', 'cats'];
            const dogSizeOrder = [
                'xsmall',
                'small',
                'medium',
                'large',
                'xlarge',
                'giant'
            ];
            const dogHairLengthOrder = ['short', 'medium', 'long'];
            const catHairLengthOrder = ['hairless', 'short', 'medium', 'long'];
            const typeA = petTypeOrder.indexOf(a.petType!.id);
            const typeB = petTypeOrder.indexOf(b.petType!.id);

            if (typeA < typeB) {
                return -1;
            } else if (typeA > typeB) {
                return 1;
            }

            const sizeA = dogSizeOrder!.indexOf(a.petSize!.id);
            const sizeB = dogSizeOrder!.indexOf(b.petSize!.id);

            if (sizeA < sizeB) {
                return -1;
            } else if (sizeA > sizeB) {
                return 1;
            }

            let hairA, hairB;
            if (a.petType!.id === 'dogs') {
                hairA = dogHairLengthOrder!.indexOf(a.hairLength!.id);
                hairB = dogHairLengthOrder!.indexOf(b.hairLength!.id);
            } else if (a.petType!.id === 'cats') {
                hairA = catHairLengthOrder!.indexOf(a.hairLength!.id);
                hairB = catHairLengthOrder!.indexOf(b.hairLength!.id);
            }

            if (hairA && hairB && hairA < hairB) {
                return -1;
            } else if (hairA && hairB && hairA > hairB) {
                return 1;
            }
            return 0;
        });
        return prices;
    };

    const validatePrices = (prices: Array<VariablePriceOption>) => {
        const validatedPrices: VariablePriceOption[] = JSON.parse(
            JSON.stringify(prices)
        );
        const marketplaceDogSizes = marketplace.petTypes.dogs
            ? marketplace.petTypes.dogs?.sizes.map(e => e.element)
            : [];
        const marketplaceCatSizes = marketplace.petTypes.cats
            ? marketplace.petTypes.cats!.sizes!.map(e => e.element)
            : [];
        const marketplaceDogHairLengths = marketplace.petTypes.dogs
            ? marketplace.petTypes.dogs?.hairLengths.map(e => e.element)
            : [];
        const marketplaceCatHairLengths = marketplace.petTypes.cats
            ? marketplace.petTypes.cats?.hairLengths.map(e => e.element)
            : [];

        const validationParams = {
            dogsSizes: marketplace.petTypes.dogs?.sizes.map(e => e.element.id),
            dogsHairLengths: marketplace.petTypes.dogs?.hairLengths.map(
                e => e.element.id
            ),
            catsSizes: ['small'],
            catsHairLengths: marketplace.petTypes.cats?.hairLengths.map(
                e => e.element.id
            )
        };

        const dogPrices = prices.filter(e => e.petType?.id === 'dogs');
        const catPrices = prices.filter(e => e.petType?.id === 'cats');

        const missingDogSizes = validationParams.dogsSizes?.filter(size => {
            if (dogPrices.map(e => e.petSize?.id).includes(size)) return false;
            else return true;
        });
        const missingDogHairLengths = validationParams.dogsHairLengths?.filter(
            hairLength => {
                if (dogPrices.map(e => e.hairLength?.id).includes(hairLength))
                    return false;
                else return true;
            }
        );
        const missingCatHairLengths = validationParams.catsHairLengths?.filter(
            hairLength => {
                if (catPrices.map(e => e.hairLength?.id).includes(hairLength))
                    return false;
                else return true;
            }
        );

        if (dogPrices.length > 0) {
            if (
                missingDogSizes &&
                missingDogSizes.length > 0 &&
                missingDogHairLengths &&
                missingDogHairLengths?.length === 0
            ) {
                missingDogSizes?.forEach(size => {
                    marketplaceDogHairLengths
                        ?.map(e => e.id)
                        .forEach(hairLength => {
                            const marketplaceSize = marketplaceDogSizes?.find(
                                element => element.id === size
                            );
                            const marketplaceHairLength =
                                marketplaceDogHairLengths?.find(
                                    element => element.id === hairLength
                                );
                            validatedPrices.push({
                                uuid: createUuid(),
                                petType: props.service.petTypes.find(
                                    e => e.id === props.vPConst.DOGS
                                ),
                                petSize: { ...marketplaceSize! },
                                hairLength: { ...marketplaceHairLength! },
                                price: 0
                            });
                        });
                });
            }
            if (
                missingDogHairLengths &&
                missingDogHairLengths.length > 0 &&
                missingDogSizes &&
                missingDogSizes?.length === 0
            ) {
                marketplaceDogSizes
                    ?.map(e => e.id)
                    .forEach(size => {
                        missingDogHairLengths.forEach(hairLength => {
                            const marketplaceSize = marketplaceDogSizes?.find(
                                element => element.id === size
                            );
                            const marketplaceHairLength =
                                marketplaceDogHairLengths?.find(
                                    element => element.id === hairLength
                                );
                            validatedPrices.push({
                                uuid: createUuid(),
                                petType: props.service.petTypes.find(
                                    e => e.id === props.vPConst.DOGS
                                ),
                                petSize: { ...marketplaceSize! },
                                hairLength: { ...marketplaceHairLength! },
                                price: 0
                            });
                        });
                    });
            }
            if (
                missingDogSizes &&
                missingDogSizes.length > 0 &&
                missingDogHairLengths &&
                missingDogHairLengths.length > 0
            ) {
                const existingRows = dogPrices.map(e => e.petSize!.id);
                const filteredExistingRows = existingRows.filter(
                    (value, index, self) => {
                        return self.indexOf(value) === index;
                    }
                );

                filteredExistingRows.forEach(size => {
                    missingDogHairLengths.forEach(hairLength => {
                        const marketplaceSize = marketplaceDogSizes?.find(
                            element => element.id === size
                        );
                        const marketplaceHairLength =
                            marketplaceDogHairLengths?.find(
                                element => element.id === hairLength
                            );
                        validatedPrices.push({
                            uuid: createUuid(),
                            petType: props.service.petTypes.find(
                                e => e.id === props.vPConst.DOGS
                            ),
                            petSize: { ...marketplaceSize! },
                            hairLength: { ...marketplaceHairLength! },
                            price: 0
                        });
                    });
                });
                missingDogSizes.forEach(size => {
                    marketplaceDogHairLengths
                        ?.map(e => e.id)
                        .forEach(hairLength => {
                            const marketplaceSize = marketplaceDogSizes?.find(
                                element => element.id === size
                            );
                            const marketplaceHairLength =
                                marketplaceDogHairLengths?.find(
                                    element => element.id === hairLength
                                );
                            validatedPrices.push({
                                uuid: createUuid(),
                                petType: props.service.petTypes.find(
                                    e => e.id === props.vPConst.DOGS
                                ),
                                petSize: { ...marketplaceSize! },
                                hairLength: { ...marketplaceHairLength! },
                                price: 0
                            });
                        });
                });
            }
        }

        if (catPrices.length > 0) {
            if (missingCatHairLengths && missingCatHairLengths.length > 0) {
                missingCatHairLengths.forEach(hairLength => {
                    const marketplaceSize = marketplaceCatSizes?.find(
                        element => element.id === 'small'
                    );
                    const marketplaceHairLength =
                        marketplaceCatHairLengths?.find(
                            element => element.id === hairLength
                        );
                    validatedPrices.push({
                        uuid: createUuid(),
                        petType: props.service.petTypes.find(
                            e => e.id === props.vPConst.CATS
                        ),
                        petSize: { ...marketplaceSize! },
                        hairLength: { ...marketplaceHairLength! },
                        price: 0
                    });
                });
            }
        }
        return sortPrices(validatedPrices);
    };

    const filterPrices = (prices: Array<VariablePriceOption>) => {
        const filterParams = {
            dogsSizes: marketplace.petTypes.dogs?.sizes.map(e => e.element.id),
            dogsHairLengths: marketplace.petTypes.dogs?.hairLengths.map(
                e => e.element.id
            ),
            catsSizes: ['small'],
            catsHairLengths: marketplace.petTypes.cats?.hairLengths.map(
                e => e.element.id
            )
        };
        const filteredPrices = prices.filter(e => {
            if (
                e.petType?.id === props.vPConst.DOGS &&
                props.priceTypeSelected ===
                    props.vPConst.BY_PET_SIZE_AND_HAIR_LENGTH
            ) {
                if (
                    filterParams.dogsSizes?.includes(e.petSize?.id!) &&
                    filterParams.dogsHairLengths?.includes(e.hairLength?.id!)
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            if (
                e.petType?.id === props.vPConst.DOGS &&
                props.priceTypeSelected === props.vPConst.BY_PET_SIZE
            ) {
                if (filterParams.dogsSizes?.includes(e.petSize?.id!)) {
                    return true;
                } else {
                    return false;
                }
            }
            if (e.petType?.id === props.vPConst.CATS) {
                if (
                    filterParams.catsSizes.includes(e.petSize?.id!) &&
                    filterParams.catsHairLengths?.includes(e.hairLength?.id!)
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        });

        return filteredPrices;
    };

    const pricesObjectTransformer = (
        prices: Array<VariablePriceOption>,
        petType: string
    ): Array<UnifiedPriceObject> => {
        const validatedPrices = validatePrices(prices);
        const filteredPrices = filterPrices(validatedPrices);
        const newPriceObjects: Array<UnifiedPriceObject> = [];
        const newPriceObject: UnifiedPriceObject = {
            hairLengths: [],
            petSize: undefined,
            petType: undefined,
            prices: []
        };
        let hairLengthCounter = 0;
        let maxHairLengthCounter = 0;
        if (petType === props.vPConst.DOGS)
            maxHairLengthCounter =
                marketplace.petTypes.dogs!.hairLengths.length - 1;
        if (petType === props.vPConst.CATS)
            maxHairLengthCounter =
                marketplace.petTypes.cats!.hairLengths.length - 1;

        const setNewPriceObject = (
            variablePriceOption: VariablePriceOption
        ) => {
            if (variablePriceOption.hairLength)
                newPriceObject.hairLengths.push(variablePriceOption.hairLength);
            if (variablePriceOption.petSize)
                newPriceObject.petSize = variablePriceOption.petSize;
            if (variablePriceOption.petType)
                newPriceObject.petType = variablePriceOption.petType;
            if (variablePriceOption)
                newPriceObject.prices.push(variablePriceOption);
        };

        filteredPrices.map((variablePriceOption, index) => {
            if (hairLengthCounter <= maxHairLengthCounter) {
                setNewPriceObject(variablePriceOption);
                if (hairLengthCounter === maxHairLengthCounter)
                    newPriceObjects.push(
                        JSON.parse(JSON.stringify(newPriceObject))
                    );
                hairLengthCounter++;
            } else {
                hairLengthCounter = 0;
                newPriceObject.hairLengths = [];
                newPriceObject.petSize = undefined;
                newPriceObject.petType = undefined;
                newPriceObject.prices = [];
                setNewPriceObject(variablePriceOption);
                if (hairLengthCounter === maxHairLengthCounter)
                    newPriceObjects.push(
                        JSON.parse(JSON.stringify(newPriceObject))
                    );
                hairLengthCounter++;
            }
        });
        return newPriceObjects;
    };

    useEffect(() => {
        if (props.serviceVariablePrice !== undefined) {
            if (
                props.priceTypeSelected ===
                    props.vPConst.BY_PET_SIZE_AND_HAIR_LENGTH ||
                (props.priceTypeSelected === props.vPConst.BY_HAIR_LENGTH &&
                    props.serviceVariablePrice.prices.length > 0)
            ) {
                if (
                    unifiedRows.length === 0 ||
                    props.serviceVariablePrice.prices[0].petType!.id !==
                        unifiedRows[0].petType!.id
                ) {
                    setUnifiedRows(
                        pricesObjectTransformer(
                            props.serviceVariablePrice.prices,
                            props.petType
                        )
                    );
                }
            }
        }
    }, [props.serviceVariablePrice]);

    const setTitle = (petType: string) => {
        if (props.isMultiplePet === false) {
            let type = '';
            petType === props.vPConst.DOGS ? (type = 'Dogs') : (type = 'Cats');
            return (
                <Box className={classes.petBox}>
                    <Box className={classes.imageBox}>
                        <Box
                            className={clsx(
                                classes.emptyImage,
                                {
                                    [classes.bgCat]:
                                        petType === props.vPConst.CATS
                                },
                                {
                                    [classes.bgDog]:
                                        petType === props.vPConst.DOGS
                                }
                            )}
                        >
                            <FontAwesomeIcon
                                icon={
                                    petType === props.vPConst.DOGS
                                        ? faDog
                                        : petType === props.vPConst.CATS
                                        ? faCat
                                        : faNarwhal
                                }
                                className={clsx(
                                    classes.uploadIcon,
                                    {
                                        [classes.uploadCat]:
                                            petType === props.vPConst.CATS
                                    },
                                    {
                                        [classes.uploadDog]:
                                            petType === props.vPConst.DOGS
                                    }
                                )}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Typography className={classes.title}>
                            Pricing for {type}
                        </Typography>
                        {props.priceTypeSelected ===
                            props.vPConst.BY_PET_SIZE &&
                            props.petType === props.vPConst.CATS && (
                                <Typography className={classes.subtitle}>
                                    Cat prices are based on a single size.
                                </Typography>
                            )}
                    </Box>
                </Box>
            );
        } else {
            return (
                <Box className={classes.petBox}>
                    <Box className={classes.buttonsContainer}>
                        <Typography className={classes.headerLabel}>
                            Select Pet Type
                        </Typography>
                        <Button
                            style={{ textTransform: 'none' }}
                            onClick={() =>
                                props.changeSubPetSelected!(props.vPConst.DOGS)
                            }
                            className={clsx(
                                classes.buttonOption,
                                classes.dogOptionButton,
                                {
                                    [classes.subPetSelected]:
                                        props.subPetSelected ===
                                        props.vPConst.DOGS
                                }
                            )}
                            startIcon={<FontAwesomeIcon icon={faDog} />}
                        >
                            <Typography className={classes.buttonLabel}>
                                For Dogs
                            </Typography>
                        </Button>
                        <Button
                            style={{ textTransform: 'none' }}
                            onClick={() =>
                                props.changeSubPetSelected!(props.vPConst.CATS)
                            }
                            className={clsx(
                                classes.buttonOption,
                                classes.catOptionButton,
                                {
                                    [classes.subPetSelected]:
                                        props.subPetSelected ===
                                        props.vPConst.CATS
                                }
                            )}
                            startIcon={<FontAwesomeIcon icon={faCat} />}
                        >
                            <Typography className={classes.buttonLabel}>
                                For Cats
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            );
        }
    };

    return (
        <Box className={classes.container} id={props.id}>
            {setTitle(props.petType)}
            {props.priceTypeSelected === props.vPConst.BY_PET_SIZE &&
                props.petType === props.vPConst.DOGS && (
                    <Box className={classes.headerContainer}>
                        <NewGridHeader
                            variables={props.serviceVariablePrice.variables}
                            priceTypeSelected={props.priceTypeSelected}
                        />
                    </Box>
                )}
            <Box className={classes.rowsContainer}>
                {props.priceTypeSelected === props.vPConst.BY_PET_SIZE &&
                    props.petType === props.vPConst.DOGS &&
                    props.serviceVariablePrice.prices.length > 0 &&
                    filterPrices(props.serviceVariablePrice.prices).map(
                        (variablePriceOption, index) => (
                            <NewGridRow
                                errors={props.errors}
                                id={props.id + '_' + index}
                                key={variablePriceOption.uuid}
                                variablePriceOption={variablePriceOption}
                                priceTypeSelected={props.priceTypeSelected}
                                vPConst={props.vPConst}
                                petType={props.petType}
                                priceIndex={index}
                                onSaveRow={props.onChange}
                            />
                        )
                    )}
                {props.priceTypeSelected === props.vPConst.BY_PET_SIZE &&
                    props.petType === props.vPConst.CATS &&
                    props.serviceVariablePrice.prices.length > 0 && (
                        <NewGridRow
                            errors={props.errors}
                            id={props.id}
                            key={props.serviceVariablePrice.prices[0].uuid}
                            variablePriceOption={
                                props.serviceVariablePrice.prices[0]
                            }
                            priceTypeSelected={props.priceTypeSelected}
                            vPConst={props.vPConst}
                            petType={props.petType}
                            priceIndex={0}
                            onSaveRow={props.onChange}
                        />
                    )}
                {props.priceTypeSelected ===
                    props.vPConst.BY_PET_SIZE_AND_HAIR_LENGTH &&
                    props.petType === props.vPConst.DOGS &&
                    unifiedRows.length > 0 &&
                    unifiedRows.map((unifiedPriceOption, index) => (
                        <NewGridRow
                            errors={props.errors}
                            key={index}
                            id={props.id + '_' + index}
                            variablePriceOption={unifiedPriceOption.prices[0]}
                            unifiedPriceOption={unifiedPriceOption}
                            priceTypeSelected={props.priceTypeSelected}
                            vPConst={props.vPConst}
                            petType={props.petType}
                            priceIndex={index}
                            onSaveRow={props.onChange}
                        />
                    ))}
                {props.priceTypeSelected ===
                    props.vPConst.BY_PET_SIZE_AND_HAIR_LENGTH &&
                    props.petType === props.vPConst.CATS &&
                    unifiedRows.length > 0 &&
                    unifiedRows.map((unifiedPriceOption, index) => (
                        <NewGridRow
                            errors={props.errors}
                            key={index}
                            id={props.id + '_' + index}
                            variablePriceOption={unifiedPriceOption.prices[0]}
                            unifiedPriceOption={unifiedPriceOption}
                            priceTypeSelected={props.priceTypeSelected}
                            vPConst={props.vPConst}
                            petType={props.petType}
                            priceIndex={index}
                            onSaveRow={props.onChange}
                        />
                    ))}
                {props.priceTypeSelected === props.vPConst.BY_HAIR_LENGTH &&
                    props.petType === props.vPConst.CATS &&
                    unifiedRows.length > 0 &&
                    unifiedRows.map((unifiedPriceOption, index) => (
                        <NewGridRow
                            errors={props.errors}
                            key={index}
                            id={props.id + '_' + index}
                            variablePriceOption={unifiedPriceOption.prices[0]}
                            unifiedPriceOption={unifiedPriceOption}
                            priceTypeSelected={props.priceTypeSelected}
                            vPConst={props.vPConst}
                            petType={props.petType}
                            priceIndex={index}
                            onSaveRow={props.onChange}
                        />
                    ))}
            </Box>
        </Box>
    );
};

export default Grid;
