import React, { FunctionComponent, MouseEvent } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";
import { PetService } from "@spike/new-booking-model";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { PetsState } from "@spike/pets-action";
import { StaffChip } from "components/UI";
import Staff from "model/Staff";
import PetCard from "components/UI/PetCard";
import { Moment } from "moment-timezone";

interface AppointmentDataVaraible {
    service: PetServiceWrapper;
    staffId: number;
    date: Moment;
}

interface AppointmentMultiple {
    clientId: number;
    petId: number;
    createdByStaffId: number;
    notes?: string;
    variableData: Array<AppointmentDataVaraible>;
}

interface PetServiceWrapper extends PetService {
    duration?: Duration;
}

interface Props {
    appointment: AppointmentMultiple;
    onAddService: () => void;
    onShowMultipleServices: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            border: '1px solid #D4D4D4',
            borderRadius: 6,
            padding: 10,
            marginBottom: 20,
            [theme.breakpoints.only('sm')]: {
                cursor: 'pointer'
            },
            '&:hover': {
                backgroundColor: '#FBFBFB',
                cursor: 'pointer',
                borderLeft: '6px solid #000000'
            }
        },
        divide: {
            paddingTop: 10,
            borderTop: '1px solid #D4D4D4',
            paddingBottom: 10,
        },
        serviceStaffContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 10,
        },
        serviceName: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '14px',
            paddingBottom: 7
        },
        serviceDataContainer: {
            display: 'flex'
        },
        priceAndDuration: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '14px',
            color: '#7A7A7A',
            paddingRight: 8
        },
        addService: {
            cursor: 'pointer',
            color: '#92B4A7',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '27px',
            paddingTop: 10,
            borderTop: '1px solid #D4D4D4',
            [theme.breakpoints.only('sm')]: {
                display: 'none'
            }
        },
        spaceClock: {
            paddingRight: '3px !important'
        },
        spacing: {
            paddingBottom: 10,
        },
    })
);

export const AppointmentCard: FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    const petsState = useSelector<RootState, PetsState>(
        state => state.pets
    );

    const staffsState = useSelector<RootState, Array<Staff>>(
        state => state.staff.staff
    )

    const formatStaffToStaffMember = (staffId: number) => {
        const staffSelected = staffsState.find(staff => staff.id === staffId);
        return {
            id: staffSelected?.id!,
            firstName: staffSelected?.person.firstName || '',
            lastName: staffSelected?.person.lastName || '',
            avatar: staffSelected?.person.avatar || ''
        }
    };

    
    const getServicesTime = (serv: PetServiceWrapper) => {
        const timeInMinutes = serv.durationInMinutes;
        return timeInMinutes! / 60 > 1
            ? `${Math.trunc(timeInMinutes! / 60)}hrs ${timeInMinutes! % 60 !== 0 ? timeInMinutes! % 60 : ''
            }${timeInMinutes! % 60 !== 0 ? 'min' : ''}`
            : `${timeInMinutes! === 60 ? 60 : timeInMinutes! % 60}min`;
    };

    const handlerAddService = (e: MouseEvent) => {
        e.stopPropagation();
        props.onAddService();
    };

    return (
        <Box className={classes.container} onClick={() => props.onShowMultipleServices()}>
            <Box className={classes.spacing}>
                <PetCard 
                    pet={petsState.list.find(pet => pet.id === props.appointment.petId)}
                />
            </Box>
            {props.appointment.variableData?.map((data) => (
                <Box className={
                    clsx(classes.divide, classes.serviceStaffContainer)} 
                    key={data.service.service.id}
                >
                    <Box>
                        <Typography className={classes.serviceName}>
                            {data.service.service.name}
                        </Typography>
                        <Box className={classes.serviceDataContainer}>
                            <Typography className={classes.priceAndDuration}>
                                {`$${data.service.price}`}
                            </Typography>
                            <Box className={classes.serviceDataContainer}>
                                <FontAwesomeIcon icon={faClock} className={clsx(classes.priceAndDuration, classes.spaceClock)} />
                                <Typography className={classes.priceAndDuration}>
                                    {getServicesTime(data.service)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <StaffChip
                        staff={formatStaffToStaffMember(data.staffId)}
                    />
                </Box>
            ))}
            <Typography className={classes.addService} onClick={handlerAddService}>
                + Add Service
            </Typography>
        </Box>
    )
};

export default AppointmentCard