import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { Option } from "@spike/model";
import { useMasterData } from "hooks";
import { FunctionComponent } from "react";
import PaymentMethod from "./PaymentMethod";
import Section from "../Section";
import { getPaymentMethods } from "@spike/payments-model";

interface PaymentMethodsProps {
  selectedId: string | null;
  paymentsEnabled: boolean;
  isFreeServicesInvoice?: boolean;
  className?: string;
  disabled?: boolean;
  onSelect: (paymentMethod: Option<string>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  })
);

export const PaymentMethods: FunctionComponent<PaymentMethodsProps> = (props) => {
  const classes = useStyles();

  const masterData = useMasterData();

  const paymentMethods = getPaymentMethods(masterData, props.paymentsEnabled);

  return (
    <Section title="Payment Method" className={props.className}>
      <Grid container spacing={2}>
        {paymentMethods.map((paymentMethod) => (
          <Grid item xs={6} sm={4} md={3} className={classes.cell} key={paymentMethod.id}>
            <PaymentMethod
              {...paymentMethod}
              onClick={() => props.onSelect(paymentMethod)}
              selected={props.selectedId === paymentMethod.id}
              disabled={(paymentMethod.id === 'pos' && props.isFreeServicesInvoice) ? true : props.disabled}
            />
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default PaymentMethods;
