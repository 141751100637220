import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { WizardFooter, WizardHeader, WizardItem } from 'components/Wizard';
import {
    Box,
    CircularProgress,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import PreviewMessage from 'components/SMS/SMSCreateCampaign/ui/PreviewMessage';
import SMSCreateFirstStep from 'components/SMS/SMSCreateCampaign/SMSCreateFirstStep';
import SMSCreateSecondStep, {
    Recipients
} from 'components/SMS/SMSCreateCampaign/SMSCreateSecondStep';
import SMSCreateThirdStep from 'components/SMS/SMSCreateCampaign/SMSCreateThirdStep';
import { ConfirmDialog } from 'components/UI';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showErrorThunk, showSuccessThunk } from '@spike/notifications-action';
import {
    decreaseStepThunk,
    deleteCampaignThunk,
    quoteSMSCampaignThunk,
    resetSaveFormThunk,
    saveCampaignThunk,
    setFilterThunk,
    updateSMSCampaignCustomersThunk
} from 'actions/smsCampaigns/SMSCampaignsActions';
import SMSCampaign, {
    SMSCampaignCustomerGroupOption,
    SMSCampaignCustomerGroups,
    SMSCampaignStatus
} from 'model/SMSCampaign';
import { RootState } from 'store';
import { SMSCampaignsStatus } from 'reducers/smsCampaigns/SMSCampaignsState';
import clsx from 'clsx';

const headerItems: Array<WizardItem> = [
    {
        title: 'Content'
    },
    {
        title: 'Recipients'
    },
    {
        title: 'Review & Send'
    }
];

interface Errors {
    name: boolean;
    message: boolean;
    unsubscribe: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'width': '100%',
            'height': '100vh',
            'display': 'flex',
            'flexFlow': 'column',
            'overflow': 'hidden',
            '& > div:first-child': {
                flex: '0 0 66px'
            },
            '&.disableNext': {
                "& [class*='nextBtn']": {
                    opacity: 0.4,
                    pointerEvents: 'none'
                }
            }
        },
        grid: {
            'flex': 1,
            'overflow': 'hidden',
            '& > div': {
                'height': '100%',
                'display': 'flex',
                'flexFlow': 'column',
                'overflowY': 'auto',
                '&:first-child': {
                    borderRight: '1px solid #D4D4D4'
                },
                '&:last-child': {
                    padding: '30px 0px',
                    backgroundColor: '#FAFAFA',
                    [theme.breakpoints.down('sm')]: {
                        background: '#ffffff',
                        padding: 0
                    }
                }
            },
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 30
            }
        },
        form: {
            'flex': 1,
            'overflowY': 'scroll',
            'padding': '50px 140px',
            '& + div': {
                flex: '0 0 95px'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '10px 50px'
            },
            [theme.breakpoints.down('xs')]: {
                padding: '30px 22px'
            }
        },
        loading: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        footer: {
            [theme.breakpoints.down('sm')]: {
                padding: '0px 22px'
            }
        }
    })
);

const SMSCampaignCreate = () => {
    const classes = useStyles();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const history = useHistory();
    const dispatch = useDispatch();

    const groups = useSelector((state: RootState) => state.smsCampaigns.groups);
    const step = useSelector<RootState, number>(
        state => state.smsCampaigns.step
    );
    const status = useSelector<RootState, SMSCampaignsStatus>(
        state => state.smsCampaigns.status
    );
    const created = useSelector<RootState, SMSCampaign | undefined>(
        state => state.smsCampaigns.created
    );

    const clients = {
        count: useSelector(
            (state: RootState) =>
                state.smsCampaigns.clients.all?.total_records || 0
        )
    };

    const arrayClient = useSelector(
        (state: RootState) => state.smsCampaigns.clients.all?.list
    );
    // Campaign state
    const [campaign, setCampaign] = useState<SMSCampaign>(
        created || {
            name: '',
            message: '',
            status_id: SMSCampaignStatus.draft,
            unsubscribe_message: 'Reply STOP to unsubscribe'
        }
    );

    const [deleting, setDeleting] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);

    // Second step data
    const [paid, setPaid] = useState<boolean>(false);
    const [recipients, setRecipients] = useState<string>('');
    const [group, setGroup] = useState<SMSCampaignCustomerGroups>();
    const [groupFilter, setGroupFilter] = useState<
        | SMSCampaignCustomerGroupOption[]
        | SMSCampaignCustomerGroupOption
        | undefined
    >();

    const [selectedClients, setSelectedClients] = useState<number[] | 'all'>(
        []
    );
    const [excludedClients, setExcludedClients] = useState<number[]>([]);
    const [idClientsSelected, setIdClientsSelected] = useState<number[]>([]);

    const selectedClientsCount = useMemo(() => {
        let clientsLength = 0;

        if (recipients === Recipients.ALL) {
            clientsLength =
                selectedClients === 'all'
                    ? clients.count
                    : selectedClients.length;
        }

        if (recipients === Recipients.GROUP) {
            const groupOpts = Object.values(SMSCampaignCustomerGroups);

            if (!groups || !group) return 0;

            if (
                !Array.isArray(groupFilter) &&
                [
                    SMSCampaignCustomerGroups.activity,
                    SMSCampaignCustomerGroups.date
                ].includes(group)
            ) {
                clientsLength =
                    groups[group].find(
                        (group: SMSCampaignCustomerGroupOption) => {
                            return group.days === groupFilter?.days;
                        }
                    )?.records || 0;
            }

            if (
                Array.isArray(groupFilter) &&
                [
                    SMSCampaignCustomerGroups.pet,
                    SMSCampaignCustomerGroups.tag
                ].includes(group)
            ) {
                clientsLength = groupFilter.reduce((count, item, index) => {
                    return count + item.records;
                }, 0);
            }
        }

        return clientsLength - excludedClients.length;
    }, [recipients, selectedClients, group, excludedClients, groupFilter]);

    useEffect(() => {
        const elements = arrayClient?.filter(
            e => !excludedClients.includes(e.id!)
        );
        const idClients: number[] = [];
        elements?.map(e => idClients.push(e.id!));
        setIdClientsSelected(idClients);
    }, [arrayClient, excludedClients]);
    // Form validations
    const [errors, setErrors] = useState<Errors>({
        name: false,
        message: false,
        unsubscribe: false
    });

    /**
     * Functions
     */
    const isValueInvalid = (value: string | undefined, min = 3): boolean => {
        if (!value) return true;

        return value.length < min;
    };

    const save = () => {
        if (!paid) {
            reset();
            dispatch(setFilterThunk(campaign.status_id));
            dispatch(showSuccessThunk('SMS Campaign saved as draft'));
            history.push(`/marketing?campaign_id=${campaign.id}`);
            return;
        }
        reset();
        dispatch(setFilterThunk(SMSCampaignStatus.scheduled));
        dispatch(showSuccessThunk('SMS Campaign scheduled successfully'));
        history.push(`/marketing?campaign_id=${campaign.id}`);
    };

    /**
     * Computed
     */
    const compoundMessage = () => {
        return `${campaign.message} \n\n${campaign.unsubscribe_message}`;
    };

    /**
     * Events
     */
    const firstStepChange = (values: {
        name: string;
        message: string;
        unsubscribe: string;
    }) => {
        setCampaign({
            ...campaign,
            name: values.name,
            message: values.message,
            unsubscribe_message: values.unsubscribe
        });

        setErrors({
            ...errors,
            name: isValueInvalid(values.name),
            message: isValueInvalid(values.message, 1),
            unsubscribe: isValueInvalid(values.unsubscribe)
        });
    };

    const secondStepChange = (values: {
        recipients: string;
        group?: SMSCampaignCustomerGroups;
        filter?:
        | SMSCampaignCustomerGroupOption[]
        | SMSCampaignCustomerGroupOption;
        selectedClients: number[] | 'all';
        excludedClients: number[];
    }) => {
        setGroup(values.group);
        setGroupFilter(values.filter);
        setRecipients(values.recipients);

        const recipientsChanged = recipients !== values.recipients;

        setSelectedClients(
            recipientsChanged
                ? values.selectedClients === 'all'
                    ? 'all'
                    : []
                : values.selectedClients
        );
        setExcludedClients(recipientsChanged ? [] : values.excludedClients);
    };

    const backStep = () => dispatch(decreaseStepThunk());

    const nextStep = () => {
        if (
            step === 1 &&
            (errors.name || errors.message || errors.unsubscribe)
        ) {
            dispatch(showErrorThunk('Fill out all the fields'));
            return;
        }

        if (step === 2) {
            if (!recipients) {
                dispatch(showErrorThunk('Choose the recipients'));
                return;
            }

            if (
                (recipients === Recipients.GROUP &&
                    selectedClientsCount === 0) ||
                (recipients === Recipients.ALL && selectedClients?.length === 0)
            ) {
                dispatch(
                    showErrorThunk(
                        'The campaign must have at least one subscriber'
                    )
                );
                return;
            }
        }

        if (step === 1) saveCampaign();
        if (step === 2) setCustomers();
    };

    const onClose = () => {
        // Si el usuario pagó mostrar mensaje y redireccionar
        if (paid) {
            save();
            return;
        }

        // Si la campaña no existe o está siendo editada salir directamente
        if (!created?.id || created?.editing) {
            // Restablecer valores del formulario
            reset();

            history.push('/marketing');
            return;
        }

        setDeleting(true);
        return false;
    };

    const confirmExit = async () => {
        setProcessing(true);

        // Eliminar campaña si tiene ID (está creada) y no está siendo editada
        await deleteCampaign();

        setTimeout(() => {
            reset();
            setDeleting(false);
            history.push('/marketing');
        }, 500);
    };

    /**
     * Dispatch actions
     */
    const reset = () => dispatch(resetSaveFormThunk());

    const deleteCampaign = () => {
        if (created?.id && !created?.editing) {
            dispatch(deleteCampaignThunk(created.id));
        }
    };

    const saveCampaign = () => {
        campaign.customer_ids =
            idClientsSelected.length > 0 && campaign.id
                ? idClientsSelected
                : [];
        dispatch(saveCampaignThunk(campaign));
    };

    const setCustomers = async () => {
        const args = Array.isArray(groupFilter)
            ? groupFilter.map(opt => opt.tag || opt.pet_type).join(',')
            : groupFilter?.days;

        await dispatch(
            updateSMSCampaignCustomersThunk(
                campaign,
                recipients === Recipients.ALL
                    ? {
                        recipients: 'all',
                        selected_ids: selectedClients,
                        excluded_ids: excludedClients
                    }
                    : {
                        recipients: 'criteria',
                        criteria: group,
                        args
                    }
            )
        );
    };

    /**
     * Effects
     */
    useEffect(() => {
        if (created) setCampaign(created);
    }, [created]);

    useEffect(() => {
        dispatch(quoteSMSCampaignThunk(selectedClientsCount));
    }, [selectedClientsCount]);

    return (
        <>
            <Box
                className={clsx(classes.root, {
                    disableBack: paid,
                    hideNext: paid
                })}
            >
                <ConfirmDialog
                    open={deleting}
                    processing={processing}
                    title={<Typography>Exit Form</Typography>}
                    question={
                        <Typography>Are you sure you want to exit?</Typography>
                    }
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel={`Exit & Delete`}
                    onCancel={() => setDeleting(false)}
                    onConfirm={confirmExit}
                />

                <WizardHeader
                    step={step}
                    title="New Campaign"
                    closePath=""
                    items={headerItems}
                    onClickClosePath={onClose}
                />

                <Grid container className={classes.grid}>
                    <Grid item xs={12} md={7}>
                        {status === SMSCampaignsStatus.Saving && (
                            <Box className={classes.loading}>
                                <CircularProgress />
                            </Box>
                        )}

                        {status !== SMSCampaignsStatus.Saving && (
                            <Fragment>
                                <Box className={classes.form}>
                                    {step === 1 && (
                                        <SMSCreateFirstStep
                                            onChange={firstStepChange}
                                            values={{
                                                name: campaign.name,
                                                message: campaign.message,
                                                unsubscribe:
                                                    campaign.unsubscribe_message
                                            }}
                                            errors={{
                                                name: errors.name,
                                                message: errors.message,
                                                unsubscribe: errors.unsubscribe
                                            }}
                                        />
                                    )}

                                    {step === 2 && (
                                        <SMSCreateSecondStep
                                            onChange={secondStepChange}
                                            selectedCount={selectedClientsCount}
                                            values={{
                                                recipients,
                                                group,
                                                filter: groupFilter,
                                                selectedClients,
                                                excludedClients
                                            }}
                                        />
                                    )}

                                    {step === 3 && (
                                        <SMSCreateThirdStep
                                            moreThanOneRecepient={selectedClientsCount > 1}
                                            onPaid={() => setPaid(true)}
                                        />
                                    )}
                                </Box>

                                {!paid && (
                                    <WizardFooter
                                        className={classes.footer}
                                        onSave={save}
                                        onBack={backStep}
                                        onNext={nextStep}
                                        showBack={step > 1}
                                        showSave={step === headerItems.length}
                                        saveLabel="Save as Draft"
                                    />
                                )}
                            </Fragment>
                        )}
                    </Grid>
                    {!isMobile && (
                        <Grid item xs={5}>
                            <PreviewMessage message={compoundMessage()} />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </>
    );
};

export default SMSCampaignCreate;
