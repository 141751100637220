import React, { FunctionComponent } from "react";
import { faBan } from "@fortawesome/pro-light-svg-icons";
import Typography from "@material-ui/core/Typography";
import ToolbarItemWithConfirmation from "./ToolbarItemWithConfirmation";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export interface Props {
  isDetail?: boolean;
  onConfirmedClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textDialog: {
      textAlign: "center",
      [theme.breakpoints.down("lg")]: {
        fontSize: "15px",
      },
    }
  })
);

export const NoShowToolbarItem: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <ToolbarItemWithConfirmation
      isDetail={props.isDetail}
      icon={faBan}
      text={"No Show"}
      onConfirmedClick={props.onConfirmedClick}
      confirmQuestion={
        <>
          <Typography className={classes.textDialog}>
            Are you sure you want to change the status
          </Typography>
          <Typography className={classes.textDialog}>
            of this appointment to <strong>No Show</strong>?
          </Typography>
        </>
      }
      confirmButtonLabel="Yes"
      cancelButtonLabel="No"
    />
  );
};

export default NoShowToolbarItem;
