import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent, useEffect, useState } from 'react';
import { reduceResolution, wbp } from 'Theme';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import moment from 'moment-timezone';
import DetailTableDashBoard from 'components/UI/DetailTableDashboard/DetailTableDashboard';
import { CommissionsStatus } from '@spike/commissions-action/lib/esm/reducer/CommissionsState';
import {
    CommissionsReport,
    SaleCommissionsReport
} from '@spike/commission-model';
import { getReportThunk } from '@spike/commissions-action';
import { useApiClientWrapper } from 'hooks';
import { Option, Period } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import TextOverflow from 'components/UI/TextOverflow';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(24)}px ${reduceResolution(24)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '24px 24px'
            }
        }
    })
);

export const CommissionsDetail: FunctionComponent = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const commissionStatus = useSelector<RootState, CommissionsStatus>(
        state => state.commissions.status
    );

    const commissionDetail = useSelector<
        RootState,
        CommissionsReport | undefined
    >(state => state.commissions.report);

    const totals = [
        {
            id: commissionDetail?.bookingCount ?? 0,
            description: 'Total Appointments',
            name: 'appointments'
        },
        {
            id: commissionDetail?.totalCommissions
                ? '$' +
                  (Number(commissionDetail?.totalCommissions) === 0
                      ? 0
                      : Number(commissionDetail?.totalCommissions).toFixed(2))
                : '$0',
            description: 'Total Commissions',
            name: 'commissions'
        }
    ];

    const [period, setPeriod] = useState<Period>({
        from: moment().startOf('month'),
        to: moment().endOf('month')
    });

    const [csvFileName, setCsvFileName] = useState<string>(
        `commission_report_${period.from.format('YYYY-MM-DD') || 'all'}_${
            period.to.format('YYYY-MM-DD') || 'time'
        }.csv`
    );

    const [staffsToFilter, setStaffsToFilter] = useState<Array<number>>([]);

    const headers = [
        { value: 'Date', numeric: false },
        { value: 'Invoice', numeric: false },
        { value: 'Customer', numeric: false },
        { value: 'Staff', numeric: false },
        { value: 'Service', numeric: false },
        { value: 'Price', numeric: true },
        { value: '%', numeric: true },
        { value: 'Commission', numeric: true }
    ];

    const rows = commissionDetail?.sales
        ? commissionDetail.sales.map(sale => [
              { value: sale.saleDate.format('MM/DD/YYYY'), numeric: false },
              { value: String(sale.invoice.number), numeric: false },
              {
                  value: <TextOverflow text={sale.customerName} length={16} />,
                  numeric: false
              },
              {
                  value: (
                      <TextOverflow
                          text={
                              sale.staff.firstName + ' ' + sale.staff.lastName
                          }
                          length={16}
                      />
                  ),
                  numeric: false
              },
              {
                  value: <TextOverflow text={sale.description} length={16} />,
                  numeric: false
              },
              { value: '$' + Number(sale.amount).toFixed(2), numeric: true },
              {
                  value: Number(sale.commissionRate * 100).toFixed(2) + '%',
                  numeric: true
              },
              {
                  value: '$' + Number(sale.commissionAmount).toFixed(2),
                  numeric: true
              }
          ])
        : [[{ value: '' }]];

    const csvHeaders = [
        { label: 'Date', key: 'date' },
        { label: 'Invoice', key: 'invoice' },
        { label: 'Customer', key: 'customerName' },
        { label: 'Staff', key: 'staff' },
        { label: 'Service', key: 'service' },
        { label: 'Price', key: 'price' },
        { label: 'Rate', key: 'rate' },
        { label: 'Commission', key: 'commission' }
    ];

    const csvData = commissionDetail?.sales
        ? commissionDetail.sales.map(sale => ({
              date: sale.saleDate.format('MM/DD/YYYY'),
              invoice: String(sale.invoice.number),
              customerName: String(sale.customerName).replace(/,/g, ' '),
              staff: `${String(sale.staff.firstName).replace(
                  /,/g,
                  ' '
              )} ${String(sale.staff.lastName).replace(/,/g, ' ')}`,
              service: String(sale.description).replace(/,/g, ' '),
              price:
                  '$' +
                  (Number(sale.amount) === 0
                      ? '0'
                      : Number(sale.amount).toFixed(2)),
              rate:
                  (Number(sale.commissionRate).toFixed(0) ===
                  String(sale.commissionRate)
                      ? Number(sale.commissionRate * 100).toFixed(0)
                      : Number(sale.commissionRate * 100).toFixed(2)) + '%',
              commission:
                  '$' +
                  (Number(sale.commissionAmount) === 0
                      ? '0'
                      : Number(sale.commissionAmount).toFixed(2))
          }))
        : [{}];

    const getStaffFormatted = (
        sales: SaleCommissionsReport[]
    ): Array<Option<number>> => {
        const staffAux: Array<Option<number>> = [];

        sales.forEach(sale => {
            const staffInList = staffAux.find(s => s.id === sale.staff.id);

            if (
                !staffInList &&
                sale.staff.id != 0 &&
                sale.staff.firstName.length > 0
            ) {
                const itemStaff: Option<number> = {
                    id: sale.staff.id,
                    name: sale.staff.firstName + ' ' + sale.staff.lastName,
                    description:
                        sale.staff.firstName + ' ' + sale.staff.lastName
                };

                staffAux.push(itemStaff);
            }
        });

        return staffAux;
    };

    const [staffOptions, setStaffOptions] = useState<Array<Option<number>>>(
        commissionDetail?.sales
            ? getStaffFormatted(commissionDetail?.sales)
            : []
    );

    useEffect(() => {
        dispatch(
            getReportThunk(
                apiClientWrapper,
                period.from,
                period.to,
                staffsToFilter
            )
        );
    }, [period, staffsToFilter]);

    useNonInitialEffect(() => {
        if (commissionStatus === CommissionsStatus.GetReportSuccess) {
            if (staffsToFilter.length === 0) {
                setStaffOptions(
                    commissionDetail?.sales
                        ? getStaffFormatted(commissionDetail?.sales)
                        : []
                );
            }
        }
    }, [commissionStatus]);

    const handleSelectStaff = (staffs: Array<number>, all: boolean) => {
        if (all === true) {
            setStaffsToFilter([]);
        } else {
            setStaffsToFilter(staffs);
        }
    };

    const handleChangePeriod = (dateFilter: Period) => {
        setPeriod({
            from: dateFilter.from,
            to: dateFilter.to
        });
        setCsvFileName(
            `commission_report_${
                dateFilter.from.format('YYYY-MM-DD') || 'all'
            }_${dateFilter.to.format('YYYY-MM-DD') || 'time'}.csv`
        );
    };

    return (
        <Box className={clsx(classes.container)}>
            <DetailTableDashBoard
                title={'Commissions Detail'}
                period={period}
                totals={totals}
                staffOptions={staffOptions}
                headers={headers}
                rows={rows}
                loading={commissionStatus === CommissionsStatus.GettingReport}
                export={true}
                csv={{
                    data: csvData,
                    headers: csvHeaders,
                    fileName: csvFileName
                }}
                onChangePeriod={period => handleChangePeriod(period)}
                onStaffChange={handleSelectStaff}
            ></DetailTableDashBoard>
        </Box>
    );
};

export default CommissionsDetail;
