import { Box, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import {
    deleteOrderThunk,
    completeTerminalOrderThunk,
    fetchTerminalsOrderThunk,
    saveTerminalOrderThunk
} from '@spike/terminals-action';
import { OverFullWindowStepsOrder, Spinner } from 'components/UI';
import { useApiClientWrapper, useMarketplace, useMasterData } from 'hooks';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import { StepsHeaderOrder } from '@spike/masterdata-model';
import {
    statusOrder,
    TerminalModel,
    TerminalOrder
} from '@spike/terminal-model';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TerminalsStatus } from '@spike/terminals-action';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';
import { isEmailValid, validatePhoneNumber } from '@spike/validations';
import { v4 as uuid } from 'uuid';
import { OrderCheckOut } from './OrderCheckOut';
import { OrderContact } from './OrderContact';
import { OrderFinish } from './OrderFinish';
import { OrderPaymentMethod } from './OrderPaymentMethod';
import { OrderSelectTerminals } from './OrderSelectTerminals';
import { OrderShippingInfo } from './OrderShippingInfo';
import { OrderShipTo } from './OrderShipTo';
interface OrderProps {
    order?: TerminalOrder;
    className?: string;
    //
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
        },

        boxSpinner: {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            width: '100%'
        },

        gridContact: {
            background: '#FBFBFB'
        },

        gridContactFirst: {
            background: '#FBFBFB',
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(35)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '35px'
            },
            height: '100%',
            overflowY: 'auto'
        },

        boxContainer: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '20px'
            }
        },

        boxCheckOut: {
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(14)}px ${reduceResolution(35)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '14px 35px'
            }
        },

        boxTitleCheckOut: {
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(14)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '14px'
            }
        }
    })
);

export const Order: FunctionComponent<OrderProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const marketplace = useMarketplace();
    const apiClientWrapper = useApiClientWrapper();

    const masterData = useMasterData();
    const modelsTerminal: Array<TerminalModel> = masterData.terminalsModels;

    const orderStatus = useSelector<RootState, TerminalsStatus>(
        state => state.terminals.status
    );
    const terminalOrder = useSelector<RootState, TerminalOrder | undefined>(
        state => state.terminals.order
    );

    const createEmptyOrder = (): TerminalOrder => {
        return {
            uuid: uuid(),
            price: 0,
            status: statusOrder.NEW,

            contactBusinessName: marketplace.basics.businessName,
            contactName: marketplace.basics.businessName,
            contactPhone: marketplace.basics.phone,
            contactEmail: marketplace.basics.email,

            deliveryAddress: marketplace.basics.address.street,
            deliverySuite: marketplace.basics.address.suite,
            deliveryCity: marketplace.basics.address.city,
            deliveryState: marketplace.basics.address.state,
            deliveryZipcode: marketplace.basics.address.zipcode,
            deliveryCountry: 'US',

            terminals: modelsTerminal.map(model => {
                const terminal = {
                    id: model.id,
                    qty: 0,
                    price: 0,
                    marketplaceId: marketplace.id,
                    addressId: marketplace.basics.address.id as number,
                    name: uuid(),
                    uuid: uuid(),
                    status: statusOrder.NEW
                };
                return terminal;
            })
        };
    };

    const createExistOrder = (): TerminalOrder => {
        return {
            id: props.order!.id,
            uuid: props.order!.uuid,
            price: props.order!.price,
            status: props.order!.status,

            contactBusinessName: marketplace.basics.businessName,
            contactName: marketplace.basics.businessName,
            contactPhone: marketplace.basics.phone,
            contactEmail: marketplace.basics.email,

            deliveryAddress: props.order?.deliveryAddress,
            deliverySuite: props.order?.deliverySuite,
            deliveryCity: props.order?.deliveryCity,
            deliveryState: props.order?.deliveryState,
            deliveryZipcode: props.order?.deliveryZipcode,
            deliveryCountry: 'US',
            deliveryName: props.order?.deliveryName,
            deliveryPhone: props.order?.deliveryPhone,

            terminals: modelsTerminal.map(model => {
                let terminal = props.order?.terminals.find(
                    terminal =>
                        terminal.id.toUpperCase() === model.id.toUpperCase()
                );

                if (terminal) {
                    terminal = {
                        id: terminal.id,
                        qty: terminal.qty,
                        price: terminal.price,
                        marketplaceId: marketplace.id,
                        addressId: marketplace.basics.address.id as number,
                        name: terminal.name,
                        uuid: terminal.uuid,
                        status: terminal.status
                    };
                }

                if (!terminal) {
                    terminal = {
                        id: model.id,
                        qty: 0,
                        price: 0,
                        marketplaceId: marketplace.id,
                        addressId: marketplace.basics.address.id as number,
                        name: uuid(),
                        uuid: uuid(),
                        status: statusOrder.NEW
                    };
                }

                return terminal;
            })
        };
    };

    const createOptionsSteps: Array<StepsHeaderOrder> = [
        {
            id: 'terminals',
            name: 'Terminals',
            active: props.order ? false : true,
            order: 1,
            finished: false
        },
        {
            id: 'information',
            name: 'Information',
            active: false,
            order: 2,
            finished: false
        },
        {
            id: 'payment',
            name: 'Payment',
            active: props.order ? true : false,
            order: 3,
            finished: false
        }
    ];

    const [buttonLoading, setButtonLoading] = useState(false);
    const [editedOrder, setEditedOrder] = useState<TerminalOrder | undefined>(
        props.order ? createExistOrder() : createEmptyOrder()
    );
    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [finishDisabled, setFinishDisabled] = useState(true);
    const [invoiceId, setInvoiceId] = useState<number>();
    const [orderId, setOrderId] = useState<number | undefined>(
        props.order ? props.order.id : undefined
    );
    const [optionsSteps, setOptionSteps] =
        useState<Array<StepsHeaderOrder>>(createOptionsSteps);
    const [showCheckOut, setShowCheckOut] = useState(true);
    const [showFinishStep, setShowFinishStep] = useState(false);

    useNonInitialEffect(() => {
        switch (orderStatus) {
            case TerminalsStatus.SaveTerminalOrderSuccess:
                const updateOption = optionsSteps;
                const optionActual = updateOption.find(
                    op => op.active === true
                );
                moveFowardStep(optionActual!, updateOption);

                setOrderId(terminalOrder?.id);
                setInvoiceId(terminalOrder?.adminInvoiceId!);
                break;

            case TerminalsStatus.CompleteTerminalOrderSuccess:
                setShowCheckOut(false);
                setShowFinishStep(true);
                dispatch(fetchTerminalsOrderThunk(apiClientWrapper));
                break;

            case TerminalsStatus.CancelTerminalOrderSuccess:
                dispatch(fetchTerminalsOrderThunk(apiClientWrapper));
                props.onClose && props.onClose();
                break;

            case TerminalsStatus.DeleteTerminalOrderSuccess:
                dispatch(fetchTerminalsOrderThunk(apiClientWrapper));
                props.onClose && props.onClose();
                break;

            case TerminalsStatus.Error:
                setButtonLoading(false);
                break;

            default:
                break;
        }
    }, [orderStatus]);

    const validateAddress = (order: TerminalOrder): Array<FieldError> => {
        const errors: Array<FieldError> = [];

        if (isEmpty(order.deliveryAddress)) {
            errors.push({
                fieldName: 'deliveryAddress',
                errorMessage: 'Street is required'
            });
        }

        if (isEmpty(order.deliveryAddress)) {
            errors.push({
                fieldName: 'deliverySuite',
                errorMessage: 'Street number is required'
            });
        }

        if (isEmpty(order.deliveryCity)) {
            errors.push({
                fieldName: 'deliveryCity',
                errorMessage: 'City is required'
            });
        }

        if (isEmpty(order.deliveryState)) {
            errors.push({
                fieldName: 'deliveryState',
                errorMessage: 'State is required'
            });
        }

        if (isEmpty(order.deliveryZipcode)) {
            errors.push({
                fieldName: 'deliveryZipcode',
                errorMessage: 'Zip code is required'
            });
        }

        if (
            !isEmpty(order.deliveryZipcode) &&
            order.deliveryZipcode &&
            order.deliveryZipcode.length !== 5
        ) {
            errors.push({
                fieldName: 'deliveryZipcode',
                errorMessage: 'Zip code length must be 5 digits.'
            });
        }

        return errors;
    };

    const validate = (order: TerminalOrder): Array<FieldError> => {
        const errors: Array<FieldError> = [];

        if (isEmpty(order.contactBusinessName)) {
            errors.push({
                fieldName: 'contactBusinessName',
                errorMessage: 'Business name is required'
            });
        }

        if (isEmpty(order.contactName)) {
            errors.push({
                fieldName: 'contactName',
                errorMessage: 'Name is required'
            });
        }

        if (isEmpty(order.contactPhone)) {
            errors.push({
                fieldName: 'contactPhone',
                errorMessage: 'Phone number is required'
            });
        }

        !isEmpty(order.contactPhone) &&
            errors.push(
                ...validatePhoneNumber(
                    order.contactPhone!,
                    marketplace.basics.address.country!.id,
                    'contactPhone'
                )
            );
        if (isEmpty(order.contactEmail)) {
            errors.push({
                fieldName: 'contactEmail',
                errorMessage: 'Email is required'
            });
        }

        if (!isEmpty(order.contactEmail) && !isEmailValid(order.contactEmail)) {
            errors.push({
                fieldName: 'contactEmail',
                errorMessage: 'Invalid email format.'
            });
        }

        if (isEmpty(order.deliveryName)) {
            errors.push({
                fieldName: 'deliveryName',
                errorMessage: 'Name is required'
            });
        }

        if (isEmpty(order.deliveryPhone)) {
            errors.push({
                fieldName: 'deliveryPhone',
                errorMessage: 'Phone number is required'
            });
        }

        !isEmpty(order.deliveryPhone) &&
            errors.push(
                ...validatePhoneNumber(
                    order.deliveryPhone!,
                    marketplace.basics.address.country!.id,
                    'deliveryPhone'
                )
            );

        const addressErrors = validateAddress(order);

        return [...errors, ...addressErrors];
    };

    const backHandler = () => {
        const updateOption = optionsSteps;
        const optionActual = updateOption.find(op => op.active === true);
        const prevOrder = optionActual!.order - 1;

        let updateOptionFilter = updateOption.filter(op => op.active !== true);
        optionActual!.active = false;
        updateOptionFilter.push(optionActual!);

        const optionPrev = updateOptionFilter.find(
            op => op.order === prevOrder
        );
        updateOptionFilter = updateOptionFilter.filter(
            op => op.order !== prevOrder
        );
        optionPrev!.active = true;
        optionPrev!.finished = false;
        updateOptionFilter.push(optionPrev!);

        setOptionSteps(updateOptionFilter);
    };

    const moveFowardStep = (
        optionActual: StepsHeaderOrder,
        updateOption: Array<StepsHeaderOrder>
    ) => {
        const nextOrder = optionActual!.order + 1;

        let updateOptionFilter = updateOption.filter(op => op.active !== true);
        optionActual!.active = false;
        optionActual!.finished = true;
        updateOptionFilter.push(optionActual!);

        const optionNext = updateOptionFilter.find(
            op => op.order === nextOrder
        );
        updateOptionFilter = updateOptionFilter.filter(
            op => op.order !== nextOrder
        );
        optionNext!.active = true;
        updateOptionFilter.push(optionNext!);

        setButtonLoading(false);
        setOptionSteps(updateOptionFilter);
    };

    const nextHandler = () => {
        const updateOption = optionsSteps;
        const optionActual = updateOption.find(op => op.active === true);

        let errors;

        switch (optionActual?.id) {
            case 'terminals':
                moveFowardStep(optionActual, updateOption);
                break;
            case 'information':
                errors = validate(editedOrder!);
                setErrors(errors);
                if (errors.length === 0) {
                    setButtonLoading(true);

                    invoiceId || editedOrder?.id
                        ? moveFowardStep(optionActual, updateOption)
                        : dispatch(
                              saveTerminalOrderThunk(
                                  apiClientWrapper,
                                  editedOrder!
                              )
                          );
                }
                break;
            case 'payment':
                dispatch(
                    completeTerminalOrderThunk(apiClientWrapper, orderId!)
                );
                setButtonLoading(true);
                break;
        }
    };

    const refreshSubtotal = (order: TerminalOrder) => {
        let subtotal = 0;
        let terminal: TerminalModel | undefined;

        order.terminals.map(model => {
            terminal = modelsTerminal.find(
                m => m.id.toUpperCase() === model.id.toUpperCase()
            );
            subtotal = subtotal + model.qty * Number(terminal!.price);
            return '';
        });

        return subtotal;
    };

    const updateTerminalHandler = (terminalOrder: TerminalOrder) => {
        const subtotal = refreshSubtotal(terminalOrder);

        const updatedOrder = { ...terminalOrder };
        set(updatedOrder, 'price', subtotal);

        updateOrderHandler(updatedOrder);
    };

    const updateOrderHandler = (terminalOrder: TerminalOrder) => {
        setEditedOrder(terminalOrder);
    };

    const completePaymentHandler = () => {
        setFinishDisabled(false);
    };

    const errorPaymentHandler = () => {
        setFinishDisabled(true);
    };

    const deleteOrderHandler = () => {
        dispatch(deleteOrderThunk(apiClientWrapper, orderId!));
    };

    const onCloseHandler = () => {
        optionsSteps.find(option => option.id === 'payment')?.active
            ? deleteOrderHandler()
            : props.onClose && props.onClose();
    };
    //

    const loadingView = (
        <Box className={classes.boxSpinner}>
            <Spinner />
        </Box>
    );

    const stepTeminalView = (
        <>
            {editedOrder ? (
                <Box className={classes.boxContainer}>
                    <OrderSelectTerminals
                        order={editedOrder}
                        modelsTerminal={modelsTerminal}
                        //
                        onChange={updateTerminalHandler}
                    />
                </Box>
            ) : (
                loadingView
            )}
        </>
    );

    const stepContactView = (
        <>
            {editedOrder ? (
                <>
                    <Box className={classes.boxContainer}>
                        <OrderContact
                            order={editedOrder}
                            errors={errors}
                            //
                            onChange={updateOrderHandler}
                        />
                    </Box>
                    <Box className={classes.boxContainer}>
                        <OrderShippingInfo
                            order={editedOrder}
                            errors={errors}
                            //
                            onChange={updateOrderHandler}
                        />
                    </Box>
                    <Box className={classes.boxContainer}>
                        <OrderShipTo
                            order={editedOrder}
                            errors={errors}
                            //
                            onChange={updateOrderHandler}
                        />
                    </Box>
                </>
            ) : (
                loadingView
            )}
        </>
    );

    const stepPaymentView = (
        <OrderPaymentMethod
            invoiceId={invoiceId!}
            //
            onComplete={completePaymentHandler}
            onError={errorPaymentHandler}
        />
    );

    const orderView = (
        <Grid container>
            <Grid item xs={1} className={classes.gridContact}></Grid>
            <Grid item xs={6} className={classes.gridContactFirst}>
                <Grid container>
                    <Grid item xs={10}>
                        {optionsSteps.find(option => option.id === 'terminals')
                            ?.active && stepTeminalView}
                        {optionsSteps.find(
                            option => option.id === 'information'
                        )?.active && stepContactView}
                        {optionsSteps.find(option => option.id === 'payment')
                            ?.active && stepPaymentView}
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <OrderCheckOut
                    order={editedOrder!}
                    modelsTerminal={modelsTerminal}
                    optionsSteps={optionsSteps}
                    loading={buttonLoading}
                    finishDisabled={finishDisabled}
                    //
                    onNext={nextHandler}
                />
            </Grid>
        </Grid>
    );

    const finishView = (
        <OrderFinish onClick={() => props.onClose && props.onClose()} />
    );

    return (
        <Box className={props.className}>
            <OverFullWindowStepsOrder
                title="Order Terminal"
                optionsSteps={optionsSteps}
                showBackClose={finishDisabled}
                //
                onBack={backHandler}
                onClose={onCloseHandler}
            >
                {showCheckOut && editedOrder && orderView}
                {showFinishStep && finishView}
            </OverFullWindowStepsOrder>
        </Box>
    );
};

export default Order;
