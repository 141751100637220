import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import { setNotificationBellAmount } from 'actions/notificationBell/NotificationBellActions';
import {
    onboardingCloseModal,
    onboardingOpenModal
} from 'actions/onboardingSteps/OnboardingStepsActions';
import { fetchThunk as fetchNotificationThunk } from 'actions/userNotifications/userNotificationActions';
import GlobalSearch from 'components/GlobalSearch';
import Notifications from 'components/Notifications';
import OnboardingSteps from 'components/OnboardingSteps';
import MessageIcon from 'components/UI/MessagesIcon';
import NotificationBell from 'components/UI/NotificationBell';
import { OnboardingSteps as OnboardingStepsModel } from 'model/OnboardingSteps';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationBellState } from 'reducers/notificationBell/NotificationBellState';
import { OnboardingStepsStatus } from 'reducers/onboardingSettings/onboardingStepsState';
import { UserNotificationsStatus } from 'reducers/userNotifications/UserNotificationsState';
import { RootState } from 'store';
import { headerHeight } from 'Theme';
import DateTime from './DateTime';
import ProfileDropdown from './ProfileDropdown';
import { Toggler } from './Toggler';
import { useMenu } from 'hooks/useMenu';
import { closeMenu, openMenu } from 'actions/menu/MenuActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

interface SignedInHeaderProps {
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            gap: 20,
            width: '100%',
            display: 'flex',
            height: headerHeight,
            backgroundColor: 'white',
            padding: '0px 10px 0px 16px',
            justifyContent: 'space-between',
            borderBottom: '1px solid #DDD',

            // Tablet
            [theme.breakpoints.up('sm')]: {
                padding: '0px 24px'
            },
            // Desktop
            [theme.breakpoints.up('lg')]: {
                padding: '0px 24px'
            }
        },

        left: {
            gap: 12,
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            maxWidth: 530
        },
        right: {
            gap: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',

            [theme.breakpoints.up('md')]: {
                gap: 15
            }
        },

        dateTime: {
            color: '#222',
            width: '100%',
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '24px',
            whiteSpace: 'nowrap',
            fontFamily: 'Poppins',

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px',
                lineHeight: '30px'
            }
        },

        closeIcon: {
            'border': 0,
            'width': 40,
            'height': 40,
            'fontSize': 20,
            'flex': '0 0 40px',
            'cursor': 'pointer',
            'position': 'relative',
            'borderRadius': '50px',
            'display': 'inline-flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'backgroundColor': 'transparent',
            'transition': 'background-color 0.2s ease-out',

            '&:hover': {
                'backgroundColor': '#ededed',
                '& + #label': {
                    display: 'flex'
                }
            }
        }
    })
);

export const SignedInHeader: FunctionComponent<SignedInHeaderProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { open: isMenuOpen } = useMenu();

    const { notificationAmount } = useSelector<
        RootState,
        NotificationBellState
    >(state => state.notificationBell);

    const [showNotificationBell, setShowNotificationBell] = useState(true);

    const [openNotificationModal, setOpenNotificationModal] = useState(false);

    const userNotificationAmount = useSelector<RootState, number>(
        state => state.userNotifications.unread.length
    );

    const notificationsStatus = useSelector<RootState, UserNotificationsStatus>(
        state => state.userNotifications.status
    );

    const onBoardingStatus = useSelector<RootState, OnboardingStepsStatus>(
        state => state.onboardingSteps.status
    );

    const onBoardingProgress = useSelector<RootState, OnboardingStepsModel>(
        state => state.onboardingSteps.onboardingSteps
    );

    const verifyOnBoardingProgress = () => {
        const isOnBoardingCompleted =
            onBoardingProgress.businessHoursDone &&
            onBoardingProgress.teamDone &&
            onBoardingProgress.petTypesDone &&
            onBoardingProgress.servicesDone &&
            onBoardingProgress.clientsDone &&
            onBoardingProgress.bookingDone;
        return isOnBoardingCompleted;
    };

    const handleNotificationBellClick = () => {
        if (verifyOnBoardingProgress() === true) {
            if (openNotificationModal === false) {
                if (notificationsStatus !== UserNotificationsStatus.Initial) {
                    setOpenNotificationModal(true);
                }
            } else {
                setOpenNotificationModal(false);
            }
        } else {
            dispatch(onboardingOpenModal());
        }
    };

    const toggleMenuHandler = () => {
        dispatch(isMenuOpen ? closeMenu() : openMenu());
    };

    useNonInitialEffect(() => {
        if (
            verifyOnBoardingProgress() === false &&
            onBoardingStatus !== OnboardingStepsStatus.Initial &&
            onBoardingStatus !== OnboardingStepsStatus.Cleared
        ) {
            dispatch(onboardingOpenModal());
        } else {
            dispatch(onboardingCloseModal());
        }
    }, [onBoardingProgress]);

    useEffect(() => {
        if (
            !(
                verifyOnBoardingProgress() === false &&
                onBoardingStatus !== OnboardingStepsStatus.Initial &&
                onBoardingStatus !== OnboardingStepsStatus.Cleared
            )
        ) {
            if (
                process.env.REACT_APP_NOTIFICATIONS_ENABLED?.toLowerCase() ===
                'true'
            ) {
                dispatch(fetchNotificationThunk());
            } else {
                setShowNotificationBell(false);
            }
        }
    }, [onBoardingProgress]);

    useNonInitialEffect(() => {
        if (verifyOnBoardingProgress() === true) {
            if (openNotificationModal === false) {
                dispatch(fetchNotificationThunk());
            }
        }
    }, [openNotificationModal]);

    useEffect(() => {
        if (verifyOnBoardingProgress() === true) {
            dispatch(setNotificationBellAmount(userNotificationAmount));
        }
    }, [userNotificationAmount]);

    useEffect(() => {
        if (verifyOnBoardingProgress() === true) {
            if (
                process.env.REACT_APP_NOTIFICATIONS_ENABLED?.toLowerCase() !==
                'true'
            ) {
                setShowNotificationBell(false);
            }
        } else if (
            verifyOnBoardingProgress() === false &&
            onBoardingStatus !== OnboardingStepsStatus.Initial &&
            onBoardingStatus !== OnboardingStepsStatus.Cleared
        ) {
            setShowNotificationBell(true);
            dispatch(fetchNotificationThunk());
        }
    }, []);

    useEffect(() => {
        if (notificationsStatus === UserNotificationsStatus.UpdateSuccess) {
            dispatch(fetchNotificationThunk());
        }
    }, [notificationsStatus]);

    return (
        <>
            <Box className={classes.header}>
                <Box className={classes.left}>
                    <Toggler open={isMenuOpen} onClick={toggleMenuHandler} />
                    <GlobalSearch />
                </Box>

                <Box className={classes.right}>
                    {process.env.REACT_APP_TWO_WAY_SMS_ENABLED?.toLowerCase() ===
                        'true' && <MessageIcon />}

                    {showNotificationBell === true && (
                        <NotificationBell
                            notificationAmount={notificationAmount}
                            onClick={() => handleNotificationBellClick()}
                        />
                    )}

                    <OnboardingSteps />
                    <DateTime className={classes.dateTime} />

                    <ProfileDropdown />

                    {props.onClose && (
                        <button
                            type="button"
                            aria-label="Close popup"
                            className={classes.closeIcon}
                            onClick={() => props.onClose && props.onClose()}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    )}
                </Box>
            </Box>

            {process.env.REACT_APP_NOTIFICATIONS_ENABLED?.toLowerCase() ===
                'true' && (
                <Notifications
                    open={openNotificationModal}
                    onClose={() =>
                        setOpenNotificationModal(!openNotificationModal)
                    }
                ></Notifications>
            )}
        </>
    );
};

export default SignedInHeader;
