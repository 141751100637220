import React, { FC } from 'react';
import {
    Box,
    CircularProgress,
    Theme,
    createStyles,
    makeStyles
} from '@material-ui/core';
import Booking from '@spike/booking-model';
import ServiceComponent from '../UI/ServiceComponent';
import { Button } from 'components/UI';
import Pet from '@spike/pet-model';
import Appointment from '@spike/appointment-model';

interface Props {
    booking?: Booking;
    appointmentId: number;
    pet?: Pet;
    petSelectedId?: number;
    loading: boolean;
    loadingService: boolean;
    showAddService: boolean;
    visibleStatus: boolean;
    onRemoveService: (uuid: string, appointmentId?: number) => void;
    onAddReport: (
        appointmentId: number,
        businessAreaId: string,
        clientId: number
    ) => void;
    onViewReport: (appointment: Appointment) => void;
    onAddService: () => void;
    onBack?: () => void;
    onBook: () => void;
    onCancel: (appointmentId?: number) => void;
    onCancelBooking: () => void;
    onCheckIn: (appointmentId: number) => void;
    onCheckOut: () => void;
    onComplete: (appointment: Appointment) => void;
    onConfirm: () => void;
    onNoShow: (appointmentId: number) => void;
    onDecline: (appointment: Appointment) => void;
    onRescheduleAppointment: () => void;
    onRescheduleBooking: () => void;
    onShowInvoice: (appointmentId: number) => void;
    onShowReport: (appointment: Appointment) => void;
    onUndo: (appointment: Appointment, statusId: number) => void;
    onShowAddReport?: (
        appointmentId: number,
        businessAreaId: string,
        clientId: number
    ) => void;
    onStart: (appointmentId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        button: {
            'width': 125,
            'height': 40,
            [theme.breakpoints.up('md')]: {
                width: 137,
                height: 43
            },
            '& .MuiButton-label': {
                [theme.breakpoints.down('md')]: {
                    fontSize: 12
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: 14
                }
            }
        }
    })
);

const ServiceInfo: FC<Props> = props => {
    const classes = useStyles();

    return (
        <Box>
            {!props.pet || props.loadingService ? (
                <CircularProgress size="1.5rem" style={{ color: '#EAB464' }} />
            ) : (
                <Box className={classes.container}>
                    {props.booking?.appointments
                        .filter(a =>
                            props.petSelectedId
                                ? a.pet.id === props.petSelectedId
                                : a.pet.id === props.pet?.id
                        )
                        .sort((ap, otherAp) => ap.id! - otherAp.id!)
                        .map((ap, index) => (
                            <ServiceComponent
                                key={ap.uuid}
                                appointment={ap}
                                appointmentId={props.appointmentId}
                                loading={props.loading}
                                first={index === 0}
                                multiple={
                                    !!(
                                        props.booking!.appointments!.length >
                                            1 ?? false
                                    )
                                }
                                visibleStatus={props.visibleStatus}
                                onAddReport={props.onAddReport}
                                onViewReport={props.onViewReport}
                                onRemoveService={props.onRemoveService}
                                onBack={props.onBack}
                                onBook={props.onBook}
                                onCancel={props.onCancel}
                                onCancelBooking={props.onCancelBooking}
                                onCheckIn={props.onCheckIn}
                                onCheckOut={props.onCheckOut}
                                onComplete={props.onComplete}
                                onConfirm={props.onConfirm}
                                onNoShow={props.onNoShow}
                                onDecline={props.onDecline}
                                onRescheduleAppointment={
                                    props.onRescheduleAppointment
                                }
                                onRescheduleBooking={props.onRescheduleBooking}
                                onShowInvoice={props.onShowInvoice}
                                onShowReport={props.onShowReport}
                                onUndo={props.onUndo}
                                onShowAddReport={props.onShowAddReport}
                                onStart={props.onStart}
                            />
                        ))}

                    {props.showAddService && (
                        <Button
                            label="+ Add Service"
                            color="green"
                            size="small"
                            className={classes.button}
                            onClick={props.onAddService}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ServiceInfo;
