import { BlockCalendarDto } from '@spike/block-calendar-action';
import { BookingDto, AppointmentDto } from '@spike/bookings-action';
import { MarketplaceDto } from '@spike/marketplace-action';
import { StaffDto } from 'actions/staff/StaffDto';
import { SmsMessageDto } from 'actions/twoWaySms/twoWaySmsDtos';
import { UserReceiptDto } from 'actions/userNotifications/userNotificationsDtos';

export interface BlockCalendarWebSocketsMessage {
    block_calendar?: BlockCalendarDto;
    deleted?: Array<number>;
    destroyed: boolean;
}

export interface AppointmentsWebSocketsMessage {
    booking?: BookingDto;
    appointment?: AppointmentDto;
    canceled?: Array<number>;
    deleted?: Array<number>;
}

export interface PosWebSocketMessage {
    body: PosWebSocketMessageBody;
}

export interface PosWebSocketMessageBody {
    message: string;
    status: string;
}

export interface MarketplaceWebSocketsMessage {
    marketplace: MarketplaceDto;
}

export interface StaffWebSocketsMessage {
    staff: StaffDto;
}

export interface TwoWaySmsWebSocketsMessage {
    sms_message: SmsMessageDto;
    unread_count: number;
}

export interface UserNofiticationsWebSocketsMessage {
    notification: UserNotificationDto;
    notification_receipt: UserReceiptDto;
}

export interface UserNotificationDto {
    channel?: string;
    id: number | undefined;
    uuid?: string;
    marketplace_id: number;
    body: string | HTMLBodyElement;
    subject: string | HTMLBodyElement;
    type_id: string;
    target_id?: number;
    target_type?: string;
    updated_at?: Date;
    created_at?: Date;
}

export interface BulkUploadWebSocketMessage {
    event: string;
    total_records: number;
    record_number: number;
    percentage: number;
    successfully_imported: number;
    errors: number;
}

export const PosSuccessStatus = 'APPROVED';
export const PosFailureStatus = 'FAILURE';
