import { FunctionComponent, RefObject } from 'react';
import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { SmsMessage } from 'model/TwoWaySms';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { reduceResolution, wbp } from 'Theme';
import moment from 'moment';
import Client from '@spike/client-model';
import Loader from '../ui/Loader';
import ChatDivider from './ChatDivider';

interface Props {
    clientData: Client;
    loading: boolean;
    messages: Array<SmsMessage>;
    timeZone: string;
    chatScrollRef: RefObject<OverlayScrollbarsComponent>;
    onScroll: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        },
        chatDividerContainer: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '10px'
            }
        },
        chatDividerLeft: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: '40px'
            }
        },
        chatDividerRight: {
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '40px'
            }
        },
        chatDividerText: {
            textAlign: 'center',
            color: '#7A7A7A',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(13)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '13px'
            },
            fontWeight: 500
        },
        chatHeaderClient: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(15)}px`,
                paddingLeft: `${reduceResolution(35)}px`,
                paddingRight: `${reduceResolution(35)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '15px',
                paddingLeft: '35px',
                paddingRight: '35px'
            }
        },
        chatHeaderClientName: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(13)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '13px'
            },
            fontWeight: 600,
            textAlign: 'left'
        },
        chatHeaderClientDate: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(13)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '13px'
            },
            fontWeight: 400,
            textAlign: 'right',
            color: '#7A7A7A'
        },
        chatBubbleClient: {
            display: 'flex',
            flexDirection: 'row',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(15)}px`,
                marginLeft: `${reduceResolution(20)}px`,
                marginRight: `${reduceResolution(20)}px`,
                marginBottom: `${reduceResolution(20)}px`,
                marginTop: `${reduceResolution(10)}px`,
                borderTopRightRadius: `${reduceResolution(12)}px`,
                borderBottomRightRadius: `${reduceResolution(12)}px`,
                borderBottomLeftRadius: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '15px',
                marginLeft: '20px',
                marginRight: '20px',
                marginBottom: '20px',
                marginTop: '10px',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                borderBottomLeftRadius: '12px'
            },
            backgroundColor: '#F8F5F1',
            color: '#000'
        },
        chatBubbleMarketplace: {
            display: 'flex',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(15)}px`,
                marginLeft: `${reduceResolution(20)}px`,
                marginRight: `${reduceResolution(20)}px`,
                marginBottom: `${reduceResolution(20)}px`,
                marginTop: `${reduceResolution(10)}px`,
                borderTopLeftRadius: `${reduceResolution(12)}px`,
                borderBottomRightRadius: `${reduceResolution(12)}px`,
                borderBottomLeftRadius: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '15px',
                marginLeft: '20px',
                marginRight: '20px',
                marginBottom: '20px',
                marginTop: '10px',
                borderTopLeftRadius: '12px',
                borderBottomRightRadius: '12px',
                borderBottomLeftRadius: '12px'
            },
            backgroundColor: '#000',
            color: '#fff'
        },
        scroll: {
            width: '100%',
            height: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(30)}px ${reduceResolution(
                    20
                )}px ${reduceResolution(30)}px ${reduceResolution(15)}px`,
                maxHeight: reduceResolution(460)
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '30px 20px 30px 15px',
                maxHeight: 460
            }
        },
        loaderContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1
        }
    })
);

const ChatMessages: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const messagesByDay = props.messages.reduce((map, message: SmsMessage) => {
        const groupedMessages =
            map.get(
                moment(message.createdAt)
                    .tz(props.timeZone)
                    .format('DD-MM-YYYY')
            ) || [];
        map.set(
            moment(message.createdAt).tz(props.timeZone).format('DD-MM-YYYY'),
            [...groupedMessages, message]
        );
        return map;
    }, new Map<string, Array<SmsMessage>>());

    return (
        <Box className={classes.container}>
            {props.loading ? (
                <Box className={classes.loaderContainer}>
                    <Loader />
                </Box>
            ) : (
                <OverlayScrollbarsComponent
                    className={classes.scroll}
                    options={{ callbacks: { onScrollStop: props.onScroll } }}
                    ref={props.chatScrollRef}
                >
                    <div>
                        {Array.from(messagesByDay).map(
                            ([day, messages], index) => (
                                <Box key={day + index}>
                                    <ChatDivider date={day} />
                                    {messages.map(message => (
                                        <Box key={message.uuid}>
                                            {message.senderType ===
                                            'Customer' ? (
                                                <Grid
                                                    container
                                                    justifyContent="flex-start"
                                                >
                                                    <Grid item xs={8}>
                                                        <Grid
                                                            container
                                                            justifyContent="flex-start"
                                                            className={
                                                                classes.chatHeaderClient
                                                            }
                                                        >
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    className={
                                                                        classes.chatHeaderClientName
                                                                    }
                                                                >
                                                                    {`${props.clientData?.firstName} ${props.clientData?.lastName}`}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    className={
                                                                        classes.chatHeaderClientDate
                                                                    }
                                                                >
                                                                    {moment(
                                                                        message.sentAt
                                                                    )
                                                                        .tz(
                                                                            props.timeZone
                                                                        )
                                                                        .isSame(
                                                                            moment().tz(
                                                                                props.timeZone
                                                                            ),
                                                                            'day'
                                                                        )
                                                                        ? 'Today ' +
                                                                          moment(
                                                                              message.sentAt
                                                                          )
                                                                              .tz(
                                                                                  props.timeZone
                                                                              )
                                                                              .format(
                                                                                  'LT'
                                                                              )
                                                                        : moment(
                                                                              message.sentAt
                                                                          )
                                                                              .tz(
                                                                                  props.timeZone
                                                                              )
                                                                              .format(
                                                                                  'dddd LT'
                                                                              )}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Box
                                                            className={
                                                                classes.chatBubbleClient
                                                            }
                                                        >
                                                            <Typography>
                                                                {
                                                                    message.message
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <Grid
                                                    container
                                                    justifyContent="flex-end"
                                                >
                                                    <Grid item xs={8}>
                                                        <Grid
                                                            container
                                                            justifyContent="flex-end"
                                                            className={
                                                                classes.chatHeaderClient
                                                            }
                                                        >
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    className={
                                                                        classes.chatHeaderClientName
                                                                    }
                                                                >
                                                                    You
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography
                                                                    className={
                                                                        classes.chatHeaderClientDate
                                                                    }
                                                                >
                                                                    {moment(
                                                                        message.sentAt
                                                                    )
                                                                        .tz(
                                                                            props.timeZone
                                                                        )
                                                                        .isSame(
                                                                            moment().tz(
                                                                                props.timeZone
                                                                            ),
                                                                            'day'
                                                                        )
                                                                        ? 'Today ' +
                                                                          moment(
                                                                              message.sentAt
                                                                          )
                                                                              .tz(
                                                                                  props.timeZone
                                                                              )
                                                                              .format(
                                                                                  'LT'
                                                                              )
                                                                        : moment(
                                                                              message.sentAt
                                                                          )
                                                                              .tz(
                                                                                  props.timeZone
                                                                              )
                                                                              .format(
                                                                                  'dddd LT'
                                                                              )}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Box
                                                            className={
                                                                classes.chatBubbleMarketplace
                                                            }
                                                        >
                                                            <Typography>
                                                                {
                                                                    message.message
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            )
                        )}
                    </div>
                </OverlayScrollbarsComponent>
            )}
        </Box>
    );
};

export default ChatMessages;
