import { faHeartbeat, faArrowsRepeat } from '@fortawesome/pro-light-svg-icons';
import { faCircleUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventContentArg } from '@fullcalendar/core';
import { Avatar, Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { status } from '@spike/appointment-model';
import clsx from 'clsx';
import { Spinner } from 'components/UI';
import { FunctionComponent } from 'react';

interface EventFullCalendarProps extends EventContentArg {
    loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            color: 'black',
            height: '100%',
            width: '100%',
            border: '1px solid rgba(255,255,255,0)',
            overflowX: 'hidden',
            overflowY: 'hidden',
            [theme.breakpoints.down('sm')]: {
                borderRadius: '4px',
                padding: '6px',
                paddingTop: '2px'
            },
            [theme.breakpoints.only('md')]: {
                borderRadius: '6px',
                padding: '7px',
                paddingTop: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                borderRadius: '8px',
                padding: '8px',
                paddingTop: '6px'
            },
            [theme.breakpoints.only('xl')]: {
                borderRadius: '10px',
                padding: '10px',
                paddingTop: '8px'
            }
        },
        spinnerContainer: {
            display: 'flex',
            justifyCOntent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
        },
        spinner: {
            [theme.breakpoints.down('sm')]: {
                height: '16px !important',
                width: '16px !important'
            },
            [theme.breakpoints.only('md')]: {
                height: '18px !important',
                width: '18px !important'
            },
            [theme.breakpoints.only('lg')]: {
                height: '24px !important',
                width: '24px !important'
            },
            [theme.breakpoints.only('xl')]: {
                height: '28px !important',
                width: '28px !important'
            }
        },
        booked: {
            'backgroundColor': 'white',
            'border': '2px solid #EAB464',
            '&:hover': {
                cursor: 'pointer',
                borderColor: 'black'
            }
        },
        confirmed: {
            'backgroundColor': '#F5DBB5',
            'border': '2px solid white',
            '&:hover': {
                cursor: 'pointer',
                borderColor: 'black'
            }
        },
        checkedIn: {
            'backgroundColor': '#ECBB74',
            'border': '2px solid white',
            '&:hover': {
                cursor: 'pointer',
                borderColor: 'black'
            }
        },
        inProgress: {
            'backgroundColor': '#BDD2CA',
            'border': '2px solid white',
            '&:hover': {
                cursor: 'pointer',
                borderColor: 'black'
            }
        },
        readyForCheckout: {
            'backgroundColor': '#A6BAC8',
            'border': '2px solid white',
            '&:hover': {
                cursor: 'pointer',
                borderColor: 'black'
            }
        },
        payment: {
            'backgroundColor': 'white',
            'border': '2px solid #444444',
            '&:hover': {
                cursor: 'pointer',
                borderColor: 'black'
            }
        },
        completed: {
            'backgroundColor': '#F1F1F1',
            'border': '2px solid #F1F1F1',
            '&:hover': {
                cursor: 'pointer',
                borderColor: 'black'
            }
        },
        loading: {
            '&:hover': {
                cursor: 'default'
            }
        },
        petName: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            wordWrap: 'break-word',
            [theme.breakpoints.down('sm')]: {
                fontSize: '7px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '9px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '11px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '13px'
            }
        },
        text: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            wordWrap: 'break-word',
            [theme.breakpoints.down('sm')]: {
                fontSize: '6px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '8px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '12px'
            }
        },
        cardContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
        },
        spacedContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        petNameContainer: {
            width: '50% !important',
            [theme.breakpoints.down('sm')]: {
                marginRight: '2px'
            },
            [theme.breakpoints.only('md')]: {
                marginRight: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '6px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '8px'
            }
        },
        row: {
            display: 'flex',
            width: '100%'
        },
        heartIcon: {
            color: '#EF4F57',
            [theme.breakpoints.down('sm')]: {
                fontSize: '8px',
                marginLeft: '2px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '10px',
                marginTop: '1px',
                marginLeft: '3px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '12px',
                marginTop: '2px',
                marginLeft: '4px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '14px',
                marginTop: '3px',
                marginLeft: '6px'
            }
        },
        userIcon: {
            marginRight: '3px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '8px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '12px',
                marginTop: '1px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '14px',
                marginTop: '2px'
            }
        },
        spacing: {
            marginRight: '3px'
        },
        avatar: {
            [theme.breakpoints.down('sm')]: {
                width: '12px',
                height: '12px'
            },
            [theme.breakpoints.only('md')]: {
                width: '14px',
                height: '14px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '16px',
                height: '16px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '18px',
                height: '18px'
            }
        },
        repeatIcon: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '8px',
                marginRight: 3,
                paddingTop: 1
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '10px',
                marginRight: 4,
                paddingTop: 2
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '12px',
                marginRight: 4,
                paddingTop: 2
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '14px',
                marginRight: 4,
                paddingTop: 3
            }
        },
        duration: {
            fontFamily: 'Poppins',
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            wordWrap: 'break-word',
            [theme.breakpoints.down('sm')]: {
                fontSize: '5px',
                paddingTop: '2px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '7px',
                paddingTop: '2px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '10px',
                paddingTop: '1px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '12px',
                paddingTop: '1px'
            }
        },
        staffContainer: {
            display: 'flex',
            width: 'auto'
        }
    })
);

export const EventFullCalendar: FunctionComponent<
    EventFullCalendarProps
> = props => {
    const classes = useStyles();
    const serviceDurationInMinutes = props.event.extendedProps.to.diff(
        props.event.extendedProps.from,
        'minutes'
    );
    const isExtendedVersion = serviceDurationInMinutes >= 30;

    const duration = (
        <Typography className={classes.duration}>
            {`${props.event.extendedProps.from.format(
                'h:mm a'
            )} - ${props.event.extendedProps.to.format('h:mm a')}`}
        </Typography>
    );

    return (
        <Box
            id={`calendar_div_event_${props.event.extendedProps.appointmentId}`}
            className={clsx(
                classes.container,
                {
                    [classes.booked]:
                        props.event.extendedProps.status.id === status.BOOKED
                },
                {
                    [classes.confirmed]:
                        props.event.extendedProps.status.id === status.CONFIRMED
                },
                {
                    [classes.checkedIn]:
                        props.event.extendedProps.status.id ===
                        status.CHECKED_IN
                },
                {
                    [classes.inProgress]:
                        props.event.extendedProps.status.id ===
                        status.IN_PROGRESS
                },
                {
                    [classes.readyForCheckout]:
                        props.event.extendedProps.status.id ===
                        status.READY_FOR_CHECK_OUT
                },
                {
                    [classes.payment]:
                        props.event.extendedProps.status.id === status.PAYMENT
                },
                {
                    [classes.completed]:
                        props.event.extendedProps.status.id === status.COMPLETED
                },
                {
                    [classes.loading]: props.loading
                }
            )}
        >
            {props.loading ? (
                <Box className={classes.spinnerContainer}>
                    <Spinner classNameCircularProgress={classes.spinner} />
                </Box>
            ) : (
                <Box className={classes.cardContainer}>
                    <Box>
                        <Box className={classes.spacedContainer}>
                            <Box
                                className={clsx(
                                    classes.row,
                                    classes.petNameContainer
                                )}
                            >
                                <Typography className={classes.petName}>
                                    {props.event.extendedProps.petName}
                                </Typography>
                                {props.event.extendedProps
                                    .petHasMedicalConditions && (
                                    <FontAwesomeIcon
                                        icon={faHeartbeat}
                                        className={classes.heartIcon}
                                    />
                                )}
                            </Box>
                            {!isExtendedVersion && duration}
                            {props.event.extendedProps.format === 'weekly' &&
                                isExtendedVersion && (
                                    <Box className={classes.staffContainer}>
                                        {props.event.extendedProps
                                            .recurringUuid &&
                                            isExtendedVersion && (
                                                <FontAwesomeIcon
                                                    icon={faArrowsRepeat}
                                                    className={
                                                        classes.repeatIcon
                                                    }
                                                />
                                            )}
                                        <Avatar
                                            src={
                                                props.event.extendedProps
                                                    .staffAvatar
                                            }
                                            className={classes.avatar}
                                        />
                                    </Box>
                                )}
                        </Box>
                        {isExtendedVersion && (
                            <>
                                <Box className={classes.row}>
                                    <FontAwesomeIcon
                                        icon={faCircleUser}
                                        className={classes.userIcon}
                                    />
                                    <Typography className={classes.text}>
                                        {`${props.event.extendedProps.client?.firstName} ${props.event.extendedProps.client?.lastName}`}
                                    </Typography>
                                </Box>
                                {duration}
                                <Box className={classes.spacedContainer}>
                                    <Typography className={classes.duration}>
                                        {props.event.extendedProps.serviceName}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default EventFullCalendar;
