import React, { FunctionComponent, useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import {
  getDentalReportByAppointmentIdThunk,
  getDentalReportPDFByAppointmentIdThunk,
  getGroomingReportByAppointmentIdThunk,
  getGroomingReportPDFByAppointmentIdThunk,
  resendReportThunk,
} from "actions/reports/ReportsActions";
import GroomingReport from "model/GroomerReport";
import DentalReport from "model/DentalReport";
import { Spinner } from "components/UI";
import GroomingReportSummary from "./GroomingReport/GroomingReportSummary";
import DentalReportSummary from "./DentalReport/DentalReportSummary";
import { ReportsStatus } from "reducers/reports/ReportsState";
import { useNonInitialEffect } from "@versiondos/hooks";

interface Props {
  appointmentId: number;
  businessAreaId: string;
  onBack: () => void;
  onClose: () => void;
  emailConfigured?: boolean;
  onEditGrooming: (report: GroomingReport) => void;
  onEditDental: (report: DentalReport) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      height: "100%",
      width: "100%",
      justifyContent: "center",
    }
  })
);

export const ViewReport: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);

  const groomingReport = useSelector<RootState, GroomingReport | undefined>((state) => state.reports.groomingReport);
  const dentalReport = useSelector<RootState, DentalReport | undefined>((state) => state.reports.dentalReport);
  const reportStatus = useSelector<RootState, ReportsStatus>((state) => state.reports.status);
  const loadingDownload = useSelector<RootState, boolean>((state) => state.reports.loading);

  useEffect(() => {
    if (props.businessAreaId === "dental") {
      dispatch(getDentalReportByAppointmentIdThunk(props.appointmentId));
    } else if (props.businessAreaId === "grooming") {
      dispatch(getGroomingReportByAppointmentIdThunk(props.appointmentId));
    }
  }, [props.appointmentId]);

  useNonInitialEffect(() => {
    if ([ReportsStatus.GetGroomingByAppointmentSuccess, ReportsStatus.GetDentalByAppointmentSuccess].includes(reportStatus)) {
      setLoading(false);
    } else if ([ReportsStatus.ResendReportSuccess, reportStatus === ReportsStatus.Error].includes(reportStatus)) {
      setSending(false);
      closeHandler();
    }
  }, [reportStatus]);

  const resendHandler = (appointmentId: number) => {
    setSending(true);
    dispatch(resendReportThunk(appointmentId));
  };

  const editGroomingHandler = () => {
    props.onEditGrooming(groomingReport!);
  };

  const editDentalHandler = () => {
    props.onEditDental(dentalReport!);
  };

  const closeHandler = () => {
    props.onClose();
  }

  const handleDownloadDentalReport = (appointmentId: number) => {
    dispatch(getDentalReportPDFByAppointmentIdThunk(appointmentId));
  };

  const handleDownloadGroomingReport = (appointmentId: number) => {
    dispatch(getGroomingReportPDFByAppointmentIdThunk(appointmentId));
  };


  const groomingReportSummary = (
    <GroomingReportSummary
      groomingReport={groomingReport!}
      singleImage={false}
      onResend={(report) => resendHandler(report.appointment.id!)}
      back={props.onBack}
      onClose={closeHandler}
      sending={sending}
      declined={groomingReport?.declined}
      onEditReport={() => editGroomingHandler()}
      onDownloadReport={handleDownloadGroomingReport}
      loading={loadingDownload}
    />);

  const dentalReportSummary = (
    <DentalReportSummary
      singleImage={true}
      dentalReport={dentalReport!}
      onResend={(report) => resendHandler(report.appointment.id!)}
      back={props.onBack}
      onClose={closeHandler}
      sending={sending}
      declined={dentalReport?.declined}
      onEditReport={() => editDentalHandler()}
      onDownloadReport={handleDownloadDentalReport}
      loading={loadingDownload}
    />
  );

  return (
    <Box className={classes.container}>
      {loading && <Spinner />}
      {!loading && props.businessAreaId === "dental" && dentalReportSummary}
      {!loading && props.businessAreaId === "grooming" && groomingReportSummary}
    </Box>
  );
};

export default ViewReport;
