import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import PetImage from "./PetImage";
import PetDescription from "./PetDescription";
import Pet from "@spike/pet-model";

export interface PetInfoProps {
  pet?: Pet;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%"

    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
    },
    image: {
      width: 64,
      height: 64,
      marginRight: 18,
      [theme.breakpoints.up('md')]: {
        marginRight: 20,
        width: "12%"
      },
    },
    petDescriptionContainer: {
      display: "flex",
      [theme.breakpoints.up('md')]: {
        width: "84%"
      }
    }
  })
);

export const PetInfo: FunctionComponent<PetInfoProps> = (props) => {
  const classes = useStyles();
  
  return (
    <Box className={clsx(classes.container)}>
      <Box className={classes.headerContainer}>
        <Box className={classes.image}>
          <PetImage petImage={props.pet?.image} petType={props.pet?.type?.id} />
        </Box>
        <Box className={classes.petDescriptionContainer}>
          <PetDescription pet={props.pet} />
        </Box>
      </Box>
    </Box>
  );
};

export default PetInfo;