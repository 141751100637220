import { ClickAwayListener, Drawer } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Booking, { BookingWrapper } from '@spike/booking-model';
import clsx from 'clsx';
import BlockedTime from 'components/Calendar3/BlockedTime';
import CreateBooking from 'components/CreateBooking';
import { Moment } from 'moment';
import { FunctionComponent, useState } from 'react';

interface CreateBookingDrawerProps {
    fromClient?: boolean;
    bookingReschedule?: BookingWrapper;
    staffId?: number;
    at?: Moment;
    parentID?: number;
    petID?: number;
    parentName?: string;
    appointmentId?: number;
    detailView?: boolean;
    clientAdded?: boolean;
    petAdded?: boolean;
    bookingRebook?: Booking;
    className?: string;
    blockedEdit?: boolean;
    petsLoaded?: boolean;
    onAddClient?: () => void;
    onAddPet?: (clientId: number) => void;
    onSave?: () => void;
    onClose: () => void;
    onBooked: (bookedAt?: Moment) => void;
    onStartBooking?: () => void;
    blockCalendarId?: number;
    showTabs?: boolean;
    onChangeTime?: (
        bookedAt: Moment | undefined,
        duration: number | undefined,
        staffId: number | undefined,
        show: boolean
    ) => void;
    onRemoveClient?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            'display': 'flex',
            'height': '100%',
            'flexDirection': 'column',
            '& .MuiDrawer-paperAnchorRight': {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: '590px !important'
                }
            }
        }
    })
);

export const CreateBookingDrawer: FunctionComponent<
    CreateBookingDrawerProps
> = props => {
    const classes = useStyles();

    const [showNewAppointment, setShowNewAppoinment] = useState(
        props.blockCalendarId === undefined
    );

    const changeTimeHandler = (
        bookedAt?: Moment,
        duration?: number,
        staffId?: number
    ) => {
        props.onChangeTime &&
            props.onChangeTime(
                bookedAt,
                duration,
                staffId,
                showNewAppointment || props.blockCalendarId === undefined
            );
    };

    return (
        <Drawer
            open={true}
            anchor="right"
            onClose={props.onClose}
            className={clsx(classes.container, props.className)}
        >
            {showNewAppointment ? (
                <CreateBooking
                    bookingRebook={props.bookingRebook}
                    fromClient={props.fromClient}
                    bookingReschedule={props.bookingReschedule}
                    staffId={props.staffId}
                    at={props.at}
                    parentID={props.parentID}
                    petID={props.petID}
                    parentName={props.parentName}
                    appointmentId={props.appointmentId}
                    detailView={props.detailView}
                    clientAdded={props.clientAdded}
                    petAdded={props.petAdded}
                    petsLoaded={props.petsLoaded}
                    onAddClient={props.onAddClient}
                    onAddPet={props.onAddPet}
                    onSave={props.onClose}
                    onClose={props.onClose}
                    onBooked={props.onBooked}
                    onChangeTime={changeTimeHandler}
                    onShowBlockedTime={
                        props.showTabs
                            ? () => setShowNewAppoinment(false)
                            : undefined
                    }
                    onRemoveClient={props.onRemoveClient}
                />
            ) : (
                <BlockedTime
                    from={props.at?.clone()}
                    to={props.at?.clone().add(15, 'minutes')}
                    staffId={props.staffId}
                    blockCalendarId={props.blockCalendarId}
                    onClose={props.onClose}
                    onBlocked={props.onClose}
                    onShowBooking={() => setShowNewAppoinment(true)}
                    onChangeTime={changeTimeHandler}
                    blockedEdit={props.blockedEdit}

                />
            )}
        </Drawer>
    );
};

export default CreateBookingDrawer;
