import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
	BlockCalendarStatus,
	clearAlertsThunk,
	countRecurrentThunk,
	deleteThunk,
	fetchAlertsThunk,
	getThunk,
	saveThunk
} from '@spike/block-calendar-action';
import { BlockCalendar, BlockCalendarAlert } from '@spike/block-calendar-model';
import Marketplace from '@spike/marketplace-model';
import { Option } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import clsx from 'clsx';
import PeriodSelector from 'components/CreateBooking/DateSelector/PeriodSelector';
import Notes from 'components/CreateBooking/Notes';
import StaffComponent from 'components/CreateBooking/StaffSelector/StaffComponent';
import Tabs, {
	blockedTimeTabId,
	bookingTabId
} from 'components/CreateBooking/Tabs';
import { AlertNewBooking } from 'components/CreateBooking/UI/AlertNewBooking';
import ConfirmClosePopup from 'components/CreateBooking/UI/ConfirmClosePopup';
import { Button, Spinner } from 'components/UI';
import {
	useApiClientWrapper,
	useAuth,
	useMarketplace,
	useMasterData,
	useTimeZone
} from 'hooks';
import Staff from 'model/Staff';
import moment, { Moment } from 'moment-timezone';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { v4 as uuid } from 'uuid';
import DeleteButton from './DeleteButton';
import InputText from './InputText';
import PopUpDeleteBlockCalendar from './PopUpDeleteBlockCalendar';
import PopUpUpdateBlockCalendar from './PopUpUpdateBlockCalendar';
import { debugConsoleLog } from 'utils/GeneralUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/pro-regular-svg-icons';

interface BlockedTimeProps {
	from?: Moment;
	to?: Moment;
	staffId?: number;
	blockCalendarId?: number;
	onChangeTime?: (
		bookedAt?: Moment,
		durationInMinutes?: number,
		staffId?: number
	) => void;
	onClose: () => void;
	onBlocked: (bookedAt?: Moment) => void;
	onShowBooking: () => void;
	blockedEdit?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			width: '100%',
			height: '100%'
		},
		tabsContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			margin: '17px 27px 11px 27px',
			[theme.breakpoints.up('md')]: {
				margin: '20px 30px 13px 30px'
			}
		},
		headerContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			margin: '17px 27px 27px 27px',
			[theme.breakpoints.up('md')]: {
				margin: '20px 30px 30px 30px'
			}
		},
		bodyContainer: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			margin: '0px 26px 0px 26px',
			[theme.breakpoints.up('md')]: {
				margin: '0px 30px 0px 30px'
			}
		},
		footerContainer: {
			display: 'flex',
			itemsAlign: 'center',
			padding: '18px 26px',
			[theme.breakpoints.up('md')]: {
				padding: '20px 30px'
			},
			borderTop: '1px solid #D4D4D4'
		},
		sectionContainer: {
			paddingTop: 18,
			[theme.breakpoints.up('md')]: {
				paddingTop: 20
			}
		},
		staffContainer: {
			paddingBottom: 18,
			[theme.breakpoints.up('md')]: {
				paddingBottom: 20
			}
		},
		button: {
			height: 50,
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				borderRadius: 30
			},
			[theme.breakpoints.up('md')]: {
				height: 55
			}
		},
		alert: {
			marginBottom: '0px !important',
			marginTop: 18,
			[theme.breakpoints.up('md')]: {
				marginTop: 20
			}
		},
		alertText: {
			fontSize: 14,
			lineHeight: '20px'
		},
		deleteButton: {
			marginRight: 16
		},
		icon: {
			cursor: 'pointer',
			fontSize: 22,
			[theme.breakpoints.up('md')]: {
				fontSize: 24
			}
		},
		titleContainer: {
			display: 'flex',
			cursor: 'pointer'
		},
		title: {
			fontWeight: 600,
			fontSize: 20,
			lineHeight: '20px',
			[theme.breakpoints.up('md')]: {
				fontSize: 22,
				lineHeight: '22px'
			}
		},

	})
);

interface BlockData {
	id: number | undefined;
	uuid: string;
	from: Moment;
	to: Moment;
	frequency: Option<string> | undefined;
	recurrent: boolean;
	title: string;
	staff: Staff | undefined;
	description: string;
}

const convertToBlockData = (
	blockCalendar: BlockCalendar,
	staff: Array<Staff>
): BlockData => ({
	id: blockCalendar.id,
	uuid: blockCalendar.uuid,
	from: blockCalendar.start.clone(),
	to: blockCalendar.end.clone(),
	frequency: blockCalendar.frequency !== undefined
		? { ...blockCalendar.frequency }
		: undefined,
	recurrent: blockCalendar.frequency !== undefined,
	title: blockCalendar.title ?? '',
	staff: staff.find(staff => staff.id === blockCalendar.staff.id),
	description: blockCalendar.description ?? ''
});

const validate = (blockData: BlockData): boolean =>
	blockData.from !== undefined &&
	blockData.to !== undefined &&
	blockData.staff !== undefined;

const convertToBlockCalendar = (
	blockData: BlockData,
	marketplace: Marketplace
): BlockCalendar => ({
	id: blockData.id,
	uuid: blockData.uuid,
	calendarId: marketplace.schedule.id ?? 0,
	staff: {
		id: blockData.staff?.id ?? 0,
		firstName: blockData.staff?.person.firstName ?? '',
		lastName: blockData.staff?.person.lastName ?? '',
		image: blockData.staff?.person.avatar ?? ''
	},
	start: blockData.from.clone(),
	end: blockData.to.clone(),
	title: blockData.title,
	description: blockData.description,
	frequency: blockData.frequency ? { ...blockData.frequency } : undefined
});

export const BlockedTime: FunctionComponent<BlockedTimeProps> = props => {
	const classes = useStyles();
	const timeZone = useTimeZone();
	const auth = useAuth();
	const marketplace = useMarketplace();
	const masterData = useMasterData();
	const dispatch = useDispatch();
	const apiClientWrapper = useApiClientWrapper();

	const staff = useSelector<RootState, Array<Staff>>(
		state => state.staff.staff
	);

	const activeStaff = staff.filter(
		member => member.active && !member.deleted
	);

	const alerts = useSelector<RootState, Array<BlockCalendarAlert>>(
		state => state.blockCalendar.alerts
	);

	const blockCalendar = useSelector<RootState, BlockCalendar | undefined>(
		state => state.blockCalendar.block
	);

	const recurrentBlockCalendar = useSelector<
		RootState,
		{ blockId: number; remaining: number } | undefined
	>(state => state.blockCalendar.recurrent);

	const blockCalendarStatus = useSelector<RootState, BlockCalendarStatus>(
		state => state.blockCalendar.status
	);

	const [blockData, setBlockData] = useState<BlockData>({
		id: undefined,
		uuid: uuid(),
		from: props.from?.clone() ?? moment().tz(timeZone),
		to: props.to?.clone() ?? moment().tz(timeZone).add(15, 'minutes'),
		frequency: undefined,
		recurrent: false,
		title: '',
		staff: props.staffId
			? activeStaff.find(member => member.id === props.staffId)
			: activeStaff.find(member => member.id === auth.user?.staffId),
		description: ''
	});

	const [closing, setClosing] = useState({ close: false, tabChange: false });

	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(props.blockCalendarId !== undefined);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
	const [touched, setTouched] = useState(false);

	const saveEnabled =
		validate(blockData) && !alerts.some(alert => alert.error);

	useEffect(() => {
		props.onChangeTime &&
			props.onChangeTime(
				blockData.from.clone(),
				blockData.to.clone().diff(blockData.from.clone(), 'minutes'),
				blockData.staff?.id
			);
		props.blockCalendarId !== undefined &&
			dispatch(getThunk(apiClientWrapper, props.blockCalendarId!));
	}, []);

	useEffect(() => {
		if (blockData.staff?.id) {
			dispatch(
				fetchAlertsThunk(
					apiClientWrapper,
					blockData.from.clone(),
					blockData.to.clone(),
					blockData.staff.id
				)
			);
		} else {
			dispatch(clearAlertsThunk());
		}
	}, [blockData]);

	useNonInitialEffect(() => {
		switch (blockCalendarStatus) {
			case BlockCalendarStatus.CountRecurrentSuccess:
				saving &&
					(recurrentBlockCalendar?.remaining ?? 1) === 1 &&
					save(blockData);
				saving &&
					(recurrentBlockCalendar?.remaining ?? 1) > 1 &&
					setShowUpdateConfirmation(true);
				break;
			case BlockCalendarStatus.SaveSuccess:
				setSaving(false);
				props.onClose();
				break;
			case BlockCalendarStatus.GetSuccess:
				setBlockData(convertToBlockData(blockCalendar!, activeStaff));
				setLoading(false);
				break;
			case BlockCalendarStatus.DeleteSuccess:
				setShowDeleteConfirmation(false);
				props.onClose();
				break;
			case BlockCalendarStatus.Error:
				setSaving(false);
				setLoading(false);
				setShowDeleteConfirmation(false);
				setShowUpdateConfirmation(false);
		}
	}, [blockCalendarStatus]);

	useNonInitialEffect(() => {
		setTouched(true);
	}, [blockData]);

	const selectTabHandler = (tabId: string) => {
		tabId === bookingTabId && touched &&
			setClosing(prev => ({ ...prev, close: false, tabChange: true }));
		tabId === bookingTabId && !touched && props.onShowBooking && props.onShowBooking();
	};

	const changePeriodHandler = (from: Moment, to: Moment) => {
		const newTo = from.clone().isBefore(to.clone())
			? to.clone()
			: from.clone().add(15, 'minutes');
		setBlockData(prev => ({
			...prev,
			from: from.clone(),
			to: newTo.clone()
		}));
		props.onChangeTime &&
			!props.blockCalendarId &&
			props.onChangeTime(
				from.clone(),
				newTo.clone().diff(from.clone(), 'minutes'),
				blockData.staff?.id
			);
	};

	const changeFrequencyDurationHandler = (
		duration: Option<string> | undefined,
		frequency: Option<string>
	) => {
		setBlockData(prev => ({
			...prev,
			frequency: { ...frequency }
		}));
	};

	const changeRecurrentHandler = (active: boolean) => {
		setBlockData(prev => ({
			...prev,
			frequency: undefined,
			recurrent: active
		}));
	};

	const changeTitleHandler = (title: string) => {
		setBlockData(prev => ({
			...prev,
			title
		}));
	};

	const selectStaffHandler = (staff: Staff) => {
		setBlockData(prev => ({
			...prev,
			staff: { ...staff }
		}));
		props.onChangeTime &&
			!props.blockCalendarId &&
			props.onChangeTime(
				blockData.from.clone(),
				blockData.to.clone().diff(blockData.from.clone(), 'minutes'),
				staff.id
			);
	};

	const changeDescriptionHandler = (description: string) => {
		setBlockData(prev => ({
			...prev,
			description
		}));
	};

	const save = (blockData: BlockData) => {
		const blockCalendar = convertToBlockCalendar(blockData, marketplace);
		dispatch(saveThunk(apiClientWrapper, blockCalendar));
	};

	const updateHandler = () => {
		setSaving(true);
		dispatch(countRecurrentThunk(apiClientWrapper, blockData.id!));
	};

	const saveHandler = () => {
		if (validate(blockData)) {
			if (blockData.id) {
				updateHandler();
			} else {
				setSaving(true);
				save(blockData);
			}
		}
	};

	const closeHandler = () => {
		closing.close && props.onClose();
		closing.tabChange && props.onShowBooking();
	};

	const deleteHandler = (recurrent: boolean) => {
		dispatch(
			deleteThunk(apiClientWrapper, blockCalendar?.id ?? 0, recurrent)
		);
	};

	const confirmCloseDialog = (
		<ConfirmClosePopup
			title="Exit Blocked Time"
			question="Your changes will not be saved. Are you sure you want to exit this blocked time?"
			onDiscard={() =>
				setClosing(prev => ({
					...prev,
					close: false,
					tabChange: false
				}))
			}
			onSave={closeHandler}
		/>
	);

	const deleteConfirmation = (
		<PopUpDeleteBlockCalendar
			blockCalendarId={props.blockCalendarId ?? 0}
			onClose={() => setShowDeleteConfirmation(false)}
			onConfirm={deleteHandler}
		/>
	);

	const updateConfirmation = (
		<PopUpUpdateBlockCalendar
			onClose={() => {
				setShowUpdateConfirmation(false);
				setSaving(false);
			}}
			onConfirm={recurrent => save(blockData)}
		/>
	);

	return (
		<Box className={classes.container}>
			{props.blockedEdit ? <Box className={classes.headerContainer}>
				<Box className={classes.titleContainer}>

					<Typography className={classes.title}>Blocked Time</Typography>
				</Box>
				<FontAwesomeIcon
					className={classes.icon}
					icon={faTimes}
					onClick={props.onClose}
				/>

			</Box>
				: <Box className={classes.headerContainer}>
					<Tabs
						tabId={blockedTimeTabId}
						onSelect={selectTabHandler}
						onClose={() =>
							touched ? setClosing(prev => ({
								...prev,
								close: true,
								tabChange: false
							})) : (props.onClose && props.onClose())
						}
					/>
				</Box>}
			{loading ? (
				<Spinner />
			) : (
				<>
					<OverlayScrollbarsComponent style={{ height: '100%' }}>
						<Box className={classes.bodyContainer}>
							<PeriodSelector
								from={blockData.from}
								to={blockData.to}
								isRecurrent={blockData.recurrent}
								frequency={blockData.frequency}
								frequencyOptions={masterData.blockFrequencies}
								onChangePeriod={changePeriodHandler}
								onChangeFrequencyDuration={
									changeFrequencyDurationHandler
								}
								onChangeRecurrent={changeRecurrentHandler}
							/>
							<Box className={classes.sectionContainer}>
								<InputText
									title="Title"
									placeholder="Add a title"
									value={blockData.title}
									onChange={changeTitleHandler}
								/>
							</Box>
							<Box
								className={clsx(
									classes.sectionContainer,
									classes.staffContainer
								)}
							>
								<StaffComponent
									options={activeStaff}
									selectedOption={blockData.staff}
									onSelectStaff={selectStaffHandler}
								/>
							</Box>
							{alerts?.map(alert => (
								<Box
									key={`${alert.alertType}_${alert.message}`}
									id={`booking_div_${alert.alertType}_alert`}
								>
									<AlertNewBooking
										className={classes.alert}
										bWidth="2px"
										icon={faExclamationTriangle}
										iconSize="lg"
										iconColor="#EAB464"
										bgColor="#F8F5F1"
										bdColor="#BCB8AE"
									>
										<Typography
											className={classes.alertText}
										>
											{alert.message}
										</Typography>
									</AlertNewBooking>
								</Box>
							))}
							<Box className={classes.sectionContainer}>
								<Notes
									title="Description"
									placeholder="Add a note"
									notes={blockData.description}
									onChangeNote={changeDescriptionHandler}
								/>
							</Box>
						</Box>
					</OverlayScrollbarsComponent>

					<Box className={classes.footerContainer}>
						{props.blockCalendarId && (
							<DeleteButton
								className={classes.deleteButton}
								onClick={() => setShowDeleteConfirmation(true)}
							/>
						)}
						<Button
							id="block_button_save"
							label="Save"
							color="green"
							size="large"
							disabled={!saveEnabled}
							loading={saving}
							variant="primary"
							className={classes.button}
							onClick={saveHandler}
						/>
					</Box>
				</>
			)}
			{(closing.close || closing.tabChange) && confirmCloseDialog}
			{showDeleteConfirmation && deleteConfirmation}
			{showUpdateConfirmation && updateConfirmation}
		</Box>
	);
};

export default BlockedTime;
