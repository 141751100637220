import {
    AppointmentsStatus,
    assignAppointmentStaffThunk,
    BookingsStatus,
    cancelAppointmentThunk,
    cancelBookingThunk,
    cancelRecurrentAppointmentsThunk,
    changeStatusAppointmentThunk,
    clientCountThunk,
    completeAppointmentThunk,
    confirmBookingThunk,
    Counter,
    declineAppointmentThunk,
    fetchClientAppointmentsThunk,
    fetchClientAppointmentsWithStaffIssuesThunk,
    fetchClientBookingsThunk,
    fetchClientBookingsWithStaffIssuesThunk,
    fetchClientMergeableAppointmentsThunk,
    fetchClientPendingAppointmentsThunk,
    fetchClientPendingBookingsThunk,
    refetchClientAppointmentsThunk,
    refetchClientBookingsThunk
} from '@spike/bookings-action';
import { Period } from '@spike/model';
import { useNonInitialEffect } from '@versiondos/hooks';
import clsx from 'clsx';
import BookingDetail from 'components/BookingDetail2';
import Checkout from 'components/Checkout';
import DisplayInvoice from 'components/Invoice/DisplayInvoice';
import NewBooking from 'components/NewBooking';
import PetCard from 'components/PetCard';
import { CompleteReport, EditReport, ViewReport } from 'components/Reports';
import {
    Button,
    ConfirmDialog,
    OverDetail,
    OverFullWindow,
    Spinner,
    CompleteAppointmentDialog
} from 'components/UI';
import Pagination from 'components/UI/Pagination';
import PopUpCancelBooking from 'components/UI/PopUpCancelBooking';
import {
    useApiClientWrapper,
    useHasPremiumAccess,
    useHubspotTrackingCode,
    useMarketplace
} from 'hooks';
import ClientModel from '@spike/client-model';
import { AvailableStaff } from '@spike/available-staff-model';
import moment from 'moment';
import Client from 'pages/Client';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { convertToYMDWithoutTimeZone } from '@spike/date-utils';

import {
    BookingsView,
    allView,
    upcomingView,
    views,
    filter,
    getAssignedStaff,
    sort,
    Sorting,
    pendingView,
    staffIssuesView
} from '@spike/bookings-view';

import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import {
    Box,
    ClickAwayListener,
    createStyles,
    makeStyles,
    Theme
} from '@material-ui/core';
import Booking, {
    BookingWrapper,
    SortFields,
    SortOrder
} from '@spike/booking-model';
import AppointmentsWebSockets from 'WebSockets/AppointmentsWebSockets';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import BookingsNotCreated from './BookingsNotCreated';
import BookingsHeader from './Header';
import StaffNotSelected from './StaffNotSelected';
import Table from './Table';
import Appointment, { status } from '@spike/appointment-model';
import { debugConsoleLog } from 'utils/GeneralUtils';
import CreateBookingDrawer from 'components/UI/CreateBookingDrawer';
import { NewBookingsStatus } from '@spike/new-bookings-action';
import { v4 as uuid } from 'uuid';
import { ClientsState, getClientThunk } from '@spike/clients-action';

interface BookingsComponentClientProps {
    clientID?: number;
    petIds: Array<number>;
    from?: string;
    onSetRevenue?: (revenue: number) => void;
}
interface Report {
    appointmentId: number;
    businessAreaId: string;
    onlyAddReport?: boolean;
    sendSms?: boolean;
}

interface CompleteReportData {
    appointmentId?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            height: '100%',
            position: 'relative',
            borderRadius:
                '18px' /* for content appointment inside client detail */
        },
        iframe: {
            width: '100%',
            height: '98%',
            border: 'none'
        },
        headerContainer: {
            width: '100%',

            [theme.breakpoints.down('sm')]: {
                'padding': '0px 16px',

                '& > div > div': {
                    padding: 0
                }
            }
        },
        boxBookingContainer: {
            paddingBottom: '30px',
            width: '100%',
            background: 'white',
            borderRadius:
                '0 0 18px 18px' /* for content appointment inside client detail */,

            [theme.breakpoints.up('md')]: {
                height: '800px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingBottom: '80px'
            }
        },
        tableContainer: {
            'flexGrow': 1,
            'width': '100%',
            'minHeight': '100%',

            '& thead': {
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            }
        },
        scroll: {
            height: '100%',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '22px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '36px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '40px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '45px'
            }
        },
        scrollClient: {
            width: '100%',
            marginTop: 12,

            [theme.breakpoints.up('md')]: {
                height: '100%',
                marginTop: '25px'
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '25px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '73px'
            }
        },
        pagination: {
            [theme.breakpoints.down('sm')]: {
                paddingRight: '9px'
            },
            [theme.breakpoints.only('md')]: {
                paddingRight: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingRight: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingRight: '30px'
            }
        },
        paginationFull: {
            [theme.breakpoints.down('sm')]: {
                paddingTop: '5px',
                paddingRight: '9px'
            },
            [theme.breakpoints.only('md')]: {
                paddingTop: '10px',
                paddingRight: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingTop: '15px',
                paddingRight: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '20px',
                paddingRight: '30px'
            }
        },
        dialog: {
            '& .MuiDialogContent-root': {
                padding: '0px'
            }
        },
        completeReport: {
            width: '90%',
            marginLeft: '0px'
        },
        addReport: {
            width: '90%',
            [theme.breakpoints.down('lg')]: {
                marginLeft: '160px'
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: '200px'
            }
        }
    })
);

export const BookingsComponentClient: FunctionComponent<
    BookingsComponentClientProps
> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const marketplace = useMarketplace();
    const timeZone = marketplace.schedule.timeZone;

    const { setTrackEvent } = useHubspotTrackingCode();

    const hasReportsPremiumAccess = useHasPremiumAccess('groomming_reports');

    const appointments = useSelector<RootState, Array<Appointment>>(
        state => state.appointments.clientAppointments.appointments
    )
        .filter(appointment => appointment.status.id !== status.BOOKED)
        .map(appointment => ({
            appointment,
            key: appointment.uuid,
            sort: {
                id: appointment.id!,
                clientName: `${appointment.customer.firstName} ${appointment.customer.lastName}`,
                petName: appointment.pet.name,
                petBreed: appointment.pet.breed?.name,
                petType: appointment.pet.type.singular,
                service: appointment.services[0].name,
                staff: `${appointment.services[0].staff?.firstName} ${appointment.services[0].staff?.lastName}`,
                dateTime: appointment.duration.from,
                total: appointment.total,
                status: appointment.status.id
            }
        }));

    const bookings = useSelector<RootState, Array<Booking>>(
        state => state.bookings.clientBookings.bookings
    ).map(booking => ({
        booking,
        key: booking.uuid,
        sort: {
            id: booking.id!,
            clientName: `${booking.appointments[0].customer.firstName} ${booking.appointments[0].customer.lastName}`,
            petName: booking.appointments[0].pet.name,
            petBreed: booking.appointments[0].pet.breed.name,
            petType: booking.appointments[0].pet.type.singular,
            service: booking.appointments[0].services[0].name,
            staff: `${booking.appointments[0].services[0].staff?.firstName} ${booking.appointments[0].services[0].staff?.lastName}`,
            dateTime: booking.bookedAt,
            total: booking.appointments[0].total,
            status: booking.appointments[0].status.id
        }
    }));

    const mergeableAppointments = useSelector<RootState, Array<Appointment>>(
        state => state.appointments.clientAppointments.mergeables
    );

    const counter = useSelector<RootState, Counter>(
        state => state.appointments.clientAppointments.counter
    );

    const appointmentsStatus = useSelector<RootState, AppointmentsStatus>(
        state => state.appointments.status
    );
    const appointmentLimits = useSelector<RootState, Period | undefined>(
        state => state.appointments.limits
    );

    const bookingsStatus = useSelector<RootState, BookingsStatus>(
        state => state.bookings.status
    );

    const newBookingsStatus = useSelector<RootState, NewBookingsStatus>(
        state => state.newBookings.status
    );

    const clientState = useSelector<RootState, ClientsState>(
        state => state.clients
    );

    const emailConfigured =
        clientState?.client &&
        clientState?.client.email &&
        clientState?.client.email.length > 0
            ? true
            : false;

    const [loading, setLoading] = useState({
        appointments: true,
        bookings: true,
        any: true
    });
    const [datePeriod, setDatePeriod] = useState<Period>({
        from: moment().tz(timeZone),
        to: moment().tz(timeZone)
    });

    const [view, setView] = useState(allView);
    const [selectedStaff, setSelectedStaff] = useState<
        Array<number | undefined>
    >([]);
    const [selectedAllStaff, setSelectedAllStaff] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [sorting, setSorting] = useState<Sorting | undefined>(undefined);

    const [showBookingCancel, setShowBookingCancel] = useState(false);
    const [showNewClient, setShowNewClient] = useState(false);
    const [showReport, setShowReport] = useState<Report | undefined>(undefined);

    const [decline, setDecline] = useState<Report | undefined>(undefined);

    const [checkoutAppointmentIds, setCheckoutAppointmentIds] = useState<
        Array<number>
    >([]);
    const [checkoutBookingId, setCheckoutBookingId] = useState<
        number | undefined
    >();

    const [customerID, setCustomerID] = useState<number | undefined>(undefined);
    const [customerName, setCustomerName] = useState<string | undefined>(
        undefined
    );
    const [petID, setPetID] = useState<number | undefined>(undefined);
    const [clientID, setClientID] = useState<number | undefined>(undefined);
    const [changeStatus, setChangeStatus] = useState(false);

    const [rescheduleAppointmentId, setRescheduleAppointmentId] = useState<
        number | undefined
    >(undefined);
    const [rescheduleBooking, setRescheduleBooking] = useState<
        BookingWrapper | undefined
    >(undefined);

    const [popupPetId, setPopupPetId] = useState<number | undefined>(undefined);

    const [invoiceIdToShow, setInvoiceIdToShow] = useState<number | undefined>(
        undefined
    );

    const [editReport, setEditReport] = useState<Report | undefined>(undefined);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [bookingDelete, setBookingDelete] = useState<Booking>();
    const [appointmentDelete, setAppointmentDelete] = useState<Appointment>();
    const [showCancelBooking, setShowCancelBooking] = useState(false);
    const [showAddReport, setShowAddReport] = useState<Report | undefined>(
        undefined
    );
    const [showModalCheckout, setShowModalCheckout] = useState(false);
    const statusToNotAddReport = [status.CANCELLED, status.NO_SHOW];
    const filteredAndSorted = sort(
        filter(
            view,
            searchText,
            selectedStaff,
            selectedAllStaff,
            appointments,
            bookings
        ),
        sorting
    );

    const [bookingId, setBookingId] = useState<number | undefined>(undefined);
    const [appointmentId, setAppointmentId] = useState<number | undefined>(
        undefined
    );
    const [showBooking, setShowBooking] = useState<boolean>(false);
    const [showClient, setShowClient] = useState(false);
    const [showModalCompleteAppointment, setShowModalCompleteAppointment] =
        useState(false);
    const [completeReportData, setCompleteReportData] =
        useState<CompleteReportData>({
            appointmentId: undefined
        });
    const [rebookBooking, setRebookBooking] = useState<Booking | undefined>(
        undefined
    );

    useEffect(() => {
        if (appointmentLimits && props.clientID) {
            load(view, appointmentLimits);
        }
    }, [appointmentLimits]);

    useEffect(() => {
        sortHandler(SortFields.DATE_TIME, SortOrder.DESCENDANT);
    }, []);

    useNonInitialEffect(() => {
        switch (appointmentsStatus) {
            case AppointmentsStatus.FetchClientSuccess:
            case AppointmentsStatus.FetchClientPendingsSuccess:
            case AppointmentsStatus.FetchClientWithStaffIssuesSuccess:
                setLoading(prev => ({
                    ...prev,
                    appointments: false,
                    any: prev.bookings
                }));
                break;
            case AppointmentsStatus.CountClientSuccess:
                break;
            case AppointmentsStatus.Error:
                setLoading(prev => ({
                    ...prev,
                    appointments: false,
                    any: prev.bookings
                }));
                break;
        }
    }, [appointmentsStatus]);

    useNonInitialEffect(() => {
        switch (bookingsStatus) {
            case BookingsStatus.FetchClientSuccess:
            case BookingsStatus.FetchClientPendingsSuccess:
            case BookingsStatus.FetchClientWithStaffIssuesSuccess:
            case BookingsStatus.Error:
                setLoading(prev => ({
                    ...prev,
                    bookings: false,
                    any: prev.appointments
                }));
                break;
        }
    }, [bookingsStatus]);

    useNonInitialEffect(() => {
        switch (appointmentsStatus) {
            case AppointmentsStatus.FetchMergeablesSuccess:
                break;
        }

        if (
            appointmentsStatus ===
                AppointmentsStatus.FetchClientMergeablesSuccess &&
            !changeStatus
        ) {
            const invalidCheckoutStatuIds = [
                status.BOOKED,
                status.CONFIRMED,
                status.CHECKED_IN,
                status.IN_PROGRESS
            ];
            const validCheckoutStatuIds = [
                status.READY_FOR_CHECK_OUT,
                status.PAYMENT
            ];

            debugConsoleLog({ mergeableAppointments });

            if (
                mergeableAppointments.some(appointment =>
                    invalidCheckoutStatuIds.includes(appointment.status.id)
                )
            ) {
                setShowModalCheckout(true);
            } else {
                const appointmentIdsForCheckout = mergeableAppointments
                    .filter(appointment =>
                        validCheckoutStatuIds.includes(appointment.status.id)
                    )
                    .map(appointment => appointment.id!);

                setCheckoutAppointmentIds(appointmentIdsForCheckout);
                setCheckoutBookingId(mergeableAppointments[0].bookingId);
            }
        }
    }, [appointmentsStatus]);

    useNonInitialEffect(() => {
        switch (newBookingsStatus) {
            case NewBookingsStatus.SingleRescheduleSuccess:
            case NewBookingsStatus.RecurrentRescheduleSuccess:
                setRescheduleAppointmentId(undefined);
                setShowBooking(false);
                break;
        }
    }, [newBookingsStatus]);

    const selectionViewHandler = (selectedViewId: string) => {
        const view = views.find(v => v.id === selectedViewId)!;
        setPage(1);
        load(view, appointmentLimits!);
        setView(view);
    };

    const load = (view: BookingsView, period: Period) => {
        const from =
            view.id === upcomingView.id
                ? convertToYMDWithoutTimeZone(moment.tz(timeZone))
                : convertToYMDWithoutTimeZone(period.from);
        const to = convertToYMDWithoutTimeZone(period.to);
        setPage(1);
        if (props.clientID) {
            dispatch(
                clientCountThunk(apiClientWrapper, props.clientID, props.petIds)
            );
            setLoading(prev => ({
                ...prev,
                appointments:
                    view.showAppointments ||
                    view.showAppointments ||
                    view.showAppointmentsWithStaffIssues,
                bookings:
                    view.showBookings ||
                    view.showPendingBookings ||
                    view.showBookingsWithStaffIssues,
                count: true,
                any: true
            }));
            view.showAppointments &&
                dispatch(
                    fetchClientAppointmentsThunk(
                        apiClientWrapper,
                        view.appointmentStatus,
                        from,
                        to,
                        props.clientID,
                        props.petIds
                    )
                );
            view.showAppointmentsWithStaffIssues &&
                dispatch(
                    fetchClientAppointmentsWithStaffIssuesThunk(
                        apiClientWrapper,
                        props.clientID,
                        props.petIds
                    )
                );
            view.showPendingAppointments &&
                dispatch(
                    fetchClientPendingAppointmentsThunk(
                        apiClientWrapper,
                        props.clientID,
                        props.petIds
                    )
                );
            view.showBookings &&
                dispatch(
                    fetchClientBookingsThunk(
                        apiClientWrapper,
                        from,
                        to,
                        props.clientID,
                        props.petIds
                    )
                );
            view.showBookingsWithStaffIssues &&
                dispatch(
                    fetchClientBookingsWithStaffIssuesThunk(
                        apiClientWrapper,
                        props.clientID,
                        props.petIds
                    )
                );
            view.showPendingBookings &&
                dispatch(
                    fetchClientPendingBookingsThunk(
                        apiClientWrapper,
                        props.clientID,
                        props.petIds
                    )
                );
        }
    };

    const changePeriodHandler = (period: Period) => {
        setDatePeriod(period);
        setPage(1);
        load(view, period);
    };

    const changeStatusHandler = (appointmentId: number, statusId: number) => {
        setPage(1);
        dispatch(
            changeStatusAppointmentThunk(
                apiClientWrapper,
                appointmentId,
                statusId
            )
        );
        if (props.from === 'client') {
            if (statusId === status.CHECKED_IN) {
                amplitude.track(
                    AMPLITUDE.CTA_CHECK_IN_APPOINTMENT_BOOKING_CLIENT
                );
            }
            if (statusId === status.IN_PROGRESS) {
                amplitude.track(AMPLITUDE.CTA_START_APPOINTMENT_BOOKING_CLIENT);
            }
        }
        if (props.from === 'pet') {
            if (statusId === status.CHECKED_IN) {
                amplitude.track(AMPLITUDE.CTA_CHECK_IN_APPOINTMENT_BOOKING_PET);
            }
            if (statusId === status.IN_PROGRESS) {
                amplitude.track(AMPLITUDE.CTA_START_APPOINTMENT_BOOKING_PET);
            }
        }
    };

    const assignStaffHandler = (
        appointmentId: number,
        staff: AvailableStaff | undefined
    ) => {
        dispatch(
            assignAppointmentStaffThunk(apiClientWrapper, appointmentId, staff)
        );
    };

    const showBookingHandler = (bookingId: number, appointmentId: number) => {
        setShowBooking(true);
        setBookingId(bookingId);
        setAppointmentId(appointmentId);
        if (props.from === 'client') {
            amplitude.track(AMPLITUDE.CTA_BOOKING_DETAILS_CLIENT_HISTORY);
        }

        if (props.from === 'pet') {
            amplitude.track(AMPLITUDE.CTA_BOOKING_DETAILS_PET_HISTORY);
        }
    };

    const showAppointmentHandler = (appointmentId: number) => {
        console.info({ appointmentId });

        const appointment = appointments.find(
            w => w.appointment!.id === appointmentId
        )?.appointment;

        setShowBooking(true);
        setBookingId(appointment?.bookingId);
        setAppointmentId(appointmentId);

        if (props.from === 'client') {
            amplitude.track(AMPLITUDE.CTA_BOOKING_DETAILS_CLIENT_HISTORY);
        }

        if (props.from === 'pet') {
            amplitude.track(AMPLITUDE.CTA_BOOKING_DETAILS_PET_HISTORY);
        }
    };
    const onBackAddReportHandler = () => {
        setShowAddReport(undefined);
    };
    const onBackBooking = () => {
        setShowBooking(false);
        setBookingId(undefined);
        setAppointmentId(undefined);
    };

    const checkoutBackHandler = () => {
        setCheckoutAppointmentIds([]);
        setBookingId(undefined);
    };

    const completeCheckoutHandler = () => {
        checkoutBackHandler();
    };

    const checkoutHandler = async (appointmentId: number) => {
        const appointment = appointments.find(
            w => w.appointment!.id === appointmentId
        )?.appointment;

        if (appointment) {
            dispatch(
                fetchClientMergeableAppointmentsThunk(
                    apiClientWrapper,
                    appointment,
                    props.clientID!
                )
            );
        }
    };
    const addReportHandler = (
        appointmentId: number,
        businessAreaId: string,
        clientId: number
    ) => {
        const appointmentFind = appointments.find(
            w => w.appointment?.id === appointmentId
        )!.appointment;
        const enableToAddReport = !statusToNotAddReport.includes(
            appointmentFind?.status.id
        );

        if (!appointmentFind.services[0].hasReport || enableToAddReport) {
            setShowAddReport({
                appointmentId,
                businessAreaId,
                onlyAddReport: true
            });
        }
    };

    const addReportBookingHandler = (
        bookingId: number,
        appointmentId: number,
        businessAreaId: string,
        clientId: number
    ) => {
        const bookingFind = bookings.find(
            w => w.booking?.id === bookingId
        )!.booking;
        const enableToAddReport = !statusToNotAddReport.includes(
            bookingFind?.appointments[0]?.status.id
        );
        if (
            !bookingFind?.appointments[0]?.services[0]?.hasReport ||
            enableToAddReport
        ) {
            setShowAddReport({
                appointmentId,
                businessAreaId,
                onlyAddReport: true
            });
        }
    };
    const backNewBookingHandler = () => {
        setShowBookingCancel(false);
        setRescheduleAppointmentId(undefined);
        setRebookBooking(undefined);
        if (props.clientID) {
            dispatch(refetchClientAppointmentsThunk(apiClientWrapper));
            dispatch(refetchClientBookingsThunk(apiClientWrapper));
        }
    };

    const rebookHandler = (selectedBooking: Booking) => {
        const bookingToRebook: Booking = {
            ...selectedBooking,
            bookedAt: moment().tz(marketplace.schedule.timeZone),
            customer: selectedBooking.customer,
            id: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            appointments: selectedBooking?.appointments.map(appointment => ({
                ...appointment,
                id: undefined,
                recurringUuid: undefined,
                uuid: uuid(),
                notes: '',
                reports: {
                    dentalReportId: undefined,
                    groomingReportId: undefined
                },
                duration: {
                    from: moment().tz(marketplace.schedule.timeZone),
                    to: moment()
                        .tz(marketplace.schedule.timeZone)
                        .add(
                            appointment.duration.to.diff(
                                appointment.duration.from
                            )
                        )
                },
                createdAt: undefined,
                updatedAt: undefined
            }))
        };

        setRebookBooking(bookingToRebook);
        setShowBooking(false);
    };

    const confirmBookingHandler = (bookingId: number) => {
        dispatch(confirmBookingThunk(apiClientWrapper, bookingId));
        if (props.from === 'client') {
            amplitude.track(AMPLITUDE.CTA_CONFIRM_APPOINTMENT_CLIENT);
        }
        if (props.from === 'pet') {
            amplitude.track(AMPLITUDE.CTA_CONFIRM_APPOINTMENT_PET);
        }
    };

    const sortHandler = (field: SortFields, order: SortOrder) => {
        setSorting({ field, order });
        setPage(1);
    };

    const bookHandler = (clientId: number, petId: number, name: string) => {
        setCustomerID(clientId);
        setPetID(petId);
        setCustomerName(name);
        setShowBookingCancel(true);
    };

    const rescheduleHandler = (appointmentId: number) => {
        const bookingWrapper = appointments.find(
            a => a.appointment.id === appointmentId
        );
        setRescheduleBooking(bookingWrapper);

        setRescheduleAppointmentId(appointmentId);
    };

    const rescheduleBookingHandler = (bookingId: number) => {
        const bookingWrapper = bookings.find(w => w.booking?.id === bookingId);
        setRescheduleBooking(bookingWrapper);

        if (
            bookingWrapper &&
            bookingWrapper.booking.appointments.length === 1
        ) {
            setRescheduleAppointmentId(
                bookingWrapper.booking.appointments[0].id!
            );
        }
    };

    const backClientHandler = () => {
        setShowNewClient(false);
        setShowClient(false);
    };

    const deleteClientHandler = () => {
        setShowNewClient(false);
        setCustomerID(undefined);
        setShowClient(false);
    };

    const showInvoiceHandler = (appointmentId: number) => {
        const appointment = appointments.find(
            w => w.appointment!.id === appointmentId
        )!.appointment;

        setInvoiceIdToShow(appointment?.invoiceId);
    };

    const declineHandler = (appointmentId: number, businessAreaId: string) => {
        const appointment = appointments.find(
            w => w.appointment?.id === appointmentId
        )!.appointment;
        if (appointment.services[0].hasReport) {
            setDecline({ appointmentId, businessAreaId });
        } else {
            dispatch(declineAppointmentThunk(apiClientWrapper, appointmentId));
        }
    };

    const undoAppointmentHandler = (
        appointmentId: number,
        statusId: number
    ) => {
        setChangeStatus(true);
        changeStatusHandler(appointmentId, statusId);
    };

    const completeHandler = (appointmentId: number, sendSms: boolean) => {
        setShowModalCompleteAppointment(false);

        dispatch(
            completeAppointmentThunk(apiClientWrapper, appointmentId, sendSms)
        );

        setTrackEvent(
            `${process.env.REACT_APP_HUBSPOT_EVENT_APPOINTMENT_COMPLETED}`
        );

        if (props.from === 'client') {
            amplitude.track(AMPLITUDE.CTA_COMPLETE_APPOINTMENT_BOOKING_CLIENT);
        }
        if (props.from === 'pet') {
            amplitude.track(AMPLITUDE.CTA_COMPLETE_APPOINTMENT_BOOKING_PET);
        }
    };

    const completeWithModalHandler = (appointmentId: number) => {
        setCompleteReportData({
            appointmentId
        });
        setShowModalCompleteAppointment(true);
    };

    const addNewClient = () => {
        setShowNewClient(true);
    };

    const handlerCreateClient = (client: ClientModel) => {
        setCustomerID(client.id!);
        setCustomerName(`${client.firstName} ${client.lastName}`);
        setPetID(client.pets.length === 1 ? client.pets[0].id : 0);

        setShowNewClient(false);
        setShowClient(false);
        setShowBookingCancel(true);
    };

    const editReportHandler = (
        appointmentId: number,
        businessAreaId: string
    ) => {
        setShowReport(undefined);
        setEditReport({ appointmentId, businessAreaId });
    };

    const viewReportHandler = (
        appointmentId: number,
        businessAreaId: string
    ) => {
        setShowReport(undefined);
        setEditReport(undefined);
        setShowReport({ appointmentId, businessAreaId });
    };

    const backReportHandler = () => {
        setShowReport(undefined);
    };

    const closeReportHandler = () => {
        setShowReport(undefined);
        setEditReport(undefined);
        setShowAddReport(undefined);
    };

    const onCancelAppointmentHandler = (appointmentId: number) => {
        const appointmentWrapper = appointments.find(
            w => w.appointment?.id === appointmentId
        )!.appointment;

        setAppointmentDelete(appointmentWrapper);
        setBookingDelete(undefined);

        setShowCancelBooking(true);
    };

    const onCancelBookingHandler = (bookingId: number) => {
        const bookingWrapper = bookings.find(w => w.booking?.id === bookingId);

        setBookingDelete(bookingWrapper?.booking);
        setAppointmentDelete(undefined);

        setShowCancelBooking(true);
    };

    const closeCancelHandler = () => {
        setShowCancelBooking(false);
    };

    const confirmCancelHandler = (
        bookingId?: number,
        appointmentId?: number,
        check?: boolean
    ) => {
        if (bookingId)
            dispatch(cancelBookingThunk(apiClientWrapper, bookingId, check));
        if (appointmentId)
            dispatch(
                cancelAppointmentThunk(apiClientWrapper, appointmentId!, check)
            );
        setShowCancelBooking(false);
    };

    const confirmCancelRecurrentHandler = (
        appointmentId: number,
        check?: boolean
    ) => {
        dispatch(
            cancelRecurrentAppointmentsThunk(
                apiClientWrapper,
                appointmentId,
                status.CANCELLED,
                check
            )
        );
        setShowCancelBooking(false);
    };

    const handleShowClient = (clientId: number) => {
        setShowClient(true);
        setCustomerID(clientId);
    };

    const handlerRescheduleFromDetail = (
        rescheduleBook: BookingWrapper,
        appId?: number
    ) => {
        setRescheduleAppointmentId(appId);
        setRescheduleBooking(rescheduleBook);
    };

    const cancelBookingView = (
        <PopUpCancelBooking
            booking={bookingDelete}
            appointment={appointmentDelete}
            //
            onClose={closeCancelHandler}
            onConfirmCancel={confirmCancelHandler}
            onConfirmCancelRecurrent={confirmCancelRecurrentHandler}
        />
    );

    const addReportView = (
        <ClickAwayListener onClickAway={closeReportHandler}>
            <OverDetail pageSidebar={true}>
                <CompleteReport
                    appointmentId={showAddReport?.appointmentId!}
                    businessAreaId={showAddReport?.businessAreaId!}
                    onBack={onBackAddReportHandler}
                    onClose={closeReportHandler}
                    declined={false}
                    onlyAddReport={showAddReport?.onlyAddReport!}
                    componentClient={true}
                    emailConfigured={emailConfigured}
                />
            </OverDetail>
        </ClickAwayListener>
    );
    const declinedView = (
        <ClickAwayListener onClickAway={closeReportHandler}>
            <OverDetail pageSidebar={true}>
                <CompleteReport
                    appointmentId={decline?.appointmentId!}
                    businessAreaId={decline?.businessAreaId!}
                    onBack={() => setDecline(undefined)}
                    onClose={closeReportHandler}
                    declined={true}
                    componentClient={true}
                />
            </OverDetail>
        </ClickAwayListener>
    );

    const viewReportView = (
        <ClickAwayListener onClickAway={closeReportHandler}>
            <OverDetail pageSidebar={true}>
                <ViewReport
                    appointmentId={showReport?.appointmentId!}
                    businessAreaId={showReport?.businessAreaId!}
                    emailConfigured={emailConfigured}
                    onBack={backReportHandler}
                    onClose={closeReportHandler}
                    onEditGrooming={() =>
                        editReportHandler(
                            showReport?.appointmentId!,
                            showReport?.businessAreaId!
                        )
                    }
                    onEditDental={() =>
                        editReportHandler(
                            showReport?.appointmentId!,
                            showReport?.businessAreaId!
                        )
                    }
                />
            </OverDetail>
        </ClickAwayListener>
    );

    const editReportView = (
        <ClickAwayListener onClickAway={closeReportHandler}>
            <OverDetail pageSidebar={true}>
                <EditReport
                    appointmentId={editReport?.appointmentId!}
                    businessAreaId={editReport?.businessAreaId!}
                    onBack={() => setEditReport(undefined)}
                    onClose={closeReportHandler}
                    onView={() =>
                        viewReportHandler(
                            editReport?.appointmentId!,
                            editReport?.businessAreaId!
                        )
                    }
                    declined={false}
                    emailConfigured={emailConfigured}
                />
            </OverDetail>
        </ClickAwayListener>
    );

    const checkout = (
        <ClickAwayListener onClickAway={checkoutBackHandler}>
            <OverDetail pageSidebar={true}>
                <Checkout
                    bookingId={checkoutBookingId!}
                    appointmentIds={checkoutAppointmentIds}
                    onBack={checkoutBackHandler}
                    onComplete={completeCheckoutHandler}
                />
            </OverDetail>
        </ClickAwayListener>
    );

    const bookCancelledView = (
        <CreateBookingDrawer
            fromClient={true}
            parentID={customerID}
            petID={petID}
            onAddClient={addNewClient}
            onClose={backNewBookingHandler}
            onBooked={backNewBookingHandler}
        />
    );

    const rescheduleView = (
        <CreateBookingDrawer
            fromClient={true}
            bookingReschedule={rescheduleBooking}
            appointmentId={appointmentId}
            onClose={backNewBookingHandler}
            onBooked={backNewBookingHandler}
            showTabs={false}
        />
    );

    const newClientView = (
        <OverFullWindow>
            <Client
                hideHeader={true}
                clientId={customerID}
                onClose={backClientHandler}
                onClientSaved={handlerCreateClient}
                onDelete={deleteClientHandler}
            />
        </OverFullWindow>
    );

    const detailBookingView = (
        <BookingDetail
            fromClient={true}
            bookingId={bookingId!}
            appointmentId={appointmentId!}
            onBack={onBackBooking}
            onReschedule={handlerRescheduleFromDetail}
            onShowClient={handleShowClient}
            onRebook={rebookHandler}
        />
    );

    const reBook = (
        <CreateBookingDrawer
            fromClient={true}
            detailView={true}
            bookingRebook={rebookBooking}
            petID={rebookBooking?.appointments[0].pet.id}
            parentID={rebookBooking?.customer.id}
            appointmentId={rebookBooking?.appointments[0].id}
            showTabs={false}
            onClose={backNewBookingHandler}
            onBooked={backNewBookingHandler}
        />
    );

    const invoiceComponentView = (
        <OverDetail>
            <DisplayInvoice
                invoiceId={invoiceIdToShow}
                onBack={() => setInvoiceIdToShow(undefined)}
            />
        </OverDetail>
    );

    const clientPetsFormView = (
        <Client
            clientId={clientID}
            petId={petID}
            onClose={() => {
                setClientID(undefined);
            }}
        />
    );

    const petPopupView = (
        <PetCard petId={popupPetId!} onClose={() => setPopupPetId(undefined)} />
    );

    const paginationFull = (
        <Pagination
            className={classes.paginationFull}
            count={filteredAndSorted.length}
            page={page}
            pageSize={pageSize}
            onPageChange={page => setPage(page)}
            onPageSizeChange={pageSize => {
                setPage(1);
                setPageSize(pageSize);
            }}
        />
    );

    const modalCompleteAppointment = (
        <CompleteAppointmentDialog
            onCancel={() => setShowModalCompleteAppointment(false)}
            onComplete={(sendSms: boolean) =>
                completeHandler(completeReportData?.appointmentId!, sendSms)
            }
        />
    );

    const spinner = <Spinner />;

    return (
        <>
            <AppointmentsWebSockets />
            <Box className={classes.container}>
                <Box className={classes.headerContainer}>
                    <BookingsHeader
                        period={datePeriod}
                        views={views
                            .filter(
                                v =>
                                    (v.id === pendingView.id &&
                                        counter.appointments.pendings +
                                            counter.bookings.pendings >
                                            0) ||
                                    (v.id === staffIssuesView.id &&
                                        counter.appointments.withIssues +
                                            counter.bookings.withIssues >
                                            0) ||
                                    ![
                                        pendingView.id,
                                        staffIssuesView.id
                                    ].includes(v.id)
                            )
                            .map(view => ({
                                id: view.id,
                                name: view.name,
                                mark: view.mark,
                                selectedBackgroundColor:
                                    view.selectedBackgroundColor
                            }))}
                        selectedViewId={view.id}
                        onSelectView={selectionViewHandler}
                        onSearch={text => {
                            setSearchText(text);
                        }}
                        onChangePeriod={changePeriodHandler}
                        onSelectStaff={(selectedStaffIds, all) => {
                            setSelectedStaff(selectedStaffIds);
                            setSelectedAllStaff(all);
                        }}
                        count={loading.any ? 0 : filteredAndSorted.length}
                        title={props.clientID ? false : true}
                        fromClient={true}
                        staff={getAssignedStaff(appointments, bookings).map(
                            staff =>
                                staff === undefined
                                    ? {
                                          id: undefined,
                                          name: 'Unassigned',
                                          avatar: ''
                                      }
                                    : {
                                          id: staff.id,
                                          name: staff.firstName,
                                          avatar: staff.avatar
                                      }
                        )}
                    />
                </Box>

                <Box className={classes.boxBookingContainer}>
                    <OverlayScrollbarsComponent
                        className={clsx({ [classes.scrollClient]: true })}
                    >
                        {loading.any && spinner}
                        {!loading.any &&
                            filteredAndSorted.length > 0 &&
                            (selectedStaff.length > 0 || selectedAllStaff) && (
                                <Box className={classes.tableContainer}>
                                    {showModalCheckout && (
                                        <ConfirmDialog
                                            open={showModalCheckout}
                                            question={
                                                <>
                                                    This appointment is unified
                                                    with another, make sure that
                                                    they are all in completed
                                                    status, in order to continue
                                                    to checkout.
                                                </>
                                            }
                                            actions={
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <Button
                                                        variant="primary"
                                                        label="Understood"
                                                        onClick={() =>
                                                            setShowModalCheckout(
                                                                false
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            }
                                            onCancel={() =>
                                                setShowModalCheckout(false)
                                            }
                                        />
                                    )}
                                    <Table
                                        wrappers={
                                            pageSize === 0
                                                ? filteredAndSorted
                                                : filteredAndSorted.slice(
                                                      (page - 1) * pageSize,
                                                      page * pageSize
                                                  )
                                        }
                                        view={view}
                                        viewClient={true}
                                        loading={loading.any}
                                        onShowBooking={showBookingHandler}
                                        onConfirm={confirmBookingHandler}
                                        onShowAppointment={
                                            showAppointmentHandler
                                        }
                                        onAssignStaff={assignStaffHandler}
                                        onCheckIn={appointmentUuid =>
                                            changeStatusHandler(
                                                appointmentUuid,
                                                status.CHECKED_IN
                                            )
                                        }
                                        onStart={appointmentUuid =>
                                            changeStatusHandler(
                                                appointmentUuid,
                                                status.IN_PROGRESS
                                            )
                                        }
                                        onComplete={(appointmentId: number) =>
                                            hasReportsPremiumAccess
                                                ? completeWithModalHandler(
                                                      appointmentId
                                                  )
                                                : completeHandler(
                                                      appointmentId,
                                                      false
                                                  )
                                        }
                                        onCheckout={checkoutHandler}
                                        onShowReport={(
                                            appointmentId: number,
                                            businessAreaId: string
                                        ) =>
                                            setShowReport({
                                                appointmentId,
                                                businessAreaId
                                            })
                                        }
                                        onNoShow={appointmentUuid =>
                                            changeStatusHandler(
                                                appointmentUuid,
                                                status.NO_SHOW
                                            )
                                        }
                                        onShowInvoice={showInvoiceHandler}
                                        onShowPet={petId => {
                                            setPopupPetId(petId);
                                        }}
                                        onShowClient={clientId => {
                                            setClientID(clientId);
                                        }}
                                        onSort={sortHandler}
                                        onReschedule={rescheduleHandler}
                                        onRescheduleBooking={
                                            rescheduleBookingHandler
                                        }
                                        onBook={bookHandler}
                                        onDecline={declineHandler}
                                        onUndoAppointment={
                                            undoAppointmentHandler
                                        }
                                        onCancel={onCancelAppointmentHandler}
                                        onCancelBooking={onCancelBookingHandler}
                                        onShowAddReport={addReportHandler}
                                        onShowAddReportBooking={
                                            addReportBookingHandler
                                        }
                                    />
                                </Box>
                            )}
                        {!loading.any &&
                            filteredAndSorted.length === 0 &&
                            (selectedStaff.length > 0 || selectedAllStaff) && (
                                <Box
                                    style={{
                                        display: 'flex',
                                        minHeight: '100%'
                                    }}
                                >
                                    <BookingsNotCreated view={view} />
                                </Box>
                            )}
                        {!loading.any &&
                            selectedStaff.length === 0 &&
                            !selectedAllStaff && (
                                <Box>
                                    <StaffNotSelected />
                                </Box>
                            )}
                    </OverlayScrollbarsComponent>
                </Box>

                {!loading.any &&
                    filteredAndSorted.length > pageSize &&
                    paginationFull}

                {showBookingCancel && bookCancelledView}
                {showBooking &&
                    !rescheduleAppointmentId &&
                    !showClient &&
                    detailBookingView}

                {checkoutAppointmentIds.length > 0 && checkout}

                {(showNewClient || showClient) && newClientView}
                {rescheduleAppointmentId && rescheduleView}
                {invoiceIdToShow && invoiceComponentView}

                {showCancelBooking && cancelBookingView}

                {editReport && editReportView}
                {showReport && viewReportView}

                {popupPetId && !clientID && petPopupView}
                {decline && declinedView}
                {clientID && clientPetsFormView}
                {showAddReport && addReportView}
                {showModalCompleteAppointment && modalCompleteAppointment}
                {rebookBooking && reBook}
            </Box>
        </>
    );
};

export default BookingsComponentClient;
