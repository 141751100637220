import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Booking, BookingWrapper } from '@spike/booking-model';
import moment, { Moment } from 'moment-timezone';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import CreateBookingHeader from './CreateBookingHeader';
import FooterComponent from './FooterComponent';
import ClientSearch from './ClientSearch/ClientSearch';
import PetSearch from './PetSearch';
import Pet from '@spike/pet-model';
import { Option } from '@spike/model';
import DateSelector from './DateSelector';
import ServiceSelector from './ServiceSelector';
import { PetService, NewBookingAlert, NewBookingAppointment } from '@spike/new-booking-model';
import {
    useApiClientWrapper,
    useAuth,
    useMarketplace,
    useTimeZone
} from 'hooks';
import StaffSelector from './StaffSelector';
import DepositSelector from './DepositSelector';
import Notes from './Notes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
    NewBookingsStatus,
    saveForDepositThunk,
    saveThunk,
    fetchAlertsThunk,
    fetchPetServicesThunk,
    recurrentRescheduleThunk,
    singleRescheduleThunk,
    saveMultipleBookingThunk
} from '@spike/new-bookings-action';
import useNonInitialEffect from '@versiondos/hooks';
import {
    AppointmentsStatus,
    countRecurrentAppointmentsThunk,
    deleteAppointmentThunk
} from '@spike/bookings-action';
import CollectDeposit from './CollectDeposit';
import AppointmentCreated from './UI/AppointmentCreated';
import ConfirmClosePopup from './UI/ConfirmClosePopup';
import clsx from 'clsx';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import { MarketplaceDepositType } from '@spike/marketplace-model';
import { convertToNumber } from '@spike/price-utils';
import { AppointmentCountRecurrent } from '@spike/appointment-model';
import { InvoicesStatus, getInvoiceThunk } from '@spike/invoices-action';
import InvoiceModel from '@spike/invoice-model';
import ConfirmRescheduleDialog from 'components/UI/ConfirmRescheduleDialog';
import ConfirmRescheduleRecurringDialog from './UI/ConfirmRescheduleRecurringDialog';
import Staff from 'model/Staff';
import { PetsState } from '@spike/pets-action';
import MultiplePets from './MultiplePets';
import PetAndServices from './PetAndServices';

interface CreateBookingProps {
    fromClient?: boolean;
    bookingRebook?: Booking;
    bookingReschedule?: BookingWrapper;
    staffId?: number;
    at?: Moment;
    parentID?: number;
    petID?: number;
    parentName?: string;
    appointmentId?: number;
    detailView?: boolean;
    clientAdded?: boolean;
    petAdded?: boolean;
    petsLoaded?: boolean;
    onAddClient?: () => void;
    onAddPet?: (clientId: number) => void;
    onSave?: () => void;
    onClose: () => void;
    onBooked: (bookedAt?: Moment) => void;
    onStartBooking?: () => void;
    onChangeTime?: (
        bookedAt?: Moment,
        durationInMinutes?: number,
        staffId?: number
    ) => void;
    onRemoveClient?: () => void;
    onShowBlockedTime?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%'
        },
        headerContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '17px 27px 27px 27px',
            [theme.breakpoints.down('sm')]: {
                margin: '20px 30px 30px 30px'
            },
            [theme.breakpoints.up('md')]: {
                margin: '20px 30px 27px 30px'
            }
        },
        appointmentContainer: {
            margin: '0px 26px 0px 26px',
            [theme.breakpoints.up('md')]: {
                margin: '0px 30px 0px 30px'
            }
        },
        totalContainer: {
            height: '15%',
            borderTop: '1px solid #D4D4D4'
        },
        marginTotalContainer: {
            marginTop: 11,
            marginBottom: 14
        },
        sectionContainer: {
            paddingTop: 18,
            [theme.breakpoints.up('md')]: {
                paddingTop: 20
            }
        },
        appointmentCreatedContainer: {
            height: '85%'
        },
        withoutEvents: {
            pointerEvents: 'none'
        },
        marginTop: {
            marginTop: -20
        }
    })
);

const enum wizardBooking {
    PetParent = 'petParent',
    Service = 'service',
    Staff = 'staff',
    Deposit = 'deposit',
    Notes = 'notes'
}

interface PetServiceWrapper extends PetService {
    duration?: Duration;
}

interface PetServiceTotal {
    price: number;
    durationInMinutes: number;
    duration?: Duration;
}

interface AppointmentDataVaraible {
    service: PetServiceWrapper;
    staffId: number;
    date: Moment;
}

interface AppointmentMultiple {
    clientId: number;
    petId: number;
    createdByStaffId: number;
    notes?: string;
    variableData: Array<AppointmentDataVaraible>;
}

export const CreateBooking: FunctionComponent<CreateBookingProps> = props => {
    const classes = useStyles();
    const timezone = useTimeZone();
    const apiClientWrapper = useApiClientWrapper();
    const auth = useAuth();
    const marketplace = useMarketplace();
    const dispatch = useDispatch();

    const alerts = useSelector<RootState, Array<NewBookingAlert> | undefined>(
        state => state.newBookings.alerts
    );
    const newBookingsStatus = useSelector<RootState, NewBookingsStatus>(
        state => state.newBookings.status
    );
    const savedBooking = useSelector<RootState, Booking | undefined>(
        state => state.newBookings.bookingSavedForDeposit
    );
    const appointmentsStatus = useSelector<RootState, AppointmentsStatus>(
        state => state.appointments.status
    );
    const petServices = useSelector<RootState, Array<PetService>>(
        state => state.newBookings.petServices
    );
    const petsState = useSelector<RootState, PetsState>(
        state => state.pets
    );
    const recurrentInfo = useSelector<
        RootState,
        undefined | AppointmentCountRecurrent
    >(state => state.appointments.recurrent);
    const invoice = useSelector<RootState, InvoiceModel | undefined>(
        state => state.invoices.invoice
    );
    const invoiceStatus = useSelector<RootState, InvoicesStatus>(
        state => state.invoices.status
    );

    const [appointmentID, setAppointmentID] = useState<number>();
    const [bookingID, setBookingID] = useState<number>();
    const [reschedule, setReschedule] = useState(false);
    const [parentId, setParentId] = useState<number | undefined>(
        props.parentID
    );
    const [parentName, setParentName] = useState<string | undefined>(
        props.parentName
    );
    const [petId, setPetId] = useState<number | undefined>(props.petID);
    const [showPet, setShowPet] = useState(false);
    const [activeFrequency, setActiveFrequency] = useState(false);
    const [frequency, setFrequency] = useState<Option<string> | undefined>(
        undefined
    );
    const [frequencyReschedule, setFrequencyReschedule] = useState<
        Option<string> | undefined
    >(undefined);
    const [duration, setDuration] = useState<Option<string> | undefined>();
    const [service, setService] = useState<PetServiceWrapper | undefined>();
    const [serviceRescheduleId, setServiceRescheduleId] = useState<
        number | undefined
    >();
    const [date, setDate] = useState<Moment>(
        props.at ? props.at : moment().tz(timezone)
    );
    const [dateReschedule, setDateReschedule] = useState<Moment | undefined>(
        undefined
    );
    const [staffId, setStaffId] = useState<number | undefined>();
    const [staffRescheduleId, setStaffRescheduleId] = useState<
        number | undefined
    >();
    const [showService, setShowService] = useState(false);
    const [showStaff, setShowStaff] = useState(false);
    const [showDepositAndNote, setShowDepositAndNote] = useState(false);
    const [isCollectDeposit, setIsCollectDeposit] = useState(false);
    const [notes, setNotes] = useState('');
    const [notesReschedule, setNotesReschedule] = useState('');
    const [appointmentUuid, setAppointmentUuid] = useState<string | null>(null);
    const [saving, setSaving] = useState(false);
    const [depositCollect, setDepositCollect] = useState(false);
    const [summary, setSummary] = useState(false);
    const [appointmentCreated, setAppointmentCreated] = useState(false);
    const [closing, setClosing] = useState({ close: false, tabChange: false });
    const [firstLoad, setFirstLoad] = useState(true);
    const [deposit, setDeposit] = useState<string | undefined>(undefined);
    const [showRescheduleModal, setShowRescheduleModal] =
        useState<boolean>(false);
    const [showRescheduleRecurringModal, setShowRescheduleRecurringModal] =
        useState<boolean>(false);
    const [rescheduleRecurring, setRescheduleRecurring] =
        useState<boolean>(false);
    const [recurring, setRecurring] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [firstReschedule, setFirstReschedule] = useState<boolean>(false);
    const [touched, setTouched] = useState(false);
    const [rebook, setRebook] = useState(false);
    const [showPetAndServices, setShowPetAndServices] = useState(false);
    const [versionPetAndService, setVersionPetAndService] = useState<'another' | 'edit' | 'add' | undefined>(undefined);
    const [multipleAppointments, setMultipleAppointments] = useState<Array<AppointmentMultiple>>([]);
    const [exceededDeposit, setExceededDeposit] = useState<boolean>(
        marketplace.deposit.type === MarketplaceDepositType.Amount &&
            convertToNumber(marketplace.deposit.value || '0') >
                (multipleAppointments.length > 0 ? multipleAppointments[0].variableData[0].service.price || 0 : service?.price || 0)
    );
    const [serviceTotal, setServiceTotal] = useState<PetServiceTotal | undefined>();
    const [bookingModified, setBookingModified] = useState<boolean>(false);
    const [showAlertBusinessHour, setShowAlertBusinessHour] = useState<boolean>(false);
    
    const handlerRemove = (value: string) => {
        switch (value) {
            case wizardBooking.PetParent:
                clearClient();
                setParentId(undefined);
                clearPet();
                setShowPet(false);
                setShowService(false);
                setShowStaff(false);
                setShowDepositAndNote(false);
                setMultipleAppointments([]);
                break;
            case wizardBooking.Service:
                setService(undefined);
                setDuration(undefined);
                break;
            case wizardBooking.Staff:
                setStaffId(undefined);
                break;
            case wizardBooking.Deposit:
                setIsCollectDeposit(false);
                break;
            case wizardBooking.Notes:
                setNotes('');
                break;
        }
    };

    const handleCloseBooking = () => {
        handlerRemove(wizardBooking.PetParent);
        handlerRemove(wizardBooking.Service);
        handlerRemove(wizardBooking.Staff);
        handlerRemove(wizardBooking.Deposit);
        handlerRemove(wizardBooking.Notes);

        props.onClose();
    };

    const handleSetFrequency = () => {
        if (frequencyReschedule?.id) {
            if (frequency?.id) {
                if (frequency?.id === frequencyReschedule?.id) {
                    return undefined;
                } else {
                    return frequency?.id;
                }
            } else {
                return null;
            }
        } else {
            if (frequency?.id) {
                return frequency?.id;
            } else {
                return undefined;
            }
        }
    };

    const rescheduleFunction = (check?: boolean, value?: string) => {
        setSaving(true);
        if (value === 'only') {
            dispatch(
                singleRescheduleThunk(
                    apiClientWrapper,
                    bookingID!,
                    appointmentID!,
                    date,
                    staffId !== staffRescheduleId ? staffId : undefined,
                    service?.service.id !== serviceRescheduleId
                        ? service?.service.id
                        : undefined,
                    notes !== notesReschedule ? notes : undefined,
                    check
                )
            );
        } else {
            dispatch(
                recurrentRescheduleThunk(
                    apiClientWrapper,
                    appointmentID!,
                    date,
                    staffId !== staffRescheduleId ? staffId : undefined,
                    service?.service.id !== serviceRescheduleId
                        ? service?.service.id
                        : undefined,
                    handleSetFrequency(),
                    notes !== notesReschedule ? notes : undefined,
                    check
                )
            );
        }
    };

    const handleBooked = () => {
        if (!reschedule) {
            setSaving(true);
        }
        if (isCollectDeposit) {
            amplitude.track(AMPLITUDE.CTA_PAY_AND_BOOK_APPOINTMENT);
        } else {
            amplitude.track(AMPLITUDE.CTA_BOOK_APPOINTMENT);
        }

        if (appointmentCreated) {
            handleCloseBooking();
        } else if (isCollectDeposit) {
            const appointmentUuid = apiClientWrapper.uuid();
            setAppointmentUuid(appointmentUuid);
            if(multipleAppointments.length > 0) {
                dispatch(
                    saveForDepositThunk(
                        apiClientWrapper,
                        parentId!,
                        multipleAppointments[0].petId,
                        multipleAppointments[0].variableData[0].service.service.id,
                        date,
                        multipleAppointments[0].notes || '',
                        appointmentUuid,
                        auth.user!.staffId,
                        multipleAppointments[0].variableData[0].staffId
                    )
                );
            } else {
                dispatch(
                    saveForDepositThunk(
                        apiClientWrapper,
                        parentId!,
                        petId!,
                        service!.service.id,
                        date,
                        notes || '',
                        appointmentUuid,
                        auth.user!.staffId,
                        staffId
                    )
                );
            }
        } else {
            if (!reschedule) {
                if(multipleAppointments.length > 1 || (multipleAppointments.length === 1 && multipleAppointments[0].variableData.length > 1)) {
                    const multipleBookingFormatted: Array<NewBookingAppointment> = getMultipleBookingToSave();
                    dispatch(
                        saveMultipleBookingThunk(
                            apiClientWrapper,
                            multipleBookingFormatted
                        )
                    );
                } else {
                    dispatch(
                        saveThunk(
                            apiClientWrapper,
                            parentId!,
                            multipleAppointments[0]?.petId ?? petId,
                            multipleAppointments[0]?.variableData[0]?.service?.service?.id ?? service?.service?.id,
                            date,
                            notes,
                            auth.user!.staffId,
                            multipleAppointments[0]?.variableData[0]?.staffId ?? staffId,
                            frequency?.id
                        )
                    );
                }
            } else {
                if (recurring) {
                    if (activeFrequency) {
                        setShowRescheduleRecurringModal(true);
                        if (dateReschedule === date) {
                            setRescheduleRecurring(false);
                        } else {
                            setRescheduleRecurring(true);
                        }
                    } else {
                        rescheduleFunction();
                    }
                } else {
                    if (dateReschedule === date) {
                        rescheduleFunction(
                            false,
                            activeFrequency ? undefined : 'only'
                        );
                    } else {
                        setShowRescheduleModal(true);
                    }
                }
            }
        }
    };

    const haveMultiplePets = () => {
        return multipleAppointments.length > 1 || (multipleAppointments.length === 1 && multipleAppointments[0].variableData.length > 1);
    }

    const handlerSavePetAndServices = (appointments?: Array<AppointmentMultiple>) => {
        setShowPetAndServices(false);
        setVersionPetAndService(undefined);
        backPetAndServices();
        setBookingModified(true);
        const petFounded = multipleAppointments.findIndex((app: AppointmentMultiple) => app.petId === petId);
        if(petFounded !== -1) {
            if(versionPetAndService === 'edit' && appointments && appointments?.length > 0) {
                setMultipleAppointments(appointments);
            } else {
                let newMultipleAppointments = [...multipleAppointments];
                newMultipleAppointments[petFounded] = {
                    ...newMultipleAppointments[petFounded], 
                    variableData: [...newMultipleAppointments[petFounded].variableData, 
                    {   
                        service: service!,
                        staffId: staffId!,
                        date: date,
                    }]
                }
                setMultipleAppointments(newMultipleAppointments);
            }
        }
        else {
            const appointmentToSave: AppointmentMultiple = {
                clientId: parentId!,
                petId: petId!,
                createdByStaffId: auth.user!.staffId,
                variableData: [{
                    service: service!,
                    staffId: staffId!,
                    date: date,
                }],
                notes: notes
            };
            setMultipleAppointments(prev => ([ ...prev, appointmentToSave ]));
        }
    };

    const handlerRemoveAppointments = (appointments: Array<AppointmentMultiple>) => {
        setShowPetAndServices(false);
        setVersionPetAndService(undefined);
        backPetAndServices();
        setMultipleAppointments(appointments);
    };

    const handlerParentID = (petParentId: number) => {
        if (petParentId !== 0) {
            setParentId(petParentId);
            setShowPet(true);
            amplitude.track(AMPLITUDE.LOAD_CLIENT_PETS);
        } else {
            handlerRemove(wizardBooking.PetParent);
            handlerRemove(wizardBooking.Service);
            handlerRemove(wizardBooking.Staff);
            handlerRemove(wizardBooking.Deposit);
            handlerRemove(wizardBooking.Notes);
            props.onRemoveClient && props.onRemoveClient();
        }
    };

    const handlerPet = (value: Pet) => {
        setPetId(value.id);
        amplitude.track(AMPLITUDE.LOAD_DATE_AND_TIME);
        amplitude.track(AMPLITUDE.LOAD_SERVICES);
        if(reschedule || rebook) {
            setShowService(true);
        }
        if (!summary && !reschedule) {
            setShowStaff(false);
            handlerRemove(wizardBooking.Service);
            handlerRemove(wizardBooking.Staff);
            handlerRemove(wizardBooking.Deposit);
            handlerRemove(wizardBooking.Notes);
        }
    };

    const handlerService = (value: PetServiceWrapper) => {
        setService(value);
        amplitude.track(AMPLITUDE.LOAD_STAFF);
        if(reschedule) {
            setShowStaff(true);
        }
    };

    const handleFrequencyDuration = (
        durationId: Option<string> | undefined,
        frequencyId: Option<string>
    ) => {
        setFrequency(frequencyId);
        setDuration(durationId);
    };

    const handlerStaff = (staffSelected?: Staff, first?: boolean) => {
        setStaffId(staffSelected?.id);
        amplitude.track(AMPLITUDE.LOAD_COLLECT_DEPOSIT_PAYMENT_METHOD);
        amplitude.track(AMPLITUDE.LOAD_FINAL_INFORMATION);
        if (first) {
            setFirstLoad(false);
        }
        if (staffSelected) {
            setShowDepositAndNote(true);
        } 
    };

    const clearPet = () => {
        setPetId(undefined);
    };

    const clearClient = () => {
        setParentId(undefined);
        setParentName('');
    };

    const addNewClient = () => {
        props.onAddClient && props.onAddClient();
    };

    const backHandler = () => {
        setSaving(true);
        setDepositCollect(false);
        setSummary(true);
        dispatch(
            deleteAppointmentThunk(
                apiClientWrapper,
                savedBooking!.appointments.find(
                    appointment => appointment.uuid === appointmentUuid
                )!.id!,
                true
            )
        );
    };

    const backPetAndServices = () => {
        setPetId(undefined);
        setService(undefined);
        setDuration(undefined);
        setStaffId(undefined);
        setShowPetAndServices(false);
        setVersionPetAndService(undefined);
    };

    const handlerCollected = () => {
        setDepositCollect(false);
        setAppointmentCreated(true);
        props.onBooked();
    };

    const changeTabHandler = () => {
        if (touched) {
            setClosing(prev => ({ ...prev, tabChange: true }));
        } else {
            props.onShowBlockedTime && props.onShowBlockedTime();
        }
    };

    const handleClose = () => {
        closing.close && handleCloseBooking();
        closing.tabChange &&
            props.onShowBlockedTime &&
            props.onShowBlockedTime();
        //setClosing(prev => ({...prev, close: false, tabChange: false}));
    };

    const handleClosing = () => {
        setClosing(prev => ({
            ...prev,
            close: true
        }))
    };

    const handleChangeRecurrency = (value: boolean) => {
        setActiveFrequency(value);
        if (!value) {
            setFrequency(undefined);
        }
    };

    const handleChangeDeposit = (value: boolean) => {
        setIsCollectDeposit(value);
        if (value) {
            setActiveFrequency(false);
            setFrequency(undefined);
            amplitude.track(AMPLITUDE.ENABLE_BOOKING_DEPOSIT);
        } else {
            amplitude.track(AMPLITUDE.DISABLE_BOOKING_DEPOSIT);
        }
    };

    const handleCloseRescheduleModal = () => {
        setShowRescheduleModal(false);
        setSaving(false);
    };

    const handleCloseRescheduleRecurringModal = () => {
        setShowRescheduleRecurringModal(false);
        setSaving(false);
    };

    const handlerAddAnotherPet = () => {
        setVersionPetAndService('another');
        setShowPetAndServices(true);
    };

    const handlerShowMultipleServices = (value: number) => {
        setPetId(value);
        setShowPetAndServices(true);
        setVersionPetAndService('edit');
    };

    const handlerAddService = (value: number) => {
        setPetId(value);
        setShowPetAndServices(true);
        setVersionPetAndService('add');
    };

    const getMultipleBookingToSave = (): Array<NewBookingAppointment> => {
        const multipleAppointmentsToSave: Array<NewBookingAppointment> = [];
        let lastService: {date: Moment | undefined, serviceInMinutes: number} = {
            date: undefined,
            serviceInMinutes: 0
        }

        multipleAppointments.forEach((appt: AppointmentMultiple) => {
            appt.variableData.forEach((data: AppointmentDataVaraible) => {
                const newDate = lastService.date?.clone().add(lastService.serviceInMinutes, 'minutes').tz(timezone) || date;
                lastService = {
                    date: newDate,
                    serviceInMinutes: data.service.durationInMinutes
                }
               
                multipleAppointmentsToSave.push({
                    petId: appt.petId,
                    serviceId: data.service.service.id,
                    date: newDate,
                    notes: notes || '',
                    createdByStaffId: appt.createdByStaffId,
                    clientId: appt.clientId,
                    staffId: data.staffId
                })
            })
        })

        return multipleAppointmentsToSave.map((appt: NewBookingAppointment): NewBookingAppointment => ({
            petId: appt.petId,
            serviceId: appt.serviceId,
            date: appt.date,
            notes: notes || '',
            createdByStaffId: appt.createdByStaffId,
            clientId: appt.clientId,
            staffId: appt.staffId
        }));
    };

    const getBookingLastDate = () => {
        const lastDuration = multipleAppointments[multipleAppointments.length - 1].variableData[multipleAppointments[multipleAppointments.length - 1].variableData.length - 1].service.durationInMinutes;
        const endDate = date?.clone().add(serviceTotal?.durationInMinutes, 'minutes').tz(timezone);
        return endDate.clone().subtract(lastDuration, 'minutes').tz(timezone);
    }

    useEffect(() => {
        if (props.at) {
            setDate(props.at);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.at]);

    useEffect(() => {
        setBookingModified(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentId, date, activeFrequency]);

    useEffect(() => {
        props.onChangeTime &&
            props.onChangeTime(date, multipleAppointments.length > 0 ? serviceTotal?.durationInMinutes : service?.durationInMinutes, staffId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, service, staffId, multipleAppointments, serviceTotal]);

    useEffect(() => {
        setExceededDeposit(
            marketplace.deposit.type === MarketplaceDepositType.Amount &&
                convertToNumber(marketplace.deposit.value || '0') >
                (multipleAppointments.length > 0 ? multipleAppointments[0].variableData[0].service.price || 0 : (service?.price || 0))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service, multipleAppointments]);

    useEffect(() => {
        if(!showPetAndServices && bookingModified) {
            setShowAlertBusinessHour(false);
            dispatch(
                fetchAlertsThunk(
                    apiClientWrapper,
                    parentId || 0,
                    petId || 0,
                    date.clone().tz('utc'),
                    activeFrequency,
                    service?.service.id,
                    staffId,
                    appointmentID ?? undefined
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingModified]);

    useEffect(() => {
        if (activeFrequency) {
            setIsCollectDeposit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFrequency]);

    useEffect(() => {
        if (isCollectDeposit) {
            setActiveFrequency(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCollectDeposit]);

    useEffect(() => {
        if (summary) {
            setShowStaff(false);
            setShowDepositAndNote(false);
            handlerRemove(wizardBooking.Service);
            handlerRemove(wizardBooking.Staff);
            handlerRemove(wizardBooking.Deposit);
            handlerRemove(wizardBooking.Notes);
            setSummary(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [petId]);

    useEffect(() => {
        if (props.parentID) {
            setShowPet(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.parentID]);

    useEffect(() => {
        if (haveMultiplePets()) {
            setFrequency(undefined);
            setActiveFrequency(false);
            setRecurring(false);
            setShowDepositAndNote(true);
        }
        if (multipleAppointments.length > 0) {
            let priceTotal = 0;
            let durationMinutesTotal = 0;
            let durationTotal: Duration = {
                hours: undefined,
                minutes: undefined
            };
            multipleAppointments.forEach((apt: AppointmentMultiple) => {
                apt.variableData?.forEach((apt: AppointmentDataVaraible) => {
                    priceTotal = priceTotal+ apt.service.price;
                    durationMinutesTotal= durationMinutesTotal + apt.service.durationInMinutes;
                })

            })
            durationTotal = {
                hours: Math.trunc(durationMinutesTotal / 60),
                minutes: durationMinutesTotal % 60
            };
            setServiceTotal({
                price: priceTotal,
                durationInMinutes: durationMinutesTotal,
                duration: durationTotal
            });
            setShowDepositAndNote(true);
        } else {
            setServiceTotal(undefined);
            setShowDepositAndNote(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multipleAppointments]);

    useEffect(() => {
        if (props.bookingReschedule) {
            const appointmentSelected =
                props.bookingReschedule.booking?.appointments?.find(
                    ap => ap.id === props.appointmentId
                ) ||
                props.bookingReschedule.appointment ||
                props.bookingReschedule.booking?.appointments[0];
            setFirstReschedule(true);
            dispatch(
                fetchPetServicesThunk(
                    apiClientWrapper,
                    appointmentSelected!.pet.id
                )
            );

            if (appointmentSelected!.invoiceId) {
                dispatch(
                    getInvoiceThunk(
                        apiClientWrapper,
                        appointmentSelected!.invoiceId
                    )
                );
            }

            if (appointmentSelected!.recurringUuid) {
                setRecurring(true);
                setActiveFrequency(true);
                if (appointmentSelected!.id) {
                    dispatch(
                        countRecurrentAppointmentsThunk(
                            apiClientWrapper,
                            appointmentSelected!.id
                        )
                    );
                }
            }
        }
    }, [props.bookingReschedule]);

    useEffect(() => {
        if (props.bookingRebook) {
            setRebook(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.bookingRebook]);

    useNonInitialEffect(() => {
        switch (newBookingsStatus) {
            case NewBookingsStatus.SavingMultiple:
            case NewBookingsStatus.Saving:
                props.onStartBooking && props.onStartBooking();
                break;
            case NewBookingsStatus.SaveMultipleSuccess:
            case NewBookingsStatus.SaveSuccess:
                setSaving(false);
                setDepositCollect(false);
                setAppointmentCreated(true);
                break;
            case NewBookingsStatus.SaveForDepositSuccess:
                setDepositCollect(true);
                setSaving(false);
                break;
            case NewBookingsStatus.RecurrentRescheduleSuccess:
            case NewBookingsStatus.SingleRescheduleSuccess:
                setSaving(false);
                props.onBooked();
                break;
            case NewBookingsStatus.FetchingPetServices:
                if (firstReschedule) {
                    setLoading(true);
                    setFirstReschedule(false);
                }
                break;
            case NewBookingsStatus.FetchPetServicesSuccess:
                const services: Array<PetServiceWrapper> = petServices.map(
                    petService => ({
                        ...petService,
                        duration: {
                            hours: Math.trunc(
                                petService.durationInMinutes / 60
                            ),
                            minutes: petService.durationInMinutes % 60
                        }
                    })
                );

                if (props.bookingRebook && props.bookingRebook.customer.id) {
                    if (!isCollectDeposit) {
                        const appointmentSelected =
                            props.bookingRebook.appointments[0];

                        setDate(appointmentSelected.duration.from.tz(timezone));
                        setParentId(props.bookingRebook.customer.id);
                        setParentName(
                            props.bookingRebook.customer.firstName +
                                ' ' +
                                props.bookingRebook.customer.lastName
                        );
                        setPetId(appointmentSelected!.pet.id);
                        setService(
                            services.find(
                                serv =>
                                    serv.service.id ===
                                    appointmentSelected!.services[0].id
                            )
                        );
                        setStaffId(appointmentSelected!.services[0].staff?.id);

                        appointmentSelected.services[0].staff?.firstName;
                        setShowPet(true);
                        setShowService(true);
                        setShowStaff(true);
                        setRebook(true);
                        setShowDepositAndNote(true);
                    }
                } else if (props.bookingReschedule?.booking) {
                    const appointmentSelected =
                        props.bookingReschedule.booking.appointments!.find(
                            ap => ap.id === props.appointmentId
                        ) || props.bookingReschedule.booking.appointments[0];
                    setBookingID(appointmentSelected!.bookingId);
                    setAppointmentID(appointmentSelected!.id);
                    setParentId(props.bookingReschedule.booking.customer.id);
                    setParentName(
                        props.bookingReschedule.booking.customer.firstName +
                            ' ' +
                            props.bookingReschedule.booking.customer.lastName
                    );
                    setPetId(appointmentSelected!.pet.id);
                    setDate(appointmentSelected.duration.from.tz(timezone));
                    setDateReschedule(
                        appointmentSelected!.duration.from.tz(timezone)
                    );
                    setService(
                        services.find(
                            serv =>
                                serv.service.id ===
                                appointmentSelected!.services[0].id
                        )
                    );
                    setServiceRescheduleId(
                        services.find(
                            serv =>
                                serv.service.id ===
                                appointmentSelected!.services[0].id
                        )?.service?.id
                    );
                    setStaffId(appointmentSelected!.services[0].staff?.id);
                    setStaffRescheduleId(
                        appointmentSelected!.services[0].staff?.id
                    );
                    setNotes(appointmentSelected!.notes);
                    setNotesReschedule(appointmentSelected!.notes);
                    setReschedule(true);
                    setShowPet(true);
                    setShowService(true);
                    setShowStaff(true);
                    setShowDepositAndNote(true);
                } else if (props.bookingReschedule?.appointment !== undefined) {
                    setBookingID(props.bookingReschedule.appointment.bookingId);
                    setAppointmentID(props.bookingReschedule.appointment!.id);
                    setParentId(
                        props.bookingReschedule.appointment!.customer.id
                    );
                    setParentName(
                        props.bookingReschedule.appointment!.customer
                            .firstName +
                            ' ' +
                            props.bookingReschedule.appointment!.customer
                                .lastName
                    );
                    setPetId(props.bookingReschedule.appointment!.pet.id);
                    setDate(
                        props.bookingReschedule.appointment!.duration.from.tz(
                            timezone
                        )
                    );
                    setDateReschedule(
                        props.bookingReschedule.appointment!.duration.from.tz(
                            timezone
                        )
                    );
                    setService(
                        services.find(
                            serv =>
                                serv.service.id ===
                                props.bookingReschedule?.appointment!
                                    .services[0].id
                        )
                    );
                    setServiceRescheduleId(
                        services.find(
                            serv =>
                                serv.service.id ===
                                props.bookingReschedule?.appointment!
                                    .services[0].id
                        )?.service?.id
                    );
                    setStaffId(
                        props.bookingReschedule?.appointment!.services[0].staff
                            ?.id
                    );
                    setStaffRescheduleId(
                        props.bookingReschedule?.appointment!.services[0].staff
                            ?.id
                    );
                    setNotes(props.bookingReschedule.appointment!.notes);
                    setNotesReschedule(
                        props.bookingReschedule.appointment!.notes
                    );
                    setReschedule(true);
                    setShowPet(true);
                    setShowService(true);
                    setShowStaff(true);
                    setShowDepositAndNote(true);
                }
                setLoading(false);
                break;
            case NewBookingsStatus.FetchAlertsSuccess:
                const alertBusinessHour = alerts?.filter(alert => alert.alertType === 'date_time');
                if(bookingModified && multipleAppointments.length > 0) {
                    const lastService = multipleAppointments[multipleAppointments.length - 1]?.variableData[multipleAppointments[multipleAppointments.length - 1].variableData.length - 1];
                    dispatch(
                        fetchAlertsThunk(
                            apiClientWrapper,
                            parentId || 0,
                            multipleAppointments[multipleAppointments.length - 1]?.petId || 0,
                            getBookingLastDate().clone().tz('utc'),
                            activeFrequency,
                            lastService?.service?.service?.id,
                            staffId,
                            appointmentID ?? undefined
                        )
                    );
                }
                if(alertBusinessHour && alertBusinessHour?.length > 0) {
                    setShowAlertBusinessHour(true);
                }
                setBookingModified(false);
                setLoading(false);
                break;
            case NewBookingsStatus.Error:
                setSaving(false);
                setLoading(false);
                setShowRescheduleRecurringModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newBookingsStatus]);

    useNonInitialEffect(() => {
        switch (appointmentsStatus) {
            case AppointmentsStatus.DeleteSuccess:
                setDepositCollect(false);
                setSaving(false);
                setTimeout(() => setSummary(false), 2000);
                break;
            case AppointmentsStatus.CountRecurrentSuccess:
                if (recurrentInfo?.frecuency) {
                    setFrequency(recurrentInfo.frecuency);
                    setFrequencyReschedule(recurrentInfo.frecuency);
                }
                break;
            case AppointmentsStatus.Error:
                setSaving(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentsStatus]);

    useNonInitialEffect(() => {
        switch (invoiceStatus) {
            case InvoicesStatus.GettingInvoice:
                setLoading(true);
                break;
            case InvoicesStatus.GetInvoiceSuccess:
                const appointmentSelected =
                    props.bookingReschedule?.booking?.appointments?.find(
                        ap => ap.id === props.appointmentId
                    );
                setDeposit(
                    invoice?.deposits?.list?.find(
                        dep =>
                            dep.appointmentId === appointmentSelected?.id ||
                            dep.appointmentId ===
                                props.bookingReschedule?.appointment?.id ||
                            dep.appointmentId ===
                                props.bookingReschedule?.booking
                                    ?.appointments[0].id
                    )?.amount
                );
                setLoading(false);
                break;
            case InvoicesStatus.Error:
                setLoading(false);
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceStatus]);

    useNonInitialEffect(() => {
        setTouched(true);
    }, [staffId, parentId, date, activeFrequency]);

    const clientView = (
        <Box className={classes.sectionContainer}>
            <ClientSearch
                fromClient={props.fromClient}
                parentID={parentId as number}
                parentName={parentName as string}
                setPetParent={handlerParentID}
                onAddClient={addNewClient}
                setPetId={value => setPetId(value)}
                readOnly={
                    !!(
                        reschedule ||
                        (props.parentID && !props.clientAdded) ||
                        props.bookingRebook
                    )
                }
                multiplePet={!reschedule && !rebook}
            />
        </Box>
    );

    const petsView = (
        <Box className={clsx(classes.sectionContainer, {[classes.marginTop]: !rebook && !reschedule})}>
            <PetSearch
                hideSelect={!props.bookingRebook && !reschedule}
                parentID={parentId}
                petId={petId}
                readOnly={
                    !!(
                        reschedule ||
                        (props.petID && !props.petAdded) ||
                        props.bookingRebook
                    )
                }
                handlerPet={handlerPet}
                onAddPet={props.onAddPet}
            />
        </Box>
    );

    const dateView = (
        <DateSelector
            isCollectDeposit={isCollectDeposit}
            date={date}
            isRecurrent={activeFrequency}
            frequency={frequency}
            duration={duration}
            showAlertBusinessHour={showAlertBusinessHour}
            multiplePet={!!(multipleAppointments.length > 1 || multipleAppointments[0]?.variableData?.length > 1)}
            onChangeFrequencyDuration={handleFrequencyDuration}
            onChangeRecurrent={handleChangeRecurrency}
            onChangeDate={(value: Moment) => setDate(value)}
            readOnly={reschedule}
            alerts={alerts?.filter(
                alert =>
                    (alert.alertType === 'date_time' && (multipleAppointments.length <= 1 || multipleAppointments[0]?.variableData.length === 1)) ||
                    alert.alertType === 'unified'
            )}
        />
    );

    const serviceView = (
        <Box className={classes.sectionContainer}>
            <ServiceSelector
                selectedService={service}
                clientId={parentId!}
                petId={petId!}
                readOnly={reschedule}
                onSelectService={handlerService}
            />
        </Box>
    );

    const staffView = (
        <Box className={classes.sectionContainer}>
            <StaffSelector
                summary={summary}
                selectedStaff={staffId}
                loginStaff={auth.user!.staffId}
                service={service}
                firstLoad={firstLoad}
                readOnly={reschedule}
                onSelectStaff={handlerStaff}
                detailView={props.detailView}
                alerts={alerts?.filter(alert => alert.alertType === 'staff')}
            />
        </Box>
    );

    const depositView = (
        <DepositSelector
            active={isCollectDeposit}
            servicePrice={service?.price}
            alerts={alerts?.filter(alert => alert.alertType === 'deposit')}
            readOnly={reschedule}
            onChangeDeposit={handleChangeDeposit}
        />
    );

    const notesView = (
        <Box className={classes.sectionContainer}>
            <Notes
                title="Booking Notes"
                placeholder="Anything important to note?"
                staffId={staffId}
                readOnly={reschedule}
                rebooking={!!props.bookingRebook}
                notes={notes}
                onChangeNote={(value: string) => setNotes(value)}
            />
        </Box>
    );

    const appointmentBookedView = (
        <Box className={classes.appointmentCreatedContainer}>
            <AppointmentCreated multipleAppointments={multipleAppointments} date={date} petId={petId}/>
        </Box>
    );

    const multiplePetsView = (
        <Box>
            <MultiplePets 
                multipleAppointments={multipleAppointments}
                onAddAnotherPet={handlerAddAnotherPet}
                onAddService={handlerAddService}
                onShowMultipleServices={handlerShowMultipleServices}
            />
        </Box>
    );

    const petAndServiceView = (
        <PetAndServices 
            parentId={parentId!}
            petId={petId}
            service={service}
            staffId={staffId}
            firstLoad={firstLoad}
            versionPetAndServices={versionPetAndService}
            multipleAppointments={multipleAppointments}
            date={date}
            petsLoaded={props.petsLoaded}
            onChangePet={handlerPet}
            onChangeService={handlerService}
            onChangeStaff={handlerStaff}
            onBack={backPetAndServices}
            onBook={handlerSavePetAndServices}
            onRemove={handlerRemoveAppointments}
        />
    );

    const confirmCloseDialog = (
        <ConfirmClosePopup
            title="Exit Booking"
            question="Your changes will not be saved. Are you sure you want to exit this booking?"
            onDiscard={() =>
                setClosing(prev => ({
                    ...prev,
                    close: false,
                    tabChange: false
                }))
            }
            onSave={handleClose}
        />
    );

    const rescheduleModal = (
        <ConfirmRescheduleDialog
            name={parentName!}
            from={dateReschedule!}
            to={date}
            open={true}
            staffId={staffId !== staffRescheduleId ? staffId : undefined}
            onCancel={handleCloseRescheduleModal}
            onConfirm={(notification?: boolean) =>
                rescheduleFunction(
                    notification,
                    activeFrequency ? undefined : 'only'
                )
            }
        />
    );

    const rescheduleRecurringModal = (
        <ConfirmRescheduleRecurringDialog
            rescheduleRecurring={rescheduleRecurring}
            name={parentName}
            from={dateReschedule}
            to={date}
            open={true}
            onCancel={handleCloseRescheduleRecurringModal}
            onConfirm={rescheduleFunction}
        />
    );

    return (
        <Box
            className={clsx(classes.container, {
                [classes.withoutEvents]: saving
            })}
        >
            {loading ? (
                <CircularProgress size="1.5rem" style={{ color: '#EAB464' }} />
            ) : (
                <>
                    {!showPetAndServices && <Box className={classes.headerContainer}>
                        <CreateBookingHeader
                            title={
                                depositCollect
                                    ? 'Collect Deposit'
                                    : appointmentCreated
                                    ? ''
                                    : reschedule
                                    ? 'Appointment Detail'
                                    : 'Add Appointment'
                            }
                            depositCollect={depositCollect}
                            onClose={showPetAndServices ? 
                                undefined :
                                appointmentCreated || !touched
                                    ? handleCloseBooking
                                    : handleClosing
                            }
                            onBack={depositCollect ? backHandler : showPetAndServices ? backPetAndServices : undefined }
                            onShowBlockedTime={
                                props.onShowBlockedTime && !appointmentCreated 
                                    ? changeTabHandler
                                    : undefined
                            }
                        />
                    </Box>}
                    {appointmentCreated ? (
                        appointmentBookedView
                    ) : showPetAndServices ? 
                    petAndServiceView : (
                        <OverlayScrollbarsComponent style={{ height: '100%' }}>
                            <Box className={classes.appointmentContainer}>
                                {depositCollect ? (
                                    <CollectDeposit
                                        bookingId={savedBooking!.id!}
                                        onClose={handleCloseBooking}
                                        onStartBooking={props.onStartBooking}
                                        onCollected={handlerCollected}
                                    />
                                ) : (
                                    <Fragment>
                                        {dateView}
                                        {clientView}
                                        {showPet && (reschedule || rebook) && petsView}
                                        {showService && (reschedule || rebook) && serviceView}
                                        {showStaff && (reschedule || rebook) && staffView}
                                        {parentId && !reschedule && !rebook && petsState.list.filter(
                                                pet => !pet.deleted && !pet.deceased
                                            ).length === 0  && petsView}
                                        {parentId && !reschedule && !rebook && petsState.list.filter(
                                            pet => !pet.deleted && !pet.deceased
                                        ).length > 0 && multiplePetsView}
                                        {showDepositAndNote && notesView}
                                        {showDepositAndNote &&
                                            !exceededDeposit &&
                                            marketplace.deposit.type !==
                                                MarketplaceDepositType.None &&
                                                !haveMultiplePets() &&
                                            depositView}
                                        {showRescheduleModal && rescheduleModal}
                                        {showRescheduleRecurringModal &&
                                            rescheduleRecurringModal}
                                    </Fragment>
                                )}
                            </Box>
                        </OverlayScrollbarsComponent>
                    )}
                    {!depositCollect && !showPetAndServices &&(
                        <Box
                            className={clsx(classes.totalContainer, {
                                [classes.marginTotalContainer]:
                                    !appointmentCreated
                            })}
                        >
                            <FooterComponent
                                success={appointmentCreated}
                                title={deposit ? 'Subtotal' : 'Total'}
                                loading={saving}
                                total={
                                    appointmentCreated
                                        ? undefined
                                        : multipleAppointments.length > 0
                                        ? serviceTotal?.price
                                        : service?.price
                                }
                                hours={!reschedule ? serviceTotal?.duration?.hours : service?.duration?.hours}
                                minutes={!reschedule ? serviceTotal?.duration?.minutes : service?.duration?.minutes}
                                buttonText={
                                    isCollectDeposit && !appointmentCreated
                                        ? 'Pay & Book Appoinment'
                                        : appointmentCreated
                                        ? 'Done'
                                        : reschedule
                                        ? 'Save'
                                        : 'Book Appoinment'
                                }
                                disabledButton={
                                    reschedule || rebook ? 
                                    ((!parentId ||
                                        !petId ||
                                        !date ||
                                        !service ||
                                        !staffId ||
                                        (activeFrequency && !frequency) ||
                                        alerts?.some(alert => alert.error)) &&
                                    !appointmentCreated )
                                    : 
                                    (multipleAppointments.length === 0) || 
                                    (activeFrequency && !frequency) || 
                                    (showPetAndServices && (!petId || !service || !staffId))
                                }
                                deposit={deposit}
                                onBook={handleBooked}
                            />
                        </Box>
                    )}
                    {(closing.close || closing.tabChange) && confirmCloseDialog}
                </>
            )}
        </Box>
    );
};

export default CreateBooking;
