import React, { FunctionComponent, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { PetService } from "@spike/new-booking-model";
import Pet from "@spike/pet-model";
import Staff from "model/Staff";
import moment, { Moment } from "moment-timezone";
import AddOrEditService from "./AddOrEditService";
import ServicesList from "./ServicesList";
import PetSearch from "../PetSearch";
import CreateBookingHeader from "../CreateBookingHeader";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import FooterComponent from "../FooterComponent";

interface AppointmentDataVaraible {
    service: PetServiceWrapper;
    staffId: number;
    date: Moment;
}

interface PetServiceWrapper extends PetService {
    duration?: Duration;
}

interface AppointmentMultiple {
    clientId: number;
    petId: number;
    createdByStaffId: number;
    notes?: string;
    variableData: Array<AppointmentDataVaraible>;
}

interface Props {
    parentId: number;
    petId?: number;
    service?: PetServiceWrapper;
    staffId?: number;
    firstLoad?: boolean;
    date?: Moment;
    versionPetAndServices?: 'another' | 'add' | 'edit' | undefined;
    multipleAppointments: Array<AppointmentMultiple>;
    petsLoaded?: boolean;
    onChangePet: (value: Pet) => void;
    onChangeService: (value: PetServiceWrapper) => void;
    onChangeStaff: (value?: Staff, first?: boolean) => void;
    onBack: () => void;
    onBook: (appointments?: Array<AppointmentMultiple>) => void;
    onRemove: (appointments: Array<AppointmentMultiple>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        petContainer: {
            marginTop: 8,
            marginBottom: 8
        },
        totalContainer: {
            height: '15%',
            borderTop: '1px solid #D4D4D4',
            marginTop: 11,
            marginBottom: 14
        },
        headerContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '17px 27px 11px 27px',
            [theme.breakpoints.up('md')]: {
                margin: '20px 30px 13px 30px'
            }
        },
        content: {
            margin: '0px 26px 0px 26px',
            [theme.breakpoints.up('md')]: {
                margin: '0px 30px 0px 30px'
            }
        },
        addService: {
            cursor: 'pointer',
            color: '#92B4A7',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '27px',
            paddingTop: 7,
        },
    })
);

export const PetAndServices: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const [screen, setScreen] = useState<'edit' | 'add' | undefined>(undefined);
    const [multipleAppointments, setMultipleAppointments] = useState<Array<AppointmentMultiple>>(props.multipleAppointments);
    let appointment: AppointmentMultiple = multipleAppointments.find((app: AppointmentMultiple) => app.petId === props.petId)!;
    const [service, setService] = useState<PetServiceWrapper | undefined>(undefined);
    const [serviceToEdit, setServiceToEdit] = useState<PetServiceWrapper | undefined>(undefined);
    const [staffId, setStaffId] = useState<number | undefined>(undefined);

    const getTitle = () => {
        if(screen === 'add') {
            return 'Add Service';
        } else if(props.versionPetAndServices === 'edit' && screen === 'edit') {
            return 'Edit Service'
        } else {
            return 'Pet & Services'
        }
    };

    const getTotalServices = () => {
        let priceTotal = 0;
        let durationMinutesTotal = 0;
        let durationTotal: Duration = {
            hours: undefined,
            minutes: undefined
        };
        const appointment = multipleAppointments.find((app: AppointmentMultiple) => app.petId === props.petId);
        appointment?.variableData.forEach((data: AppointmentDataVaraible) => {
            priceTotal = priceTotal + data.service.price;
            durationMinutesTotal= durationMinutesTotal + data.service.durationInMinutes;
        })

        durationTotal = {
            hours: Math.trunc(durationMinutesTotal / 60),
            minutes: durationMinutesTotal % 60
        };

        return {
            price: priceTotal,
            durationInMinutes: durationMinutesTotal,
            duration: durationTotal
        };
    };

    const handlerEditService = (data: AppointmentDataVaraible) => {
        setScreen('edit');
        setService(data.service);
        props.onChangeService(data.service);
        setStaffId(data.staffId);
        setServiceToEdit(data.service);
    };

    const isDisabledButton = () => {
        if(props.versionPetAndServices !== 'edit' && (!props.parentId || !props.petId || !props.service || !props.staffId)) {
            return true;
        } else if(props.versionPetAndServices === 'edit') {
            if((screen === 'edit' && (!service || !staffId)) || (screen === 'add' && (!props.parentId || !props.petId || !props.service || !props.staffId))) {
                return true;
            } 
            return false;
        }
        return false;
    };

    const handlerBook = () => {
        if(props.versionPetAndServices === 'edit' && screen === undefined) {
            const appointmentSelected = multipleAppointments.find((app: AppointmentMultiple) => app.petId === props.petId);
            if(appointmentSelected?.variableData.length === 0) {
                const appointmentsWithoutThisPet = multipleAppointments.filter((app: AppointmentMultiple) => app.petId !== props.petId);
                props.onRemove(appointmentsWithoutThisPet)
            } else {
                props.onBook(multipleAppointments);
            }
        } else if(props.versionPetAndServices === 'edit' && screen === 'add') {
            const petFounded = multipleAppointments.findIndex((app: AppointmentMultiple) => app.petId === props.petId);
            if(petFounded !== -1) {
                let newMultipleAppointments = [...multipleAppointments];
                newMultipleAppointments[petFounded] = {
                    ...newMultipleAppointments[petFounded], 
                    variableData: [...newMultipleAppointments[petFounded].variableData, 
                    {   
                        service: props.service!,
                        staffId: props.staffId!,
                        date: moment(),
                    }]
                }
                setMultipleAppointments(newMultipleAppointments);
            }
        } else if(props.versionPetAndServices === 'edit' && screen === 'edit') {
            const petFounded = multipleAppointments.findIndex((app: AppointmentMultiple) => app.petId === props.petId);
            if(petFounded !== -1) {
                let newMultipleAppointments = [...multipleAppointments];
                const appt: AppointmentMultiple = newMultipleAppointments[petFounded];
                const elementEdited: AppointmentDataVaraible = {
                    date: moment(),
                    service: service!,
                    staffId: staffId!
                } 
                const index = appt.variableData.findIndex((app: AppointmentDataVaraible) => app.service.service.id === serviceToEdit?.service.id);
                if(index !== -1) {
                    newMultipleAppointments[petFounded].variableData[index] = elementEdited;
                }
                setMultipleAppointments(newMultipleAppointments);
            } 
        } else {
            props.onBook();
        }
        setService(undefined);
        setStaffId(undefined);
        setScreen(undefined);
    };

    const handleRemoveAppointment = () => {
        if(screen === 'edit') {
            const petIndex = multipleAppointments.findIndex((app: AppointmentMultiple) => app.petId === props.petId);
            if(petIndex !== -1) {
                const appointmentsWithoutApptRemoved = multipleAppointments[petIndex].variableData.filter((appt: AppointmentDataVaraible) => appt.service.service.id !== serviceToEdit?.service.id);
                let newMultipleAppointments = [...multipleAppointments];
                newMultipleAppointments[petIndex] = {
                    ...newMultipleAppointments[petIndex], 
                    variableData: appointmentsWithoutApptRemoved
                }
                setService(undefined);
                setStaffId(undefined);
                setScreen(undefined);
                setMultipleAppointments(newMultipleAppointments);
            }
        } else {
            const appointmentsWithoutApptRemoved = props.multipleAppointments.filter((appt: AppointmentMultiple) => appt.petId !== props.petId);
            setMultipleAppointments(appointmentsWithoutApptRemoved);
            setService(undefined);
            setStaffId(undefined);
            setScreen(undefined);
            props.onRemove(appointmentsWithoutApptRemoved);
        }
        
    };

    const handlerBack = () => {
        setService(undefined);
        setStaffId(undefined);
        setScreen(undefined);
    }
    
    const addView = (
        <AddOrEditService 
            parentId={props.parentId!}
            petId={props.petId}
            service={props.service || service}
            staffId={props.staffId || staffId}
            firstLoad={true}
            petsLoaded={props.petsLoaded}
            versionPetAndServices={props.versionPetAndServices}
            multipleAppointments={multipleAppointments}
            screen={screen}
            date={props.date}
            onChangePet={props.onChangePet}
            onChangeService={props.onChangeService}
            onChangeStaff={props.onChangeStaff}
        />
    );

    const editView = (
        <AddOrEditService 
            parentId={props.parentId!}
            petId={props.petId}
            service={service}
            staffId={staffId}
            firstLoad={true}
            petsLoaded={props.petsLoaded}
            versionPetAndServices={props.versionPetAndServices}
            multipleAppointments={multipleAppointments}
            screen={screen}
            date={props.date}
            onChangePet={() => {}}
            onChangeService={setService}
            onChangeStaff={(value?: Staff) => setStaffId(value?.id)}
        />
    );

    const petsView = (
        <Box className={classes.petContainer}>
            <PetSearch
                parentID={props.parentId}
                petId={props.petId}
                multiplePets={true}
                hideSelect={true}
                petsLoaded={props.petsLoaded}
                multipleAppointments={props.multipleAppointments}
                readOnly={true}
                handlerPet={() => {}}
            />
        </Box>
    );

    const serviceList = (
        <ServicesList
            multipleAppointment={appointment!}
            onEdit={handlerEditService}
        />
    );

    return (
        <>
            <Box className={classes.headerContainer}>
                <CreateBookingHeader
                    title={getTitle()}
                    onBack={() => screen === 'edit' || screen === 'add' ? handlerBack() : props.onBack()}
                />
            </Box>
            <OverlayScrollbarsComponent style={{ height: '100%' }}>
                <Box className={classes.content}>
                    {(props.versionPetAndServices === 'add' || props.versionPetAndServices === 'another' || screen === 'add') && addView}
                    {props.versionPetAndServices === 'edit' && screen === 'edit' && editView}
                    {props.versionPetAndServices === 'edit' && screen === undefined &&
                        <>
                            {petsView}
                            {serviceList}
                            <Typography className={classes.addService} onClick={() => setScreen('add')}>
                                + Add Service
                            </Typography>
                        </>
                    }
                </Box>
            </OverlayScrollbarsComponent>
            <Box  className={classes.totalContainer}>
                <FooterComponent
                    success={props.versionPetAndServices === 'edit' && screen === undefined && multipleAppointments.length > 0 ? true : false}
                    title='Total'
                    loading={false}
                    total={props.versionPetAndServices === 'edit' && screen === undefined ? getTotalServices().price : (service?.price ?? props.service?.price)}
                    hours={props.versionPetAndServices === 'edit' && screen === undefined ? getTotalServices()?.duration?.hours : (service?.duration?.hours ?? props.service?.duration?.hours)}
                    minutes={props.versionPetAndServices === 'edit' && screen === undefined ? getTotalServices()?.duration?.minutes : (service?.duration?.minutes ?? props.service?.duration?.minutes)}
                    buttonText={props.versionPetAndServices === 'edit' && screen === undefined ? 'Done' : 'Save'}
                    disabledButton={isDisabledButton()}
                    onBook={handlerBook}
                    onRemove={props.versionPetAndServices === 'edit' && (screen === undefined || screen === 'edit') ? handleRemoveAppointment : undefined}
                />
            </Box>
        </>
    )
};

export default PetAndServices