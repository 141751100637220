import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { pageSidebarWidth, reduceResolution, wbp } from 'Theme';
import PageSidebarItemComponent from './PageSidebarItem';
import { PageSidebarItem } from './PageSidebarModel';
import { width } from '@fortawesome/pro-light-svg-icons/faTimes';

interface PageSidebarProps {
    title: string;
    info?: JSX.Element;
    items: Array<PageSidebarItem>;
    selectedKey?: string;
    className?: string;
    onSelect?: (key: string) => void;
    onBack?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            borderRight: '1px solid #DDDDDD',
            backgroundColor: 'white',
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            },
            [theme.breakpoints.down(wbp)]: {
                minWidth: `${reduceResolution(pageSidebarWidth)}px`,
                padding: `${reduceResolution(8)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                minWidth: `${pageSidebarWidth}px`,
                padding: '8px'
            },
            [theme.breakpoints.down(575)]: {
                borderRight: 'none'
            }
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(14)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '14px'
            }
        },
        title: {
            fontFamilty: 'Poppins',
            fontWeight: 600,
            color: 'black',
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(18)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '18px'
            }
        },
        info: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderBottom: '1px solid #D4D4D4',
            [theme.breakpoints.down(wbp)]: {
                paddingBottom: `${reduceResolution(14)}px`,
                marginBottom: `${reduceResolution(22)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingBottom: '14px',
                marginBottom: '22px'
            }
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                overflowY: 'scroll',
                width: '105%',
                paddingRight: '5%'
            }
        },
        icon: {
            cursor: 'pointer',
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(10)}px`,
                width: `${reduceResolution(16)}px !important`,
                height: `${reduceResolution(16)}px !important`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '10px',
                width: '16px !important',
                height: '16px !important'
            }
        }
    })
);

export const PageSidebar: FunctionComponent<PageSidebarProps> = props => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.header}>
                {props.onBack && (
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        className={classes.icon}
                        onClick={props.onBack}
                    />
                )}
                <Typography className={classes.title}>{props.title}</Typography>
            </Box>
            {props.info && <Box className={classes.info}>{props.info}</Box>}
            <Box className={classes.body}>
                {props.items.map(item => (
                    <PageSidebarItemComponent
                        id={`clients_div_${item.title
                            .toLowerCase()
                            .replaceAll(' ', '_')}`}
                        page={'clients'}
                        key={item.key}
                        item={item}
                        selectedKey={props.selectedKey}
                        onClick={key => {
                            props.onSelect && props.onSelect(key);
                        }}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default PageSidebar;
