import {
    Box,
    Grid,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FieldError } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import { deleteClientThunk, saveClientThunk } from '@spike/clients-action';
import {
    Button,
    CannotBeDeleted,
    ConfirmDialog,
    ConfirmDialogWarning,
    FormSection
} from 'components/UI';
import { Client as ClientModel } from '@spike/client-model';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientsStatus } from '@spike/clients-action';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';
import AuthorizedContacts from './AuthorizedContacts';
import MarketingNotification from './MarketingNotifications';
import PersonalInformation from './PersonalInformation';
import Tags from './Tags';
import { validateClient } from './Validations';
import clsx from 'clsx';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import { fetchOnboardingStepsThunk } from 'actions/onboardingSteps/OnboardingStepsActions';
import { MobileDivider } from 'components/UI/MobileDivider';
import { ClientEditHeader } from './ClientEditHeader';
import { Img } from 'react-image';
import BlockClient from 'components/Client/BlockClient';

interface ClientProps {
    client: ClientModel;
    onBook: () => void;
    onClose: () => void;
    onSaved?: (client: ClientModel) => void;
    onDeleted?: () => void;
    onPetNotesEdited?: () => void;
    onSelect: (key: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: '24px 16px',
            backgroundColor: '#fff',
            heigth: '100%',
            [theme.breakpoints.up('md')]: {
                backgroundColor: '#FAFAFA',
                padding: '55px 56px 0px 61px'
            }
        },
        rowSpacing: {
            [theme.breakpoints.up('md')]: {
                marginTop: 53
            }
        },
        mobileTitle: {
            fontSize: 20,
            fontWeight: 600,
            marginBottom: 24,

            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        contacts: {
            '& > div:first-child': {
                [theme.breakpoints.down('sm')]: {
                    display: 'none'
                }
            }
        },
        footer: {
            'marginTop': 60,
            'paddingBottom': 8,
            'flexWrap': 'nowrap',

            [theme.breakpoints.up('md')]: {
                paddingTop: 40,
                paddingBottom: 100
            },

            '& button': {
                'height': 54,
                'borderRadius': 30,

                [theme.breakpoints.down('sm')]: {
                    width: '100%'
                },

                '& span': {
                    fontSize: 18,
                    fontWeight: 600
                }
            }
        },
        deleteButton: {
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '12px'
            }
        },
        noPadding: {
            '& > div:last-child > div': {
                padding: '0px'
            }
        },
        dividerWrapper: {
            margin: '0px -16px'
        },
        personalInfoHeader: {
            position: 'relative',
            width: '100%',
            heigth: '100px',
            background: '#FAEFDF',
            marginBottom: 30,
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 12,
            overflow: 'hidden'
        },
        personalInfoHeaderLeft: {
            padding: '24px 0px 18px 23px',
            flex: 1,
            [theme.breakpoints.down('md')]: {
                padding: '24px 23px 18px 23px'
            }
        },
        personalInfoHeaderTitle: {
            fontSize: 18,
            fontWeight: 600,
            marginBottom: 6
        },
        personalInfoHeaderText: {
            [theme.breakpoints.down('sm')]: {
                fontSize: 14
            }
        },
        personalInfoHeaderRight: {
            'width': '38%',
            'position': 'relative',
            '& img': {
                position: 'absolute',
                top: '-12px',
                right: 0,
                objectFit: 'contain',
                transition: 'all .5s linear',
                [theme.breakpoints.down(1540)]: {
                    right: '-55px'
                }
            },
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        }
    })
);

export const Client: FunctionComponent<ClientProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const marketplace = useMarketplace();

    const clientsStatus = useSelector<RootState, ClientsStatus>(
        state => state.clients.status
    );
    const savedClient = useSelector<RootState, ClientModel | undefined>(
        state => state.clients.savedClient
    );

    const [editClient, setEditClient] = useState(props.client);
    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [saving, setSaving] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const [showSaveAlert, setShowAlert] = useState(false);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

    const clientsDone = useSelector<RootState, boolean>(
        state => state.onboardingSteps.onboardingSteps.clientsDone ?? false
    );

    useNonInitialEffect(() => {
        setEditClient(props.client);
    }, [props.client.id]);

    useNonInitialEffect(() => {
        switch (clientsStatus) {
            case ClientsStatus.SaveSuccess: {
                setEditClient(savedClient!);
                props.onSaved && props.onSaved(savedClient!);
                setSaving(false);
                break;
            }
            case ClientsStatus.DeleteSuccess: {
                if (clientsDone === true) {
                    dispatch(fetchOnboardingStepsThunk());
                }
                props.onDeleted && props.onDeleted();
                setDeleting(false);
                break;
            }
            case ClientsStatus.Error: {
                setSaving(false);
                break;
            }
        }
    }, [clientsStatus]);

    const saveHandler = () => {
        const errors = validateClient(
            editClient,
            marketplace.basics.address.country!
        );
        setErrors(errors);

        if (errors.length === 0) {
            dispatch(saveClientThunk(apiClientWrapper, { ...editClient }));
            setSaving(true);
        }
    };

    const deleteHandler = () => {
        setDeleting(true);
        dispatch(deleteClientThunk(apiClientWrapper, editClient.id!));
    };

    const confirmBackHandler = () => {};

    const viewSaveAlert = (
        <ConfirmDialogWarning
            open={showSaveAlert}
            question={<Box className="saveChangeTitle">Save Changes</Box>}
            subQuestion={
                <Box className="saveChangeMessage">
                    You have not saved your changes. Are you sure you want to
                    exit?
                </Box>
            }
            confirmButtonLabel={'Exit Anyway'}
            cancelButtonLabel={'Cancel'}
            onCancel={() => setShowAlert(false)}
            onConfirm={() => confirmBackHandler()}
        />
    );

    const personalInfoHeader = (
        <Box className={classes.personalInfoHeader}>
            <Box className={classes.personalInfoHeaderLeft}>
                <Typography className={classes.personalInfoHeaderTitle}>
                    Add new client
                </Typography>
                <Typography className={classes.personalInfoHeaderText}>
                    Please enter either an <strong>email address</strong> or a{' '}
                    <strong>phone number</strong> to create a new client.
                </Typography>
            </Box>
            <Box className={classes.personalInfoHeaderRight}>
                <Img
                    src="images/clients/createClientInfo.png"
                    width={300}
                    height={147}
                />
            </Box>
        </Box>
    );

    return (
        <>
            <Grid className={classes.container}>
                {!props.client.id ? (
                    <Typography variant="h2" className={classes.mobileTitle}>
                        Add client
                    </Typography>
                ) : isMobile ? (
                    <>
                        <ClientEditHeader
                            client={editClient}
                            onBook={props.onBook}
                            onClose={props.onClose}
                            onSelect={props.onSelect}
                        />
                    </>
                ) : undefined}

                {showSaveAlert && viewSaveAlert}

                <FormSection
                    title="Personal Information"
                    subtitle="Enter the client's personal information. You must add their first and last name, plus either an email or phone number."
                >
                    {editClient && !editClient.id && personalInfoHeader}
                    <PersonalInformation
                        client={editClient}
                        errors={errors}
                        onChange={setEditClient}
                    />
                </FormSection>

                <Box className={classes.dividerWrapper}>
                    <MobileDivider />
                </Box>

                <FormSection
                    title="Tags"
                    subtitle="Choose tags that will allow you to segment client groups."
                    className={classes.rowSpacing}
                >
                    <Tags
                        tags={editClient.tags}
                        onChange={tags =>
                            setEditClient(prev => ({ ...prev, tags }))
                        }
                    />
                </FormSection>

                <Box className={classes.dividerWrapper}>
                    <MobileDivider />
                </Box>

                <FormSection
                    title="Authorized & Emergency Contacts"
                    subtitle="Add authorized contacts that can pick up and drop off the client’s pet or emergency contacts that can make decisions on behalf of the client."
                    className={clsx(
                        classes.rowSpacing,
                        classes.contacts,
                        classes.noPadding
                    )}
                >
                    <AuthorizedContacts
                        clientId={editClient.id}
                        contacts={editClient.authorizedContacts}
                        onChange={authorizedContacts =>
                            setEditClient(prev => ({
                                ...prev,
                                authorizedContacts
                            }))
                        }
                    />
                </FormSection>

                <Box className={classes.dividerWrapper}>
                    <MobileDivider />
                </Box>

                <FormSection
                    title="Notifications"
                    subtitle="Enable or disable client notifications."
                    className={classes.rowSpacing}
                >
                    <MarketingNotification
                        notifications={editClient.notifications}
                        onChange={notifications =>
                            setEditClient(prev => ({ ...prev, notifications }))
                        }
                    />
                </FormSection>

                {editClient.id && (
                    <>
                        <Box className={classes.dividerWrapper}>
                            <MobileDivider />
                        </Box>

                        <FormSection
                            title="Block Client"
                            subtitle="Blocking clients prevents them from booking appointments."
                            className={classes.rowSpacing}
                        >
                            <BlockClient client={editClient} />
                        </FormSection>
                    </>
                )}

                <Grid
                    item
                    container
                    justifyContent="flex-end"
                    className={classes.footer}
                >
                    {!editClient.destroyable && (
                        <Grid xs={12}>
                            <CannotBeDeleted text="This client cannot be removed because it has at least one appointment." />
                        </Grid>
                    )}
                    {editClient?.id && editClient.destroyable && (
                        <Button
                            label="Delete"
                            variant="danger"
                            id="clients_button_delete"
                            disabled={!editClient.destroyable || saving}
                            onClick={() => setShowDeleteConfirmation(true)}
                            loading={deleting}
                            className={classes.deleteButton}
                        />
                    )}
                    <Button
                        id="clients_button_save"
                        label="Save"
                        color="orange"
                        disabled={deleting}
                        loading={saving}
                        onClick={saveHandler}
                    />
                </Grid>
            </Grid>
            {showDeleteConfirmation && (
                <ConfirmDialog
                    id="clients_confirm_dialog_"
                    open={true}
                    title={
                        <Typography>
                            {editClient!.firstName} {editClient!.lastName}
                        </Typography>
                    }
                    question={
                        <Typography>
                            Do you want to delete this Client?
                        </Typography>
                    }
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Delete"
                    onCancel={() => setShowDeleteConfirmation(false)}
                    onConfirm={() => {
                        setShowDeleteConfirmation(false);
                        deleteHandler();
                    }}
                />
            )}
        </>
    );
};

export default Client;
