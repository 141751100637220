import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button, Link } from 'components/UI';
import NotMobileYet from 'components/UI/NotMobileYet';
import { useHubspotTrackingCode } from 'hooks';
import React, { FunctionComponent } from 'react';
import { Img } from 'react-image';
import { wbp } from 'Theme';

interface IntroductionProps {
    className?: string;
    enrollmentStarted?: boolean;
    onAddCuddlesPay?: () => void;
    onLearnMore?: () => void;
    isButtonDisabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            minHeight: '100%',
            height: '100%'
        },
        gridContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '90px',
            [theme.breakpoints.down(1025)]: {
                gap: '40px',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                paddingTop: '40px',
                paddingLeft: '15px',
                paddingRight: '15px'
            }
        },
        left: {
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                justifyContent: 'center'
            },
            [theme.breakpoints.down(1025)]: {
                height: 'auto',
                minHeight: 'auto'
            }
        },
        right: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                alignItems: 'flex-start'
            },
            [theme.breakpoints.down(1025)]: {
                alignItems: 'flex-start',
                minHeight: 'auto'
            }
        },
        body: {
            width: '100%',
            maxWidth: '506px'
        },
        footer: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginTop: '40px',
            [theme.breakpoints.down('md')]: {
                justifyContent: 'flex-start'
            },
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-start'
            }
        },
        image: {
            width: '38vw',
            [theme.breakpoints.up('md')]: {
                width: '35vw'
            },
            [theme.breakpoints.up('xl')]: {
                width: '30vw'
            }
        },
        title: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: '#EAB464',
            fontSize: '16px',
            lineHeight: '27px'
        },
        subtitle: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            color: '#222222',
            letterSpacing: '-0.02em',
            fontSize: '32px',
            lineHeight: '130%',
            marginTop: '6px',
            marginBottom: '15px',
            maxWidth: '420px',
            [theme.breakpoints.down('md')]: {
                fontSize: '26px',
                lineHeight: '30px',
                marginTop: '5px'
            }
        },
        firstTextLine: {
            display: 'flex',
            marginTop: '30px'
        },
        textLine: {
            display: 'flex',
            [theme.breakpoints.down(wbp)]: {
                marginTop: '25px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '25px'
            }
        },
        textLineWider: {
            maxWidth: '435px'
        },
        text: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#222222',
            fontSize: '16px',
            lineHeight: '130%'
        },
        check: {
            color: '#92B4A7',
            display: 'flex',
            alignItems: 'flex-start',
            margin: '0px 10px 0px 0px',
            width: '20px !important',
            height: '20px !important'
        },
        button: {
            [theme.breakpoints.down(wbp)]: {
                marginRight: '16px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '20px'
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '14px',
                height: '40px',
                padding: '10px 20px'
            }
        },
        learnMore: {
            '& .MuiButton-label': {
                fontSize: '14px'
            }
        }
    })
);

export const Introduction: FunctionComponent<IntroductionProps> = props => {
    const classes = useStyles();

    const { setTrackEvent } = useHubspotTrackingCode();

    const check = (
        <FontAwesomeIcon icon={faCheckCircle} className={classes.check} />
    );

    const learnMoreHandler = () => {
        setTrackEvent(
            `${process.env.REACT_APP_HUBSPOT_EVENT_CUDDLESPAY_LEARN}`
        );
        window.open(process.env.REACT_APP_PAYMENTS_LEARN_MORE_URL);
    };

    return (
        <>
            <NotMobileYet />
            <Box className={clsx(classes.container, props.className)}>
                <Grid className={classes.gridContainer} container>
                    <Box className={classes.left}>
                        <Img
                            src="/images/payments/cuddlespay-hero.png"
                            className={classes.image}
                        />
                    </Box>
                    <Box className={classes.right}>
                        <Box className={classes.body}>
                            <Typography className={classes.title}>
                                Payments & Payouts
                            </Typography>
                            <Typography className={classes.subtitle}>
                                Start accepting payments through Cuddles
                            </Typography>
                            <Box className={classes.firstTextLine}>
                                {check}
                                <Typography className={classes.text}>
                                    Easily connect your bank account for an
                                    all-in-one solution
                                    <br />
                                </Typography>
                            </Box>
                            <Box
                                className={clsx(
                                    classes.textLine,
                                    classes.textLineWider
                                )}
                            >
                                {check}
                                <Typography className={classes.text}>
                                    Simplify & save with one affordable
                                    processing rate of 2.5% + .20¢ per
                                    transaction
                                    <br />
                                </Typography>
                            </Box>
                            <Box className={classes.textLine}>
                                {check}
                                <Typography className={classes.text}>
                                    Accept all forms of payment, including
                                    credit card, debit card, and Apple Pay
                                    <br />
                                </Typography>
                            </Box>
                            <Box className={classes.textLine}>
                                {check}
                                <Typography className={classes.text}>
                                    Automatically track tips & commissions
                                    <br />
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.footer}>
                            <Button
                                label={
                                    props.enrollmentStarted
                                        ? 'Continue Process'
                                        : 'Get Started'
                                }
                                color="green"
                                className={classes.button}
                                id="start-cuddlespay"
                                onClick={() => {
                                    setTrackEvent(
                                        `${process.env.REACT_APP_HUBSPOT_EVENT_CUDDLESPAY_GET_STARTED}`
                                    );
                                    props.onAddCuddlesPay &&
                                        props.onAddCuddlesPay();
                                }}
                                disabled={props.isButtonDisabled}
                            />
                            <Link
                                className={classes.learnMore}
                                color="black"
                                label="Learn More"
                                id="learn-cuddlespay"
                                onClick={learnMoreHandler}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Box>
        </>
    );
};

export default Introduction;
