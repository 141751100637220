import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Pagination, Spinner } from "components/UI";
import Client from "@spike/client-model";
import React, { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

interface TableProps {
  page: Array<Client>;
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  loading?: boolean;
  className?: string;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  onEditClient?: (clientId: number) => void;
  onEditPet?: (clientId: number, petId: number) => void;
  onBook?: (client: Client) => void;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spinner: {
      [theme.breakpoints.down(wbp)]: {
        marginTop: `${reduceResolution(50)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginTop: "50px",
      },
    },
    pagination: {
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(33)}px`,
        paddingRight: `${reduceResolution(31)}px`,
        paddingBottom: `${reduceResolution(29)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "33px",
        paddingRight: "31px",
        paddingBottom: "29px",
      },
    },
  })
);

export const Table: FunctionComponent<TableProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={props.className}>
      <TableHeader />
      {props.loading && <Spinner className={classes.spinner} />}
      {!props.loading && (
        <TableBody
          clients={props.page}
          onEdit={props.onEditClient}
          onEditPet={props.onEditPet}
          onBook={props.onBook}
        />
      )}
      {!props.loading && props.totalRecords > props.pageSize && (
        <Pagination
          count={props.totalRecords}
          page={props.pageNumber}
          pageSize={props.pageSize}
          onPageChange={props.onChangePage}
          onPageSizeChange={props.onChangePageSize}
          disableAllPageSize={true}
          className={classes.pagination}
        />
      )}
    </Box>
  );
};

export default Table;
