import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { FunctionComponent } from "react";
import { reduceResolution, wbp } from "Theme";

interface IconAvatarProps {
    icon?: IconDefinition;
    bgColor?: string; //hex
    color?: string; //hex
    size?: number;
    imageUrl?: string;
    className?: string;
    hasSubIcon?: boolean;
    subIconColor?: string;
    subIconBgColor?: string;
    subIcon?: IconDefinition;
    subIconClassName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            pointerEvents: "none",
            [theme.breakpoints.down(wbp)]: {
                width: (props: IconAvatarProps) => props.size ? reduceResolution(props.size) : `${reduceResolution(40)}px`,
                height: (props: IconAvatarProps) => props.size ? reduceResolution(props.size) : `${reduceResolution(40)}px`,
            },
            [theme.breakpoints.up(wbp)]: {
                width: (props: IconAvatarProps) => props.size ? props.size : "40px",
                height: (props: IconAvatarProps) => props.size ? props.size : "40px",
            },
            position: "relative"
        },
        image: {
            pointerEvents: "none",
            backgroundImage: (props: IconAvatarProps) => "url(" + props.imageUrl + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderRadius: "50%",
            width: "100%",
            height: "100%",
        },
        emptyImage: {
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            background: (props: IconAvatarProps) => props.bgColor ? props.bgColor : "#F8F5F1",
            
            width: "100%",
            height: "100%",
        },
        icon: {
            color: (props: IconAvatarProps) => props.color ? props.color : "#BAA997",
            backgroundColor: (props: IconAvatarProps) => props.bgColor ? props.bgColor : "#FAEFDF",
            pointerEvents: "none",
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(22)}px !important`,
                height: `${reduceResolution(22)}px !important`,
            },
            [theme.breakpoints.up(wbp)]: {
                width: "22px !important",
                height: "22px !important",
            },
        },
        subIconContainer: {
            position: "absolute",
            zIndex: 10,
            left: -5,
            top: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            background: (props: IconAvatarProps) => props.subIconBgColor ? props.subIconBgColor : "#F8F5F1",
            border: "3px solid #fff",
            pointerEvents: "none",
            width: "35%",
            height: "35%",
        },

        subIcon: {
            color: (props: IconAvatarProps) => props.subIconColor ? props.subIconColor : "#BAA997",
            backgroundColor: (props: IconAvatarProps) => props.subIconBgColor ? props.subIconBgColor : "#FAEFDF",
            fontWeight: 600,
            pointerEvents: "none",
            
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(10)}px !important`,
                height: `${reduceResolution(10)}px !important`,
            },
            [theme.breakpoints.up(wbp)]: {
                width: "10px !important",
                height: "10px !important",
            },
        }

    })
);

export const IconAvatar: FunctionComponent<IconAvatarProps> = (props) => {
    const classes = useStyles(props);

    return (
        <><Box className={clsx(classes.container, props.className)}>
            {!isEmpty(props.imageUrl) ? (
                <Box className={classes.image} />
            ) : (
                <Box
                    className={clsx(
                        classes.emptyImage
                    )}
                >
                    <FontAwesomeIcon
                        icon={props.icon ? props.icon : faXmark}
                        className={clsx(
                            classes.icon
                        )} />
                </Box>
            )}
            <Box className={classes.subIconContainer}>

                <FontAwesomeIcon
                    icon={props.subIcon ? props.subIcon : faXmark}
                    className={clsx(
                        classes.subIcon, props.subIconClassName
                    )} />

            </Box>
        </Box>


        </>
    );
};

export default IconAvatar;
