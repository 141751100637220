import React, {
  FunctionComponent,
  MouseEvent,
  useState,
  useEffect,
} from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { CheckboxCircle } from "components/UI/CheckboxCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faEllipsisH, faEllipsis, faBan, faCircleCheck, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { RowToolbar } from "./RowToolbar";
import { SelectableOption } from "model";
import { ActionsPopup } from "../ActionsPopup/ActionsPopup";
import RowToolbarMobile from "./RowToolbarMobile";

interface Props {
  className?: string;
  id: number;
  content: JSX.Element;
  name: string;
  duplicateOptions?: Array<SelectableOption<string>>;
  statusId: number;
  selected: boolean;
  duplicated?: boolean;
  onEdit?: (id: number) => void;
  onActivate?: (id: number, active: boolean) => void;
  onDuplicate?: (
    id: number,
    name: string,
    selectedOptionsIds: Array<string>
  ) => void;
  onDelete?: (id: number) => void;
  onSelect?: (id: number, selected: boolean) => void;
  showToolbar?: boolean;
  onOpenToolbar?: () => void;
  mobileToolbar?: boolean;
  isMobile?: boolean;
}

interface StyleProps {
  mobileToolbar?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => createStyles({
    container: {
      display: "flex",
      width: "100%",
      position: "relative",
      borderTopWidth: "1px",
      borderTopStyle: "solid",
      borderTopColor: "#F1F1F1",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "14px",
        paddingBottom: "14px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: "16px",
        paddingBottom: "16px",
      },
    },
    rowSelectionContainer: {
      display: "flex",
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        width: "52px",
      },
      [theme.breakpoints.only("md")]: {
        width: "62px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "75px",
        paddingLeft: "10px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "92px",
        paddingLeft: "12px",
      },
    },
    contentToolbarButtonContainer: (props) => ({
      display: "flex",
      flex: "1 1 auto",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "9px",
        paddingBottom: "9px",
        borderRadius: "10px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "13px",
        paddingBottom: "12px",
        borderRadius: "14px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "18px",
        paddingBottom: "17px",
        borderRadius: "18px",
        minHeight: "120px",
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: "25px",
        paddingBottom: "23px",
        borderRadius: "22px",
        minHeight: "150px",
      },
      "&:hover": {
        background: "#F8F8F8",
        [theme.breakpoints.down("md")]: {
          background: "#ffffff",
        },
      },


      [theme.breakpoints.down("md")]: {
        ...(props.mobileToolbar ? {
          "& > .toolbarButtonContainer": {
            display: "none",
          }
        } : {}),
      },
    }),
    
    selected: {
      background: "#F8F8F8",
    },
    contentContainer: {
      flex: "1 1 auto",
    },
    toolbarButtonContainer: (props) => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "37px",
      },
      [theme.breakpoints.only("md")]: {
        width: "50px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "67px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "90px",
      },
      [theme.breakpoints.down("md")]: {
        ...(props.mobileToolbar ? {
            display: "none",
        } : {}),
      },
    }),
    toolbarContainer: (props) => ({
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "40px",
        paddingLeft: "16px",
      },
      [theme.breakpoints.only("md")]: {
        width: "52px",
        paddingLeft: "16px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "70px",
        paddingLeft: "16px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "93px",
        paddingLeft: "22px",
      },
      [theme.breakpoints.down("md")]: {
        ...(props.mobileToolbar ? {
          display: "none",
        } : {}),
      },
    }),
    toolbarMobileContainer:{
      [theme.breakpoints.down("md")]: {
        position: "absolute",
        top: "0px",
        right: "0px",
        height: "100%",
        width: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
    },
    toolbarMobileContainerNew:{
      
    },
    duplicated: {
      position: "absolute",
      backgroundColor: "#5E8677",
      color: "#ffffff",
      [theme.breakpoints.down("lg")]: {
        height: "26px",
        fontSize: "12px",
        top: "60px",
        right: "0px",
      },
      [theme.breakpoints.up("xl")]: {
        height: "30px",
        fontSize: "15px",
        top: "75px",
        right: "0px",
      },
    },
    toolbarMobileButton:{
      background: "transparent",
      border: "none",
      color: "#000",
      "& svg": {
        fontSize: "22px",
        color: "#000"
      }
    }
  })
);

export const Row: FunctionComponent<Props> = (props) => {
  const classes = useStyles({ mobileToolbar: props.mobileToolbar });

  const [showToolbarButton, setShowToolbarButton] = useState(false);
  const [showToolbar, setShowToolbar] = useState(props.showToolbar || false);
  const [showDuplicated, setShowDuplicated] = useState(props.duplicated);
  const [showMobileDialog, setShowMobileDialog] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowDuplicated(false);
    }, 3000);
  }, []);

  useEffect(() => {
    showToolbar && props.onOpenToolbar && props.onOpenToolbar();
  }, [showToolbar]);

  useEffect(() => {
    setShowToolbar(props.showToolbar!);
  }, [props.showToolbar]);

  const clickToolbarButtonHandler = (event: MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setShowToolbar((prevValue) => !prevValue);
  };

  const clickToolbarMobileButtonHandler = (event: MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setShowMobileDialog(true);
  }

  const onSelectHanlder = (selected: boolean): void => {
    props.onSelect && props.onSelect(props.id, selected);
  };

  const mouseEnterHandler = (event: MouseEvent): void => {
    setShowToolbarButton(true);
  };

  const mouseLeaveHandler = (event: MouseEvent): void => {
    setShowToolbarButton(false);
  };

  const onEditHandler = (): void => {
    setShowToolbar(false);
    props.onEdit && props.onEdit(props.id);
  };

  const onActivateHandler = (active: boolean): void => {
    setShowToolbar(false);
    props.onActivate && props.onActivate(props.id, active);
  };

  const onDuplicateHandler = (
    name: string,
    selectedOptionIds: Array<string>
  ): void => {
    setShowToolbar(false);
    props.onDuplicate && props.onDuplicate(props.id, name, selectedOptionIds);
  };

  const onDeleteHandler = (): void => {
    setShowToolbar(false);
    props.onDelete && props.onDelete(props.id);
  };

  const closeMobileDialogHandler = (): void => {
    setShowMobileDialog(false);
  }

  const containerStyle = clsx(classes.container, props.className);
  const contentToolbarButtonContainerStyle = clsx(
    classes.contentToolbarButtonContainer,
    { [classes.selected]: props.selected || showToolbar }
  );
  const toolbarButton = showToolbar ? (
    <FontAwesomeIcon icon={faEllipsisV} />
  ) : (
    <FontAwesomeIcon icon={faEllipsisH} />
  );
  const toolbar = showToolbar ? (
    <RowToolbar
      statusId={props.statusId}
      onEdit={onEditHandler}
      onActivate={props.onActivate ? onActivateHandler : undefined}
      onDuplicate={props.onDuplicate ? onDuplicateHandler : undefined}
      onDelete={props.onDelete ? onDeleteHandler : undefined}
      name={props.name}
      duplicateOptions={props.duplicateOptions}
    />
  ) : null;

  const toolbarMobile =  (
    <RowToolbarMobile
      statusId={props.statusId}
      onEdit={onEditHandler}
      onActivate={props.onActivate ? onActivateHandler : undefined}
      onDuplicate={props.onDuplicate ? onDuplicateHandler : undefined}
      onDelete={props.onDelete ? onDeleteHandler : undefined}
      name={props.name}
      duplicateOptions={props.duplicateOptions}
      showDialog={showMobileDialog}
      onCloseDialog={closeMobileDialogHandler}
    />
  )

  const duplicated = showDuplicated && (
    <Chip className={classes.duplicated} label="Duplicated" />
  );

  return (
    <Box
      className={containerStyle}
      onMouseEnter={mouseEnterHandler}
      onMouseOver={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <Box className={classes.rowSelectionContainer}>
        {props.onSelect &&
          (<CheckboxCircle
            checked={props.selected}
            onChange={(checked) => {
              onSelectHanlder(checked);
            }}
          />)}
      </Box>
      <Box
        className={contentToolbarButtonContainerStyle}
        onClick={onEditHandler}
      >
        <Box className={classes.contentContainer}>{props.content}</Box>
        <Box
          className={classes.toolbarButtonContainer}
          onClick={clickToolbarButtonHandler}
          id="services_list_button_toolbar"
        >
          {showToolbarButton || showToolbar ? toolbarButton : null}
        </Box>

        {props.mobileToolbar && props.isMobile && (
          <Box className={classes.toolbarMobileContainer}>
            <button
              type="button"
              aria-label="Service actions"
              onClick={clickToolbarMobileButtonHandler}
              className={classes.toolbarMobileButton}
            >
              <FontAwesomeIcon icon={faEllipsis} />
            </button>
          </Box>
        )} 
      </Box>
      {props.mobileToolbar && props.isMobile ? (
        <Box className={classes.toolbarMobileContainerNew}>{toolbarMobile}</Box>
      ) : <Box className={classes.toolbarContainer}>{toolbar}</Box>}
      
      {duplicated}
    </Box>
  );
};

export default Row;
