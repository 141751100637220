import React, { FunctionComponent, useMemo } from "react";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import AddPetAndServiceOption from "./AddPetAndServiceOption";
import { Moment } from "moment-timezone";
import { Button } from "components/UI";
import { PetService } from "@spike/new-booking-model";
import AppointmentCard from "./AppointmentCard";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { PetsState } from "@spike/pets-action";
import Pet from "@spike/pet-model";

interface AppointmentDataVaraible {
    service: PetServiceWrapper;
    staffId: number;
    date: Moment;
}

interface AppointmentMultiple {
    clientId: number;
    petId: number;
    createdByStaffId: number;
    notes?: string;
    variableData: Array<AppointmentDataVaraible>;
}

interface PetServiceWrapper extends PetService {
    duration?: Duration;
}

interface Props {
    multipleAppointments: Array<AppointmentMultiple>;
    onAddAnotherPet: () => void;
    onAddService: (value: number) => void;
    onShowMultipleServices: (value: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addAnotherPet: {
            cursor: 'pointer',
            color: '#92B4A7',
            fontWeight: 600,
            fontSize: 14,
            lineHeight: '27px',
            marginTop: 10
        },
    })
);

export const MultiplePets: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const petsStateFilter = useSelector<RootState, PetsState>(
        state => state.pets
    );

    const pets = useMemo(() => {
        return petsStateFilter.list.filter(
            pet => !pet.deleted && !pet.deceased
        );
    }, [petsStateFilter.list]);

    const havePetToAddService = () => {
        let petFounded = false;
        pets.forEach((pet: Pet) => {
            const appoitnemntFounded = props.multipleAppointments.some((appt: AppointmentMultiple) => appt.petId === pet.id);
            if(!appoitnemntFounded) {
                petFounded = true;
            }
        });
        return petFounded;
    };

    return (
        <Box>
            {props.multipleAppointments.length === 0 && <AddPetAndServiceOption onAddPetAndServices={props.onAddAnotherPet}/>}
            {props.multipleAppointments.map((app: AppointmentMultiple) => (
                <AppointmentCard 
                    key={app.petId}
                    appointment={app}
                    onAddService={() => props.onAddService(app.petId)}
                    onShowMultipleServices={() => props.onShowMultipleServices(app.petId)}
                />
            ))}
            {props.multipleAppointments.length > 0 && !isMobile && havePetToAddService() && <Button 
                color='green'
                size='small'
                label='+ Add Another Pet'
                
                onClick={props.onAddAnotherPet}
            />}

            {props.multipleAppointments.length > 0 && isMobile && havePetToAddService() &&
            <Typography className={classes.addAnotherPet} onClick={props.onAddAnotherPet}>
                + Add Another Pet
            </Typography>}
        </Box>
    )
};

export default MultiplePets