import * as amplitude from '@amplitude/analytics-browser';
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { BookingsStatus, lockBookingThunk } from '@spike/bookings-action';
import InvoiceModel from '@spike/invoice-model';
import { InvoicesStatus } from '@spike/invoices-action';
import { Lock } from '@spike/model';
import { showWarningThunk } from '@spike/notifications-action';
import { PaymentsStatus } from '@spike/payments-action';
import useNonInitialEffect from '@versiondos/hooks';
import { fetchServicesThunk } from 'actions/services/ServicesActions';
import clsx from 'clsx';
import Invoice from 'components/Invoice';
import { Spinner } from 'components/UI';
import { AMPLITUDE } from 'constants/index';
import { useApiClientWrapper, useAuth } from 'hooks';
import moment, { Moment } from 'moment-timezone';
import {
    FunctionComponent,
    RefObject,
    useEffect,
    useRef,
    useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ServicesState, ServicesStatus } from 'reducers/services/ServicesState';
import { RootState } from 'store';
import Back from './Back';
import Blocked from './Blocked';
import PaymentDone from './PaymentDone';
import Payments from './Payments';
import ApiClient from 'api';
import { sessionUuid } from 'api/ApiClient';

interface CheckoutProps {
    bookingId: number;
    appointmentIds: Array<number>;
    className?: string;
    onBack?: () => void;
    onComplete?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            minHeight: '100%',
            height: 'max-content',
            backgroundColor: '#FFFFFF',

            [theme.breakpoints.up('md')]: {
                backgroundColor: '#FBFBFB'
            }
        },
        leftContainer: {
            width: 175,
            height: '100%',
            display: 'flex',
            paddingTop: 60,
            paddingLeft: 50,

            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        rightContainer: {
            flex: 1,
            height: '100%',
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            padding: '24px 16px',

            [theme.breakpoints.up('md')]: {
                padding: '56px 150px 0px'
            }
        },
        hide: {
            display: 'none !important'
        },
        paymentBackground: {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 10,
            display: 'flex',
            minHeight: '100%',
            width: '100%',
            backgroundColor: 'rgba(200,200,200,0.8)'
        },
        paymentRightContainer: {
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
            width: '100%',
            display: 'flex',
            position: 'absolute',
            borderLeft: '2px solid #F1F1F1',
            backgroundColor: 'white',
            overflowY: 'auto',

            [theme.breakpoints.up('md')]: {
                width: '600px'
            }
        },
        title: {
            fontSize: 20,
            lineHeight: 1,
            fontWeight: 600,
            color: '#222222',

            [theme.breakpoints.up('md')]: {
                fontSize: 36
            }
        },
        invoice: {
            [theme.breakpoints.up('md')]: {
                marginBottom: 50
            }
        },
        backButton: {
            '& svg': {
                marginTop: -1,
                marginRight: 6,
                verticalAlign: 'middle',
                width: '20px !important',
                height: '20px !important',
                fontSize: '20px !important'
            }
        },
        mobile: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);

const scrollToTopHandler = (ref: RefObject<HTMLDivElement>) => {
    ref.current!.scrollTop = 0;
};

export const Checkout: FunctionComponent<CheckoutProps> = props => {
    const classes = useStyles();

    const apiClientWrapper = useApiClientWrapper();
    const dispatch = useDispatch();

    const auth = useAuth();

    const authToken = auth.token;
    const baseUrl = `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_API_BASE_URL}`;
    const marketplaceId = apiClientWrapper.marketplaceId;
    const blockTimeoutInSeconds = Number(
        process.env.REACT_APP_BLOCK_TIMEOUT_SECONDS || 60
    );

    const paymentsContainerRef = useRef<HTMLDivElement>(null);

    const bookingsStatus = useSelector<RootState, BookingsStatus>(
        state => state.bookings.status
    );
    const lockedBookings = useSelector<RootState, Map<number, Lock>>(
        state => state.bookings.lockedBookings
    );
    const [lock, setLock] = useState<{
        locking: boolean;
        info: Lock | undefined;
    }>({ locking: true, info: undefined });

    const invoice = useSelector<RootState, InvoiceModel | undefined>(
        state => state.invoices.invoice
    );

    const invoicesStatus = useSelector<RootState, InvoicesStatus>(
        state => state.invoices.status
    );

    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );

    const serviceState = useSelector<RootState, ServicesState>(
        state => state.services
    );
    const [showPayments, setShowPayments] = useState(false);
    const [showPaymentDone, setShowPaymentDone] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState({
        total: '',
        date: moment()
    });
    const [sendReport, setSendReport] = useState(false);

    const completeInvoiceHandler = () => {
        setShowPayments(true);
        amplitude.track(AMPLITUDE.CTA_PAY_CHECK_OUT);
    };

    const backHandler = () => {
        props.onBack && props.onBack();
    };

    const completeHandler = (total: string, date: Moment) => {
        setPaymentInfo({ total, date });
        setShowPayments(false);
        setShowPaymentDone(true);
    };

    const back = <Back onBack={backHandler} />;

    useEffect(() => {
        let reportControl = true;
        if (serviceState.services.length > 0) {
            invoice?.lines.map(eachInvoice => {
                if (reportControl) {
                    serviceState.services.find(
                        service => service.id === eachInvoice.service?.id
                    )?.hasReport
                        ? (reportControl = false)
                        : (reportControl = true);
                }
            });
            setSendReport(!reportControl);
        } else {
            serviceState.status !== ServicesStatus.Fetching &&
                dispatch(fetchServicesThunk());
        }
    }, [serviceState.services, invoice]);

    const unlock = () => {
        fetch(
            `${baseUrl}booking/unlock/${props.bookingId}?marketplace_id=${marketplaceId}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Session-Uuid': sessionUuid,
                    'Authorization': `Bearer ${authToken}`
                },
                body: '',
                keepalive: true // Permite que la solicitud se complete incluso si la página se cierra
            }
        );
    };

    useEffect(() => {
        const isOnIOS =
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/OS X/i);

        const eventName = isOnIOS ? 'pagehide' : 'beforeunload';

        window.addEventListener(eventName, unlock);
        return () => window.removeEventListener(eventName, unlock);
    }, []);

    useEffect(() => {
        dispatch(lockBookingThunk(apiClientWrapper, props.bookingId));
        amplitude.track(AMPLITUDE.LOAD_CHECK_OUT_APPOINTMENT);
        return unlock;
    }, []);

    useNonInitialEffect(() => {
        switch (bookingsStatus) {
            case BookingsStatus.LockEnd:
                const lockInfo = lockedBookings.get(props.bookingId);
                lockInfo && setLock({ locking: false, info: { ...lockInfo } });
                break;
            case BookingsStatus.Error:
                lock.locking && props.onBack && props.onBack();
                break;
        }
    }, [bookingsStatus]);

    useNonInitialEffect(() => {
        switch (invoicesStatus) {
            case InvoicesStatus.BookingLocked:
            case InvoicesStatus.BookingAlreadyProcessed:
                props.onBack && props.onBack();
                break;
        }
    }, [invoicesStatus]);

    useNonInitialEffect(() => {
        const timer = setTimeout(() => {
            dispatch(showWarningThunk('Checkout was closed by inactivity.'));
            props.onBack && props.onBack();
        }, blockTimeoutInSeconds * 1000);

        return () => clearTimeout(timer);
    }, [invoicesStatus, paymentsStatus]);

    useNonInitialEffect(() => {
        switch (paymentsStatus) {
            case PaymentsStatus.BookingLocked:
            case PaymentsStatus.BookingAlreadyProcessed:
                props.onBack && props.onBack();
                break;
        }
    }, [paymentsStatus]);

    return (
        <Box className={classes.container}>
            {!showPaymentDone &&
                !lock.locking &&
                lock.info?.status === 'locked' && (
                    <>
                        <Box className={classes.leftContainer}>{back}</Box>
                        <Box className={classes.rightContainer}>
                            <Typography className={classes.title}>
                                <span
                                    className={clsx(
                                        classes.mobile,
                                        classes.backButton
                                    )}
                                >
                                    {back}
                                </span>
                                Checkout
                            </Typography>
                            <Invoice
                                appointmentIds={props.appointmentIds}
                                bookingId={props.bookingId}
                                className={classes.invoice}
                                onComplete={completeInvoiceHandler}
                            />
                        </Box>
                    </>
                )}
            {!showPaymentDone && lock.locking && <Spinner />}
            {!showPaymentDone &&
                !lock.locking &&
                lock.info?.status === 'rejected' && (
                    <Blocked
                        blockerUser={`${lock.info.lockedBy.firstName} ${lock.info.lockedBy.lastName}`}
                        onBack={backHandler}
                    />
                )}
            {showPayments && invoice && invoice.id && (
                <Box className={classes.paymentBackground}>
                    <div
                        className={classes.paymentRightContainer}
                        ref={paymentsContainerRef}
                    >
                        <Payments
                            title="Checkout"
                            invoiceId={invoice.id}
                            bookingId={invoice.bookingId}
                            tip={invoice.tip}
                            totalBalance={invoice!.totalDue}
                            subtotalWithoutTipTaxes={invoice!.subtotal}
                            customerEmail={invoice!.customer?.email || ''}
                            onComplete={completeHandler}
                            onClose={() => setShowPayments(false)}
                            onScrollToTop={() =>
                                scrollToTopHandler(paymentsContainerRef)
                            }
                            hiddenReport={sendReport}
                        />
                    </div>
                </Box>
            )}
            {showPaymentDone && (
                <PaymentDone {...paymentInfo} onClose={props.onComplete} />
            )}
        </Box>
    );
};

export default Checkout;
