import { FunctionComponent, useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BodySection, ActionSection } from 'components/UI/Section';
import { MarketplaceTax } from '@spike/marketplace-model';
import { useMarketplace } from 'hooks';
import { toInteger } from 'lodash';
import { TaxesSelector } from 'components/UI/TaxesSelector';
import { Option } from '@spike/model';
import Service, {
    FixedPriceOption as ServiceFixedPrice,
    VariablePrice as ServiceVariablePrice,
    OptionsCache
} from 'model/Service';
import NewVariablePrice from './NewVariablePrice/NewVariablePrice';
import PriceTypeSelector from './PriceTypeSelector';
import Typography from '@material-ui/core/Typography';
import FixedPrice from './FixedPrice';
import Section from '../Section';
import Box from '@material-ui/core/Box';
import { FieldErrorWithKey } from '../model';
import { validatePricingStep } from '../Validations';

interface Props {
    service: Service;
    collapsed?: boolean;
    disabled?: boolean;
    editable?: boolean;
    completed?: boolean;
    modifiable?: boolean;
    errors: Array<FieldErrorWithKey>;
    onEdit?: () => void;
    onNext: () => void;
    onChange: (
        taxable: boolean,
        fixedPrice: Array<ServiceFixedPrice>,
        variablePrice: ServiceVariablePrice | undefined,
        taxes: Array<MarketplaceTax>
    ) => void;
    onAddTaxes?: () => void;
}

interface PetTypeState {
    options: Array<Option<string>>;
    title: string;
    type: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        titleDescriptionSelectorContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        titleDescriptionContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        titleContainer: {
            marginBottom: 10,

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        title: {
            'fontSize': 20,
            'fontWeight': 600,
            '& span': {
                fontWeight: 600,
                color: '#EAB464'
            }
        },
        descriptionContainer: {
            marginBottom: 25
        },
        description: {
            fontSize: 14,
            fontWeight: 400,
            color: '#000',

            [theme.breakpoints.up('md')]: {
                fontSize: 18
            }
        },
        selectorContainer: {
            display: 'flex'
        },
        checkboxContainer: {},
        priceContainer: {
            display: 'flex',
            [theme.breakpoints.down('lg')]: {
                marginTop: '25px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '33px'
            }
        },
        taxable: {
            '& .MuiCheckbox-root': {
                paddingLeft: '0px !important'
            },
            '& p': {
                [theme.breakpoints.down('lg')]: {
                    fontSize: '12px'
                },
                [theme.breakpoints.up('xl')]: {
                    fontSize: '16px'
                }
            }
        },
        errorMessage: {
            color: 'red',
            marginTop: 10,
            [theme.breakpoints.down('sm')]: {
                fontSize: 14
            }
        }
    })
);

const fixedPriceOption: Option<string> = {
    id: 'fixed_price',
    name: 'Fixed Price'
};
const byPetSizePriceOption: Option<string> = {
    id: 'by_pet_size',
    name: 'By Pet Size'
};
const byHairLenghtPriceOption: Option<string> = {
    id: 'by_hair_lenght',
    name: 'By Hair Length'
};
const byPetSizeAndHairLengthPriceOption: Option<string> = {
    id: 'by_pet_size_and_hair_length',
    name: 'By Pet Size & Hair Length'
};

const dogsPriceOptions: Array<Option<string>> = [
    fixedPriceOption,
    byPetSizePriceOption,
    byPetSizeAndHairLengthPriceOption
];

const catsPriceOptions: Array<Option<string>> = [
    fixedPriceOption,
    byPetSizePriceOption,
    byHairLenghtPriceOption
];

const multiplePetPriceOptions: Array<Option<string>> = [
    fixedPriceOption,
    byPetSizePriceOption,
    byPetSizeAndHairLengthPriceOption
];

const exoticsPriceOptions: Array<Option<string>> = [fixedPriceOption];

export const Pricing: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const marketplace = useMarketplace();
    const depositAmount = toInteger(marketplace.deposit.value);
    const depositType = marketplace.deposit.type;

    const [errors, setErrors] = useState<Array<FieldErrorWithKey>>(
        props.errors
    );

    const isComplete = (
        fixedPrice: Array<ServiceFixedPrice>,
        variablePrice: ServiceVariablePrice | undefined
    ) => {
        return (
            (fixedPrice.length > 0 &&
                fixedPrice.every(fp => fp.price !== undefined)) ||
            (variablePrice &&
                variablePrice.prices.length > 0 &&
                !variablePrice.prices.some(
                    vp =>
                        (variablePrice.variables.petTypeVariable &&
                            !vp.petType) ||
                        (variablePrice.variables.petSizeVariable &&
                            !vp.petSize) ||
                        (variablePrice.variables.hairTypeVariable &&
                            !vp.hairType) ||
                        vp.price === undefined
                ) &&
                !variablePrice.prices.some(
                    vp =>
                        vp.price &&
                        depositType !== 'percentage' &&
                        vp.price < depositAmount
                ))
        );
    };

    const [isMultiplePet, setIsMultiplePet] = useState(() => {
        if (
            props.service.pricing.variablePrice?.prices &&
            props.service.petTypes.length > 1
        ) {
            return true;
        } else {
            return false;
        }
    });

    const [disabledNext, setDisabledNext] = useState(
        !isComplete(
            props.service.pricing.fixedPrice,
            props.service.pricing.variablePrice
        )
    );

    const [showFixedPrice, setShowFixedPrice] = useState(
        props.service.pricing.variablePrice === undefined
    );

    const [showVariablePrice, setShowVariablePrice] = useState(
        props.service.pricing.variablePrice !== undefined
    );

    const [petTypeOptions, setPetTypeOptions] = useState<PetTypeState>(() => {
        if (props.service?.petTypes.length === 1) {
            if (
                props.service.pricing.variablePrice?.prices[0].petType!.id ===
                'dogs'
            ) {
                return {
                    options: dogsPriceOptions,
                    title: 'Price for Dogs',
                    type: 'dogs'
                };
            } else if (
                props.service.pricing.variablePrice?.prices[0].petType!.id ===
                'cats'
            ) {
                return {
                    options: catsPriceOptions,
                    title: 'Price for Cats',
                    type: 'cats'
                };
            } else {
                return {
                    options: exoticsPriceOptions,
                    title: 'Price for Exotics',
                    type: 'exotics'
                };
            }
        } else {
            if (props.service.petTypes.length === 2) {
                return {
                    options: dogsPriceOptions,
                    title: 'Price for Dogs',
                    type: 'multipleTypeDefault'
                };
            } else {
                return {
                    options: dogsPriceOptions,
                    title: '',
                    type: 'default'
                };
            }
        }
    });

    const [lastOptionsCache, setLastOptionsCache] = useState<OptionsCache>(
        () => {
            if (
                props.service.pricing.variablePrice &&
                props.service.pricing.variablePrice.prices.length > 0
            ) {
                if (
                    props.service.pricing.variablePrice.variables
                        .petSizeVariable &&
                    props.service.pricing.variablePrice.variables
                        .hairLengthVariable
                ) {
                    return {
                        dogs: byPetSizeAndHairLengthPriceOption,
                        cats: byPetSizeAndHairLengthPriceOption
                    };
                } else if (
                    props.service.pricing.variablePrice.variables
                        .petSizeVariable
                ) {
                    return {
                        dogs: byPetSizePriceOption,
                        cats: byPetSizePriceOption
                    };
                } else {
                    return {
                        dogs: byHairLenghtPriceOption,
                        cats: byHairLenghtPriceOption
                    };
                }
            } else {
                return {
                    dogs: fixedPriceOption,
                    cats: fixedPriceOption
                };
            }
        }
    );

    const [priceTypeSelected, setPriceTypeSelected] = useState<Option<string>>(
        () => {
            if (
                props.service.pricing.variablePrice &&
                props.service.pricing.variablePrice.prices.length > 0
            ) {
                if (
                    props.service.pricing.variablePrice.variables
                        .petSizeVariable &&
                    props.service.pricing.variablePrice.variables
                        .hairLengthVariable
                ) {
                    return byPetSizeAndHairLengthPriceOption;
                } else if (
                    props.service.pricing.variablePrice.variables
                        .petSizeVariable
                ) {
                    return byPetSizePriceOption;
                } else {
                    return byHairLenghtPriceOption;
                }
            } else {
                return fixedPriceOption;
            }
        }
    );

    const [subPetSelected, setSubPetSelected] = useState<string>(() => {
        if (
            isMultiplePet === true &&
            props.service.pricing.variablePrice &&
            props.service.pricing.variablePrice.prices.length > 0
        )
            return 'dogs';
        else return 'none';
    });

    const [isEdit, setIsEdit] = useState(() => {
        if (props.service.pricing.variablePrice?.prices) return true;
        else return false;
    });

    useEffect(() => {
        setErrors(props.errors);
    }, [props.errors]);

    useEffect(() => {
        if (isEdit) {
            setIsEdit(true);
        } else setPriceTypeSelected({ id: 'fixed_price', name: 'Fixed Price' });

        if (props.service.petTypes !== undefined) {
            const petTypesLength = props.service.petTypes.length;
            if (petTypesLength === 1) {
                setIsMultiplePet(false);
                switch (props.service.petTypes[0].id) {
                    case 'dogs':
                        setPetTypeOptions({
                            options: dogsPriceOptions,
                            title: 'Price for Dogs',
                            type: 'dogs'
                        });
                        break;
                    case 'cats':
                        setPetTypeOptions({
                            options: catsPriceOptions,
                            title: 'Price for Cats',
                            type: 'cats'
                        });
                        break;
                    case 'exotics':
                        setPetTypeOptions({
                            options: exoticsPriceOptions,
                            title: 'Price for Exotics',
                            type: 'exotics'
                        });
                        break;
                }
            }
            if (petTypesLength === 2) {
                setIsMultiplePet(true);
                setSubPetSelected('dogs');
                setPetTypeOptions({
                    options: dogsPriceOptions,
                    title: 'Price for Dogs',
                    type: 'multipleTypeDefault'
                });
            }
        }
    }, [props.service.petTypes]);

    useEffect(() => {
        if (priceTypeSelected.id === 'fixed_price') {
            setShowFixedPrice(true);
            setShowVariablePrice(false);
        } else {
            setShowFixedPrice(false);
            setShowVariablePrice(true);
        }
        const activeVariables = props.service.pricing.variablePrice?.variables;
        if (
            activeVariables &&
            activeVariables.petSizeVariable === true &&
            activeVariables.hairLengthVariable === true &&
            priceTypeSelected.id !== 'by_pet_size_and_hair_length'
        ) {
            props.onChange(
                props.service.pricing.taxable,
                [],
                undefined,
                props.service.pricing.taxes
            );
            setDisabledNext(true);
            setErrors([]);
        }
        if (
            activeVariables &&
            activeVariables.petSizeVariable === true &&
            activeVariables.hairLengthVariable === false &&
            priceTypeSelected.id !== 'by_pet_size'
        ) {
            props.onChange(
                props.service.pricing.taxable,
                [],
                undefined,
                props.service.pricing.taxes
            );
            setDisabledNext(true);
            setErrors([]);
        }
        if (
            activeVariables &&
            activeVariables.petSizeVariable === false &&
            activeVariables.hairLengthVariable === true &&
            priceTypeSelected.id !== 'by_hair_lenght'
        ) {
            props.onChange(
                props.service.pricing.taxable,
                [],
                undefined,
                props.service.pricing.taxes
            );
            setDisabledNext(true);
            setErrors([]);
        }
    }, [priceTypeSelected]);

    useEffect(() => {
        if (subPetSelected === 'dogs') {
            setPetTypeOptions({
                options: multiplePetPriceOptions,
                title: 'Price for Dogs',
                type: 'dogs'
            });
            setPriceTypeSelected(lastOptionsCache.dogs);
        }
        if (subPetSelected === 'cats') {
            setPetTypeOptions({
                options: multiplePetPriceOptions,
                title: 'Price for Cats',
                type: 'cats'
            });
            setPriceTypeSelected(lastOptionsCache.cats);
        }
    }, [subPetSelected]);

    useEffect(() => {
        if (
            props.collapsed &&
            subPetSelected === 'dogs' &&
            !errors.some(error => error.fieldName === 'pricing_dogs') &&
            errors.some(error => error.fieldName === 'pricing_cats')
        ) {
            setSubPetSelected('cats');
        }
        if (
            props.collapsed &&
            subPetSelected === 'cats' &&
            errors.some(error => error.fieldName === 'pricing_dogs')
        ) {
            setSubPetSelected('dogs');
        }
    }, [errors]);

    const taxesChangeHandler = (taxes: Array<MarketplaceTax>): void => {
        const checked = taxes.length == 0 ? false : true;
        props.onChange(
            checked,
            props.service.pricing.fixedPrice,
            props.service.pricing.variablePrice,
            taxes
        );
    };

    const fixedPriceChangeHandler = (
        fixedPrice: Array<ServiceFixedPrice>
    ): void => {
        setDisabledNext(!isComplete(fixedPrice, undefined));
        props.onChange(
            props.service.pricing.taxable,
            fixedPrice,
            undefined,
            props.service.pricing.taxes
        );
    };

    const variablePriceChangeHandler = (
        variablePrice: ServiceVariablePrice
    ): void => {
        setDisabledNext(!isComplete([], variablePrice));
        props.onChange(
            props.service.pricing.taxable,
            [],
            variablePrice,
            props.service.pricing.taxes
        );
    };

    const fixedPrices: Array<ServiceFixedPrice> =
        props.service.pricing.fixedPrice.length > 0
            ? props.service.petTypes.map(pt =>
                  props.service.pricing.fixedPrice.some(
                      fp => fp.petType.id === pt.id
                  )
                      ? props.service.pricing.fixedPrice.find(
                            fp => fp.petType.id === pt.id
                        )!
                      : { petType: pt, price: undefined }
              )
            : props.service.petTypes.map(petType => ({
                  price: undefined,
                  petType: petType
              }));

    return (
        <Section position={3} title="Pricing" {...props}>
            <BodySection>
                <Box className={classes.container}>
                    <Box className={classes.titleDescriptionSelectorContainer}>
                        <Box className={classes.titleDescriptionContainer}>
                            <Box className={classes.titleContainer}>
                                <Typography className={classes.title}>
                                    Service <span>*</span>
                                </Typography>
                            </Box>
                            <Box className={classes.descriptionContainer}>
                                <Typography className={classes.description}>
                                    Select whether your service has fixed or
                                    variable pricing. Variable pricing allows
                                    you to set prices based on pet size, and/or
                                    hair length.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <PriceTypeSelector
                        {...props}
                        id="services_add_service_pricing_pricetype"
                        options={petTypeOptions.options}
                        selected={priceTypeSelected}
                        onChange={value => {
                            setPriceTypeSelected(value);
                            setErrors([]);
                        }}
                        className={classes.checkboxContainer}
                        subPetSelected={subPetSelected}
                        lastOptionsCache={lastOptionsCache}
                        changeLastOptionCache={setLastOptionsCache}
                    />
                    <Box>
                        {showVariablePrice && (
                            <NewVariablePrice
                                {...props}
                                errors={errors}
                                onChange={variablePriceChangeHandler}
                                priceTypeSelected={priceTypeSelected}
                                subPetSelected={subPetSelected}
                                changeSubPetSelected={setSubPetSelected}
                                isMultiplePet={isMultiplePet}
                            />
                        )}
                    </Box>
                    <Box className={classes.priceContainer}>
                        {showFixedPrice && (
                            <FixedPrice
                                errors={errors}
                                id="services_add_service_pricing_fixed_price"
                                fixedPrices={fixedPrices}
                                onChange={fixedPriceChangeHandler}
                                isMultiplePet={isMultiplePet}
                            />
                        )}
                    </Box>
                    <Box className={classes.checkboxContainer}>
                        <TaxesSelector
                            id="services_add_service_pricing_taxes_selector"
                            checked={
                                props.service.pricing.taxes.length == 0
                                    ? false
                                    : true
                            }
                            selectedTaxes={props.service.pricing.taxes}
                            onChange={taxesChangeHandler}
                            title="Taxable Service"
                            subtitle="Choose the applicable tax for this service."
                            onTaxClick={props.onAddTaxes}
                        ></TaxesSelector>
                    </Box>
                </Box>
            </BodySection>
            <ActionSection
                id="services_add_service_pricing_button_next"
                onNext={props.onNext}
                disabled={!validatePricingStep(props.service) || disabledNext}
            />
        </Section>
    );
};

export default Pricing;
