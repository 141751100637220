import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/pro-light-svg-icons";
import { faBan, faCheck, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Box, createStyles, Divider, makeStyles, Theme, Typography} from "@material-ui/core";
import clsx from "clsx";
import VariablePriceTitle from "components/Services/VariablePriceTitle";
import AddOnService from "@spike/addon-service-model";
import { FunctionComponent, useState } from "react";
import { reduceResolution, wbp } from "Theme";
import SelectMobile from "../../UI/SelectMobile";
import { ActionsPopup } from 'components/UI/ActionsPopup/ActionsPopup';

interface RowProps {
  service: AddOnService;
  showStatus?: boolean;
  onEdit?: () => void;
  onChangeStatus?: (status: boolean) => void;
  onDelete?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        alignItems: "center",
      },
      "& td": {
        height: 73,
        fontSize: 15,
        fontWeight: 500,
        [theme.breakpoints.up('sm')]: {
            position: "relative",
        },
        borderBottom: "solid 1px #DDDDDD",

        [theme.breakpoints.down("sm")]: {
          height: "62px",
          display: "flex",
          alignItems: "center",
          width: "20vw",
        },

        "&:first-child": {
          width: "55%",
          fontWeight: 500,

          [theme.breakpoints.down(768)]: {
            paddingLeft: 20,
          },
          [theme.breakpoints.between(768, "sm")]: {
            paddingLeft: 30,
          },
          [theme.breakpoints.only("md")]: {
            paddingLeft: 50,
          },
          [theme.breakpoints.only("lg")]: {
            paddingLeft: 60,
          },
          [theme.breakpoints.only("xl")]: {
            paddingLeft: 90,
          },
          [theme.breakpoints.down("xs")]: {
            width: "60vw",
          },
        },
        "&:last-child": {
          paddingRight: 0,
          textAlign: "right",
            [theme.breakpoints.up('sm')]: {
                paddingRight: 60,
            },
            [theme.breakpoints.down("md")]: {
              justifyContent: "flex-end",
              paddingRight: "25px",
              paddingLeft: "0px",
            },
        },
      },
      "& a": {
        color: "#222",
        display: "block",
        padding: "5px 0px",
        textDecoration: "none",
      },
    },
    taxContainer: {
      [theme.breakpoints.down(wbp)]: {
        paddingLeft: `${reduceResolution(5)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingLeft: "5px",
      },
      display: "flex",
    },
    taxNameContainer: {
      width: "65%",
    },
    taxName: {
      margin: "auto",
      fontWeight: 500,
      fontSize: "12px",
    },
    taxPercentageContainer: {

      width: "45%",
    },
    taxPercentage: {
      fontWeight: 300,
      fontSize: "12px",
      color: "#7A7A7A !important"
    },
    noTax: {
      fontSize: "13px",
      fontWeight: 500
    },
    toggleIcon: {
      padding: 5,
      cursor: "pointer",
      [theme.breakpoints.down('sm')]: {
          float: 'left'
      }
    },
    dropdownParent:{
        [theme.breakpoints.down('md')]: {
            "& .MuiDialog-scrollPaper": {
              "& > div": {
                height: "44%",
              }
            }
        },
    },
    dropdown: {
        [theme.breakpoints.up('sm')]: {
            top: 50,
            right: 60,
            width: 140,
            zIndex: 10,
            borderRadius: 14,
            textAlign: "left",
            padding: "10px 0px",
            position: "absolute",
            backgroundColor: "#fff",
            border: "solid 1px #000",
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left'
        },
    },
    dropdownItem: {
      width: "100%",
      color: "#222",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.95,
      cursor: "pointer",
      margin: "2px 0px",
      padding: "0px 20px",
      display: "inline-block",

      "& svg": {
        marginRight: 6,
        verticalAlign: "middle",
      },

      "&:hover": {
        backgroundColor: "#FAFAFA",
      },
    },
    dropdownTitle: {
        marginTop: 25,
        "& p":{
          fontWeight: 500
        }
    },
    status: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: 1.8,
      borderRadius: 14,
      color: "#222222",
      padding: "0px 14px",
      display: "inline-block",
      backgroundColor: "#F4F3F0",
      textTransform: "capitalize",

      "&.active": {
        color: "#fff",
        backgroundColor: "#5E8677",
      },
    },
    taxesColumn: {
      [theme.breakpoints.down(wbp)]: {
        marginBottom: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginBottom: "15px",
      },
    },
    taxChip: {
      [theme.breakpoints.down(wbp)]: {
        marginBottom: `${reduceResolution(15)}px`,
        marginTop: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        marginBottom: "15px",
        marginTop: "15px",
      },

    },
    taxTitle: {
      background: "#F1F1F1 !important",
      border: "none !important"
    },

    active: {
      background: "#fff !important",
      border: "2px solid #000 !important"
    },

    hover: {
      background: "#f4f4f4 !important"
    },
    headerDivider: {
      [theme.breakpoints.down(wbp)]: {
         marginTop: `${reduceResolution(15)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
         marginTop: "15px",
      },
    },
    price: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'right'
        },
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
      },
    },
    textName: {
      "& > span": {
        [theme.breakpoints.down('sm')]: {
          fontWeight: 600,
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          position: 'relative',
          maxWidth: '100%',
        }
      },
    },
    headerActionPopup:{
      textAlign: 'left',
      marginTop: 25,
      marginBottom: 5,
      "& > p":{
        fontSize: 16,
        fontWeight: 600,
      }
    }
  })
);
const sm = 500;

const Row: FunctionComponent<RowProps> = (props) => {
  const classes = useStyles();
  const { innerWidth: width } = window;
  const [showDropdown, setShowDropdown] = useState(false);
  const [showTaxes, setShowTaxes] = useState(false);
  const [hover, setHover] = useState(false);

  const Formatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

  const taxes = (
    <Box className={classes.taxChip} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <VariablePriceTitle title={props.service.taxes ? props.service.taxes.length + " Taxes" : "0 Taxes"} expandable={true} className={clsx(classes.taxTitle, { [classes.hover]: hover, [classes.active]: showTaxes })} onChange={setShowTaxes} />
    </Box>
  );

  const headerActionPopup = (
    <Box className={classes.headerActionPopup}>
      <Typography>{props.service.name}</Typography>
    </Box>
  );

  return (
    <tr className={classes.root}>
      <td className={classes.textName}><span>{props.service.name}</span></td>
      <td className={classes.price}>{Formatter.format(props.service.price)}</td>
      {width > sm && (
          <td>{(props.service.taxes && props.service.taxes.length > 0) ? <Box className={classes.taxesColumn}>
              {taxes}
              {showTaxes && props.service.taxes.length > 0 &&
                  props.service.taxes.map((tax) => {
                      return (
                          <Box className={classes.taxContainer} key={tax.uuid}>
                              <Box className={classes.taxNameContainer}>
                                  <Typography className={classes.taxName} >{tax.name}</Typography>
                              </Box>
                              <Box className={classes.taxPercentageContainer}>
                                  <Typography className={classes.taxPercentage}>
                                      {tax.percentage}%
                                  </Typography>
                              </Box>
                          </Box>
                      )
                  })
              }
          </Box> : <Typography className={classes.noTax}>
              Not taxable
          </Typography>
          }</td>
      )}
      {props.showStatus && width > sm && (
        <td>
          <span className={clsx(classes.status, { active: props.service.active })}>{props.service.active ? "Active" : "Inactive"}</span>
        </td>
      )}

      <td>
        <span className={classes.toggleIcon} onClick={() => setShowDropdown(prev => !prev)}>
          <FontAwesomeIcon icon={faEllipsis} />
        </span>

        {showDropdown && (
            <>
                {width > sm ? (
                    <Box className={classes.dropdown}>
                        <Box className={classes.dropdownItem} onClick={() => { setShowDropdown(false); props.onEdit && props.onEdit() }}>
                            <FontAwesomeIcon icon={faPen} />
                            Edit
                        </Box>
                        {!props.service.active && (
                            <Box className={classes.dropdownItem} onClick={() => { setShowDropdown(false); props.onChangeStatus && props.onChangeStatus(true) }}>
                                <FontAwesomeIcon icon={faCheck} />
                                Activate
                            </Box>
                        )}
                        {props.service.active && (
                            <Box className={classes.dropdownItem} onClick={() => { setShowDropdown(false); props.onChangeStatus && props.onChangeStatus(false) }}>
                                <FontAwesomeIcon icon={faBan} />
                                Deactivate
                            </Box>
                        )}
                        {props.service.destroyable && (
                            <Box className={classes.dropdownItem} onClick={() => { setShowDropdown(false); props.onDelete && props.onDelete() }}>
                                <FontAwesomeIcon icon={faTrash} />
                                Delete
                            </Box>
                        )}
                    </Box>
                ) : (

                    <ActionsPopup
                        header={headerActionPopup}
                        actions={[
                            {
                                label: 'Edit',
                                icon: faPen,
                                onClick: () => { setShowDropdown(false); props.onEdit && props.onEdit() }
                            },
                            {
                                label: props.service.active ? 'Deactivate' : 'Activate',
                                icon: props.service.active ? faBan : faCheck,
                                onClick: () => { setShowDropdown(false); props.onChangeStatus && props.onChangeStatus(!props.service.active) }
                            },
                            {
                                label: 'Remove',
                                icon: faTrash,
                                onClick: () => { setShowDropdown(false); props.onDelete && props.onDelete() }
                            }
                        ]}
                        onClose={() => setShowDropdown(false)}
                    />
                )}
            </>
        )}
      </td>
    </tr>
  );
};

export default Row;
