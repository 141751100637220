import React, { FunctionComponent, useState } from 'react';
import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import Staff from 'model/Staff';
import CheckboxCircle from 'components/UI/CheckboxCircle';
import {
    faPencil,
    faBan,
    faCheckCircle,
    faPen,
    faCircleCheck,
    faEnvelope,
    faPaperPlane
} from '@fortawesome/pro-light-svg-icons';
import ToolbarButton from 'components/UI/Toolbar/ToolbarButton';
import { ToolbarItem } from 'components/UI/Toolbar/ToolbarItem';
import ToolbarItemWithConfirmation from 'components/UI/Toolbar/ToolbarItemWithConfirmation';
import { formatPhoneNumber } from '@spike/phone-utils';
import clsx from 'clsx';
import { ScheduleStatus } from './ScheduleStatus';
import { Button, OverFullWindow, StaffChip } from 'components/UI';
import StaffServices from 'components/Staff/StaffServices';
import { useAuth, useMarketplace } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ActionsPopup } from 'components/UI/ActionsPopup/ActionsPopup';
import { OWNER_ID } from '@spike/model';
export interface Props {
    staffData: Staff;
    editStaff: (staffId: number) => void;
    deleteStaff: (staffDeleted: Staff) => void;
    activate: (active: boolean) => void;
    resendInvitation: (staffId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rowContainer: (props: Props) => ({
            'display': 'flex',
            'textAlign': 'right',
            'cursor': 'pointer',
            'borderBottom': '1px solid #F1F1F1',
            '&:hover': {
                backgroundColor: props.staffData.active ? '#FAFAFA' : 'white'
            }
        }),
        cellContainer: (props: Props) => ({
            alignItems: 'start',
            textAlign: 'initial',
            opacity: props.staffData.active ? 1 : 0.3,
            [theme.breakpoints.down('lg')]: {
                marginTop: '37px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '35px'
            }
        }),
        cellContainerCheck: (props: Props) => ({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            opacity: props.staffData.active ? 1 : 0.3,
            [theme.breakpoints.down('lg')]: {
                height: '97px'
            },
            [theme.breakpoints.up('xl')]: {
                height: '100px'
            }
        }),
        cellName: {
            '& .MuiChip-root': {
                background: 'inherit',
                [theme.breakpoints.down('md')]: {
                    maxWidth: '100px'
                },
                [theme.breakpoints.only('md')]: {
                    maxWidth: '150px'
                },
                [theme.breakpoints.only('lg')]: {
                    maxWidth: '200px'
                },
                [theme.breakpoints.up('xl')]: {
                    maxWidth: '300px'
                }
            },
            [theme.breakpoints.down('lg')]: {
                marginTop: '34px !important'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '31px !important'
            }
        },
        cellStaff: {
            [theme.breakpoints.down('lg')]: {
                marginTop: '29px !important'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '27px !important'
            }
        },
        cellMenuContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'center',
            [theme.breakpoints.down('lg')]: {
                height: '97px'
            },
            [theme.breakpoints.up('xl')]: {
                height: '100px'
            }
        },
        avatar: {
            [theme.breakpoints.down('lg')]: {
                marginRight: '18px',
                height: '27px !important',
                width: '27px !important',
                marginLeft: '12px'
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: '22px',
                height: '33px !important',
                width: '33px !important',
                marginLeft: '16px'
            }
        },
        toolbarButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                width: '37px'
            },
            [theme.breakpoints.only('md')]: {
                width: '50px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '67px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '90px'
            }
        },
        containerDropDown: {
            display: 'flex',
            position: 'relative',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        menuContainer: {
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            margin: 'auto'
        },
        dropdown: {
            [theme.breakpoints.down('lg')]: {
                marginTop: '30px',
                marginLeft: '-40px',
                borderRadius: '10px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '60px',
                borderRadius: '15px'
            }
        },
        gridContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'initial'
        },
        cells: {
            paddingLeft: '20px',
            [theme.breakpoints.down('lg')]: {
                fontSize: '14px',
                width: '105px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '18px',
                width: '140px'
            }
        },
        cellsTwoRows: {
            [theme.breakpoints.down('lg')]: {
                fontSize: '14px',
                width: '187px'
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: '18px',
                width: '250px'
            }
        },
        textDialog: {
            textAlign: 'center',
            [theme.breakpoints.down('lg')]: {
                fontSize: '15px'
            }
        },
        leftSpace: {
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '15px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '20px'
            }
        },
        leftSpaceServices: {
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '45px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '60px'
            }
        },
        role: {
            paddingLeft: '0px !important'
        },
        phone: {
            width: 'auto !important',
            paddingLeft: '0px !important'
        },
        active: {
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '62px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '87px'
            }
        },
        inactive: {
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '21px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '43px'
            }
        },
        leftCellSpace: {
            [theme.breakpoints.down('lg')]: {
                paddingLeft: '22px'
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: '30px'
            }
        },
        desktop: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobile: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        mobileRow: (props: Props) => ({
            'padding': '0px 16px',
            'borderTop': 'solid 1px #D4D4D4',
            'opacity': props.staffData.active ? 1 : 0.6,

            '&:last-child': {
                borderBottom: 'solid 1px #D4D4D4'
            },
            '& > div': {
                padding: '12px 0px'
            }
        }),
        mobileCell: {
            display: 'inline-flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        avatarCell: {
            'display': 'flex',
            'alignItems': 'center',
            'flexDirection': 'row',
            'justifyContent': 'flex-start',

            '& img': {
                width: 48,
                height: 48,
                marginRight: 12,
                borderRadius: '50%'
            }
        },
        staffName: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 500,
            marginBottom: 4
        },
        staffRole: {
            fontSize: 14,
            lineHeight: 1
        },
        servicesCount: {
            'fontSize': 14,
            'lineHeight': 1,

            '& span': {
                width: 23,
                height: 23,
                color: '#fff',
                marginTop: -1,
                lineHeight: 1.8,
                paddingLeft: 2,
                textAlign: 'center',
                borderRadius: '50%',
                verticalAlign: 'middle',
                display: 'inline-block',
                backgroundColor: '#5E8677',
                marginRight: 6
            }
        },
        actionCol: {
            marginLeft: 'auto'
        },
        staffButton: {
            'all': 'unset',
            'fontSize': 32,

            '& svg': {
                display: 'block'
            }
        },
        popup: {
            width: '100%',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
        },
        popupHeader: {
            'widht': '100%',
            'textAlign': 'right',
            'padding': '15px 16px',

            '& button': {
                border: 0,
                padding: 0,
                fontSize: 20,
                background: 'none'
            }
        },
        popupBody: {
            'flex': 1,
            'display': 'flex',
            'padding': '0px 16px',
            'flexDirection': 'column',
            'justifyContent': 'center',

            '& h1': {
                fontSize: 26,
                lineHeight: 1,
                fontWeight: 600,
                marginBottom: 18
            },
            '& p': {
                'fontSize': 16,
                'lineHeight': 1.4,

                '& b': {
                    fontWeight: 500
                }
            }
        },
        popupFooter: {
            'gap': 10,
            'display': 'flex',
            'padding': '0px 24px 20px',

            '& button': {
                'height': 55,
                'width': '100%',
                'borderRadius': 30,

                '& span': {
                    fontSize: 16,
                    fontWeight: 600
                }
            }
        }
    })
);

export const StaffRowList: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const marketplace = useMarketplace();
    const auth = useAuth();

    const [showActions, setShowActions] = useState(false);
    const [showActivateConfirm, setShowActivateConfirm] = useState(false);
    const [showDeactivateConfirm, setShowDeactivateConfirm] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const isUser = auth.user!.staffId === props.staffData.id;
    const isOwner = props.staffData.role?.id === OWNER_ID;

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const handleActivate = () => {
        setShowActivateConfirm(false);
        props.activate(true);
    };
    const handleDeactivate = () => {
        setShowDeactivateConfirm(false);
        props.activate(false);
    };
    const handleEdit = () => {
        props.staffData.id && props.editStaff(props.staffData.id);
    };

    const handleResendInvite = (staffId: number) => {
        props.resendInvitation(staffId);
        if (showActions) {
            setShowActions(false);
        }
    };

    const StaffActionHeader: React.FunctionComponent<{
        staff: Props['staffData'];
    }> = props => (
        <Box className={clsx(classes.mobileCell, classes.avatarCell)}>
            <img src="/images/avatar/staff-avatar.svg" alt="Staff Avatar" />

            <Box>
                <Typography className={classes.staffName}>
                    {props.staff.person.firstName} {props.staff.person.lastName}
                </Typography>
                <Typography className={classes.staffRole}>
                    {props.staff.role?.name}
                </Typography>
            </Box>
        </Box>
    );

    return (
        <>
            {isMobile ? (
                <>
                    <Grid container className={classes.mobileRow}>
                        <Grid
                            item
                            xs={7}
                            className={clsx(
                                classes.mobileCell,
                                classes.avatarCell
                            )}
                            onClick={handleEdit}
                        >
                            <img
                                src="/images/avatar/staff-avatar.svg"
                                alt="Staff Avatar"
                            />

                            <Box>
                                <Typography className={classes.staffName}>
                                    {props.staffData.person.firstName}{' '}
                                    {props.staffData.person.lastName}
                                </Typography>
                                <Typography className={classes.staffRole}>
                                    {props.staffData.role?.name}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            className={classes.mobileCell}
                            onClick={handleEdit}
                        >
                            <Typography className={classes.servicesCount}>
                                <span>{props.staffData.services.length}</span>
                                Services
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            className={clsx(
                                classes.mobileCell,
                                classes.actionCol
                            )}
                        >
                            <button
                                type="button"
                                aria-label="Staff actions"
                                className={classes.staffButton}
                                onClick={() => setShowActions(true)}
                            >
                                <FontAwesomeIcon icon={faEllipsis} />
                            </button>
                        </Grid>
                    </Grid>

                    {!showActivateConfirm &&
                        !showDeactivateConfirm &&
                        !showDeleteConfirm &&
                        showActions && (
                            <ActionsPopup
                                header={
                                    <StaffActionHeader
                                        staff={props.staffData}
                                    />
                                }
                                actions={[
                                    {
                                        label: 'Edit',
                                        icon: faPen,
                                        onClick: handleEdit
                                    },
                                    ...(props.staffData.last_login == null &&
                                    props.staffData.id
                                        ? [
                                              {
                                                  label: 'Resend Invitation',
                                                  icon: faPaperPlane,
                                                  onClick: () => {
                                                      if (props.staffData.id) {
                                                          handleResendInvite(
                                                              props.staffData.id
                                                          );
                                                      }
                                                  }
                                              }
                                          ]
                                        : []),
                                    ...(!isUser &&
                                    !isOwner &&
                                    !props.staffData.active
                                        ? [
                                              {
                                                  label: 'Activate',
                                                  icon: faCircleCheck,
                                                  onClick: () =>
                                                      setShowActivateConfirm(
                                                          true
                                                      )
                                              }
                                          ]
                                        : []),
                                    ...(!isUser &&
                                    !isOwner &&
                                    props.staffData.active
                                        ? [
                                              {
                                                  label: 'Deactivate',
                                                  icon: faBan,
                                                  onClick: () =>
                                                      setShowDeactivateConfirm(
                                                          true
                                                      )
                                              }
                                          ]
                                        : [])
                                ]}
                                onClose={() => setShowActions(false)}
                            />
                        )}

                    {showActivateConfirm && (
                        <OverFullWindow hideHeader>
                            <Box className={classes.popup}>
                                <Box className={classes.popupHeader}>
                                    <button type="button">
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            onClick={() =>
                                                setShowActivateConfirm(false)
                                            }
                                        />
                                    </button>
                                </Box>
                                <Box className={classes.popupBody}>
                                    <Typography variant="h1">
                                        Activate Staff
                                    </Typography>
                                    <Typography>
                                        Do you want to activate{' '}
                                        <b>
                                            {props.staffData.person.firstName}{' '}
                                            {props.staffData.person.lastName}?
                                        </b>
                                    </Typography>
                                </Box>
                                <Box className={classes.popupFooter}>
                                    <Button
                                        color="black"
                                        label="Cancel"
                                        onClick={() =>
                                            setShowActivateConfirm(false)
                                        }
                                    />
                                    <Button
                                        color="green"
                                        label="Confirm"
                                        onClick={handleActivate}
                                    />
                                </Box>
                            </Box>
                        </OverFullWindow>
                    )}

                    {showDeactivateConfirm && (
                        <OverFullWindow hideHeader>
                            <Box className={classes.popup}>
                                <Box className={classes.popupHeader}>
                                    <button type="button">
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            onClick={() =>
                                                setShowDeactivateConfirm(false)
                                            }
                                        />
                                    </button>
                                </Box>
                                <Box className={classes.popupBody}>
                                    <Typography variant="h1">
                                        Deactivate Staff
                                    </Typography>
                                    <Typography>
                                        Do you want to deactivate{' '}
                                        <b>
                                            {props.staffData.person.firstName}{' '}
                                            {props.staffData.person.lastName}?
                                        </b>
                                    </Typography>
                                </Box>
                                <Box className={classes.popupFooter}>
                                    <Button
                                        color="black"
                                        label="Cancel"
                                        onClick={() =>
                                            setShowDeactivateConfirm(false)
                                        }
                                    />
                                    <Button
                                        label="Confirm"
                                        variant="danger"
                                        onClick={handleDeactivate}
                                    />
                                </Box>
                            </Box>
                        </OverFullWindow>
                    )}

                    {showDeleteConfirm && (
                        <OverFullWindow hideHeader>
                            <Box className={classes.popup}>
                                <Box className={classes.popupHeader}>
                                    <button type="button">
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            onClick={() =>
                                                setShowDeleteConfirm(false)
                                            }
                                        />
                                    </button>
                                </Box>
                                <Box className={classes.popupBody}>
                                    <Typography variant="h1">
                                        Delete Staff
                                    </Typography>
                                    <Typography>
                                        Are you sure you want to delete{' '}
                                        <b>
                                            {props.staffData.person.firstName}{' '}
                                            {props.staffData.person.lastName}
                                        </b>{' '}
                                        staff?
                                    </Typography>
                                </Box>
                                <Box className={classes.popupFooter}>
                                    <Button
                                        color="black"
                                        label="Cancel"
                                        onClick={() =>
                                            setShowDeleteConfirm(false)
                                        }
                                    />
                                    <Button
                                        label="Confirm"
                                        variant="danger"
                                        onClick={() => {
                                            setShowDeleteConfirm(false);
                                            props.deleteStaff(props.staffData);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </OverFullWindow>
                    )}
                </>
            ) : (
                <Grid
                    container
                    item
                    className={clsx(classes.rowContainer, classes.desktop)}
                >
                    <Box
                        className={classes.cellContainerCheck}
                        onClick={handleEdit}
                    >
                        {!props.staffData.active ? (
                            <Box className={classes.inactive}>
                                <CheckboxCircle checked={false} ban={true} />
                            </Box>
                        ) : (
                            <Box className={classes.active}></Box>
                        )}
                    </Box>
                    <Grid
                        item
                        className={clsx(
                            classes.cellContainer,
                            classes.cellName
                        )}
                        xs={2}
                        onClick={handleEdit}
                    >
                        <StaffChip
                            staff={{
                                ...props.staffData.person,
                                id: props.staffData.id!
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        className={clsx(
                            classes.cellContainer,
                            classes.leftSpace
                        )}
                        xs={1}
                        onClick={handleEdit}
                    >
                        <Typography
                            noWrap={true}
                            className={clsx(classes.cells, classes.role)}
                        >
                            {props.staffData.role?.name}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        className={clsx(
                            classes.cellContainer,
                            classes.leftSpace
                        )}
                        xs={1}
                        onClick={handleEdit}
                    >
                        <ScheduleStatus schedule={props.staffData.schedule} />
                    </Grid>
                    <Grid
                        item
                        className={clsx(
                            classes.cellContainer,
                            classes.leftSpaceServices,
                            {
                                [classes.cellStaff]:
                                    props.staffData.services.length > 1
                            }
                        )}
                        xs={2}
                    >
                        <StaffServices services={props.staffData.services} />
                    </Grid>
                    <Grid
                        item
                        className={clsx(
                            classes.cellContainer,
                            classes.leftSpace
                        )}
                        xs={3}
                        onClick={handleEdit}
                    >
                        <Typography className={classes.cellsTwoRows}>
                            {props.staffData.person.email}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        className={classes.cellContainer}
                        xs={1}
                        onClick={handleEdit}
                    >
                        <Typography
                            noWrap={true}
                            className={clsx(classes.cells, classes.phone)}
                        >
                            {formatPhoneNumber(
                                props.staffData.person.phoneNumber,
                                marketplace.basics.address.country!.id
                            )
                                ? formatPhoneNumber(
                                      props.staffData.person.phoneNumber,
                                      marketplace.basics.address.country!.id
                                  )
                                : '-'}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        className={clsx(
                            classes.cellMenuContainer,
                            classes.leftSpace
                        )}
                        xs={1}
                    >
                        <Box className={classes.toolbarButtonContainer}>
                            <ToolbarButton id="staff_list_menu">
                                <ToolbarItem
                                    icon={faPencil}
                                    onClick={handleEdit}
                                    text={'Edit'}
                                    id="staff_list_menu_actions_edit_"
                                />

                                {props.staffData.last_login == null &&
                                    props.staffData.id !== undefined && (
                                        <ToolbarItem
                                            icon={faPaperPlane}
                                            onClick={() => {
                                                if (props.staffData.id) {
                                                    handleResendInvite(
                                                        props.staffData.id
                                                    );
                                                }
                                            }}
                                            text={'Resend Invitation'}
                                            id="staff_list_menu_actions_resend_invite"
                                        />
                                    )}

                                {!isUser &&
                                    !isOwner &&
                                    (!props.staffData.active ? (
                                        <ToolbarItemWithConfirmation
                                            id="staff_list_menu_actions_activate_toolbar_item"
                                            icon={faCheckCircle}
                                            onConfirmedClick={handleActivate}
                                            text={'Activate'}
                                            confirmQuestion={
                                                <Typography>
                                                    Do you want to activate{' '}
                                                    <b>{`${props.staffData.person.firstName} ${props.staffData.person.lastName}`}</b>{' '}
                                                    ?{' '}
                                                </Typography>
                                            }
                                            confirmButtonLabel="Yes"
                                            cancelButtonLabel="No"
                                        />
                                    ) : (
                                        <ToolbarItemWithConfirmation
                                            id="staff_list_menu_actions_deactivate_toolbar_item"
                                            icon={faBan}
                                            onConfirmedClick={handleDeactivate}
                                            text={'Deactivate'}
                                            confirmQuestion={
                                                <Typography>
                                                    Do you want to deactivate{' '}
                                                    <b>{`${props.staffData.person.firstName} ${props.staffData.person.lastName}`}</b>{' '}
                                                    ?{' '}
                                                </Typography>
                                            }
                                            confirmButtonLabel="Yes"
                                            cancelButtonLabel="No"
                                        />
                                    ))}
                            </ToolbarButton>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </>
    );
};
