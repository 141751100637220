import { FunctionComponent, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableRow, TableCell, Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import { Product } from "@spike/product-model";
import ToolbarButton from "components/UI/Toolbar/ToolbarButton";
import ToolbarItem from "components/UI/Toolbar/ToolbarItem";
import { faCamera, faPencil, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { deleteProductThunk, fetchProductsThunk } from "@spike/products-action";
import { useDispatch, useSelector } from "react-redux";
import { fetchThunk as fetchVendorsThunk } from "actions/productVendors/ProductVendorsActions";
import { fetchThunk as fetchCategoriesThunk } from "actions/productCategories/ProductCategoriesActions";
import { ProductsStatus } from "@spike/products-action";
import { RootState } from "store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Img } from "react-image";
import { useApiClientWrapper, useMasterData } from "hooks";
import ToolbarItemWithConfirmation from "../UI/Toolbar";
import isEmpty from "lodash/isEmpty";
import {getPriceBySize} from "../Utils";

interface Props {
  product: Product;
  onEdit: (product: Product) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#FAFAFA !important",
      }
    },
    cell: {
      paddingLeft: "0px",
      verticalAlign: "top !important",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        paddingTop: "21px",
        paddingBottom: "21px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        paddingTop: "26px",
        paddingBottom: "26px",
      },
    },
    options: {
      position: "absolute",
    },
    firstCell: {
      [theme.breakpoints.down("md")]: {
        paddingLeft: "48px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "60px",
      },
    },
    imageCell: {
      width: "5%"
    },
    productCell: {
      width: "25%"
    },
    cells: {
      width: "12%"
    },
    text: {
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "22px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "27px"
      },
    },
    nameContainer: {
      display: "flex",
      flexDirection: "column"
    },
    textDialog: {
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        lineHeight: "22px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        lineHeight: "27px"
      },
    },
    imageBox: {
      [theme.breakpoints.down("md")]: {
        width: "57px",
        height: "53px",
        borderRadius: "8px"
      },
      [theme.breakpoints.up("lg")]: {
        width: "71px",
        height: "66px",
        borderRadius: "10px"
      },
    },
    uploadBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      backgroundColor: "#F1F1F1",
      [theme.breakpoints.down("md")]: {
        borderRadius: "8px"
      },
      [theme.breakpoints.up("lg")]: {
        borderRadius: "10px"
      },
    },
    uploadIcon: {
      color: "#5E8677",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
        lineHeight: "24px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "18px",
        lineHeight: "30px",
      },
    },
    codeText: {
      color: "#7A7A7A",
      [theme.breakpoints.down("md")]: {
        fontSize: "11px",
        lineHeight: "14px",
        marginTop: "4px"
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "14px",
        lineHeight: "17px",
        marginTop: "6px"
      },
    },
    titleDialog: {
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "19px",
        lineHeight: "29px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "24px",
        lineHeight: "36px",
      },
    },
    description: {
      color: "#7A7A7A",
      overflowWrap: "break-word",
    },
    capitalize: {
      textTransform: 'capitalize'
    }
  })
);




export const ProductRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const apiClientWrapper = useApiClientWrapper();
  const masterData = useMasterData();

  const status = useSelector<RootState, ProductsStatus>(state => state.products.status);

  const vendorDefault = props.product && props.product.vendors && props.product.vendors.find((v) => v.default === true);

  useEffect(() => {
    if (status === ProductsStatus.DeleteSuccess) {
      dispatch(fetchProductsThunk(apiClientWrapper));   
      dispatch(fetchVendorsThunk());
      dispatch(fetchCategoriesThunk());  
    }
  }, [status]);

  const handleDeleteProduct = (product: Product) => {
    dispatch(deleteProductThunk(apiClientWrapper, product));
  }

  return (
    <TableRow className={classes.row}>
      <TableCell className={clsx(classes.cell, classes.firstCell, classes.imageCell)} onClick={() => props.onEdit(props.product)}>
        <Box className={classes.imageBox}>
          {props.product.imageUrl ? <Img src={props.product.imageUrl} className={classes.imageBox}/> 
          : 
          <Box className={classes.uploadBox}>
            <FontAwesomeIcon
              icon={faCamera}
              className={clsx(classes.uploadIcon)}
            />
          </Box>}
        </Box>
      </TableCell>
      <TableCell className={clsx(classes.cell , classes.productCell)} onClick={() => props.onEdit(props.product)}>
        <Box className={classes.nameContainer}>
          <Typography className={classes.text}>{isEmpty(props.product.businessProduct?.name) ? props.product.name : props.product.businessProduct?.name}</Typography>
          <Typography className={clsx(classes.text, classes.description)}>
            {(props.product.businessProduct?.description || "").length < 100 ? props.product.businessProduct?.description || "" : props.product.businessProduct?.description?.substring(0, 100) + "..."}
          </Typography>
          <Typography className={classes.codeText}>Barcode: {props.product.businessProduct?.code || "-"}</Typography>
        </Box>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.cells, classes.capitalize)} onClick={() => props.onEdit(props.product)}>
        <Typography className={classes.text}>{isEmpty(props.product.businessProduct?.brand) ? props.product.brand :props.product.businessProduct?.brand || "-"}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.cells, classes.capitalize)} onClick={() => props.onEdit(props.product)}>
        <Typography className={classes.text}>{isEmpty(props.product.businessProduct?.manufacturer) ? props.product.manufacturer : props.product.businessProduct?.manufacturer|| "-"}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.cells)} onClick={() => props.onEdit(props.product)}>
        <Typography className={classes.text}>{props.product.category?.name || "-"}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.cells)} onClick={() => props.onEdit(props.product)}>
        <Typography className={classes.text}>{vendorDefault?.vendor?.name || props.product.vendors.some(vendor => !vendor.vendor?.deleted) ? props.product.vendors.filter(vendor => !vendor.vendor?.deleted)[0].vendor?.name : "-"}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.cells)} onClick={() => props.onEdit(props.product)}>
        <Typography className={classes.text}>{getPriceBySize(props.product.businessProduct, masterData)}</Typography>
      </TableCell>
      <TableCell className={clsx(classes.cell, classes.cells)}>
        <Box className={classes.options}>
          <ToolbarButton>
            <ToolbarItem icon={faPencil} onClick={() => props.onEdit(props.product)} text={"View Detail"} />
            {props.product.businessProduct?.destroyable
             && <ToolbarItemWithConfirmation
              icon={faTrashAlt}
              text="Delete"
              centered
              onConfirmedClick={() => handleDeleteProduct(props.product)}
              confirmQuestion={
                <Typography className={classes.titleDialog}>
                  Are you sure you want to delete this product?
                </Typography>
              }
              subConfirmQuestion={
                <Typography className={classes.textDialog}>
                  Deleting product will permanently remove it from your database. You can always recreate it in the future.
                </Typography>
              }
              confirmButtonLabel="Delete"
              cancelButtonLabel="Nevermind"
            />}
          </ToolbarButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ProductRow;