import { SmsChat, SmsMessage } from "model/TwoWaySms";
import { SmsConversationDto, SmsMessageDto } from "./twoWaySmsDtos";
import moment from "moment";
import store from "store";


export const convertToSmsMessage = (smsMessageDto: SmsMessageDto): SmsMessage => {
  const timeZone = store.getState().marketplace.marketplace.schedule.timeZone;

  return {
    id: smsMessageDto.id,
    uuid: smsMessageDto.uuid ?? '',
    marketplaceId: smsMessageDto.marketplace_id,
    clientId: smsMessageDto.customer_id,
    senderId: smsMessageDto.sender_id,
    senderType: smsMessageDto.sender_type,
    receiverId: smsMessageDto.receiver_id,
    receiverType: smsMessageDto.receiver_type,
    message: smsMessageDto.message,
    note: smsMessageDto.note,
    sentAt: smsMessageDto.sent_at,
    receivedAt: smsMessageDto.received_at,
    readAt: smsMessageDto.read_at,
    createdAt: moment(smsMessageDto.created_at).tz(timeZone)
  };
};


export const convertToSmsChat = (smsConversationDto: SmsConversationDto): SmsChat => {
  return {
    client: smsConversationDto.customer,
    message: convertToSmsMessage(smsConversationDto.last_sms_message),
    unread: smsConversationDto.unread_sms_messages
  };

}


export const convertToSmsMessageSaveDto = (smsMessage: SmsMessage): SmsMessageDto => {

  return {
    id: smsMessage.id,
    marketplace_id: smsMessage.marketplaceId,
    customer_id: smsMessage.clientId,
    sender_id: smsMessage.senderId,
    sender_type: smsMessage.senderType,
    receiver_id: smsMessage.receiverId,
    receiver_type: smsMessage.receiverType,
    message: smsMessage.message,
    note: smsMessage.note,
    sent_at: smsMessage.sentAt,
    received_at: smsMessage.receivedAt,
    read_at: smsMessage.readAt
  };
};