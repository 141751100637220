import { FunctionComponent, } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import PetImage from "./PetImage";
import PetDescription from "./PetDescription";
import Pet from "@spike/pet-model";

interface PetCardProps {
    pet?: Pet;
    onShowPetCard?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
        display: 'flex'
    },
    image: {
        width: 64,
        height: 64,
        marginRight: 18,
        [theme.breakpoints.up('md')]: {
            marginRight: 20
        }
    },
    petDescriptionContainer: {
        display: 'flex',
        width: '80%'
    }
  })
);

export const PetCard: FunctionComponent<PetCardProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={classes.container}>
        <Box className={classes.image}>
            <PetImage
                petImage={props.pet?.image}
                petType={props.pet?.type?.id}
            />
        </Box>
        <Box className={classes.petDescriptionContainer}>
            <PetDescription
                pet={props.pet}
                onShowPetCard={props.onShowPetCard}
            />
        </Box>
    </Box>
  );
};

export default PetCard;