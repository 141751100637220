import React, { FunctionComponent, useState } from 'react';
import Box from '@material-ui/core/Box';
import { isEmpty, toNumber } from 'lodash';
import { useMarketplace } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { reduceResolution, wbp } from 'Theme';
import { Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PriceField } from 'components/UI';
import clsx from 'clsx';
import { FixedPriceOption as ServiceFixedPrice } from 'model/Service';
import { FieldErrorWithKey } from '../model';

interface Props {
    id?: string;
    fixedPrices: Array<ServiceFixedPrice>;
    isMultiplePet?: boolean;
    errors: Array<FieldErrorWithKey>;
    onChange: (fixedPrices: Array<ServiceFixedPrice>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                gap: 16
            }
        },
        nextPetTypePriceContainer: {
            [theme.breakpoints.up('md')]: {
                marginLeft: '57px'
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '76px'
            }
        },
        title: {
            color: '#000',
            fontSize: 16,
            fontWeight: 600,
            marginBottom: 16
        },
        priceContainer: {
            marginBottom: 24
        },
        price: {
            '& .MuiInputBase-input': {
                paddingLeft: 35,
                paddingRight: 35
            }
        },
        depositAlert: {
            'display': 'none',
            'backgroundColor': '#F8F5F1',
            'border': '2px solid #BCB8AE',
            'borderRadius': '10px',
            'padding': '14px 16px 16px',
            'alignItems': 'center',

            'width': '100%',
            'marginBottom': 24,

            '& b': {
                fontWeight: 600
            }
        },
        showAlert: {
            display: 'flex'
        },
        iconInfo: {},
        sectionIconInfo: {
            'color': '#BAA997',
            '&:hover': {
                cursor: 'pointer'
            },
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(24)}px`,
                paddingRight: `${reduceResolution(10)}px`,
                marginTop: `${reduceResolution(2)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '24px',
                paddingRight: '10px',
                marginTop: '2px'
            }
        },
        boxIcon: {
            [theme.breakpoints.down(wbp)]: {
                width: `${reduceResolution(38)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                width: '38px'
            }
        },
        spanMessage: {
            fontSize: 14,
            lineHeight: 1.3,
            fontWeight: 400
        },
        errorMessage: {
            color: 'red',
            marginBottom: 10,
            [theme.breakpoints.down('sm')]: {
                fontSize: 12,
                lineHeight: 1.1
            }
        }
    })
);

export const FixedPrice: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const marketplace = useMarketplace();
    const depositAmount = toNumber(marketplace.deposit.value);
    const depositType = marketplace.deposit.type;

    const [showAlert, setShowAlert] = useState(false);

    const priceChangeHandler = (
        petTypeId: string,
        price: number | undefined
    ): void => {
        const fixedPrices = props.fixedPrices.slice();
        fixedPrices.find(
            fixedPrice => fixedPrice.petType.id === petTypeId
        )!.price = price;
        props.onChange(fixedPrices);
    };

    const onBlurChangePriceHandler = () => {
        if (depositType !== 'percentage') {
            if (
                props.fixedPrices.some(
                    fixedPrice =>
                        fixedPrice.price !== undefined &&
                        fixedPrice.price < depositAmount
                )
            ) {
                setShowAlert(true);
            } else {
                setShowAlert(false);
            }
        }
    };
    const prices = props.fixedPrices.map((fixedPrice, index) => {
        return (
            <Box
                key={fixedPrice.petType.id}
                className={clsx({
                    [classes.nextPetTypePriceContainer]: index > 0
                })}
            >
                <Box>
                    {!isEmpty(props.errors) &&
                    (fixedPrice.price === undefined ||
                        fixedPrice.price === 0) ? (
                        <Typography className={classes.errorMessage}>
                            Price for {fixedPrice.petType.plural.toLowerCase()}{' '}
                            is required
                        </Typography>
                    ) : (
                        <Typography className={classes.title}>
                            {'Price for ' + fixedPrice.petType.plural}
                        </Typography>
                    )}
                </Box>
                <Box className={classes.priceContainer}>
                    <PriceField
                        id={
                            props.id
                                ? props.id +
                                  '_input_price_' +
                                  fixedPrice.petType.plural +
                                  '_' +
                                  index
                                : props.id
                        }
                        value={fixedPrice.price}
                        error={
                            !isEmpty(props.errors) &&
                            (fixedPrice.price === undefined ||
                                fixedPrice.price === 0)
                        }
                        onChange={price => {
                            priceChangeHandler(fixedPrice.petType.id, price);
                        }}
                        onBlur={onBlurChangePriceHandler}
                        placeholder="$0.00"
                        className={classes.price}
                    />
                </Box>
            </Box>
        );
    });

    const iconSVG = (icon: IconDefinition, styles: string) => {
        return <FontAwesomeIcon icon={icon} className={styles} />;
    };

    const depositAlert = (message: string) => (
        <Box
            className={clsx(classes.depositAlert, {
                [classes.showAlert]: showAlert === true
            })}
        >
            <Box className={classes.iconInfo}>
                <Box className={classes.boxIcon}>
                    {iconSVG(faInfoCircle, `${classes.sectionIconInfo}`)}
                </Box>
            </Box>
            <Box
                className={classes.spanMessage}
                dangerouslySetInnerHTML={{ __html: message }}
            />
        </Box>
    );

    return (
        <Box>
            <Box className={classes.container}>{prices}</Box>
            <Box className={classes.container}>
                {props.isMultiplePet === true &&
                    depositAlert(
                        'The price entered for <b>Dogs or Cats</b> is lower than the price you have set for your deposits.'
                    )}
                {props.isMultiplePet === false &&
                    depositAlert(
                        'The price entered is lower than the price you have set for your deposits.'
                    )}
            </Box>
        </Box>
    );
};

export default FixedPrice;
