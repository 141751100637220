import React, { FunctionComponent, Fragment, MouseEvent, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ConfirmDialog } from "components/UI";
import { wbp, reduceResolution } from "Theme";
import clsx from "clsx";

export interface Props {
  id?: string;
  icon: IconProp;
  onConfirmedClick: () => void;
  text: string;
  color?: string;
  title?: JSX.Element;
  confirmQuestion: JSX.Element;
  confirmSubquestion?: JSX.Element,
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  isDetail?: boolean;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      width: "max-content",
      [theme.breakpoints.down(wbp)]: {
        paddingTop: `${reduceResolution(8)}px`,
        paddingBottom: `${reduceResolution(8)}px`,
      },
      [theme.breakpoints.up(wbp)]: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
    icon: {
      width: "15px",
      [theme.breakpoints.down("lg")]: {
        marginRight: "7px",
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: "10px",
      },
    },
    title: {
      whiteSpace: "nowrap",
      marginLeft: 2,
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: "16px",
      },
    },
    detail: {
      color: "#EF4F57"
    }
  })
);

export const ToolbarItemWithConfirmation: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(false);

  const clickHandler = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setShowDialog(true);
  }

  const cancelHandler = () => {
    setShowDialog(false);
  }

  const confirmHandler = () => {
    setShowDialog(false);
    props.onConfirmedClick();
  }

  return (
    <Fragment>
      <Box className={classes.item} onClick={clickHandler} id={props.id}>
        <FontAwesomeIcon color={props.color} icon={props.icon} className={clsx(classes.icon, {[classes.detail]: props.isDetail})} />
        <Typography style={{ color: props.color || "" }} className={clsx(classes.title, {[classes.detail]: props.isDetail})}>{props.text}</Typography>
      </Box>
      <ConfirmDialog
        id={props.id}
        open={showDialog}
        title={props.title}
        question={props.confirmQuestion}
        subquestion={props.confirmSubquestion}
        confirmButtonLabel={props.confirmButtonLabel || "Confirm"}
        cancelButtonLabel={props.cancelButtonLabel || "Cancel"}
        onCancel={cancelHandler}
        onConfirm={confirmHandler}
      />
    </Fragment>
  );
};

export default ToolbarItemWithConfirmation;
