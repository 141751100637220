import { Table as TableMUI, TableContainer } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { BookingWrapper, SortFields, SortOrder } from '@spike/booking-model';
import clsx from 'clsx';
import { AvailableStaff } from '@spike/available-staff-model';
import { FunctionComponent } from 'react';
import { BookingsView } from '@spike/bookings-view';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import TableNotFound from './TableNotFound';
import TableSpinner from './TableSpinner';

interface Props {
    wrappers: Array<BookingWrapper>;
    view: BookingsView;
    viewClient?: boolean;
    loading?: boolean;
    className?: string;
    onShowBooking: (bookingId: number, appointmentId: number) => void;
    onConfirm: (bookingId: number) => void;
    onShowAppointment: (appointmentId: number) => void;
    onAssignStaff: (
        appointmentId: number,
        member: AvailableStaff | undefined
    ) => void;
    onCheckIn: (appointmentId: number) => void;
    onStart: (appointmentId: number) => void;
    onComplete: (appointmentId: number, businessAreaId: string) => void;
    onCheckout: (appointmentId: number) => void;
    onShowReport: (appointmentId: number, businessAreaId: string, clientId?: number) => void;
    onCancel: (appointmentId: number) => void;
    onCancelBooking: (bookingId: number) => void;
    onNoShow: (appointmentId: number) => void;
    onShowInvoice: (appointmentId: number) => void;
    onShowPet: (petId: number) => void;
    onSort: (field: SortFields, order: SortOrder) => void;
    onReschedule: (appointmentId: number) => void;
    onRescheduleBooking: (bookingId: number) => void;
    onBook: (clientId: number, petId: number, name: string) => void;
    onDecline: (appointmentId: number, businessAreaId: string) => void;
    onUndoAppointment: (appointmentId: number, statusId: number) => void;
    onShowClient: (clientId: number) => void;
    onShowAddReport?: (appointmentId: number, businessAreaId: string, clientId: number) => void;
    onShowAddReportBooking?: (
        bookingId: number,
        appointmentId: number,
        businessAreaId: string,
        clientId: number
    ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            display: 'flex',
            width: '100%',
            overflowX: 'visible',

            [theme.breakpoints.down('sm')]: {
                overflow: 'hidden',
                padding: '0px 16px'
            }
        }
    })
);

export const Table: FunctionComponent<Props> = props => {
    const classes = useStyles();

    return (
        <TableContainer
            className={clsx(classes.tableContainer, props.className)}
        >
            <TableMUI>
                <TableHeader
                    viewClient={props.viewClient}
                    onSort={props.onSort}
                />
                {props.loading ? (
                    <TableSpinner />
                ) : props.wrappers.length > 0 ? (
                    <TableBody
                        wrappers={props.wrappers}
                        view={props.view}
                        viewClient={props.viewClient}
                        onShowBooking={(
                            bookingId: number,
                            appointmentId: number
                        ) => props.onShowBooking(bookingId, appointmentId)}
                        onConfirm={props.onConfirm}
                        onShowAppointment={props.onShowAppointment}
                        onAssignStaff={props.onAssignStaff}
                        onCheckIn={props.onCheckIn}
                        onStart={props.onStart}
                        onComplete={props.onComplete}
                        onCheckout={props.onCheckout}
                        onShowReport={props.onShowReport}
                        onCancel={props.onCancel}
                        onCancelBooking={props.onCancelBooking}
                        onNoShow={props.onNoShow}
                        onShowInvoice={props.onShowInvoice}
                        onShowPet={props.onShowPet}
                        onShowClient={props.onShowClient}
                        onReschedule={props.onReschedule}
                        onRescheduleBooking={props.onRescheduleBooking}
                        onBook={props.onBook}
                        onDecline={props.onDecline}
                        onUndoAppointment={props.onUndoAppointment}
                        onShowAddReport={props.onShowAddReport!}
                        onShowAddReportBooking={props.onShowAddReportBooking}
                    />
                ) : (
                    <TableNotFound
                        title={props.view.emptyMessage.title}
                        description={props.view.emptyMessage.description}
                    />
                )}
            </TableMUI>
        </TableContainer>
    );
};

export default Table;
