import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';

export interface Props {
	icon: IconProp;
	onClick: () => void;
	text: string;
	color?: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		item: {
			display: 'flex',
			cursor: 'pointer',
			alignItems: 'center',
			[theme.breakpoints.down('lg')]: {
				paddingTop: '6px',
				paddingBottom: '6px'
			},
			[theme.breakpoints.up('xl')]: {
				paddingTop: '8px',
				paddingBottom: '8px'
			}
		},
		icon: {
			width: '15px',
			[theme.breakpoints.down('lg')]: {
				marginRight: '7px'
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: '10px'
			}
		},
		title: {
			whiteSpace: 'nowrap',
			marginLeft: 2,
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px'
			}
		},
		iconHistory: {
			margin: 0,
			marginLeft: -4,
			[theme.breakpoints.down('lg')]: {
				marginRight: '7px'
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: '10px'
			}
		},
		titleHistoty: {
			alignItems: 'center',
			marginLeft: -1,
			whiteSpace: 'nowrap',
			[theme.breakpoints.down('lg')]: {
				fontSize: '12px'
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '16px'
			}
		}
	})
);

export const ToolbarItemClientsHistory: FunctionComponent<Props> = ({
	icon,
	onClick,
	text,
	color
}) => {
	const classes = useStyles();
	return (
		<Box className={classes.item} onClick={onClick}>
			<FontAwesomeIcon
				color={color}
				icon={icon}
				className={classes.iconHistory}
			/>
			<Typography style={{ color }} className={classes.titleHistoty}>
				{text}
			</Typography>
		</Box>
	);
};

export const ToolbarItemClients: FunctionComponent<Props> = ({
	icon,
	onClick,
	text,
	color
}) => {
	const classes = useStyles();
	return (
		<Box className={classes.item} onClick={onClick}>
			<FontAwesomeIcon
				color={color}
				icon={icon}
				className={classes.icon}
			/>
			<Typography style={{ color }} className={classes.title}>
				{text}
			</Typography>
		</Box>
	);
};

export default ToolbarItemClients;
