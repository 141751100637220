import React, { FunctionComponent, useEffect, useState } from 'react';
import PageSidebar, { PageSidebarItem } from 'components/PageSidebar';
import ServicesComponent from 'components/Services';
import PrivatePage from './PrivatePage';
import { faList, faPuzzlePiece } from '@fortawesome/pro-light-svg-icons';
import AddOnServices from 'components/AddOnServices';
import { useLocation } from 'react-router-dom';

interface ServicesProps {
	create: string;
}

const PrimaryItem = {
	icon: faList,
	title: 'Primary Services',
	key: 'primary'
};

const AddOnsItem = {
	icon: faPuzzlePiece,
	title: 'Add-Ons',
	key: 'add_ons'
};

const sidebarItems: Array<PageSidebarItem> = [PrimaryItem, AddOnsItem];

export const Services: FunctionComponent<ServicesProps> = props => {
	const location = useLocation();

	const [selectedKey, setSelectedKey] = useState<string>(PrimaryItem.key);

	const sidebar = (
		<PageSidebar
			title="Services"
			items={sidebarItems}
			selectedKey={selectedKey}
			onSelect={setSelectedKey}
		/>
	);

	useEffect(() => {
		setSelectedKey(location.hash.replace('#', '') || PrimaryItem.key);
	}, [location]);

	return (
		<PrivatePage
			title="Services - Cuddles"
			pageName="/services"
			pageSidebar={sidebar}
		>
			{selectedKey === PrimaryItem.key && (
				<ServicesComponent {...props} />
			)}
			{selectedKey === AddOnsItem.key && <AddOnServices />}
		</PrivatePage>
	);
};

export default Services;
