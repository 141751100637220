import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '../UI/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';
import Service from '../../model/Service';
import clsx from 'clsx';

interface Props {
    isEditMode: boolean;
    service: Service;
    onOk: () => void;
    onAddStaff: () => void;
    onDoLater: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        },
        content: {
            width: '100%',
            display: 'flex',
            padding: '0px 20px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                flex: 1
            }
        },
        checkIcon: {
            'width': 82,
            'height': 82,
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'backgroundColor': '#5E8677',
            'borderRadius': '50%',
            'marginBottom': 22,
            'paddingTop': 6,

            '& svg': {
                fontSize: 41,
                color: '#fff'
            }
        },
        title: {
            fontSize: 26,
            lineHeight: 1,
            fontWeight: 600,
            marginBottom: 12,

            [theme.breakpoints.up('md')]: {
                fontSize: 70
            }
        },
        description: {
            fontSize: 16,
            lineHeight: 1.4,
            textAlign: 'center',
            width: '100%',

            [theme.breakpoints.up('md')]: {
                fontSize: 30
            }
        },
        buttons: {
            padding: '24px 16px',
            width: '100%',

            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center'
            }
        },
        button: {
            'height': 55,
            'width': '100%',
            'borderRadius': 30,

            [theme.breakpoints.up('md')]: {
                width: 'auto',
                minWidth: 210
            },

            '& span': {
                fontSize: 16,
                fontWeight: 600
            }
        }
    })
);

export const Success: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const serviceHasStaff = props.service.staff.length > 0;

    const okButton = (
        <Button
            label="OK"
            color="black"
            onClick={props.onOk}
            className={classes.button}
            id="services_success_button_go_to_services"
        />
    );

    const servicesButton = (
        <Button
            color="black"
            label="Go to Services"
            id="services_success_button_go_to_services"
            onClick={props.onOk}
            className={clsx(classes.button)}
        />
    );

    const button = !serviceHasStaff ? okButton : servicesButton;

    return (
        <Box className={classes.container}>
            <Box className={classes.content}>
                <Box className={classes.checkIcon}>
                    <FontAwesomeIcon icon={faCheck} />
                </Box>
                <Typography className={classes.title}>
                    {serviceHasStaff ? 'Good Job!' : 'Success'}
                </Typography>
                {serviceHasStaff && (
                    <Typography className={classes.description}>
                        <strong>{props.service.name}</strong>&nbsp;was{' '}
                        {props.isEditMode ? 'edited' : 'added'}&nbsp;
                        succesfully.
                    </Typography>
                )}
            </Box>
            <Box className={classes.buttons}>{button}</Box>
        </Box>
    );
};

export default Success;
