import { Period } from '@spike/model';
import {
    fetchPaymentsWithoutPaginationThunk,
    fetchRefundsWithoutPaginationThunk,
    PaymentsStatus
} from '@spike/payments-action';
import { Payment, Refund } from '@spike/payments-model';
import ExportCSV from 'components/UI/V2/ExportCSV';
import { useApiClientWrapper } from 'hooks';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

interface TransactionsExportCSVProps {
    period: Period;
    selectedCategory: string;
    clientId: number | null;
}

const SELECTED_CATEGORY = 'paid';

const TransactionsExportCSV: FunctionComponent<
    TransactionsExportCSVProps
> = props => {
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const paymentsWithoutPagination = useSelector<RootState, Array<Payment>>(
        state => state.payments.paymentsWithoutPagination
    );
    const refundsWithoutPagination = useSelector<RootState, Array<Refund>>(
        state => state.payments.refundsWithoutPagination
    );
    const paymentsStatus = useSelector<RootState, PaymentsStatus>(
        state => state.payments.status
    );

    useEffect(() => {
        if (props.selectedCategory === SELECTED_CATEGORY) {
            dispatch(
                fetchPaymentsWithoutPaginationThunk(
                    apiClientWrapper,
                    props.clientId,
                    props.period
                )
            );
        } else {
            dispatch(
                fetchRefundsWithoutPaginationThunk(
                    apiClientWrapper,
                    props.clientId,
                    props.period
                )
            );
        }
    }, [props.clientId, props.period, props.selectedCategory]);

    const csvFileName = `${props.selectedCategory}_${
        props.period?.from?.format('YYYY-MM-DD') || 'all'
    }_${props.period?.to?.format('YYYY-MM-DD') || 'time'}.csv`;

    const paymentsCSVHeaders = [
        {
            label: 'Date',
            key: 'date'
        },
        {
            label: 'Invoice',
            key: 'invoice'
        },
        {
            label: 'Client',
            key: 'client'
        },
        {
            label: 'Paid Type',
            key: 'paidType'
        },
        {
            label: 'Amount',
            key: 'amount'
        },
        {
            label: 'Status',
            key: 'status'
        }
    ];

    const paymentsCSVData = paymentsWithoutPagination?.map(item => {
        return {
            date: item.paidAt.format('YYYY-MM-DD'),
            invoice: item.invoice.number,
            client: item.customer.name,
            paidType: item.paymentMethod.id,
            amount: `${item.amount}`,
            status: item.status.name
        };
    });

    const refundsCSVHeaders = [
        {
            label: 'Date of Refund',
            key: 'dateOfRefund'
        },
        {
            label: 'Invoice',
            key: 'invoice'
        },
        {
            label: 'Client',
            key: 'client'
        },
        {
            label: 'Paid Type',
            key: 'paidType'
        },
        {
            label: 'Amount',
            key: 'amount'
        },
        {
            label: 'Note',
            key: 'note'
        },
        {
            label: 'Status',
            key: 'status'
        }
    ];

    const refundsCSVData = refundsWithoutPagination?.map(item => {
        return {
            dateOfRefund: item.refundDate.format('YYYY-MM-DD'),
            invoice: item.invoice.number,
            client: `${item.customer.firstName} ${item.customer.lastName}`,
            paidType: item.payment.paymentMethod.id,
            amount: `${item.amount}`,
            note: item.reason ?? '',
            status: item.status.name
        };
    });

    const isLoading =
        paymentsStatus === PaymentsStatus.FetchingPaymentsWithoutPagination ||
        paymentsStatus === PaymentsStatus.FetchingRefundsWithoutPagination;

    return (
        <ExportCSV
            label="Export"
            onLoading={isLoading}
            fileName={csvFileName}
            data={
                props.selectedCategory === SELECTED_CATEGORY
                    ? paymentsCSVData
                    : refundsCSVData
            }
            headers={
                props.selectedCategory === SELECTED_CATEGORY
                    ? paymentsCSVHeaders
                    : refundsCSVHeaders
            }
        />
    );
};

export default TransactionsExportCSV;
